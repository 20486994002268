import StandardNavbar from "parts/navbar/standardNavbar";
import PassportProcessingHeader from "./component/passportProcessingHeader";
import PassportProcessingInfo from "./component/passportProcessingInfo";
import QuickLinks from "parts/quickLinks";
const PassportProcessingAndRenewal = () => {
  return (
    <section className="passport-process-page">
      <StandardNavbar />
      <PassportProcessingHeader />
      <PassportProcessingInfo />
      <QuickLinks />
    </section>
  );
};

export default PassportProcessingAndRenewal;
