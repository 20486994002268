import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//others
import { useToggleLoading } from "hooks";
import { GetRequest } from "functions/http";
import { AUTH } from "config/endpoints";
import RouteConstant from "./constant";
//redux
import { setUser, setStatus, setToggleLogin } from "state/slice/user";

import PropTypes from "prop-types";

const PrivateRoute = ({ children }) => {
  const Dispatch = useDispatch();
  const toggleLoading = useToggleLoading();
  const auth = useSelector((data) => data.auth);
  const authStatus = auth.status;
  const isLoggedIn = auth.isLoggedIn;
  const location = useLocation();
  useEffect(() => {
    let getUser = async () => {
      if (authStatus !== "idle") return;
      if (localStorage.getItem("token") && !isLoggedIn) {
        Dispatch(setStatus("loading"));
        toggleLoading(true);
        GetRequest(AUTH)
          .then((res) => {
            if (res.data.status) {
              Dispatch(
                setUser({
                  ...res.data.data,
                  id: res.data.data._id,
                })
              );
            }
            Dispatch(setStatus("success"));
          })
          .catch((er) => {
            Dispatch(setToggleLogin(false));
            Dispatch(setStatus("failed"));
          })
          .finally(() => toggleLoading(false));
      } else if (isLoggedIn) {
        Dispatch(setStatus("success"));
      } else {
        Dispatch(setStatus("failed"));
        Dispatch(setToggleLogin(false));
      }
    };
    getUser();
    return () => (getUser = null);
  }, [isLoggedIn, authStatus]);
  if (authStatus === "idle" || authStatus === "loading") {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to={
          RouteConstant.ACCESS_DENIED +
          "?redirect_url=" +
          location.pathname +
          location.search
        }
      />
    );
  }
  return children;
};
PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
export default PrivateRoute;
