import { Box, Button, TextField } from "@mui/material";
import { GetRequest, PostRequest } from "functions/http";
import { useEffect, useState } from "react";
import { UPDATE_CURRENCY_RATE, GET_CURRENCY_RATE } from "config/endpoints";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { toast } from "react-toastify";
const CurrencySetting = () => {
  const toggleLoading = useToggleLoading();
  const [rate, setRate] = useState(null);
  useEffect(() => {
    toggleLoading(true);
    let fetchData = () => {
      GetRequest(GET_CURRENCY_RATE)
        .then((res) => {
          if (res) {
            setRate(res?.data?.data?.USD_TO_UGX);
          } else {
            setRate(null);
            // toast.error(res.data.msg || "Something went wrong.");
          }
        })
        .catch((er) => {
          setRate(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, []);
  const handleSubmit = () => {
    if (!rate) {
      toast.error("Please enter currency conversation rate.");
      return;
    }
    toggleLoading(true);
    PostRequest(UPDATE_CURRENCY_RATE, { USD_TO_UGX: rate })
      .then((res) => {
        if (res) {
          toast.success("Currency conversation rate updated.");
        } else {
          toast.error(res.data.msg || "Something went wrong.");
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  return (
    <Box my={3} display="flex" flexWrap="wrap">
      <TextField
        value={rate || 0}
        onChange={(ev) => setRate(ev.target.value)}
        inputProps={{ min: 0 }}
        type="number"
        sx={{ ml: 2, mt: 2, maxWidth: 350, width: 1 }}
        label="USD to UGX Currency rate"
        placeholder="Enter UGX currency rate"
      />
      <Button variant="contained" sx={{ ml: 2, mt: 2 }} onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};

export default CurrencySetting;
