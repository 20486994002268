import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import BgImage from "assets/img/static/bg/apply-for-visa.png";
import { Link } from "react-router-dom";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import Search from "component/search";
import Faq from "parts/faq";

const FaqList = () => {
  return (
    <Box className="container" py={5}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              background: `radial-gradient(86.68% 193.95% at 13.4% 13.32%, #FFFFFF 0%, #E8E8E8 100%)`,
            }}
            position="relative"
            minHeight={{
              xs: 550,
              sm: 600,
            }}
          >
            <Box
              className="resImg"
              sx={{ position: "absolute", bottom: 0, right: 0 }}
            >
              <img src={BgImage} alt="Apply for Visa" />
            </Box>
            <Box p={3}>
              <Typography variant="h5" fontWeight={600} color="black.main">
                Let’s apply for a{" "}
              </Typography>

              <Typography
                variant="h4"
                fontSize={60}
                fontWeight={800}
                color="primary"
                textTransform="uppercase"
                fontStyle="italic"
              >
                VISA
              </Typography>
              <Typography variant="body1" fontWeight={600}>
                <Link to="">Apply Now</Link>
              </Typography>
            </Box>
            <Box
              position="absolute"
              bottom={30}
              left={0}
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                startIcon={<HeadsetMicIcon />}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#5B5B5BAB",
                  "&:hover": {
                    backgroundColor: "#5B5B5BAB",
                  },
                }}
              >
                Still Have A Question? Contact support
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ color: "#414141" }}
          >
            <Search
              style={{
                backgroundColor: "#F8F8F8",
                minWidth: {
                  xs: "unset",
                  md: 400,
                },
              }}
              iconColor="inherit"
              placeholder="Search For Question"
              inputStyle={{
                color: "#414141",
              }}
            />
          </Box>
          <Box pt={5}>
            <Faq
              data={[
                {
                  title: "What is a visa?",
                  desc: `A visa is a document that allows a person to enter a foreign country for a specific purpose, such as tourism, business, or study. A visa typically specifies the length of time that a person is permitted to stay in the country, as well as the activities they are allowed to engage in while they are there.`,
                },
                {
                  title: "What types of visas are available?",
                  desc: `There are many different types of visas available, and the type of
                    visa you need will depend on the purpose of your trip and the length
                    of your stay. Some common types of visas include tourist visas,
                    business visas, student visas, and work visas.`,
                },
                {
                  title: "How do I apply for a visa?",
                  desc: `The process for applying for a visa can vary depending on the
                    country you are traveling to and the type of visa you need. In
                    general, you will need to fill out an application form and submit it
                    along with supporting documents, such as a passport and proof of
                    travel arrangements. You may also need to pay a fee to apply for a
                    visa.`,
                },
                {
                  title: "How long does it take to get a visa?",
                  desc: `The processing time for a visa can vary depending on the country you
                    are traveling to and the time of year. In some cases, visas may be
                    issued within a few days, while in other cases it may take several
                    weeks. It is important to apply for your visa well in advance of
                    your planned trip to ensure that you have enough time to receive
                    your visa before you travel.`,
                },
                {
                  title:
                    "Can I apply for a visa on my own, or do I need to use a visa center?",
                  desc: `While it is possible to apply for a visa on your own, using a visa
                    center can save you time and ensure that your application is
                    completed accurately. Visa centers typically provide information and
                    assistance to help you complete your application, and may also
                    handle the submission of your application on your behalf.`,
                },
                {
                  title:
                    "Is it possible to apply for a visa through 3rd party services ? ",
                  desc: `In addition to helping with visa applications, many visa centers
                    also offer other services, such as passport processing and renewal,
                    translation services, and notarization services. These services can
                    be helpful if you need to obtain or renew a passport, or if you need
                    to have documents translated or notarized for your trip`,
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FaqList;
