import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

export default function Faq({ data }) {
  return (
    <Box sx={{ color: "#fff" }}>
      {data?.map((item, i) => (
        <Accordion
          key={i}
          sx={{
            boxShadow: "none",
            marginBottom: 2,
            ":before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            sx={{
              padding: "10px 0",
              borderBottom: "1px solid transparent",
              minHeight: "unset",
              color: ({ palette }) => palette?.grey[500],

              "&.Mui-expanded": {
                borderColor: ({ palette }) => palette?.primary?.main,
                color: ({ palette }) => palette?.primary?.main,
                minHeight: "unset",
                margin: 0,
              },
              ".MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            <Typography variant="subtitle1" color="inherit" fontWeight={600}>
              <Box component="span" mr={3}>
                {i + 1 < 10 ? `0${i + 1}` : i + 1}
              </Box>
              {item?.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 2 }}>
            <Typography variant="body1">{item?.desc}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
