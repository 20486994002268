import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const StandardCard = ({
  title,
  titleColor,
  description,
  link,
  linkText,
  linkType,
  tag,
  tagColor,
}) => {
  return (
    <Box className="standard-card" p={{ xs: 2, lg: 5 }}>
      {tag ? (
        <Box className="tag" mb={2}>
          <Typography variant="caption" color={tagColor || "inherit"}>
            {tag}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <Typography
        variant="h6"
        color={titleColor || "inherit"}
        fontWeight={700}
        mb={2}
        className="title"
      >
        {title}
      </Typography>
      <Typography variant="body2" fontWeight={400}>
        {description}
      </Typography>
      {link && linkText ? (
        <Typography variant="body2" mt={3}>
          {linkType !== "external" ? (
            <Link to={link}>{linkText}</Link>
          ) : (
            <a href={link} target="_blank">
              {linkText}
            </a>
          )}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default StandardCard;
