import RenderCellExpand from "component/grid/expandCell";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import RouteConstant from "navigation/constant";
import moment from "moment";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const AgencyBulkTableColumns = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },

  {
    field: "bulkId",
    minWidth: 250,
    headerName: "Bulk Id",
    renderCell: RenderCellExpand,
  },
  {
    field: "totalApplied",
    headerName: "Total Applications",
  },

  {
    field: "submitted",
    headerName: "Submitted",
    renderCell: ({ value }) => {
      return value ? (
        <Chip color="success" label="Submitted" size="small" />
      ) : (
        <Chip color="warning" label="Not Submitted" size="small" />
      );
    },
  },
  {
    field: "confirmed",
    headerName: "Confirmed",
    renderCell: ({ value }) => {
      return value ? (
        <Chip color="success" label="Confirmed" size="small" />
      ) : (
        <Chip color="warning" label="Not Confirmed" size="small" />
      );
    },
  },
  {
    field: "total",
    headerName: "Total Charges",
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    minWidth: 150,
    cellClassName: "font-tabular-nums",
  },
  {
    field: "paymentMethod",
    headerName: "Payment Mode ",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "isPaid",
    headerName: "Payment",
    renderCell: ({ value }) => {
      return value ? (
        <Chip color="success" label="Paid" size="small" />
      ) : (
        <Chip color="error" label="Pending" size="small" />
      );
    },
  },
  {
    field: "date",
    minWidth: 100,
    headerName: "Date",
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("D MMM Y") : "NA"}</span>;
    },
  },
  {
    field: "detail",
    headerName: "Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.BULK_ALL_APPLICATION + "/" + value}>Details</Link>
    ),
  },
];

export default AgencyBulkTableColumns;
