import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import BgImage from "assets/img/static/bg/uae-help.png";

const UAEHelp = () => {
  return (
    <Box
      color="white.main"
      sx={{
        backgroundColor: ({ palette }) => palette?.primary?.main,
        mt: "-1px",
      }}
      position="relative"
    >
      <Box
        sx={{
          backgroundImage: `url(${BgImage})`,
          backgroundSize: "contain",
          zIndex: 1,
          position: "absolute",
          right: 0,
          width: "70%",
          height: "100%",
          bottom: 0,
          backgroundPosition: "bottom right",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box className="container">
        <Grid container position="relative">
          <Grid item xs={12} md={6}>
            <Box py={15}>
              <Typography
                variant="body1"
                textTransform="uppercase"
                fontWeight={500}
                letterSpacing={3}
              >
                HOW WE HELP YOU WITH
              </Typography>

              <Typography
                variant="h4"
                fontWeight={600}
                fontSize={{ xs: 42, sm: 60 }}
                my={1}
              >
                UAE services
              </Typography>

              <Typography variant="body1" fontWeight={400} my={2}>
                Obtaining a visa for the UAE can be a complex and time-consuming
                process, with different requirements for different types of
                visas. At ExpressVFS, we offer comprehensive visa services for
                the UAE, including tourist visas, work visas, residency visas,
                and more.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UAEHelp;
