import { Box, IconButton, Typography } from "@mui/material";
import TransparentNavbar from "parts/navbar/transparentNavbar";
import { Link, useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

import RouteConstant from "navigation/constant";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

const Resources = () => {
  const navigate = useNavigate();

  return (
    <section className="resources-page">
      <div className=" banner-header resources-bg">
        <TransparentNavbar />
        <div className="banner-header-overlay"></div>
        <div className="banner-header-container container">
          <div className="banner-header-content ">
            <IconButton onClick={() => navigate(-1)}>
              <WestIcon sx={{ fontSize: 38, color: "#fff" }} />
            </IconButton>
            <h3 className="bannerHeading">Resources</h3>
            <p>
              Get hands on all the resource that help for your successfully trip
            </p>
          </div>
        </div>
      </div>
      <Box className="bg-dark" py={10}>
        <div className="container">
          <Typography variant="body1" color="white.main" mb={2}>
            Welcome to the resources tab of the Express Visa Facilitation Service
            Centre!
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Here, you'll find a range of resources to help you navigate the
            process of applying for a data analytics visa and make the most of
            your time in [country].
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            First, we recommend checking out our comprehensive guide to data
            analytics visas. This guide covers everything you need to know about
            the application process, including eligibility requirements,
            documentation, and how to prepare for your visa interview.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Next, take a look at our list of top data analytics programs in
            [country]. These programs are renowned for their excellence in data
            analytics education and research, and many of them offer internship
            and job opportunities for international students.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            We also have a list of top data analytics companies in [country]
            that are actively hiring international talent. These companies are
            at the forefront of data analytics and offer exciting career
            opportunities for those with the right skills and experience.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            In addition to these resources, we also offer a range of services to
            help you succeed in your data analytics career. These include resume
            and cover letter review, job search assistance, and networking
            events with industry professionals.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            We hope these resources are helpful as you pursue your data
            analytics career in [country]. If you have any further questions,
            don't hesitate to reach out to us for assistance.
          </Typography>
          <Box my={4}>
            <Typography variant="caption" color="white.main">
              Quick Links
            </Typography>
            <Box display="flex" mt={2}>
              <Typography color="white.main" mr={3}>
                <Link to={RouteConstant.APPLY_VISA} className="bright-link">
                  Apply For Visa <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
              <Typography color="white.main" mr={3}>
                <Link to={RouteConstant.FAQ} className="bright-link">
                  FAQ <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
              <Typography color="white.main">
                <Link to={RouteConstant.CONTACT_US} className="bright-link">
                  Contact Us <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
            </Box>
          </Box>
        </div>
      </Box>
    </section>
  );
};

export default Resources;
