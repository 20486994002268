import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { HomeContactModel } from "_models/data/data.homeContact.model";
import { ErrorHomeContactModel } from "_models/error/errors.homeContact.model";
import FacebookIcon from "assets/svgIcons/facebookIcon";
import InstagramIcon from "assets/svgIcons/instagramIcon";
import LinkedinIcon from "assets/svgIcons/linkedinIcon";
import TwitterIcon from "assets/svgIcons/twitterIcon";
import { Fragment, useState } from "react";

const HomeContactUs = () => {
  const [state, setState] = useState(new HomeContactModel());
  const [error, setError] = useState(new ErrorHomeContactModel());

  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const inputList = [
    { type: "text", name: "name", label: "Name" },
    { type: "text", name: "emailPhone", label: "Email or Phone Number" },
    {
      type: "select",
      name: "serviceType",
      label: "Service Type",
      options: [
        { value: "1", label: "One" },
        { value: "2", label: "Two" },
      ],
    },
  ];

  const socialList = [
    { Icon: InstagramIcon, link: "" },
    { Icon: TwitterIcon, link: "" },
    { Icon: LinkedinIcon, link: "" },
    { Icon: FacebookIcon, link: "" },
  ];
  return (
    <Box className="home-contact-bg">
      <Box className="container">
        <Grid container spacing={5} justifyContent="flex-end">
          <Grid item xs={12} md={6}>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box p={5} sx={{ backgroundColor: "#fff" }}>
                    <Typography variant="h5" fontWeight={500} mb={2}>
                      Contact Us
                    </Typography>
                    <Box
                      component="form"
                      fontWeight={300}
                      onSubmit={handleSubmit}
                    >
                      {inputList?.map(({ type, name, label, options }, i) => (
                        <Fragment key={i}>
                          <FormControl fullWidth margin="dense">
                            {type === "text" ? (
                              <TextField
                                label={label}
                                placeholder={label}
                                error={error?.[name] !== ""}
                                helperText={error?.[name]}
                                onFocus={() => handleFocus(name)}
                                value={state?.[name]}
                                name={name}
                                onChange={(ev) => handleChange(ev, name)}
                                InputLabelProps={{
                                  sx: {
                                    color: ({ palette }) => palette?.grey[500],
                                  },
                                }}
                              />
                            ) : type === "select" ? (
                              <>
                                <InputLabel
                                  id={name}
                                  sx={{
                                    color: ({ palette }) =>
                                      error?.[name]
                                        ? palette?.error?.main
                                        : palette?.grey[500],
                                  }}
                                >
                                  {label}
                                </InputLabel>
                                <Select
                                  labelId={name}
                                  placeholder={label}
                                  label={label}
                                  error={error?.[name] !== ""}
                                  onFocus={() => handleFocus(name)}
                                  value={state?.[name]}
                                  name={name}
                                  onChange={(ev) => handleChange(ev, name)}
                                >
                                  {options?.map((option, index) => (
                                    <MenuItem key={index} value={option?.value}>
                                      {option?.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {error?.[name] ? (
                                  <FormHelperText
                                    sx={{
                                      color: ({ palette }) =>
                                        error?.[name]
                                          ? palette?.error?.main
                                          : "inherit",
                                    }}
                                  >
                                    {error?.[name]}
                                  </FormHelperText>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </FormControl>
                        </Fragment>
                      ))}

                      <Box mt={1}>
                        <Button
                          type="submit"
                          disableElevation
                          variant="contained"
                          className="rounded-2 text-capitalize"
                          sx={{ fontWeight: 300 }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>

                    <Box pt={5} className="d-flex justify-content-between">
                      {socialList?.map(({ Icon, link }, i) => (
                        <IconButton
                          key={i}
                          className="rounded-circle"
                          sx={{ width: 40, height: 40 }}
                        >
                          {" "}
                          <a href={link} target="_blank">
                            <Icon height={20} />
                          </a>
                        </IconButton>
                      ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box p={3} className="h-100" sx={{ backgroundColor: "#fff" }}>
                    <Typography variant="subtitle1">Locate Us</Typography>
                    <Typography variant="body2">
                      Plot 90 Kanjokya Street, Kanjokya House , Kampala , Uganda
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box p={3} className="h-100" sx={{ backgroundColor: "#fff" }}>
                    <Typography variant="subtitle1">Email us</Typography>

                    <Typography variant="body2">
                      <a href="mailto:info@expressvfs.com">info@expressvfs.com</a>{" "}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeContactUs;
