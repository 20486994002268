import {
  Box,
  Button,
  TextField,
  Dialog,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
const EmbassyFilter = ({
  element,
  value,
  onClose = () => {},
  onChange = () => {},
}) => {
  const theme = useTheme();

  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const [filter, setFilter] = useState(value);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  useEffect(() => {
    setFilterAnchorEl(element || null);
  }, [element]);
  const handleClose = () => {
    setFilterAnchorEl(null);
    onClose();
  };
  const handleClearFilter = () => {
    setFilter({ status: "", fromDate: null, toDate: null });
    handleClose();
  };
  useEffect(() => {
    onChange(filter);
  }, [filter]);
  return (
    <Dialog
      open={openFilter}
      onClose={handleClose}
      aria-labelledby="filter-dlg"
      aria-describedby="alert-dialog-description"
      fullWidth={mobileSize}
      sx={{ Width: 800 }}
    >
      <DialogTitle id="filter-dlg">
        <Box display="flex" justifyContent="space-between">
          Filter
          <Button onClick={handleClearFilter}>Clear</Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MenuItem onClick={handleClose}>
          <FormControl fullWidth>
            <InputLabel id="filter-dlg-select">Status</InputLabel>
            <Select
              labelId="filter-dlg-select"
              label="Status"
              value={filter.status}
              onChange={(ev) =>
                setFilter({ ...filter, status: ev.target.value })
              }
            >
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"denied"}>Denied</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              label="Date from"
              inputFormat="dd/MM/yyyy"
              value={filter.fromDate}
              onChange={(value) => setFilter({ ...filter, fromDate: value })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              label="Date to"
              inputFormat="dd/MM/yyyy"
              onChange={(value) => setFilter({ ...filter, toDate: value })}
              value={filter.toDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </MenuItem>
      </DialogContent>
    </Dialog>
  );
};
EmbassyFilter.propTypes = {
  element: PropTypes.object,
  value: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};
export default EmbassyFilter;
