import { useEffect, useState } from "react";
import {
  Checkbox,
  Grid,
  TextField,
  Autocomplete,
  Box,
  FormControlLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Countries from "state/data/countries";
import { ErrorCountrySettingModel } from "_models/error/errors.countrySettings.model";
import { CountrySettingModel } from "_models/data/data.countrySettings.model";
import { ValidateVisSettings } from "functions/validators";
import { GetRequest, PostRequest } from "functions/http";
import {
  ADD_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
  GET_COUNTRY_BY_ID,
} from "config/endpoints";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { fetchCountries } from "state/slice/countries";
import { useDispatch } from "react-redux";
const CountrySettings = ({
  updateId,
  newItem,
  canceled = () => {},
  refresh = () => {},
  ...props
}) => {
  const Dispatch = useDispatch();
  const toggleLoading = useToggleLoading();
  const [error, setError] = useState(new ErrorCountrySettingModel());
  const [state, setState] = useState(new CountrySettingModel());

  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  useEffect(() => {
    if (updateId) {
      toggleLoading(true);
      GetRequest(GET_COUNTRY_BY_ID(updateId))
        .then((res) => {
          if (res?.data?.status) {
            const _data = res.data?.data;
            const matchedCountry = Countries.findIndex(
              (el) => el.code === _data.code
            );
            const state_data = {
              country: Countries[matchedCountry],
              countryType: _data.countryType,
              tax: _data.visaCharges.tax || 0,
              charges: _data.visaCharges.charges,
              discount: _data.visaCharges.discount || 0,
              onlyDiscountForBulk: _data.visaCharges.onlyDiscountForBulk,
              minimumApplicationDis:
                _data.visaCharges.minimumApplicationDis || 0,
            };
            setState(new CountrySettingModel(state_data));
          } else {
            toast.error("No data found.");
            setState(new CountrySettingModel());
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      setState(new CountrySettingModel());
    }
  }, [updateId]);
  const handleSubmit = () => {
    const isValid = ValidateVisSettings(state);
    if (isValid.valid) {
      if (
        state.countryType === "toCountry" &&
        Number(state.discount) >= Number(state.charges) + Number(state.tax)
      ) {
        toast.error("Discount can not be more than charges.");
        return;
      }
      toggleLoading(true);
      const url = updateId ? UPDATE_COUNTRY(updateId) : ADD_COUNTRY;
      PostRequest(url, state)
        .then((res) => {
          if (res.data.status) {
            toast.success("Visa country updated");
            refresh();
            Dispatch(fetchCountries());
          } else {
            toast.error(res.data.msg || "Something went wrong.");
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  const handleDelete = () => {
    const sure = window.confirm("Are you sure to delete this country.");
    if (sure) {
      toggleLoading(true);
      PostRequest(DELETE_COUNTRY(updateId))
        .then((res) => {
          if (res.data.status) {
            refresh();
            toast.success("Visa country deleted");
            Dispatch(fetchCountries());
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    }
  };
  const handleCancel = () => {
    canceled();
  };

  return (
    <Box {...props}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Autocomplete
            disablePortal
            options={Countries}
            value={state.country}
            onChange={(e, value) => setState({ ...state, country: value })}
            autoHighlight
            getOptionLabel={(option) => option.label}
            disabled={updateId}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Country"
                error={error.countryError !== ""}
                onFocus={() => handleFocus("countryError")}
                helperText={error.countryError}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth error={error.countryTypeError !== ""}>
            <InputLabel id="country-type-label">Country type</InputLabel>
            <Select
              labelId="country-type-label"
              id="country-type-select"
              label="Country type"
              onFocus={() => handleFocus("countryTypeError")}
              value={state.countryType}
              name="countryType"
              onChange={(ev) => handleChange(ev, "countryType")}
            >
              <MenuItem value={"fromCountry"}>From Country</MenuItem>
              <MenuItem value={"toCountry"}>To Country</MenuItem>
            </Select>
          </FormControl>
          {error.countryTypeError && (
            <FormHelperText sx={{ color: "error.main" }}>
              {error.countryTypeError}
            </FormHelperText>
          )}
        </Grid>
        {state.countryType !== "fromCountry" ? (
          <>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Fees"
                placeholder="Enter Fees in $"
                variant="outlined"
                error={error.chargesError !== ""}
                helperText={error.chargesError}
                onFocus={() => handleFocus("chargesError")}
                name="charges"
                value={state.charges}
                onChange={(ev) => handleChange(ev, "charges")}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Tax"
                placeholder="Enter Tax in $"
                variant="outlined"
                error={error.taxError !== ""}
                helperText={error.taxError}
                onFocus={() => handleFocus("taxError")}
                name="tax"
                value={state.tax}
                onChange={(ev) => handleChange(ev, "tax")}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Discount"
                placeholder="Enter Discount in $"
                variant="outlined"
                name="discount"
                value={state.discount}
                onChange={(ev) => handleChange(ev, "discount")}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(ev) =>
                      setState({
                        ...state,
                        onlyDiscountForBulk: Boolean(ev.target.checked),
                      })
                    }
                    checked={state.onlyDiscountForBulk}
                  />
                }
                label="Discount Only for Bulk"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", my: 4 }}>
        <Button variant="contained" size="large" onClick={handleSubmit}>
          Submit
        </Button>
        {newItem ? (
          <Button
            variant="contained"
            size="large"
            onClick={handleCancel}
            color="error"
            sx={{ ml: 4 }}
          >
            Cancel
          </Button>
        ) : null}
        {updateId ? (
          <Button
            variant="contained"
            size="large"
            onClick={handleDelete}
            color="error"
            sx={{ ml: 4 }}
          >
            Delete
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default CountrySettings;
