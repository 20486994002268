import EnjazHeader from "./component/header";
import EnjazServices from "./component/services";
import EnjazVisaType from "./component/visaType";

const EnjazService = () => {
  return (
    <div className="enjaz-page">
      <EnjazHeader />
      <EnjazServices />
      <EnjazVisaType />
    </div>
  );
};

export default EnjazService;
