import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import Logo from "assets/img/static/icon/logo2.png";
const styles = StyleSheet.create({
  page: { padding: 10, marginLeft: "auto", marginRight: "auto" },
  img: { display: "flex", justifyContent: "center" },
  content: {
    backgroundColor: "#eeeeee",
    borderRadius: 12,
    padding: 12,
    marginTop: 20,
    display: "flex",
    flexWrap: "wrap",
  },
  bulkContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginRight: 20,
    marginBottom: 20,
  },
  smText: {
    fontSize: 11,
    marginTop: 6,
    fontWeight: "bold",
  },
  smTxt2: {
    fontSize: 11,
    fontWeight: "bold",
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginRight: 20,
    marginBottom: 20,
  },
  amountTxt: {
    fontSize: 12,
  },
  feesTextWrapper: {
    width: "100%",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    borderBottom: "1px solid #cecece",
    padding: 5,
    marginBottom: 20,
  },
});
const ApplicationReceipt = ({ application, isBulk, payment }) => {
  if (!application) return <></>;
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View>
          <View style={styles.img}>
            <View>
              <Image src={Logo} style={{ width: 80 }} />
            </View>
          </View>
          <Text style={{ fontSize: 14, marginTop: 40, marginBottom: 10 }}>
            Welcome onboard!
          </Text>
          <Text style={{ fontSize: 12, marginBottom: 10 }}>
            Hi {application?.userDetails?.name},
          </Text>
          <Text style={{ fontSize: 12, lineHeight: "1.2" }}>
            Thanks for applying for visa. We’ve received your application
            {isBulk ? (
              "s"
            ) : (
              <Text style={{ fontWeight: 700 }}>
                &nbsp;{application?.applicationId}
              </Text>
            )}{" "}
            . You can visit the visa center on your chosen date&nbsp;
            <Text style={{ fontWeight: 700 }}>
              {application?.appointmentDate &&
                moment(application?.appointmentDate).format("DD-MMM-YY")}
              &nbsp;
            </Text>
            for further verification process.
          </Text>
          {isBulk === true ? (
            <View style={styles.content}>
              <View style={styles.bulkContainer}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Bulk ID
                </Text>
                <Text style={styles.smText}>{application?.bulkId}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Agency Name
                </Text>
                <Text style={styles.smText}>
                  {application?.agencyDetails?.agencyName}
                </Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Total Application
                </Text>
                <Text style={styles.smText}>{application?.totalApplied}</Text>
              </View>

              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Service type
                </Text>
                <Text style={styles.smText}>Applied for visa</Text>
              </View>
            </View>
          ) : (
            <View
              style={{
                backgroundColor: "#eeeeee",
                borderRadius: 12,
                padding: 12,
                marginTop: 20,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Booking ID
                </Text>
                <Text style={styles.smText}>{application?.applicationId}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Name
                </Text>
                <Text style={styles.smText}>{application?.name}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Email
                </Text>
                <Text style={styles.smText}>{application?.email}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Passport Type
                </Text>
                <Text style={styles.smText}>{application?.passportType}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Service type
                </Text>
                <Text style={styles.smText}>Applied for visa</Text>
              </View>
            </View>
          )}
          <View style={[styles.content, { flexDirection: "column" }]}>
            <View style={styles.feesTextWrapper}>
              <Text style={styles.amountTxt}>Fee</Text>
              <Text style={styles.smTxt2}>${payment?.fees}</Text>
            </View>
            <View style={styles.feesTextWrapper}>
              <Text style={styles.amountTxt}>Tax</Text>
              <Text style={styles.smTxt2}>${payment?.tax}</Text>
            </View>
            <View style={styles.feesTextWrapper}>
              <Text style={styles.amountTxt}>Discount</Text>
              <Text style={styles.smTxt2}>${payment?.discount}</Text>
            </View>
            <View style={styles.feesTextWrapper}>
              <Text style={styles.amountTxt}>Total</Text>
              <Text style={styles.smTxt2}>${payment?.amount}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ApplicationReceipt;
