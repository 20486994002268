import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import NoResult from "parts/noResult";
import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import {
  GET_APP_DETAILS_BY_EMBASSY,
  CHANGE_STATUS_BY_EMBASSY,
} from "config/endpoints";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import { GetStatus } from "functions/outputFunctions";
import useDownload from "hooks/useDownload";
const EmbassyVisaApplicationDetails = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const Download = useDownload();
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [status, setStatus] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [reason, setReason] = useState("");
  const [openRejectText, setOpenRejectText] = useState(false);

  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_APP_DETAILS_BY_EMBASSY(id))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res?.data?.data);
            setReason(res?.data?.data?.reason);
            setStatus(res?.data?.data?.status);
          } else {
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id, refresh]);

  useEffect(() => {
    if (status === "denied") {
      setOpenRejectText(true);
    } else {
      setOpenRejectText(false);
    }
  }, [status]);
  const handleSubmit = () => {
    if (status === "denied" && !reason) {
      toast.error("Please enter  reason.");
      return;
    }
    toggleLoading(true);

    PostRequest(CHANGE_STATUS_BY_EMBASSY(id), {
      status: status,
      reason: reason,
    })
      .then((res) => {
        if (res.data?.status) {
          setRefresh(!refresh);
          toast.success("Status updated.");
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  return (
    <section className="application-details-page">
      <Navbar />
      <section className="wrapper container g-0 content-height-2">
        <Box mb={4}>
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography variant="overline" ml={2} fontSize={18}>
            Visa Application Details
          </Typography>
        </Box>
        {details != null ? (
          <Box>
            <Box
              sx={{
                bgcolor: "grey.200",
                borderRadius: 4,
                p: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Full Name </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.nameTitle} {details.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Email </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Phone </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport Type </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.passportType}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport Number </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.passportNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> National Id </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.nationalId}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Booking Id </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.applicationId}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Applied Date</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {moment(details.date).format("D MMM Y")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">From Country</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.fromCountry?.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">To Country</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.toCountry?.label}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Booking Status </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {GetStatus(details.status)}
                  </Typography>
                </Grid>
                {details.reason ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Reason</Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {details.reason}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download Passport</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(details.passportPicture?.path, "passport-EDA")
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download National ID</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(
                          details.nationalIdPicture?.path,
                          "national-id-EDA"
                        )
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Interpol certificate</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(
                          details.interpolCertificatePicture?.path,
                          "interpol-certificate-EDA"
                        )
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3} mt={4}>
              <Grid item xs={12} md={6} mx={"auto"}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Box width="min(100%,400px)" my={2}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel id="demo-simple-select-label">
                        Change Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label=" Change Status"
                        onChange={(ev) => setStatus(ev.target.value)}
                      >
                        <MenuItem value={"pending"} disabled>
                          Pending
                        </MenuItem>
                        <MenuItem value={"inReview"} disabled>
                          In Review
                        </MenuItem>
                        <MenuItem value={"processing"} disabled>
                          Processing
                        </MenuItem>
                        <MenuItem value={"hold"} disabled>
                          Hold
                        </MenuItem>
                        <MenuItem value={"returned"} disabled>
                          Returned
                        </MenuItem>
                        <MenuItem value={"sentToEmbassy"} disabled>
                          Sent To Embassy
                        </MenuItem>
                        <MenuItem
                          value={"approved"}
                          disabled={[
                            "approved",
                            "collect",
                            "collected",
                          ].includes(details?.status)}
                        >
                          Approve
                        </MenuItem>
                        <MenuItem
                          value={"denied"}
                          disabled={["collect", "collected"].includes(
                            details?.status
                          )}
                        >
                          Denied
                        </MenuItem>
                        <MenuItem value={"collect"} disabled>
                          Ready to collect
                        </MenuItem>
                        <MenuItem value={"collected"} disabled>
                          Collected
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {openRejectText && (
                      <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        label="Reason"
                        placeholder="Enter reason"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(ev) => setReason(ev.target.value)}
                      />
                    )}
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box className="p-center">
            <NoResult />
          </Box>
        )}
      </section>
    </section>
  );
};

export default EmbassyVisaApplicationDetails;
