import StandardNavbar from "parts/navbar/standardNavbar";
import TranslationHeader from "./component/translationHeader";
import TranslationInfo from "./component/translationInfo";
import QuickLinks from "parts/quickLinks";
import QueryForm from "parts/queryForm";

const TranslationService = () => {
  return (
    <section className="translation-page">
      <StandardNavbar />
      <TranslationHeader />
      <TranslationInfo />
      <QuickLinks />
      <QueryForm title="For More Your Queries <br /> Contact Our Support <br /> Team " />
    </section>
  );
};

export default TranslationService;
