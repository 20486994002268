import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProgressing: false,
};

export const progressSlice = createSlice({
  name: "progressbar",
  initialState,
  reducers: {
    setStopProgress: (state, action) => {
      state.isProgressing = false;
    },
    setStartProgress: (state, action) => {
      state.isProgressing = true;
    },
    setToggleProgress: (state, action) => {
      state.isProgressing = action.payload;
    },
  },
});

export const { setStopProgress, setStartProgress, setToggleProgress } = progressSlice.actions;

export default progressSlice.reducer;
