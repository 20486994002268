import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";

const TicketingInfo = () => {
  const list = [
    {
      title: "Airline Tickets ",
      description: `Our airline ticketing services make it easy for you to book your flights to your desired destination. We work with a wide range of airlines to provide you with the best options for your travel needs. Our team will also ensure that you receive the best possible price for your airline tickets.`,
      link: "#",
    },
    {
      title: "Hotel Reservation",
      description: `We understand that finding the right accommodations can be challenging when planning a trip. That's why we offer hotel reservation services to help you find the perfect place to stay during your travels. Our team will work with you to find the best accommodations that meet your needs and budget.`,
      link: "#",
    },
    {
      title: "Car Rentals ",
      description: `If you need a car for your travels, our car rental services can help you find the perfect rental car for your trip. We work with a wide range of car rental companies to provide you with the best options for your travel needs. Our team will also ensure that you receive the best possible price for your rental car.`,
      link: "#",
    },
    {
      title: "Cruise Bookings",
      description: `Cruises are a popular way to see the world, and we can help you plan your next cruise vacation. Our team of travel experts will work with you to find the perfect cruise that meets your needs and budget. We will also assist you in booking your cruise and provide guidance on the necessary documentation required for your trip.`,
      link: "#",
    },
  ];
  return (
    <Box className="container">
      <Box pt={5}>
        <Typography variant="body1">
          At ExpressVFS, we offer a wide range of ticketing services to help you
          plan your travel and make your trip as hassle-free as possible. Our
          team of travel experts is dedicated to providing exceptional service
          and expertise to ensure that your travel plans are seamless and
          stress-free.
        </Typography>
      </Box>
      <Box py={5}>
        <Grid container spacing={3}>
          {list?.map(({ title, description, link }, i) => (
            <Grid item key={i} xs={12} sm={6} lg={3}>
              <StandardCard
                title={title}
                description={description}
                link={link}
                linkText="Apply Now"
                titleColor="primary"
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TicketingInfo;
