import { Box, Grid, Typography, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import NoResult from "parts/noResult";
import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { GET_BY_ID, GET_DETAILS_ID } from "config/endpoints";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import moment from "moment";
import AdminApplicationTable from "component/grid/table/adminApplicationTable";
const SlotDetails = () => {
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_BY_ID(id))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res?.data?.data);
          } else {
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);
  return (
    <section className="slot-details-page">
      <Navbar />
      <section className="wrapper container g-0">
        <Box mb={4}>
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography variant="overline" ml={2} fontSize={18}>
            Appoint Time Slot Details
          </Typography>
        </Box>
        {details != null ? (
          <Box>
            <Box>
              <Box
                sx={{
                  bgcolor: "grey.200",
                  borderRadius: 4,
                  p: 3,
                  mb: 4,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Appointment Date </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {moment(details.date).format("D MMM Y")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Appointment From </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {moment(details.from).format("h:mm A")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Appointment To </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {moment(details.to).format("h:mm A")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">
                      Allowed Application{" "}
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.allowedApplication}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">
                      {" "}
                      Applied Application{" "}
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.applied}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box>
              <Typography variant="overline" ml={2} fontSize={18}>
                Applications in this appointment slots
              </Typography>
              <AdminApplicationTable URL={GET_DETAILS_ID(id) + "?"} />
            </Box>
          </Box>
        ) : (
          <Box className="p-center">
            <NoResult />
          </Box>
        )}
      </section>
    </section>
  );
};

export default SlotDetails;
