import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import Charges from "./component/countrySetting";
import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VisaSlotTimeSettings from "./component/visaSlotTimeSettings";
import AllCountriesTables from "component/grid/table/allCountryTable";
import CurrencySetting from "./component/currencySetting";
const Settings = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [addNew, setAddNew] = useState(false);
  const [editCountry, setEditCountry] = useState(null);

  const handleRefresh = () => {
    window.location.reload();
  };

  if (user.role !== "admin") {
    navigate("/");
    return null;
  }
  const handleEdit = (data) => {
    if (data.field === "edit") {
      window.scrollTo(0, 0);
      setEditCountry(data.value);
    }
  };
  return (
    <section className="admin-settings-page">
      <AdminNavbar />
      <AdminHeader />
      <section className="wrapper container g-0">
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h5">Country setting for visa</Typography>
          <Tooltip title="Add new country">
            <IconButton
              onClick={() => {
                setAddNew(true);
                setEditCountry(null);
              }}
            >
              <AddCircleOutlineIcon color="success" sx={{ fontSize: 38 }} />
            </IconButton>
          </Tooltip>
        </Box>
        {addNew || editCountry ? (
          <Charges
            newItem
            refresh={handleRefresh}
            canceled={() => {
              setAddNew(false);
              setEditCountry(null);
            }}
            updateId={editCountry}
          />
        ) : null}
        <Box>
          <AllCountriesTables height={400} handleCellClick={handleEdit} />
        </Box>
        <VisaSlotTimeSettings />
        <CurrencySetting />
      </section>
    </section>
  );
};

export default Settings;
