import { useEffect, useState, useTransition } from "react";

import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { ErrorCreateUserModel } from "_models/error/errors.CreateUser.model";
import { CreateUserModel } from "_models/data/data.createUser.model";
import { ValidateRegistrationForm } from "functions/validators";
import { GetRequest, PostRequest } from "functions/http";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSelector } from "react-redux";
import {
  CREATE_USER,
  GET_USER_FOR_ADMIN_BY_ID,
  UPDATE_USER,
  BAN_USER,
} from "config/endpoints";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import AdminNavbar from "parts/navbar/adminNavbar";
import Role from "config/Role";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const CreateUser = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const [search] = useSearchParams();
  const availableCountry = useSelector((data) => data.countries.to);
  //local state data
  const [error, setError] = useState(new ErrorCreateUserModel());
  const [state, setState] = useState(new CreateUserModel());
  const [banStatus, setBanStatus] = useState(null);
  const [isPending, startTransition] = useTransition();
  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const handleCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
  };
  //input handler
  const handleChange = (event, name) => {
    if (name === "email") {
      setState({ ...state, [name]: event?.target?.value.trim() });
    } else {
      setState({ ...state, [name]: event.target.value });
    }
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const id = search.get("id");

  const handleSignup = () => {
    const isValid = ValidateRegistrationForm(state, Boolean(id));
    if (isValid.valid) {
      toggleLoading(true);
      const _data = {
        ...state,
        countries: state.countries.map((e) => e.code),
        agencyData: {
          agencyName: state.agencyName,
          phone: state.phone,
          address: state.address,
        },
      };
      let url = id ? UPDATE_USER(id) : CREATE_USER;
      PostRequest(url, _data)
        .then((res) => {
          if (res.data.status) {
            if (id) {
              toast.success("User updated");
              window.location.reload();
            } else {
              toast.success(
                "Successfully registered. We have sent an email to the user."
              );
              setState(new CreateUserModel());
            }
          } else {
            toast.error(res?.data?.msg || "Something went wrong.");
          }
        })
        .catch((er) => {
          console.log(HTTP_ERROR(er));
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  useEffect(() => {
    if (!id || availableCountry.length < 1) return;
    toggleLoading(true);
    GetRequest(GET_USER_FOR_ADMIN_BY_ID(id))
      .then((res) => {
        if (res.data.status) {
          let newData = new CreateUserModel(res.data.data);
          const initialCountry = res.data?.data?.accessDetails?.[0]?.countries;
          if (initialCountry) {
            const indexes = initialCountry.map((e) =>
              availableCountry.findIndex((el) => el.code === e)
            );
            if (initialCountry.length === availableCountry.length) {
              setSelectAll(true);
            } else {
              setSelectAll(false);
            }
            const _values = indexes.map((e) => availableCountry[e]);
            newData = {
              ...newData,
              countries: _values,
              description: res.data?.data?.accessDetails?.[0]?.description,
            };
          }
          setState(newData);
          setBanStatus(res?.data?.data?.isActive);
        } else {
          setState(new CreateUserModel());
          setBanStatus(null);
          // toast.error(res?.data?.msg || "Something went wrong.");
        }
      })
      .catch((er) => {
        setState(new CreateUserModel());
        setBanStatus(null);
        // toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  }, [id, availableCountry]);
  console.log(state);
  const handleBan = () => {
    setOpenConfirmDlg(false);
    toggleLoading(true);
    PostRequest(BAN_USER(id), { banStatus: !banStatus })
      .then((res) => {
        if (res.data.status) {
          toast.success("User updated");
          window.location.reload();
        } else {
          toast.error(res?.data?.msg || "Something went wrong.");
        }
      })
      .catch((er) => {
        console.log(HTTP_ERROR(er));
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  const handleCountryChange = (e, value) => {
    if (value.length === availableCountry.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    startTransition(() => {
      setState((state) => ({ ...state, countries: value }));
    });
  };
  useEffect(() => {
    if (!selectAll) {
      startTransition(() => {
        setState((state) => ({ ...state, countries: [] }));
      });
    } else {
      startTransition(() => {
        setState((state) => ({ ...state, countries: availableCountry }));
      });
    }
  }, [selectAll, availableCountry]);

  return (
    <section className="admin-create-user-page">
      <AdminNavbar />
      <section className="wrapper container g-0 content-height">
        <IconButton sx={{ mb: 1 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>
        <Box px={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="h4" mt={2} fontWeight={500} mb={5}>
              User management
            </Typography>
            {banStatus != null ? (
              <Button
                color={banStatus ? "error" : "success"}
                variant="contained"
                onClick={() => setOpenConfirmDlg(true)}
              >
                {banStatus ? "Ban user" : "unban user"}
              </Button>
            ) : null}
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                User Role
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  label="Role"
                  value={state.role}
                  onChange={(ev) => handleChange(ev, "role")}
                >
                  <MenuItem
                    value={Role.STAFF}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {Role.STAFF}
                  </MenuItem>
                  <MenuItem
                    value={Role.EMBASSY}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {Role.EMBASSY}
                  </MenuItem>
                  <MenuItem
                    value={Role.MANAGER}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {Role.MANAGER}
                  </MenuItem>
                  <MenuItem
                    value={Role.AGENCY}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {Role.AGENCY}
                  </MenuItem>
                  <MenuItem
                    value={Role.BASIC}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {Role.BASIC}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {state.role !== Role.AGENCY && state.role !== Role.BASIC ? (
              <Grid item md={6} xs={12}>
                <Typography variant="subtitle1" mb={1} fontWeight={600}>
                  Country access
                </Typography>
                <Autocomplete
                  multiple
                  options={availableCountry}
                  value={state.countries}
                  onChange={handleCountryChange}
                  disableCloseOnSelect
                  // disabled={state.isAllCountry}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Countries"
                      placeholder="Select country"
                      sx={{ maxHeight: 80 }}
                      error={error.countriesError !== ""}
                      helperText={error.countriesError}
                      onFocus={() => handleFocus("countriesError")}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isPending ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(ev) => setSelectAll(ev.target.checked)}
                      checked={selectAll}
                    />
                  }
                  label="Select All"
                />
              </Grid>
            ) : null}

            {!id && state?.role === Role.AGENCY ? (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" mb={1} fontWeight={600}>
                  Agency Name
                </Typography>
                <TextField
                  label="Enter Agency Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={error.agencyNameError !== ""}
                  helperText={error.agencyNameError}
                  onFocus={() => handleFocus("agencyNameError")}
                  name="agencyName"
                  value={state.agencyName}
                  onChange={(ev) => handleChange(ev, "agencyName")}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Name
              </Typography>
              <TextField
                label="Enter Name"
                variant="outlined"
                fullWidth
                type="text"
                id="signup_name"
                error={error.nameError !== ""}
                helperText={error.nameError}
                onFocus={() => handleFocus("nameError")}
                name="name"
                value={state.name}
                onChange={(ev) => handleChange(ev, "name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Phone
              </Typography>
              <TextField
                label="Enter Phone"
                variant="outlined"
                fullWidth
                type="text"
                error={error.phoneError !== ""}
                helperText={error.phoneError}
                onFocus={() => handleFocus("phoneError")}
                name="phone"
                value={state.phone}
                onChange={(ev) => handleChange(ev, "phone")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Email
              </Typography>
              <TextField
                label="Enter email"
                variant="outlined"
                fullWidth
                type="email"
                error={error.emailError !== ""}
                helperText={error.emailError}
                onFocus={() => handleFocus("emailError")}
                id="signup_email"
                name="email"
                value={state.email}
                onChange={(ev) => handleChange(ev, "email")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Address
              </Typography>
              <TextField
                label="Enter Address"
                variant="outlined"
                fullWidth
                type="text"
                error={error.addressError !== ""}
                helperText={error.addressError}
                onFocus={() => handleFocus("addressError")}
                name="address"
                value={state.address}
                onChange={(ev) => handleChange(ev, "address")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Password
              </Typography>
              <TextField
                label="Enter password"
                variant="outlined"
                fullWidth
                type="password"
                name="password"
                error={error.passwordError !== ""}
                helperText={error.passwordError}
                onFocus={() => handleFocus("passwordError")}
                id="password"
                value={state.password}
                onChange={(ev) => handleChange(ev, "password")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Confirm Password
              </Typography>
              <TextField
                label="Enter confirm password"
                variant="outlined"
                fullWidth
                type="password"
                error={error.confirmPasswordError !== ""}
                helperText={error.confirmPasswordError}
                onFocus={() => handleFocus("confirmPasswordError")}
                id="password-confirm"
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={(ev) => handleChange(ev, "confirmPassword")}
              />
            </Grid>
            {state.role !== Role.AGENCY && state.role !== Role.BASIC ? (
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" mb={1} fontWeight={600}>
                  Description
                </Typography>
                <TextField
                  label="Enter description"
                  variant="outlined"
                  fullWidth
                  name="description"
                  value={state.description}
                  onChange={(ev) => handleChange(ev, "description")}
                  multiline
                  rows={4}
                />
              </Grid>
            ) : null}
          </Grid>

          <div className="p-center">
            <Button
              variant="contained"
              sx={{ width: 300, my: 4, mx: "auto" }}
              size="large"
              onClick={handleSignup}
            >
              {id ? "Update" : "Create"} user
            </Button>
          </div>
        </Box>
        <Dialog
          open={openConfirmDlg}
          onClose={handleCloseConfirmDlg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure to {banStatus ? "ban" : "remove ban from"} this user ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Banned user can not login and can not perform any action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDlg}>Cancel</Button>
            <Button onClick={handleBan} variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </section>
  );
};

export default CreateUser;
