import {
  Box,
  Grid,
  Typography,
  Stepper,
  StepLabel,
  Step,
  StepContent,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PaymentReceipt from "component/pdf/paymentReceipt";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import NoResult from "parts/noResult";
import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import {
  GET_APPLICATION_BY_ADMIN,
  GET_VISA_APP_DETAILS_ID,
  CHANGE_STATUS_BY_ADMIN,
  CHANGE_STATUS_BY_STAFF,
} from "config/endpoints";
import moment from "moment";

import { useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import { GetStatus } from "functions/outputFunctions";
import { GetActiveStatus } from "functions/outputFunctions";
import { GetPayMethod } from "functions/outputFunctions";
import useDownload from "hooks/useDownload";
import Role from "config/Role";
import RouteConstant from "navigation/constant";
import PassportReceipt from "component/pdf/passportReceipt";
const AdminVisaApplicationDetails = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const Download = useDownload();
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const ROLE = useSelector((state) => state.auth.user.role);
  const [status, setStatus] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [reason, setReason] = useState("");
  const [lockerNo, setLockerNo] = useState("");
  const [openRejectText, setOpenRejectText] = useState(false);

  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      toggleLoading(true);
      const url =
        ROLE === "admin"
          ? GET_APPLICATION_BY_ADMIN(id)
          : GET_VISA_APP_DETAILS_ID(id);
      GetRequest(url)
        .then((res) => {
          if (res.data?.status) {
            setDetails(res?.data?.data);
            setLockerNo(res?.data?.data?.lockerNo||"")
            setReason(res?.data?.data?.reason);
            setStatus(res?.data?.data?.status);
          } else {
            // toast.error(res.data.msg);
            setDetails(null);
            setReason("");
            setStatus("");
          }
        })
        .catch((er) => {
          setDetails(null);
          setReason("");
          setStatus("");
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id, refresh]);

  useEffect(() => {
    if (status === "returned") {
      setOpenRejectText(true);
    } else {
      setOpenRejectText(false);
    }
  }, [status]);
  const handleSubmit = () => {
    if (status === "returned" && !reason) {
      toast.error("Please enter  reason.");
      return;
    }
    if (
      ["approved", "denied", "sentToEmbassy", "collect"].includes(
        details.status
      )
    ) {
      const warning = window.confirm(
        `Are you sure to change status. Current status is " ${details.status} "`
      );
      if (!warning) return;
    }
    toggleLoading(true);
    const url =
      ROLE === Role.STAFF
        ? CHANGE_STATUS_BY_STAFF(id)
        : CHANGE_STATUS_BY_ADMIN(id);
    PostRequest(url, { status: status, reason: reason, lockerNo: lockerNo })
      .then((res) => {
        if (res.data?.status) {
          setRefresh(!refresh);
          toast.success("Status updated.");
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  console.log(details);
  return (
    <section className="application-details-page">
      <Navbar />
      <section className="wrapper container g-0 content-height-2">
        <Box mb={4}>
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography variant="overline" ml={2} fontSize={18}>
            Visa Application Details
          </Typography>
        </Box>
        {details != null ? (
          <Box>
            <Box
              sx={{
                bgcolor: "grey.200",
                borderRadius: 4,
                p: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Full Name </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.nameTitle} {details.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Email </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Phone </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport Type </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.passportType}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport Number </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.passportNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> National Id </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.nationalId}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Booking Id </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.applicationId}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Appointment Date </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {moment(details.appointmentSlot?.date).format("D MMM Y")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Appointment Time </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Link
                      to={
                        RouteConstant.SLOTS_DETAILS +
                        "/" +
                        details.appointmentSlot?._id
                      }
                    >
                      {moment(details.appointmentSlot?.from).format("h:mm A")}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Tax</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.tax}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Fees</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.charges}
                  </Typography>
                </Grid>
                {details.discount ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Discount</Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.discount}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Total Fees</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Payment status</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.isPaid ? (
                      <Chip label="Paid" color="success" />
                    ) : (
                      <Chip label="Not Paid" color="error" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Payment method</Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {GetPayMethod(details.paymentMethod)}
                  </Typography>
                </Grid>
                {ROLE === "manager" || ROLE === "admin" ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Payment Details</Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      <Link
                        to={
                          RouteConstant.PAYMENT_DETAILS +
                          "/" +
                          details.paymentId
                        }
                      >
                        See Payment details
                      </Link>
                    </Typography>
                  </Grid>
                ) : null}
                {details?.payment?.length > 0 ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Payment Id</Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {details?.payment[0].paymentId}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Applied Date</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {moment(details.date).format("D MMM Y")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">From Country</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.fromCountry?.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">To Country</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.toCountry?.label}
                  </Typography>
                </Grid>
                {details.agency?.length > 0 ? (
                  <>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Agency Name</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.agency[0].agencyName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Agency Phone</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.agency[0].phone}
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {details.bulk?.length > 0 ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Bulk Id</Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.bulk[0]?.bulkId}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Applied By</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.user.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Booking Status </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {GetStatus(details.status)}
                  </Typography>
                </Grid>
                {details.reason ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Reason</Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {details.reason}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download Passport</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(details.passportPicture?.path, "passport-EDA")
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download National ID</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(
                          details.nationalIdPicture?.path,
                          "national-id-EDA"
                        )
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Interpol certificate</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(
                          details.interpolCertificatePicture?.path,
                          "interpol-certificate-EDA"
                        )
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                {details?.toCountry?.code === "SA" ? (
                  <>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Enjaz</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(details.enjazPicture?.path, "enjaz-doc")
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Medical Gcc</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(
                              details.medicalGccPicture?.path,
                              "medical-gcc"
                            )
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Wakala</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(details.wakalaPicture?.path, "wakala")
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Embassy Form</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(
                              details.embassyFormPicture?.path,
                              "embasyForm"
                            )
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Contract</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(details.contractPicture?.path, "Contract")
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Yellow Book</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(
                              details.yellowBookPicture?.path,
                              "yellow-book"
                            )
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={3}
              flexDirection="column"
            >
              <Button variant="outlined" title="Download payment receipt">
                <PDFDownloadLink
                  style={{ color: "#000" }}
                  document={
                    <PaymentReceipt
                      billTo={details.name}
                      salesNo={details.applicationId}
                      date={details.appointmentDate}
                      payMethod={GetPayMethod(details.paymentMethod)}
                      total={details.isPaid ? details.total : 0}
                      due={details.isPaid ? 0 : details.total}
                      serviceName="VISA"
                      description="SERVICE FEE"
                      qty={1}
                      rate={details.total}
                      amount={details.total}
                    />
                  }
                  fileName="visa-payment-receipt.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Download payment receipt"
                  }
                </PDFDownloadLink>
              </Button>
              <Button
                variant="outlined"
                title="Download passport receipt"
                sx={{ mt: 2 }}
              >
                <PDFDownloadLink
                  style={{ color: "#000" }}
                  document={
                    <PassportReceipt
                      name={details.name}
                      passPortNo={details.passportNumber}
                      ApplicationNo={details.applicationId}
                      agencyName={details?.agency?.[0]?.agencyName || ""}
                      submissionDate={details.appointmentDate}
                      lockerNo={details.lockerNo}
                    />
                  }
                  fileName="visa-passport-receipt.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading
                      ? "Loading document..."
                      : "Download passport receipt"
                  }
                </PDFDownloadLink>
              </Button>
            </Box>
            <Grid container spacing={3} mt={4}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="body1">Submitted</Typography>
                  <Stepper
                    activeStep={GetActiveStatus(details?.status)}
                    orientation="vertical"
                  >
                    {steps.map((step, index) => {
                      const labelProps = {};
                      if (details?.status === "returned" && index === 2) {
                        labelProps.optional = (
                          <Typography variant="caption" color="error">
                            Returned
                          </Typography>
                        );

                        labelProps.error = true;
                      }
                      if (details?.status === "denied" && index === 3) {
                        labelProps.optional = (
                          <Typography variant="caption" color="error">
                            Denied
                          </Typography>
                        );

                        labelProps.error = true;
                      }
                      return (
                        <Step key={step.label} expanded>
                          <StepLabel {...labelProps}>{step.label}</StepLabel>
                          <StepContent>
                            <Typography variant="caption" color="grey.400">
                              {step.description}
                            </Typography>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Box width="min(100%,400px)" my={2}>
                    <TextField
                      fullWidth
                      placeholder="Enter passport locker no"
                      label="Locker No"
                      sx={{ mb: 3 }}
                      value={lockerNo}
                      onChange={(e) => setLockerNo(e.target.value)}
                    />
                    {ROLE === Role.STAFF ? (
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="demo-simple-select-label">
                          Change Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label=" Change Status"
                          onChange={(ev) => setStatus(ev.target.value)}
                        >
                          <MenuItem value={"pending"} disabled>
                            Pending
                          </MenuItem>
                          <MenuItem
                            value={"inReview"}
                            disabled={
                              !["pending", "inReview"].includes(details.status)
                            }
                          >
                            In Review
                          </MenuItem>
                          <MenuItem value={"processing"} disabled>
                            Processing
                          </MenuItem>
                          <MenuItem value={"hold"} disabled>
                            Hold
                          </MenuItem>
                          <MenuItem value={"returned"} disabled>
                            Returned
                          </MenuItem>
                          <MenuItem value={"sentToEmbassy"} disabled>
                            Sent To Embassy
                          </MenuItem>
                          <MenuItem value={"approved"} disabled>
                            Approve
                          </MenuItem>
                          <MenuItem value={"denied"} disabled>
                            Denied
                          </MenuItem>
                          <MenuItem
                            value={"collect"}
                            disabled={details.status !== "approved"}
                          >
                            Ready to collect
                          </MenuItem>
                          <MenuItem
                            value={"collected"}
                            disabled={details.status !== "collect"}
                          >
                            Collected
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="demo-simple-select-label">
                          Change Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          label=" Change Status"
                          onChange={(ev) => setStatus(ev.target.value)}
                        >
                          <MenuItem
                            value={"pending"}
                            disabled={[
                              "approved",
                              "collect",
                              "collected",
                            ].includes(details?.status)}
                          >
                            Pending
                          </MenuItem>
                          <MenuItem
                            value={"inReview"}
                            disabled={[
                              "approved",
                              "collect",
                              "collected",
                            ].includes(details?.status)}
                          >
                            In Review
                          </MenuItem>
                          <MenuItem
                            value={"processing"}
                            disabled={[
                              "approved",
                              "collect",
                              "collected",
                            ].includes(details?.status)}
                          >
                            Processing
                          </MenuItem>
                          <MenuItem
                            value={"hold"}
                            disabled={[
                              "approved",
                              "collect",
                              "collected",
                            ].includes(details?.status)}
                          >
                            Hold
                          </MenuItem>
                          <MenuItem
                            value={"returned"}
                            disabled={[
                              "approved",
                              "collect",
                              "collected",
                            ].includes(details?.status)}
                          >
                            Returned
                          </MenuItem>
                          <MenuItem
                            value={"sentToEmbassy"}
                            disabled={[
                              "approved",
                              "collect",
                              "collected",
                            ].includes(details?.status)}
                          >
                            Sent To Embassy
                          </MenuItem>
                          <MenuItem value={"approved"} disabled>
                            Approve
                          </MenuItem>
                          <MenuItem value={"denied"} disabled>
                            Denied
                          </MenuItem>
                          <MenuItem
                            value={"collect"}
                            disabled={details.status !== "approved"}
                          >
                            Ready to collect
                          </MenuItem>
                          <MenuItem
                            value={"collected"}
                            disabled={details.status !== "collect"}
                          >
                            Collected
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    {openRejectText && (
                      <TextField
                        fullWidth
                        sx={{ mb: 3 }}
                        label="Reason"
                        placeholder="Enter reason"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(ev) => setReason(ev.target.value)}
                      />
                    )}
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {ROLE === "admin" ? (
              <Box mt={3}>
                <Typography variant="overline" ml={2} fontSize={18}>
                  Application Activity
                </Typography>
                <Box>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Activity Name</TableCell>
                          <TableCell align="right">Modification</TableCell>
                          <TableCell align="right">Date</TableCell>
                          <TableCell align="right">Who did this</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details.trackStatus.map((row, key) => (
                          <TableRow
                            key={key}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <span className="text-capitalize">
                                {row.name}
                              </span>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span className="text-capitalize">
                                {row.modification}
                              </span>
                            </TableCell>
                            <TableCell align="right">
                              {moment(row.date).format("DD-MMM-YY HH:mm:ss")}
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                to={
                                  RouteConstant.CREATE_USER +
                                  `?id=${row.modifiedBy}`
                                }
                              >
                                {row.modifiedBy}
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            ) : null}
          </Box>
        ) : (
          <Box className="p-center">
            <NoResult />
          </Box>
        )}
      </section>
    </section>
  );
};

export default AdminVisaApplicationDetails;
const steps = [
  {
    label: "Booked An Appointment",
  },

  {
    label: "Physical Verification done",
  },
  {
    label: "Sent to embassy",
  },
  {
    label: "Verified From Embassy",
  },
  {
    label: "Ready to collect",
  },
  {
    label: "Collected",
  },
];
