import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { Box } from "@mui/material";
import ApplicationColumn from "../columns/blogAndNews";
import RouteConstant from "navigation/constant";

const BlogAndNewsTable = ({ width, height, URL, isNews, ...props }) => {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    `${URL}?searchKey=${searchKey}`
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);
  const handleSearchFilter = (v) => {
    const searchValue = v?.quickFilterValues?.toString()?.replace(",", " ");
    setSearchKey(searchValue);
  };
  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        title: el?.title,
        isFeatured: el?.isFeatured,
        isAd: el?.isAd,
        adFor: el?.adFor,
        date: el?.date || null,
        photo: el?.image?.path,
        detail:
          (isNews ? RouteConstant.CREATE_NEWS : RouteConstant.CREATE_BLOG) +
          "?id=" +
          el._id,
      };
    });
    setRows(value);
  }, [data]);

  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={ApplicationColumn}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        disableColumnSelector
        // disableDensitySelector
        components={{ Toolbar: GridToolbar }}
        onFilterModelChange={handleSearchFilter}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
};

export default BlogAndNewsTable;
