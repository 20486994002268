import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "assets/img/static/icon/logo3.png";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 9,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 50,
  },
  textPrimary: {
    color: "#A35E9A",
  },
  section: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: 150,
    // height: 60,
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
    marginTop: 40,
    color: "#A35E9A",
  },
  borderTop: {
    borderTopWidth: 0.3,
    borderColor: "#5d5d5d",
    paddingTop: 20,
  },
  rowHeader: {
    fontWeight: 600,
    fontFamily: "Helvetica-Bold",
    marginTop: 10,
    padding: "2px 4px",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    flexDirection: "row",
  },
  leftCol: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  rightCol: {
    display: "flex",
    width: "50%",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  tdLeft: {
    padding: "5px 20px",
  },
  tdRight: {
    padding: "5px 25px",
  },
  mb5: {
    marginBottom: 5,
  },
  mt5: {
    marginTop: 5,
  },
  footer: {
    marginTop: 10,
    paddingTop: 10,
    borderTop: "dashed  #5d5d5d",
    borderTopWidth: 0.3,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  leftFooter: {
    display: "flex",
    flexDirection: "column",
  },
  footerRight: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 50,
  },
  total: {
    marginBottom: 5,
    fontWeight: 600,
    fontFamily: "Helvetica-Bold",
  },
});

const PaymentReceipt = ({
  billTo,
  salesNo,
  date,
  payMethod,
  total,
  due,
  serviceName,
  description,
  qty,
  rate,
  amount,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View>
          <Text style={styles.textPrimary}>ExpressVFS</Text>
          <Text>1st Floor, Room 10, Kanjokya House</Text>
          <Text>Plot 90, Kanjokya Street, Kampala - Uganda</Text>
          <Text>P. O. Box 112768, Kampala</Text>
          <Text>info@expressvfs.com</Text>
          <Text>www.expressvfs.com</Text>
        </View>
        <View>
          <Image src={Logo} style={styles.logo} />
        </View>
      </View>
      <Text style={styles.title}>SALES RECEIPT</Text>
      <View style={styles.section}>
        <View>
          <Text style={styles.bold}>BILL TO:</Text>
          <Text style={styles.mt5}>{billTo || ""}</Text>
        </View>
        <View>
          <Text style={styles.mb5}>
            <Text style={styles.bold}>SALES NO: </Text> {salesNo || ""}
          </Text>
          <Text>
            <Text style={styles.bold}> DATE: </Text>{" "}
            {moment(date).format("DD/MM/YYYY")}
          </Text>
        </View>
      </View>
      <View style={styles.borderTop}>
        <Text style={[styles.bold, { marginBottom: 5 }]}>PAYMENT METHOD:</Text>
        <Text style={styles.bold}>{payMethod || ""}</Text>
      </View>
      <View
        style={[
          styles.rowHeader,
          { color: "#A35E9A", backgroundColor: "rgba(114, 65, 107, .2)" },
        ]}
      >
        <View style={styles.leftCol}>
          <Text style={styles.tdLeft}>Service</Text>
          <Text style={styles.tdLeft}>Description</Text>
        </View>
        <View style={styles.rightCol}>
          <Text style={styles.tdRight}>Qty</Text>
          <Text style={styles.tdRight}>Rate</Text>
          <Text style={styles.tdRight}>Amount</Text>
        </View>
      </View>
      
      <View style={styles.rowHeader} >
        <View style={styles.leftCol}>
          <Text style={styles.tdLeft}>{serviceName}</Text>
          <Text style={styles.tdLeft}>{description}</Text>
        </View>
        <View style={styles.rightCol}>
          <Text style={styles.tdRight}>{qty}</Text>
          <Text style={styles.tdRight}>${rate}</Text>
          <Text style={styles.tdRight}>${amount}</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.leftFooter}>
          <Text style={styles.total}>Total:</Text>
          <Text>Balance Due:</Text>
        </View>
        <View style={styles.footerRight}>
          <Text style={styles.total}>${total}</Text>
          <Text>${due}</Text>
        </View>
      </View>
    </Page>
  </Document>
);
export default PaymentReceipt;
