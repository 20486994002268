import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import RouteConstant from "navigation/constant";
const VisaGuide = () => {
  const navigate = useNavigate();
  return (
    <section>
      <Navbar />
      <Helmet>
        <title>Visa guide  </title>
        <meta
          name="description"
          content="Get Your Visa Quickly and Hassle-Free with Our Reliable and Professional Visa Services. Our Team of Experts Will Guide You Through the Application Process, Ensuring Accuracy and Speed"
        />
      </Helmet>
      <section className="wrapper container g-0">
        <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h5" className="fm-pt-mono" mb={1}>
          Apply for a visa
        </Typography>
        <Typography color="grey.500">
          Find the information and begin your application
        </Typography>
        <Typography color="#F46412" variant="h4" mt={4}>
          Step by Step Guide
        </Typography>
        <Box display="flex" flexWrap="wrap" my={4}>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                01
              </Typography>
              <Typography variant="h5" mb={1}>
                Identify visa type
              </Typography>
              <Typography mb={1}>
                Choose the right visa <br /> for your trip
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                02
              </Typography>
              <Typography variant="h5" mb={1}>
                Begin your application
              </Typography>
              <Typography mb={1}>
                Get started with your <br />
                visa application
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                03
              </Typography>
              <Typography variant="h5" mb={1}>
                Book an appointment
              </Typography>
              <Typography mb={1}>
                Choose a Visa <br />
                Application Centre
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                04
              </Typography>
              <Typography variant="h5" mb={1}>
                Pay your fees
              </Typography>
              <Typography mb={1}>
                Find out the amount <br />
                you need to pay
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                05
              </Typography>
              <Typography variant="h5" mb={1}>
                Visit a Visa Application Centre
              </Typography>
              <Typography mb={1}>
                Attend your scheduled
                <br /> appointment
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                06
              </Typography>
              <Typography variant="h5" mb={1}>
                Track your application
              </Typography>
              <Typography mb={1}>
                Stay informed about the <br />
                progress of your application
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mr: 1, mb: 1 }}>
            <CardContent>
              <Typography variant="h5" className="fm-pt-mono" mb={1}>
                07
              </Typography>
              <Typography variant="h5" mb={1}>
                Collect your passport
              </Typography>
              <Typography mb={1}>
                Receive your passport from
                <br /> the Visa Application Centre
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" justifyContent="flex-end" pr={20}>
          <Link to={RouteConstant.APPLY_VISA}>
            <Button
              variant="contained"
              endIcon={<ArrowOutwardIcon />}
              size="larges"
            >
              APPLY NOW
            </Button>
          </Link>
        </Box>
        <Box my={4}>
          <Typography variant="h5" color="grey.700">
            Additional Services
          </Typography>
          <Box display="flex" flexWrap="wrap" my={4}>
            <Card sx={{ mr: 1, mb: 1 }}>
              <CardContent>
                <Typography variant="h5" className="fm-pt-mono" mb={1}>
                  01
                </Typography>
                <Typography variant="h5" mb={1}>
                  Book an appointment
                </Typography>
                <Typography mb={1}>
                  Schedule your appointment <br />
                  with us
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ mr: 1, mb: 1 }}>
              <CardContent>
                <Typography variant="h5" className="fm-pt-mono" mb={1}>
                  02
                </Typography>
                <Typography variant="h5" mb={1}>
                  Track your application
                </Typography>
                <Typography mb={1}>
                  Stay informed of your <br />
                  application status
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ mr: 1, mb: 1 }}>
              <CardContent>
                <Typography variant="h5" className="fm-pt-mono" mb={1}>
                  03
                </Typography>
                <Typography variant="h5" mb={1}>
                  Help
                </Typography>
                <Typography mb={1}>
                  Information about your local
                  <br /> centre
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </section>
    </section>
  );
};

export default VisaGuide;
