import StandardNavbar from "parts/navbar/standardNavbar";
import React from "react";
import UAEWelcome from "./component/uaeWelcome";
import UAEHelp from "./component/uaeHelp";
import { Box, Typography } from "@mui/material";
import UAEInfo from "./component/uaeInfo";

const UAE = () => {
  return (
    <section className="uae-page">
      <StandardNavbar transparent />
      <section className="content-height">
        <UAEWelcome />
        <UAEHelp />

        {/* Worl Map  BG */}
        <Box className="world-map-bg bg-dark" py={8}>
          <Box
            className="overlay"
            sx={{ backgroundColor: "#1C1C1C", opacity: 0.85 }}
          />
          <Box className="container">
            <Typography variant="body1" color="white.main" fontWeight={400}>
              Our team of experts is well-versed in the UAE visa application
              process and will guide you through every step of the way. We offer
              visa application processing, document review and submission, and
              visa consultation services to ensure that your application is
              completed accurately and submitted on time.
            </Typography>
          </Box>
        </Box>

        <UAEInfo />
      </section>
    </section>
  );
};

export default UAE;
