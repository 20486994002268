export class ErrorCountrySettingModel {
  countryError;
  taxError;
  chargesError;
  discountError;
  onlyDiscountForBulkError;
  countryTypeError;
  constructor(data = {}) {
    this.countryError = data.countryError || "";
    this.taxError = data.taxError || "";
    this.chargesError = data.chargesError || "";
    this.discountError = data.discountError || "";
    this.onlyDiscountForBulkError = data.onlyDiscountForBulkError || false;
    this.countryTypeError = data.countryTypeError || "";
  }
}
