export class ErrorVisaSlotSettingModel {
  durationError;
  totalSlotsError;
  applicationPerSlotError;
  breakDurationError;
  startingTimeError;
  breakTimeError;
  constructor(data = {}) {
    this.durationError = data.durationError || "";
    this.totalSlotsError = data.totalSlotsError || "";
    this.applicationPerSlotError = data.applicationPerSlotError || "";
    this.breakDurationError = data.breakDurationError || "";
    this.startingTimeError = data.startingTimeError || "";
    this.breakTimeError = data.breakTimeError || "";
  }
}
