export class UserRegisterModel {
  name;
  email;
  password;
  confirmPassword;
  phone;
  constructor(data = {}) {
    this.name = data.name || "";
    this.email = data.email || "";
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
    this.phone = data.phoneError || "";
  }
}
