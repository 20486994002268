import ServiceCard from "component/card/serviceCard";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import SyncIcon from "@mui/icons-material/Sync";
import TranslateIcon from "@mui/icons-material/Translate";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";

import { Box, Grid, Typography } from "@mui/material";
import RouteConstant from "navigation/constant";

const HomeServices = () => {
  const servicesList = [
    {
      title: "Visa application assistance",
      description:
        "Get assisted with your visa application with out support team  ",
      link: RouteConstant.VISA_ASSISTANT,
      Icon: HeadsetMicIcon,
    },
    {
      title: "Passport processing and renewal",
      description: "Learn more about passport processing and renewal",
      link: RouteConstant.PASSPORT_PROCESSING_AND_RENEWAL,
      Icon: SyncIcon,
    },
    {
      title: "Translation <br /> services",
      description: "Get help with <br /> translation",
      link: RouteConstant.TRANSLATION_SERVICE,
      Icon: TranslateIcon,
    },
    {
      title: "Notarization <br /> services",
      description: "Get Assisted with all your legal stuffs",
      link: RouteConstant.NOTARIZATION_SERVICE,
      Icon: CurrencyExchangeIcon,
    },
    {
      title: "Visa Application process",
      description: "Get to know your application process",
      link: RouteConstant.APPLICATION_PROCESS,
      Icon: SyncAltIcon,
    },
    {
      title: "Ticketing",
      description:
        "Book ticket for flight, accommodation, hotels, activities and many more ",
      link: RouteConstant?.TICKETING,
      Icon: ConfirmationNumberOutlinedIcon,
    },
    {
      title: "Tips for preparing for a trip abroad",
      description: "Get best tips to plan your abroad trips without any hustle",
      link:RouteConstant.TIPS_FOR_ABROAD,
      Icon: InfoOutlinedIcon,
      linkType: "external",
    },
    {
      title: "Resources",
      description:
        "Get hands on all the resource that help for your successfully trip",
      link: RouteConstant.RESOURCES,
      Icon: FileOpenIcon,
    },
    {
      title: "Contact Us",
      description: "Facing any issue ? don’t worry you can contact us",
      link: RouteConstant.CONTACT_US,
      Icon: RecordVoiceOverOutlinedIcon,
    },
    {
      title: "Apply For Visa",
      description: "Apply for my visa hassle free with ExpressVFS",
      link: RouteConstant?.APPLY_VISA,
      Icon: "",
    },
  ];
  return (
    <Box className="container" py={5}>
      <Typography variant="overline" mb={2}>
        Select Your Service
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {servicesList?.map(
          ({ title, description, link, linkType, Icon }, i) => (
            <Grid key={i} item xs={6} md={3} lg={2.4} xl={2.4}>
              <ServiceCard
                title={title}
                description={description}
                link={link}
                linkType={linkType}
                Icon={Icon}
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default HomeServices;
