//mui
import { useState, useEffect } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Card, CardContent } from "@mui/material";
//navigation
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Countdown from "react-countdown";
import { useToggleLoading } from "hooks";
import { GetRequest } from "functions/http";
import { RESEND_EMAIL } from "config/endpoints";
import { HTTP_ERROR } from "functions/http";
import { toast } from "react-toastify";
import RouteConstant from "navigation/constant";
const UserNotVerified = () => {
  const toggleLoading = useToggleLoading();
  const { email } = useParams();
  const [disable, setDisable] = useState(true);
  const [fresh, setFresh] = useState(false);
  const [lastTime, setLastTime] = useState(0);

  useEffect(() => {
    const getLastTime = Number(localStorage.getItem("resendEmail"));
    if (!getLastTime) {
      setDisable(false);
      return;
    }
    setLastTime(getLastTime);
    if (lastTime && new Date().getTime() - lastTime > 1000 * 65 * 1) {
      setDisable(false);
      return;
    }
    setDisable(true);
  }, [fresh, email, lastTime]);

  const handleResend = async () => {
    localStorage.setItem("resendEmail", new Date().getTime());
    toggleLoading(true);
    GetRequest(RESEND_EMAIL(email))
      .then((res) => {
        if (res.status) {
          setDisable(true);
          setFresh(!fresh);
          toast.success(res.msg || "Email sent");
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  const timeEnd = () => {
    setDisable(false);
    setLastTime(0);
  };
  const renderer = ({ minutes, seconds, completed }) => {
    if (!completed) {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    } else return null;
  };
  return (
    <Box className="p-center" height="100vh">
      <Card sx={{ width: 300 }}>
        <CardContent>
          <Box textAlign="center">
            <MailOutlineIcon sx={{ fontSize: 52 }} color="primary" />
          </Box>
          <h5 className=" text-center w-100 mb-2">
            We have sent an email to your email. Please verify your email.
          </h5>

          <Box display="flex" flexDirection="column" alignItems="center">
            {disable && lastTime ? (
              <Box
                sx={{
                  width: 150,
                  textAlign: "center",
                  border: "1px solid",
                  borderColor: "primary.main",
                  borderRadius: 4,
                  color: "primary.main",
                  fontWeight: "bold",
                }}
              >
                <Countdown
                  date={lastTime ? lastTime + 1000 * 65 * 1 : 0}
                  renderer={renderer}
                  onComplete={timeEnd}
                />
              </Box>
            ) : null}
            <Button
              onClick={handleResend}
              variant="contained"
              disabled={disable}
              sx={{ mt: 3 }}
            >
              Resend email
            </Button>
            <Link to={RouteConstant.HOME}>
              <Button>Home</Button>
            </Link>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default UserNotVerified;
