import { useState } from "react";
import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import { Typography, Box, IconButton } from "@mui/material";
import PaymentTable from "component/grid/table/adminPaymentTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PaymentFilter from "component/filter/payment";
import PaymentChart from "./component/paymentChart";
const PaymentAdmin = () => {
  const [filterButton, setFilterButton] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [filter, setFilter] = useState({
    category: "",
    method: "",
    startDate: null,
    endDate: null,
    minAmount: 0,
    maxAmount: 0,
  });
  return (
    <section>
      <section className="admin-settings-page">
        <AdminNavbar />
        <AdminHeader />

        <section className="wrapper container g-0">
          <Box mb={7}>
            <PaymentChart />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h5">All Payments</Typography>
            <div className={isFiltered ? "filter-badge" : ""}>
              <IconButton
                onClick={(event) => setFilterButton(event.currentTarget)}
              >
                <FilterAltIcon />
              </IconButton>
            </div>
          </Box>
          <Box>
            <PaymentTable
              bodyData={{
                searchKey: searchKey,
                ...filter,
              }}
            />
          </Box>
        </section>
        <PaymentFilter
          element={filterButton}
          onChange={(filter) => setFilter(filter)}
          onClose={() => setFilterButton(null)}
          value={filter}
          isFiltered={(value) => setIsFiltered(value)}
        />
      </section>
    </section>
  );
};

export default PaymentAdmin;
