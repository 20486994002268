import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import RouteConstant from "navigation/constant";

const defaultLinks = [
  { label: "Apply For Visa", link: RouteConstant?.APPLY_VISA },
  { label: "FAQ", link: RouteConstant?.FAQ },
  { label: "Contact Us", link: RouteConstant?.CONTACT_US },
];
const QuickLinks = ({ links = defaultLinks }) => {
  return (
    <Box className="container" pb={8}>
      <Typography variant="body1" color="grey.500">
        Quick Links
      </Typography>
      <Box display="flex" flexWrap="wrap" mt={2}>
        {Array?.isArray(links) && links?.length ? (
          links?.map((item, i) => (
            <Typography
              key={i}
              color="black.main"
              my={1}
              mr={{ xs: 4, lg: 10 }}
            >
              <Link to={item?.link}>
                {item?.label} <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
              </Link>
            </Typography>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default QuickLinks;
