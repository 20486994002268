export class ChangePWModel2 {
  oldPassword;
  password;
  confirmPassword;
  constructor(data = {}) {
    this.oldPassword = data.oldPassword || "";
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
  }
}
