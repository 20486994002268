export class CountrySettingModel {
  country;
  tax;
  charges;
  discount;
  onlyDiscountForBulk;
  countryType;
  constructor(data = {}) {
    this.country = data.country || null;
    this.tax = data.tax || 0;
    this.charges = data.charges || 0;
    this.discount = data.discount || 0;
    this.onlyDiscountForBulk = data.onlyDiscountForBulk || false;
    this.countryType = data.countryType || "";
  }
}
