import { Box, Grid, Typography } from "@mui/material";
import GirlPic from "assets/img/static/bg/women.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const HomeInfo = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box pt={12}>
      <div className="info-bg">
        <div className="info-bg-overlay"></div>
        <div className="info-content container">
          <Grid
            container
            // spacing={3}
            height={1}
            marginTop={0}
          >
            <Grid
              item
              xs={12}
              md={7}
              py={mobileSize ? 3 : 5}
              // order={mobileSize ? 1 : 2}
            >
              <Typography variant="h4" fontWeight="bold" mb={2}>
                ExpressVFS
              </Typography>
              <Typography variant="body2" mb={2}>
                Welcome to the ExpressVFS! We are here to help you with all of
                your visa needs. Whether you are traveling for business or
                pleasure, we can assist you in obtaining the appropriate visa
                for your trip.
              </Typography>
              <Typography variant="body2" mb={2}>
                Our team of experts is dedicated to providing fast and efficient
                service to help you obtain your visa quickly and easily. We
                understand that applying for a visa can be a daunting process,
                but we are here to make it as smooth and stress-free as
                possible.We offer a range of services, including assistance with
                completing and submitting your visa application, as well as
                providing guidance on the documentation and requirements needed
                for your specific visa type.
              </Typography>
              <Typography variant="body2">
                Thank you for choosing ExpressVFS. We look forward to helping
                you with your visa needs.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
              pt={mobileSize ? "480px" : 0}
              // order={mobileSize ? 0 : 1}
              position="relative"
            >
              <div className="resImg">
                <img src={GirlPic} alt="girl" className="info-right-image" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
};

export default HomeInfo;
