import AdminNavbar from "parts/navbar/adminNavbar";
import { Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { GET_ALL_APPS_BY_EMBASSY } from "config/endpoints";
import EmbassyApplicationTable from "component/grid/table/embassyApplicationTable";
import SearchIcon from "@mui/icons-material/Search";
import EmbassyFilter from "component/filter/embassyFilter";
const EmbassyAdminDashboard = () => {
  const [filter, setFilter] = useState({
    status: "",
    fromDate: null,
    toDate: null,
  });
  const [filterButton, setFilterButton] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  return (
    <section className="admin-all-bookings-page">
      <AdminNavbar />

      <section className="wrapper container g-0">
        <Box className="p-center" my={3} px={2}>
          <Box width={320}>
            <div className="search-box">
              <SearchIcon color="black.main" />
              <input
                onChange={(ev) => setSearchKey(ev.target.value)}
                placeholder="Search application "
                type="search"
                value={searchKey}
              />
            </div>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Typography variant="overline" fontSize={20}>
            All Bookings
          </Typography>
          <Box>
            <IconButton
              onClick={(event) => setFilterButton(event.currentTarget)}
            >
              <FilterAltIcon
                color={
                  filter.status !== "" || filter.fromDate || filter.toDate
                    ? "primary"
                    : "secondary"
                }
              />
            </IconButton>
          </Box>
        </Box>

        <Box my={4}>
          <EmbassyApplicationTable
            URL={
              GET_ALL_APPS_BY_EMBASSY +
              `?searchKey=${searchKey}&status=${filter.status}&fromDate=${filter.fromDate}&toDate=${filter.toDate}`
            }
          />
        </Box>

        <EmbassyFilter
          element={filterButton}
          onChange={(filter) => setFilter(filter)}
          onClose={() => setFilterButton(null)}
          value={filter}
        />
      </section>
    </section>
  );
};

export default EmbassyAdminDashboard;
