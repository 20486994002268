import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";
import NotarizationImage from "assets/img/static/bg/notarization-image.png";
import { Link } from "react-router-dom";

const PassportProcessingInfo = () => {
  const list = [
    {
      title: "New Passport Applications",
      description: `Applying for a new passport can be a complicated and time-consuming process, but our team of experts is here to help. We will work with you to ensure that your application is completed accurately and submitted on time. We will also provide guidance on the necessary documentation and requirements to obtain a new passport.`,
      link: "#",
    },
    {
      title: "Passport Renewals",
      description: `If your passport is set to expire soon, our passport renewal service can help you renew your passport quickly and easily. We will assist you in completing the necessary forms and provide guidance on the documentation required for renewal. Our team will also ensure that your application is submitted on time, so you receive your renewed passport in a timely manner.`,
      link: "#",
    },
    {
      title: "Expedited Passport Processing",
      description: `If you need your passport in a hurry, we offer expedited passport processing services. Our team will work with you to ensure that your application is expedited and submitted as quickly as possible. We will also provide guidance on the necessary documentation and requirements for expedited processing.`,
      link: "#",
    },
  ];
  return (
    <>
      <Box className="container">
        <Box py={5}>
          <Grid container spacing={3}>
            {list?.map(({ title, description, link }, i) => (
              <Grid item key={i} xs={12} md={4}>
                <StandardCard
                  title={title}
                  description={description}
                  link={link}
                  linkText="Apply Now"
                  titleColor="primary"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box pb={5}>
          <Typography variant="body1">
            At ExpressVFS, we understand that obtaining a passport can be a
            stressful and time-consuming process. That's why we offer
            comprehensive passport processing and renewal services to help you
            obtain or renew your passport quickly and easily. Contact us today
            to learn more about how we can assist you in obtaining your
            passport.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PassportProcessingInfo;
