import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import BookingDataCard from "./component/bookingDataCard";
import { Box, Button, IconButton, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
import AdminApplicationTable from "component/grid/table/adminApplicationTable";
import SlotByDateTable from "component/grid/table/slotByDateTable";
import {
  COUNT_ALL_APPLICATION,
  COUNT_REMAIN_APPLICATION,
  COUNT_DONE_APPLICATION,
  GET_RECENT_APPLICATION,
  GET_SLOTS_DETAILS,
} from "config/endpoints";
import ApplicationChart from "./component/appliactionChart";
import Filter from "component/filter";
const AdminDashboard = () => {
  const toggleLoading = useToggleLoading();
  const [allCount, setAllCount] = useState(0);
  const [allType, setAllType] = useState("day");
  const [isFiltered, setIsFiltered] = useState(false);

  const [remainCount, setRemainCount] = useState(0);
  const [remainType, setRemainType] = useState("day");

  const [doneCount, setDoneCount] = useState(0);
  const [doneType, setDoneType] = useState("day");

  const [filter, setFilter] = useState({
    status: "",
    method: "",
    fromDate: null,
    toDate: null,
    minAmount: 0,
    maxAmount: 0,
    paymentStatus: 1,
  });
  const [filterButton, setFilterButton] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(COUNT_ALL_APPLICATION + "?type=" + allType)
        .then((res) => {
          if (res.data?.status) {
            setAllCount(res.data.data);
          } else {
            toast.error(res.data.msg);
            setAllCount(0);
          }
        })
        .catch((er) => {
          setAllCount(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [allType]);
  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(COUNT_REMAIN_APPLICATION + "?type=" + remainType)
        .then((res) => {
          if (res.data?.status) {
            // setRemainCount(res.data.data);
          } else {
            toast.error(res.data.msg);
            setRemainCount(0);
          }
        })
        .catch((er) => {
          setRemainCount(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [remainType]);

  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(COUNT_DONE_APPLICATION + "?type=" + doneType)
        .then((res) => {
          if (res.data?.status) {
            setDoneCount(res.data.data);
          } else {
            // toast.error(res.data.msg);
            setDoneCount(0);
          }
        })
        .catch((er) => {
          setDoneCount(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [doneType]);

  return (
    <section className="admin-dashboard-page">
      <AdminNavbar />
      <AdminHeader onSearchChange={(value) => setSearchKey(value)} />
      <section className="wrapper container g-0 content-height-2">
        <Box mb={8}>
          <ApplicationChart />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <BookingDataCard
            heading="Total Applications"
            data={allCount}
            activeOption={allType}
            onClick={(v) => setAllType(v)}
          />
          <BookingDataCard
            heading="Remaining Application"
            data={remainCount}
            activeOption={remainType}
            onClick={(v) => setRemainType(v)}
          />
          <BookingDataCard
            heading="Done Applications"
            data={doneCount}
            activeOption={doneType}
            onClick={(v) => setDoneType(v)}
          />
        </Box> */}
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Typography variant="overline" fontSize={20}>
            Booking
          </Typography>
          <Box>
            <Box display="flex" alignItems="baseline">
              <div className={isFiltered ? "filter-badge" : ""}>
                <IconButton
                  onClick={(event) => setFilterButton(event.currentTarget)}
                >
                  <FilterAltIcon />
                </IconButton>
              </div>
              <Button>
                <Link
                  className="dark-link ms-2"
                  to={RouteConstant.ALL_BOOKINGS}
                >
                  View all
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <AdminApplicationTable
            URL={
              GET_RECENT_APPLICATION +
              `?searchKey=${searchKey}&status=${filter.status}&fromDate=${filter.fromDate}&toDate=${filter.toDate}&minAmount=${filter.minAmount}&maxAmount=${filter.maxAmount}&method=${filter.method}&paymentStatus=${filter.paymentStatus}`
            }
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Typography variant="overline" fontSize={20}>
              Today Appointment Slots
            </Typography>
            <Box>
              <Link
                className="dark-link ms-2"
                to={RouteConstant.ALL_BOOKING_SLOTS}
              >
                View all
              </Link>
            </Box>
          </Box>
          <Box>
            <SlotByDateTable
              URL={GET_SLOTS_DETAILS + "?"}
              bodyData={{ date: new Date() }}
            />
          </Box>
        </Box>
      </section>

      <Filter
        element={filterButton}
        onChange={(filter) => setFilter(filter)}
        onClose={() => setFilterButton(null)}
        value={filter}
        isFiltered={(value) => setIsFiltered(value)}
      />
    </section>
  );
};

export default AdminDashboard;
