import { Box, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import HeaderImage from "assets/img/static/bg/tips-abroad-header.png";
import { Link } from "react-router-dom";

const TipsAbroadHeader = () => {
  return (
    <Box pt={5}>
      <Box className="container" color="black.main">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box pb={5}>
              <NavigateToBack />

              <Title
                title="Tips for preparing <br /> for a trip abroad"
                fontFamilyClass="fm-poppins"
                fontSize={42}
                fontWeight={700}
              />
              <Typography variant="body1" fontWeight={400} mb={2} mt={2}>
                Preparing for a trip abroad can be both exciting and
                overwhelming, as there are many things to consider and arrange
                before you go. Here are some tips to help you prepare for a
                successful and enjoyable trip.
              </Typography>
              <Typography
                variant="body1"
                fontWeight={700}
                letterSpacing={1}
                textTransform="uppercase"
              >
                <Link>Apply Now</Link>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            position="relative"
          >
            <Box className="travel-abroad-header-image-text">
              <Title
                title="TRAVEL"
                fontFamilyClass="fm-poppins"
                fontSize={100}
                fontWeight={800}
                variant="h4"
              />
              <Box ml={5}>
                <Title
                  title="ABROAD"
                  fontFamilyClass="fm-poppins"
                  fontSize={100}
                  fontWeight={800}
                  variant="h4"
                />
              </Box>
            </Box>
            <Box className="resImg" width="100%">
              <img src={HeaderImage} alt="Welcome" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TipsAbroadHeader;
