import { Box, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import HeaderImage from "assets/img/static/bg/translation-header.png";

const TranslationHeader = () => {
  return (
    <Box pt={5}>
      <Box className="container" color="black.main">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box pb={5}>
              <NavigateToBack />
              <Box pt={5}>
                <Title
                  title="Translation services"
                  fontFamilyClass="fm-poppins"
                  fontSize={42}
                  fontWeight={700}
                />
                <Typography variant="body1" fontWeight={400} mb={2} mt={2}>
                  Translation services are a vital tool in today's globalized
                  world, where businesses and individuals often need to
                  communicate across language barriers
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            position="relative"
            overflow="hidden"
          >
            <Box className="resImg" width="100%">
              <img src={HeaderImage} alt="Welcome" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TranslationHeader;
