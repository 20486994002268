export class ErrorCreateUserModel {
  agencyNameError;
  phoneError;
  addressError;
  nameError;
  emailError;
  passwordError;
  confirmPasswordError;
  role;
  countriesError;
  constructor(data = {}) {
    this.agencyNameError = data.agencyNameError || "";
    this.phoneError = data.phoneError || "";
    this.addressError = data.addressError || "";
    this.nameError = data.nameError || "";
    this.emailError = data.emailError || "";
    this.passwordError = data.passwordError || "";
    this.confirmPasswordError = data.confirmPasswordError || "";
    this.role = data.role || "";
    this.countriesError = data.countriesError || "";
  }
}
