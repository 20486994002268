import {
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
} from "@mui/material";

import Navbar from "parts/navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { CREATE_CONTACT } from "config/endpoints";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ContactModel } from "_models/data/data.contact.model";
import { ErrorContactModel } from "_models/error/errors.contact.model";
import { ValidateContact } from "functions/validators";
// CREATE_CONTACT
const ContactUs = () => {
  const user = useSelector((state) => state.auth.user);
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const [state, setState] = useState(new ContactModel());
  const [error, setError] = useState(new ErrorContactModel());
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const handleSubmit = () => {
    const isValid = ValidateContact(state);
    if (isValid.valid) {
      toggleLoading(true);
      const data = {
        user: user._id,
        ...state,
      };
      PostRequest(CREATE_CONTACT, data)
        .then((res) => {
          if (res.data.status) {
            toast.success("Message sent.");
            setState(new ContactModel());
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  return (
    <section className="Contact-page">
      <Navbar />
      <section className="wrapper container g-0 content-height">
        <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
        >
          <Box
            width={mobileSize ? "100%" : 600}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              variant={mobileSize ? "h3" : "h2"}
              fontWeight="bolder"
              color="primary.main"
              mb={2}
            >
              Contact
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="center"
              color="grey.500"
              mb={3}
            >
              We are always at your service, Please Fill your queries.
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Full Name"
                  fullWidth
                  placeholder="Enter Full Name"
                  error={error.nameError !== ""}
                  helperText={error.nameError}
                  onFocus={() => handleFocus("nameError")}
                  value={state.name}
                  name="name"
                  onChange={(ev) => handleChange(ev, "name")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Passport Number"
                  fullWidth
                  placeholder="Enter Passport Number"
                  value={state.passport}
                  name="passport"
                  onChange={(ev) => handleChange(ev, "passport")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email Id"
                  fullWidth
                  placeholder="Enter Email Id"
                  type="email"
                  error={error.emailError !== ""}
                  helperText={error.emailError}
                  onFocus={() => handleFocus("emailError")}
                  value={state.email}
                  name="email"
                  onChange={(ev) => handleChange(ev, "email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  fullWidth
                  placeholder="Enter Your Query"
                  multiline
                  rows={4}
                  error={error.messageError !== ""}
                  helperText={error.messageError}
                  onFocus={() => handleFocus("messageError")}
                  value={state.message}
                  name="message"
                  onChange={(ev) => handleChange(ev, "message")}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </section>
    </section>
  );
};

export default ContactUs;
