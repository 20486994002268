import { Box, Grid, IconButton, Typography, Chip, Button } from "@mui/material";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PaymentReceipt from "component/pdf/paymentReceipt";
import {
  GET_BULK_DETAILS_ADMIN,
  GET_ALL_APPLICATION_BY_BULK_ADMIN,
} from "config/endpoints";
import NoResult from "parts/noResult";
import moment from "moment";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector } from "react-redux";
import RouteConstant from "navigation/constant";
import { GetPayMethod } from "functions/outputFunctions";
import AdminApplicationTable from "component/grid/table/adminApplicationTable";
const BulkApplicationDetails = () => {
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const { id } = useParams();
  const userRole = useSelector((state) => state.auth.user.role);
  const [details, setDetails] = useState(null);
  const [filter, setFilter] = useState({
    status: "",
    fromDate: null,
    toDate: null,
  });

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const handleOpenFilter = (event) => {
    // setFilterAnchorEl(event.currentTarget);
    console.log(event);
    console.log(event.currentTarget);
  };
  const handleClose = () => {
    setFilterAnchorEl(null);
  };

  useEffect(() => {
    let fetchData = () => {
      if (!id) {
        return;
      }
      toggleLoading(true);
      GetRequest(GET_BULK_DETAILS_ADMIN(id))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res.data.data);
          } else {
            // toast.error(res.data.msg);
            setDetails(null);
          }
        })
        .catch((er) => {
          setDetails(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);
  const handleClearFilter = () => {
    setFilter({ status: "", fromDate: null, toDate: null });
    handleClose();
  };
  if (userRole === "basic") {
    navigate(RouteConstant.HOME);
    return null;
  }
  console.log("details", details);

  return (
    <section>
      <Navbar />
      <section className="wrapper container g-0 content-height">
        <Box mb={4}>
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography variant="overline" ml={2} fontSize={18}>
            Bulk Application Details
          </Typography>
        </Box>
        <Box>
          {details ? (
            <Box>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "grey.200",
                  borderRadius: 3,
                  my: 4,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Agency</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.agencyDetails?.agencyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Bulk ID</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.bulkId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">
                      Total Application
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.applications?.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Date</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {moment(details.paymentDetails?.date).format("D MMM Y")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Fees</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      $ {details?.fees}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Tax</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      $ {details?.tax}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Discount</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      $ {details?.discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Total Charges</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      $ {details?.total}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Payment Method</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {GetPayMethod(details?.paymentMethod)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Payment Status</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.isPaid ? (
                        <Chip label="Paid" color="success" />
                      ) : (
                        <Chip label="Not Paid" color="error" />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Payment Id</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      <Link
                        to={
                          RouteConstant.PAYMENT_DETAILS +
                          "/" +
                          details?.paymentDetails?._id
                        }
                      >
                        {details?.paymentDetails?.paymentId}
                      </Link>
                    </Typography>
                  </Grid>
                  {details.slotDetails?.length > 0
                    ? details.slotDetails?.map((slot, index) => {
                        return (
                          <Grid item xs={12} md={4} lg={3} key={slot._id}>
                            <Typography variant="subtitle2">
                              Booked Slot {index + 1}
                            </Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                              <Link
                                to={
                                  RouteConstant.SLOTS_DETAILS + "/" + slot._id
                                }
                              >
                                {moment(slot.from).format("DD MMM-HH:mm")}
                              </Link>
                            </Typography>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button variant="outlined" title="Download payment receipt">
                  <PDFDownloadLink
                    style={{ color: "#000" }}
                    document={
                      <PaymentReceipt
                        billTo={details?.agencyDetails?.agencyName}
                        salesNo={details.bulkId}
                        date={details?.slotDetails?.[0]?.date}
                        payMethod={GetPayMethod(details.paymentMethod)}
                        total={details.isPaid ? details.total : 0}
                        due={details.isPaid ? 0 : details.total}
                        serviceName="VISA"
                        description="SERVICE FEE"
                        qty={details.totalApplied}
                        rate={(details.total/details.totalApplied).toFixed(2)}
                        amount={details.total}
                      />
                    }
                    fileName="visa-payment-receipt.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading
                        ? "Loading document..."
                        : "Download payment receipt"
                    }
                  </PDFDownloadLink>
                </Button>
              </Box>
              <Box my={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography variant="h5"> All application</Typography>
                  <IconButton onClick={handleOpenFilter}>
                    <FilterAltIcon />
                  </IconButton>
                </Box>
                <AdminApplicationTable
                  URL={
                    GET_ALL_APPLICATION_BY_BULK_ADMIN(details._id) +
                    `?status=${filter.status}&fromDate=${filter.fromDate}&toDate=${filter.toDate}`
                  }
                />
              </Box>
            </Box>
          ) : (
            <Box className="p-center" my={3}>
              <NoResult />
            </Box>
          )}
        </Box>
      </section>
    </section>
  );
};

export default BulkApplicationDetails;
