import HomeHeader from "./component/header";
import HomeServices from "./component/services";
import HomeInfo from "./component/info";
import SelectCountry from "./component/selectCountry";
import Subscribe from "./component/subscribe";

import { Helmet } from "react-helmet";
import HomeContactUs from "./component/contactUs";
const Home = () => {
  return (
    <div className="home-page">
      <Helmet>
        <title>Express Visa Facilitation Services</title>
      </Helmet>
      <HomeHeader />
      <HomeServices />
      <HomeInfo />
      <SelectCountry />
      <Subscribe />

      <HomeContactUs />
    </div>
  );
};

export default Home;
