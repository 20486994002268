import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { Box } from "@mui/material";
import SupportColumn from "../columns/support";

const SupportTable = ({
  width,
  height,
  URL,
  onDetailClick = () => {},
  ...props
}) => {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);

  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    `${URL}`
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        email: el?.email,
        phone: el?.phone,
        passport: el?.passport,
        message: el?.message,
        isSolved: el?.isSolved,
        view: el?._id,
      };
    });
    setRows(value);
  }, [data]);
  const handleDetails = (data) => {
    if (data.field === "view") {
      onDetailClick(data.id);
    }
  };
  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={SupportColumn}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        components={{ Toolbar: GridToolbar }}
        disableColumnSelector
        disableDensitySelector
        onCellClick={handleDetails}
      />
    </Box>
  );
};

export default SupportTable;
