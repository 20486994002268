import { useState, useEffect, useCallback } from "react";

import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import LaptopPic from "assets/img/static/bg/laptop.png";
import LogoIcon from "assets/img/static/icon/logo_icon.png";
import { Link, useNavigate, useParams } from "react-router-dom";

//model
import { ChangePWModel } from "_models/data/data.changePW.model";
import { ErrorChangePWModel } from "_models/error/errors.changePW.model";
import { ValidateChangePWForm } from "functions/validators";
//function
import { useToggleLoading } from "hooks";
import { PostRequest } from "functions/http";
import { PASSWORD_CHANGE } from "config/endpoints";
import { HTTP_ERROR } from "functions/http";
import { toast } from "react-toastify";
import RouteConstant from "navigation/constant";

const ChangePassword = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const { token } = useParams();
  //local state
  const [error, setError] = useState(new ErrorChangePWModel());
  const [state, setState] = useState(new ChangePWModel());

  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const handleSubmit = useCallback(() => {
    const isValid = ValidateChangePWForm(state);
    console.log(isValid);
    if (isValid.valid) {
      toggleLoading(true);
      const data = {
        password: state.password,
        verified_token: token,
      };
      PostRequest(PASSWORD_CHANGE, data)
        .then((res) => {
          if (res.data.status) {
            navigate(`${RouteConstant.LOGIN}`);
            toast.success("Password has been changed.");
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  }, [state]);
  useEffect(() => {
    const enterHit = (ev) => {
      if (ev.keyCode === 13 || ev.which === 13) {
        handleSubmit();
      }
    };
    window.addEventListener("keydown", enterHit);
    return () => {
      window.removeEventListener("keydown", enterHit);
    };
  }, [handleSubmit]);
console.log("error",error);
  return (
    <section className="change-password-page ">
      <Grid container spacing={3} className="content-height">
        <Grid md={6} xs={12} item>
          <div className="left-carousel-container p-center px-3 px-lg-0">
          <Swiper
              modules={[Pagination]}
              className="lef-auth-swiper"
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide>
                <Box>
                  <div className="text-center resImg">
                    <img src={LaptopPic} alt="laptop" />
                  </div>
                  <Box>
                    <Typography
                      variant="h6"
                      color="white.main"
                      textAlign="center"
                      mb={2}
                    >
                      Apply VISA From Anywhere
                    </Typography>
                    <Typography
                      variant="body2"
                      maxWidth={400}
                      color="white.main"
                      mx="auto"
                      textAlign="center"
                      mb={14}
                    >
                      Apply for Visas Anywhere with Our Easy-to-Use Online
                      Application System. Submit Your Application in Minutes and
                      Get Your Visa Quickly and Hassle-Free. No Matter Where
                      You're Going, We've Got You Covered!
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
             
            </Swiper>
          </div>
        </Grid>
        <Grid md={6} xs={12} item>
          <Box px={3} py={4} className="p-center">
            <Box width="min(100%,350px)">
              <Box>
                <div>
                  <Link to={RouteConstant.HOME}>
                    <img src={LogoIcon} alt="logo icon" width={80} />
                  </Link>
                </div>
                <Typography variant="h5" fontWeight={600}>
                  Change Password
                </Typography>
                <Typography variant="subtitle2" color="grey.400">
                  Forget password ! Don’t worry we got you
                </Typography>
              </Box>

              <Box mt={6}>
                <Typography variant="subtitle1" mb={1} fontWeight={600}>
                  Password
                </Typography>
                <TextField
                  label="Enter password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  onChange={(ev) => handleChange(ev, "password")}
                  error={error.passwordError !== ""}
                  onFocus={() => handleFocus("passwordError")}
                  value={state.password}
                  helperText={error.passwordError}
                />
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle1" mb={1} fontWeight={600}>
                  Confirm Password
                </Typography>
                <TextField
                  label="Enter confirm password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  onChange={(ev) => handleChange(ev, "confirmPassword")}
                  error={error.confirmPasswordError !== ""}
                  onFocus={() => handleFocus("confirmPasswordError")}
                  value={state.confirmPassword}
                  helperText={error.confirmPasswordError}
                />
              </Box>
              <Button
                variant="contained"
                sx={{ width: 300, my: 4, mx: "auto" }}
                size="large"
                onClick={handleSubmit}
              >
                Change password
              </Button>
              <Button fullWidth onClick={() => navigate(-1)}>
                Back
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default ChangePassword;
