import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import WelcomeImage from "assets/img/static/bg/saudi-arabia-welcome.png";
import WelcomeImageBg from "assets/img/static/bg/saudi-arabia-welcome-bg.png";

const SaudiArabiaWelcome = () => {
  return (
    <Box pt={5}>
      <Box className="container" color="black.main">
        <Grid container position="relative">
          <Grid item xs={12} lg={5}>
            <Box pb={5}>
              <NavigateToBack />
              <Typography
                variant="body1"
                textTransform="uppercase"
                fontWeight={600}
                letterSpacing={1}
                mb={1}
              >
                Welcome to
              </Typography>
              <Title title="The Kingdom of" color="inherit" fontWeight={600} />
              <Title title="Saudi Arabia " />
              <Typography variant="body1" fontWeight={400} mb={1} mt={8}>
                Saudi Arabia, officially the Kingdom of Saudi Arabia is a
                country in Western Asia. It covers the bulk of the Arabian
                Peninsula, and has a land area of about 2,150,000 km2 (830,000
                sq mi), making it the fifth-largest country in Asia, the
                second-largest in the Arab world, and the largest in Western
                Asia and the Middle East.
              </Typography>
              <Box className="d-flex" mt={2}>
                <Button
                  disableElevation
                  variant="contained"
                  className="rounded-2"
                  sx={{
                    my: 1,
                    mr: 2,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Apply For Visa
                </Button>
                <Button
                  disableElevation
                  variant="outlined"
                  className="rounded-2"
                  color="secondary"
                  sx={{
                    my: 1,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Track my visa
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7} display="flex" alignItems="flex-end">
            <Box className="resImg" pt={{ lg: 20 }} zIndex={-1}>
              <Box
                sx={{
                  backgroundImage: `url(${WelcomeImageBg})`,
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  height: "100%",
                  zIndex: "-1",
                  backgroundSize: "contain",
                  backgroundRepeat: " no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <img src={WelcomeImage} alt="Welcome" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SaudiArabiaWelcome;
