import { ThemeProvider } from "@mui/material";
import Theme from "assets/theme/index";
import { Provider } from "react-redux";
import store from "state/store";
import { BrowserRouter } from "react-router-dom";
import Navigation from "navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
function App() {
  moment.tz.setDefault("Africa/Kampala");
  return (
    <div className="App ">
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <BrowserRouter>
            <ToastContainer
              position="top-center"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Navigation />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
