import { BLOB } from "functions/http";
import { useCallback } from "react";

const useDownload = () => {
  const handleDownload = useCallback((path, fileName) => {
    BLOB("GET", `/download?path=${path}`).then((response) => {
      if (response) {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName || "file");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    });
  }, []);
  return handleDownload;
};

export default useDownload;
