import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";
import { Link } from "react-router-dom";
import UAEImage from "assets/img/static/bg/uae-image.png";

const UAEInfo = () => {
  const list = [
    {
      title: "Dubai ",
      description: `Dubai is a global hub for business, tourism, and luxury living. It is known for its iconic landmarks, such as the Burj Khalifa, the world's tallest building, and the Palm Jumeirah, an artificial island shaped like a palm tree. Dubai is also home to some of the world's best shopping destinations, including the Dubai Mall and the Mall of the Emirates.`,
      link: "#",
    },
    {
      title: "Abu Dhabi",
      description: `Abu Dhabi is the capital city of the UAE and is known for its stunning architecture, world-class museums, and beautiful beaches. It is also home to some of the world's largest oil reserves, making it an important center for the oil and gas industry.`,
      link: "#",
    },
    {
      title: "Sharjah ",
      description: `Sharjah is known for its cultural heritage and is often referred to as the cultural capital of the UAE. It is home to numerous museums, including the Sharjah Art Museum and the Sharjah Archaeology Museum, as well as several traditional souks, or markets, where visitors can purchase local handicrafts and goods.`,
      link: "#",
    },
    {
      title: "Ras Al Khaimah",
      description: ` Ras Al Khaimah is located in the northernmost part of the UAE and is known for its stunning natural beauty. It is home to the Jebel Jais mountain, the highest mountain peak in the UAE, as well as several pristine beaches and natural hot springs.`,
      link: "#",
    },
    {
      title: "Fujairah ",
      description: `Fujairah is located on the east coast of the UAE and is known for its scenic beauty and historical landmarks. It is home to the Fujairah Fort, a 17th-century fortification that has been restored and is now a popular tourist attraction, as well as several beaches and dive sites that are popular with water sports enthusiasts.`,
      link: "#",
    },
    {
      title: "Ajman  ",
      description: `Ajman is one of the smallest emirates in the UAE, located on the coast of the Persian Gulf. It is known for its beautiful beaches, cultural attractions, and traditional souks. The Ajman Museum, housed in an 18th-century fort, is a popular attraction that offers a glimpse into the region's rich history.`,
      link: "#",
    },
    {
      title: "Umm Al Quwain",
      description: `Umm Al Quwain is located on the coast of the Arabian Gulf and is known for its natural beauty and outdoor activities. It is home to several nature reserves, including the Al Sinniyah Island Nature Reserve, where visitors can observe a variety of bird species, as well as the Dreamland Aqua Park, a popular water park that offers rides and attractions for all ages.`,
      link: "#",
    },
    {
      title: "Al Ain",
      description: `Al Ain is located in the eastern part of the Abu Dhabi Emirate and is known for its lush greenery, historic landmarks, and cultural attractions. It is home to the Al Ain Oasis, a UNESCO World Heritage Site, as well as several museums and galleries, including the Al Ain National Museum and the Al Ain Palace Museum`,
      link: "#",
    },
  ];
  return (
    <Box className="container">
      <Box pt={10}>
        <Typography variant="body1">
          Whether you're traveling to the UAE for business or pleasure, we are
          here to help simplify the visa application process for you. Contact us
          today to learn more about our UAE visa services and how we can assist
          you in obtaining your UAE visa.
        </Typography>
      </Box>
      <Box maxWidth={1100} margin="auto" py={5}>
        <Grid container spacing={3}>
          {list?.map(({ title, description, link }, i) => (
            <Grid item key={i} xs={12} sm={6} md={4}>
              <StandardCard
                title={title}
                description={description}
                link={link}
                linkText="Apply Now"
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              className="standard-card hovered d-flex flex-column justify-content-center"
              p={5}
              sx={{
                backgroundImage: `url(${UAEImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Typography variant="h6" fontWeight={700} className="title">
                Explore United <br />
                Arab Emirates <br /> Today
              </Typography>

              <Typography variant="body2" mt={3}>
                <Link to={""}>Apply</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UAEInfo;
