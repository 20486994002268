import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "assets/img/static/icon/logo3.png";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 10,
    paddingTop: 15,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 15,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "row",
    fontSize: 8,
    marginBottom: 18,
  },
  inputItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  logo: {
    width: 130,
    borderRightWidth: 0.6,
    borderColor: "#5d5d5d",
    borderBottomStyle: "solid",
    paddingRight: 10,
    // height: 60,
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  dashedText: {
    flex: 1,
    borderBottomWidth: 0.3,
    borderColor: "#5d5d5d",
    borderBottomStyle: "dashed",
    paddingLeft:15,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  dashedText2: {
    borderBottomWidth: 0.3,
    borderColor: "#5d5d5d",
    borderBottomStyle: "dashed",
  },
});

const PassportReceipt = ({
  name,
  passPortNo,
  ApplicationNo,
  agencyName,
  submissionDate,
  lockerNo,
}) => (
  <Document>
    <Page size={[550, 220]} style={styles.page}>
      <View style={styles.header}>
        <View>
          <Image src={Logo} style={styles.logo} />
        </View>
        <View>
          <Text>info@expressvfs.com</Text>
          <Text>www.expressvfs.com</Text>
        </View>
        <View>
          <Text>1st Floor, Room 10, Kanjokya House</Text>
          <Text>Plot 90, Kanjokya Street, Kampala - Uganda</Text>
          <Text>P. O. Box 112768, Kampala</Text>
          <Text>+256 393 193 675 | +256 775 937 100</Text>
        </View>
        <View style={styles.inputItem}>
          <Text style={styles.bold}>No.</Text>
          <Text style={styles.dashedText2}>{ApplicationNo}</Text>
        </View>
      </View>
      <View style={styles.inputItem}>
        <Text>Name :</Text>
        <Text style={styles.dashedText}>{name}</Text>
      </View>
      <View style={styles.inputItem}>
        <Text>Number of Passports:</Text>
        <Text style={styles.dashedText}>{passPortNo}</Text>
      </View>
      <View style={styles.inputItem}>
        <Text>Agent’s Name:</Text>
        <Text style={styles.dashedText}>{agencyName}</Text>
      </View>
      <View style={styles.inputItem}>
        <Text>Submission Date:</Text>
        <Text style={styles.dashedText}>{moment(submissionDate).format("DD/MM/YYYY")}</Text>
      </View>
      <View style={styles.inputItem}>
        <Text>Collection Date:</Text>
        <Text style={styles.dashedText}></Text>
      </View>
      <View style={styles.inputItem}>
        <Text>Locker No:</Text>
        <Text style={styles.dashedText}>{lockerNo}</Text>
      </View>
    </Page>
  </Document>
);
export default PassportReceipt;
