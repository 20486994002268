import React, { useEffect, useState } from "react";
import TextEditor from "component/textEditor";

import AdminNavbar from "parts/navbar/adminNavbar";
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import usePhotoReview from "config/photoReview";
import { BlogModel } from "_models/data/data.blog.model";
import { ErrorBlogModel } from "_models/error/error.blog.model";
import moment from "moment";
import { toast } from "react-toastify";
import { GetRequest, HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { ValidateBlog } from "functions/validators";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import {
  CREATE_NEWS,
  UPDATE_NEWS,
  GET_NEWS_BY_ID,
  DELETE_NEWS_BY_ID,
  DOMAIN,
} from "config/endpoints";
import { PostRequest } from "functions/http";
import { useSearchParams, useNavigate } from "react-router-dom";
import RouteConstant from "navigation/constant";
import DeleteIcon from "@mui/icons-material/Delete";
import useFile from "hooks/useFile";

const CreateNews = () => {
  const toggleLoading = useToggleLoading();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("id");
  //local state
  const [state, setState] = useState(new BlogModel());
  const [error, setError] = useState(new ErrorBlogModel());
  const [photo, preview, setPhoto] = usePhotoReview("");
  const [refresh, setRefresh] = useState(false);
  const fileValidation = useFile();

  const handleEditor = (value) => {
    setState((state) => ({ ...state, description: value }));
  };
  const handleChange = (event, name) => {
    setState((state) => ({ ...state, [name]: event.target.value }));
  };
  const fileChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setPhoto(undefined);
      return;
    }
    const { isError, errorMsg } = fileValidation({
      fileTypes: ["png", "jpg", "jpeg", "PNG"],
      maxSize: 1024 * 1024 * 1,
      file: e?.target?.files[0],
    });
    if (isError) {
      toast.error(errorMsg);
      return;
    }
    setPhoto(e.target.files[0]);
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      GetRequest(GET_NEWS_BY_ID(id))
        .then((res) => {
          if (res.data.status) {
            const _data = res.data?.data;
            const tags = Array.isArray(_data.tags)
              ? _data.tags.join("#")
              : _data.tags;
            const data = {
              ..._data,
              tags: tags,
            };
            setState(new BlogModel(data));
            setPhoto(DOMAIN + _data?.image?.path);
          } else {
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id, refresh]);
  const handleSubmit = () => {
    if (!photo) {
      toast.error("Please select photo.");
      return;
    }
    const isValid = ValidateBlog(state);
    if (isValid.valid) {
      toggleLoading(true);
      let url = id ? UPDATE_NEWS(id) : CREATE_NEWS;
      const formData = new FormData();
      formData.append("title", state.title);
      formData.append("description", state.description);
      formData.append("isAd", state.isAd);
      formData.append("isFeatured", state.isFeatured);
      formData.append("adFor", state.adFor);
      if (typeof photo === "object") {
        formData.append("news", photo);
      }
      if (state.tags) {
        const allTags = state?.tags?.trim()?.split("#");
        if (Array.isArray(allTags)) {
          allTags.forEach((e) => formData.append("tags", e));
        }
      }
      PostRequest(url, formData, true)
        .then((res) => {
          if (res.data.status) {
            // setState(new UserLoginModel());
            toast.success(id ? "News updated" : "New news added");
            // setRefresh(!refresh);
            navigate(RouteConstant.ALL_NEWS);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  const handleDelete = () => {
    const confirm = window.confirm("Are you sure to delete this news ?");
    if (confirm) {
      PostRequest(DELETE_NEWS_BY_ID(id))
        .then((res) => {
          if (res.data.status) {
            toast.success("News deleted.");
            navigate(RouteConstant.ALL_NEWS);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    }
  };

  return (
    <section className="admin-create-news-page">
      <AdminNavbar />
      <section className="wrapper container g-0 content-height-2">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="h5" mb={3}>
            Create News
          </Typography>
          {id ? (
            <Button
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
              onClick={handleDelete}
            >
              Delete
            </Button>
          ) : null}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {
              <Box>
                <div style={{ position: "relative" }}>
                  <input
                    type="file"
                    className="hidden-file-input"
                    onChange={(e) => fileChange(e, setPhoto)}
                  />
                  <Box
                    sx={{
                      border: "1px solid #efefef",
                      borderRadius: 2,
                      p: 1,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CameraAltOutlinedIcon sx={{ fontSize: 50 }} />
                    <span>
                      {photo
                        ? typeof photo === "string"
                          ? photo
                          : photo.name
                        : "No file chosen"}
                    </span>
                  </Box>
                </div>
              </Box>
            }
            <TextField
              fullWidth
              label="News Title"
              placeholder="Enter news title"
              sx={{ my: 3 }}
              error={error.titleError !== ""}
              helperText={error.titleError}
              onFocus={() => handleFocus("titleError")}
              name="title"
              value={state.title}
              onChange={(ev) => handleChange(ev, "title")}
            />
            <TextEditor
              value={state.description}
              onChange={handleEditor}
              placeholder="Enter news description"
              error={error.descriptionError}
              onFocus={() => handleFocus("descriptionError")}
            />
            <TextField
              fullWidth
              label="Tags"
              placeholder="Enter tags separated by # : #apple#dolphin#mango"
              sx={{ my: 3 }}
              name="tags"
              value={state.tags}
              onChange={(ev) => handleChange(ev, "tags")}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isFeatured}
                  onChange={(ev) =>
                    setState({ ...state, isFeatured: ev.target.checked })
                  }
                />
              }
              label="Featured"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isAd}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(ev) =>
                    setState({ ...state, isAd: ev.target.checked })
                  }
                />
              }
              label="Advertise"
            />
            {state.isAd && (
              <TextField
                fullWidth
                label="Ad for"
                placeholder="Enter adds for"
                sx={{ my: 3 }}
                name="adFor"
                value={state.adFor}
                onChange={(ev) => handleChange(ev, "adFor")}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Preview" />
              <Box position="relative">
                <CardMedia
                  component="img"
                  height="194"
                  image={preview}
                  alt="News Image"
                />
                <Box sx={{ position: "absolute", bottom: 10, left: 10 }}>
                  {state.isFeatured && (
                    <Typography
                      variant="caption"
                      bgcolor="grey.200"
                      borderRadius={1}
                      p={1}
                    >
                      Featured
                    </Typography>
                  )}
                  {state.isAd && (
                    <Typography
                      variant="caption"
                      bgcolor="blue"
                      borderRadius={1}
                      color="white.main"
                      p={1}
                      ml={2}
                    >
                      Ad
                    </Typography>
                  )}
                </Box>
              </Box>

              <CardContent>
                <Typography variant="h5" mt={3}>
                  {state.title}
                </Typography>
                <Typography variant="caption" color="grey.500">
                  {moment(new Date(state.date)).format("D MMM Y")} &nbsp;
                  &#x2022; &nbsp;
                  {state.isAd ? state.adFor + " Ad" : null}
                </Typography>
                <Box mt={3}>
                  <div
                    dangerouslySetInnerHTML={{ __html: state.description }}
                  ></div>
                </Box>
              </CardContent>
            </Card>
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button variant="contained" size="large" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </section>
    </section>
  );
};

export default CreateNews;
