export class ErrorChangePWModel2 {
  oldPasswordError;
  passwordError;
  confirmPasswordError;
  constructor(data = {}) {
    this.oldPasswordError = data.oldPasswordError || "";
    this.passwordError = data.passwordError || "";
    this.confirmPasswordError = data.confirmPasswordError || "";
  }
}
