import Role from "config/Role";
import RouteConstant from "./constant";
import NotFound from "pages/page404";
import Home from "pages/home";
import Register from "pages/register";
import Login from "pages/login";
import ForgotPassword from "pages/forgotPassword";
import OtpVerify from "pages/otpVerify";
import ChangePassword from "pages/changePassword";
import VisaGuide from "pages/visaGuide";
import VisaFormFill from "pages/visaFormFill";
import VisaAppointment from "pages/visaAppointment";
import VisaPayment from "pages/visaPayment";
import VisaBookConfirmed from "pages/visaConfirmed";
import TrackApplication from "pages/trackApplication";
import UserProfile from "pages/profile";
import News from "pages/news";
import NewsDetails from "pages/newsDetails";
import Blog from "pages/blog";
import BlogDetails from "pages/blogDetails";
import UserNotVerified from "userNotVerified/index.js";
import AdminLogin from "pages/admin/adminLogin";
import AdminDashboard from "pages/admin/dashboard";
import AllBookings from "pages/admin/allBookings";
import AdminApplicationDetails from "pages/admin/applicationDetails";
import ProfileSettings from "pages/admin/profileSetting";
import Settings from "pages/admin/settings";
import VisaApplicationDetails from "pages/applicationDetails";
import Disclaimer from "pages/disclaimer";
import TermAndCondition from "pages/term&condition";
import CookiePolicy from "pages/cookiePolicy";
import PrivacyPolicy from "pages/privacyPolicy";
import RefundPolicy from "pages/refundPolicy";
import ContactUs from "pages/contactUs";
import VisaBulkAppointment from "pages/visaBulkAppointment";
import BulkAllApplication from "pages/bulkAllApplication";
import CreateBlog from "pages/admin/createBlog";
import AllBlogs from "pages/admin/allBlogs";
import CreateNews from "pages/admin/createNews";
import AllNews from "pages/admin/allNews";
import AllUsers from "pages/admin/users";
import CreateUser from "pages/admin/createUser";
import CustomerComplain from "pages/admin/customerComplain";
import AdminTrackApplication from "pages/admin/track";
import EnjazService from "pages/enjaz";
import EnjazFamilyVisa from "pages/enjaz/familyVisa";
import Faq from "pages/faq/faq";
import OtherAdminDashboard from "pages/admin/StaffAdminDashboard";
import AllBookingSlots from "pages/admin/allSlots";
import SlotDetails from "pages/admin/slotDetails";
import GetStart from "pages/getStart";
import EmbassyAdminDashboard from "pages/admin/embassyDashboard.js";
import EmbassyVisaApplicationDetails from "pages/admin/embassyApplicationDetails";
import Support from "pages/admin/support";
import PassportProcessingAndRenewal from "pages/passportProcessing&renewal";
import TranslationService from "pages/translationService";
import NotarizationService from "pages/notarizationService";
import ApplicationProcess from "pages/applicationProcess";
import TipsForAbroad from "pages/tipsAbroad";
import Resources from "pages/resources";
import Requirement from "pages/requirement";
import AboutUs from "pages/aboutUs";
import VisaAssistant from "pages/visaAssistant";
import Checkout from "pages/checkout";
import PaymentAdmin from "pages/admin/payment";
import PaymentDetails from "pages/admin/paymentDetails";
import BulkApplicationDetails from "pages/admin/bulkApplicationDetails";
import AccessDenied from "pages/accessDenied";
import SaudiArabia from "pages/saudiArabia";
import UAE from "pages/uae";
import Ticketing from "pages/ticketing";
const AllRoutes = [
  // NOT SECURE PAGES
  {
    path: "/",
    element: <Home />,
    isSecured: false,
    name: "Home",
    exact: true,
  },

  {
    path: RouteConstant.HOME,
    element: <Home />,
    isSecured: false,
    name: "Home",
    exact: true,
  },
  {
    path: RouteConstant.ACCESS_DENIED,
    element: <AccessDenied />,
    isSecured: false,
    name: "AccessDenied",
  },
  {
    path: RouteConstant.GET_STARTED,
    element: <GetStart />,
    isSecured: false,
    name: "Home",
  },
  {
    path: RouteConstant.ENJAZ_SERVICE,
    element: <EnjazService />,
    isSecured: false,
    name: "EnjazService",
  },
  {
    path: RouteConstant.ENJAZ_FAMILY_VISA_SERVICE,
    element: <EnjazFamilyVisa />,
    isSecured: false,
    name: "EnjazFamilyVisa",
  },
  {
    path: RouteConstant.FAQ,
    element: <Faq />,
    isSecured: false,
    name: "Faq",
  },
  {
    path: RouteConstant.REGISTER,
    element: <Register />,
    isSecured: false,
    name: "Register",
  },
  {
    path: RouteConstant.LOGIN,
    element: <Login />,
    isSecured: false,
    name: "Login",
  },
  {
    path: RouteConstant.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    isSecured: false,
    name: "ForgotPassword",
  },
  {
    path: RouteConstant.VERIFY_OTP + "/:email",
    element: <OtpVerify />,
    isSecured: false,
    name: "VerifyOtp",
  },
  {
    path: RouteConstant.CHANGE_PASSWORD + "/:token",
    element: <ChangePassword />,
    name: "ChangePassword",
    isSecured: false,
  },
  {
    path: RouteConstant.VISA_GUIDE,
    element: <VisaGuide />,
    name: "VisaGuide",
    isSecured: false,
  },
  {
    path: RouteConstant.NEWS,
    element: <News />,
    name: "News",
    isSecured: false,
  },
  {
    path: RouteConstant.NEWS_DETAILS + "/:id",
    element: <NewsDetails />,
    name: "NewsDetails",
    isSecured: false,
  },
  {
    path: RouteConstant.BLOG,
    element: <Blog />,
    name: "Blog",
    isSecured: false,
  },
  {
    path: RouteConstant.BLOG_DETAILS + "/:id",
    element: <BlogDetails />,
    name: "BlogDetails",
    isSecured: false,
  },
  {
    path: RouteConstant.USER_NOT_VERIFIED + "/:email",
    element: <UserNotVerified />,
    name: "UserNotVerified",
    isSecured: false,
  },
  {
    path: RouteConstant.TERM_CONDITION,
    element: <TermAndCondition />,
    name: "TermAndCondition",
    isSecured: false,
  },
  {
    path: RouteConstant.COOKIE_POLICY,
    element: <CookiePolicy />,
    name: "CookiePolicy",
    isSecured: false,
  },
  {
    path: RouteConstant.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
    name: "PrivacyPolicy",
    isSecured: false,
  },
  {
    path: RouteConstant.REFUND_POLICY,
    element: <RefundPolicy />,
    name: "RefundPolicy",
    isSecured: false,
  },
  {
    path: RouteConstant.CONTACT_US,
    element: <ContactUs />,
    name: "ContactUs",
    isSecured: false,
  },
  {
    path: RouteConstant.DISCLAIMER,
    element: <Disclaimer />,
    name: "Disclaimer",
    isSecured: false,
  },
  {
    path: RouteConstant.PASSPORT_PROCESSING_AND_RENEWAL,
    element: <PassportProcessingAndRenewal />,
    name: "PassportProcessingAndRenewal",
    isSecured: false,
  },
  {
    path: RouteConstant.TRANSLATION_SERVICE,
    element: <TranslationService />,
    name: "TranslationService",
    isSecured: false,
  },
  {
    path: RouteConstant.NOTARIZATION_SERVICE,
    element: <NotarizationService />,
    name: "NotarizationService",
    isSecured: false,
  },
  {
    path: RouteConstant.APPLICATION_PROCESS,
    element: <ApplicationProcess />,
    name: "ApplicationProcess",
    isSecured: false,
  },
  {
    path: RouteConstant.TIPS_FOR_ABROAD,
    element: <TipsForAbroad />,
    name: "TipsForAbroad",
    isSecured: false,
  },
  {
    path: RouteConstant.RESOURCES,
    element: <Resources />,
    name: "Resources",
    isSecured: false,
  },
  {
    path: RouteConstant.REQUIREMENT,
    element: <Requirement />,
    name: "Requirement",
    isSecured: false,
  },
  {
    path: RouteConstant.ABOUT_US,
    element: <AboutUs />,
    name: "AboutUs",
    isSecured: false,
  },
  {
    path: RouteConstant.VISA_ASSISTANT,
    element: <VisaAssistant />,
    name: "VisaAssistant",
    isSecured: false,
  },
  {
    path: RouteConstant.SAUDI_ARABIA,
    element: <SaudiArabia />,
    name: "SaudiArabia",
    isSecured: false,
  },
  {
    path: RouteConstant.UAE,
    element: <UAE />,
    name: "UAE",
    isSecured: false,
  },
  {
    path: RouteConstant.TICKETING,
    element: <Ticketing />,
    name: "Ticketing",
    isSecured: false,
  },
  // SECURE PAGES BELOW ALL
  {
    path: RouteConstant.APPLY_VISA,
    element: <VisaFormFill />,
    name: "VisaFormFill",
    isSecured: true,
  },
  {
    path: RouteConstant.VISA_SET_APPOINTMENT + "/:id",
    element: <VisaAppointment />,
    name: "VisaAppointment",
    isSecured: true,
  },
  {
    path: RouteConstant.CHECKOUT,
    element: <Checkout />,
    name: "Checkout",
    isSecured: true,
  },
  // ONLY AGENCY PAGE JUST BELOW
  {
    path: RouteConstant.VISA_SET_BULK_APPOINTMENT + "/:id",
    element: <VisaBulkAppointment />,
    name: "VisaBulkAppointment",
    isSecured: true,
    access: [Role.AGENCY],
  },
  {
    path: RouteConstant.BULK_ALL_APPLICATION + "/:id",
    element: <BulkAllApplication />,
    name: "BulkAllApplication",
    isSecured: true,
    // access: [Role.AGENCY],
  },
  {
    path: RouteConstant.VISA_PAYMENT + "/:id",
    element: <VisaPayment />,
    name: "VisaPayment",
    isSecured: true,
  },
  {
    path: RouteConstant.VISA_CONFIRMED,
    element: <VisaBookConfirmed />,
    name: "VisaBookConfirmed",
    isSecured: true,
  },
  {
    path: RouteConstant.TRACK_APPLICATION,
    element: <TrackApplication />,
    name: "TrackApplication",
    isSecured: true,
  },
  {
    path: RouteConstant.VISA_APPLICATION_DETAILS + "/:id",
    element: <VisaApplicationDetails />,
    name: "VisaApplicationDetails",
    isSecured: true,
  },
  {
    path: RouteConstant.USER_PROFILE,
    element: <UserProfile />,
    name: "USER_PROFILE",
    isSecured: true,
  },
  // ADMINISTRATIVE
  {
    path: RouteConstant.ADMIN_LOGIN,
    element: <AdminLogin />,
    name: "AdminLogin",
    isSecured: false,
  },

  // ONLY ADMIN

  {
    path: RouteConstant.SETTINGS,
    element: <Settings />,
    name: "Settings",
    isSecured: true,
    access: [Role.ADMIN],
  },
  {
    path: RouteConstant.ALL_USERS,
    element: <AllUsers />,
    name: "AllUsers",
    isSecured: true,
    access: [Role.ADMIN],
  },
  {
    path: RouteConstant.CREATE_USER,
    element: <CreateUser />,
    name: "CreateUser",
    isSecured: true,
    access: [Role.ADMIN],
  },

  // ADMIN, MANAGER
  {
    path: RouteConstant.ADMIN_DASHBOARD,
    element: <AdminDashboard />,
    name: "AdminDashboard",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.ALL_BOOKINGS,
    element: <AllBookings />,
    name: "AllBookings",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.CREATE_BLOG,
    element: <CreateBlog />,
    name: "CreateBlog",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.ALL_BLOGS,
    element: <AllBlogs />,
    name: "AllBlogs",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.CREATE_NEWS,
    element: <CreateNews />,
    name: "CreateNews",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.ALL_NEWS,
    element: <AllNews />,
    name: "AllNews",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.CUSTOMER_COMPLAIN,
    element: <CustomerComplain />,
    name: "CustomerComplain",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.SUPPORT,
    element: <Support />,
    name: "Support",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.PAYMENTS,
    element: <PaymentAdmin />,
    name: "PaymentAdmin",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  {
    path: RouteConstant.PAYMENT_DETAILS + "/:paymentId",
    element: <PaymentDetails />,
    name: "PaymentDetails",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER],
  },
  // admin manager staff
  {
    path: RouteConstant.ADMIN_APPLICATION_DETAILS + "/:id",
    element: <AdminApplicationDetails />,
    name: "AdminApplicationDetails",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER, Role.STAFF],
  },
  {
    path: RouteConstant.ALL_BOOKING_SLOTS,
    element: <AllBookingSlots />,
    name: "AllBookingSlots",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER, Role.STAFF],
  },
  {
    path: RouteConstant.SLOTS_DETAILS + "/:id",
    element: <SlotDetails />,
    name: "SlotDetails",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER, Role.STAFF],
  },
  {
    path: RouteConstant.ADMIN_BULK_APPLICATION_DETAILS + "/:id",
    element: <BulkApplicationDetails />,
    name: "BulkApplicationDetails",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER, Role.STAFF],
  },
  //embassy
  {
    path: RouteConstant.EMBASSY_DASHBOARD,
    element: <EmbassyAdminDashboard />,
    name: "EmbassyAdminDashboard",
    isSecured: true,
    access: [Role.EMBASSY],
  },
  {
    path: RouteConstant.EMBASSY_APPLICATION_DETAILS + "/:id",
    element: <EmbassyVisaApplicationDetails />,
    name: "EmbassyVisaApplicationDetails",
    isSecured: true,
    access: [Role.EMBASSY],
  },
  // all administrative
  {
    path: RouteConstant.PROFILE_SETTINGS,
    element: <ProfileSettings />,
    name: "ProfileSettings",
    isSecured: true,
    access: [Role.ADMIN, Role.EMBASSY, Role.MANAGER, Role.STAFF],
  },

  {
    path: RouteConstant.STAFF_DASHBOARD,
    element: <OtherAdminDashboard />,
    name: "OtherAdminDashboard",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER, Role.STAFF, Role.EMBASSY],
  },
  {
    path: RouteConstant.TRACK_BY_ADMIN,
    element: <AdminTrackApplication />,
    name: "AdminTrackApplication",
    isSecured: true,
    access: [Role.ADMIN, Role.MANAGER, Role.STAFF, Role.EMBASSY],
  },

  {
    path: "*",
    element: <NotFound />,
    isSecured: false,
    name: "NotFound",
  },
];
export default AllRoutes;
