import { Button, Grid, TextField, Typography } from "@mui/material";

const Subscribe = () => {
  return (
    <div className="subscribe-bg">
      <div className="overlay"></div>
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="p-center">
            <Typography variant="h4" color="white.main">
              Get updates about visa, <br />
              tour and travel,
              <br /> changes in rules
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              placeholder="Enter your name"
              label="Name"
              fullWidth
              color="white"
              focused
              inputProps={{ sx: { color: "#fff" } }}
            />
            <TextField
              placeholder="Enter your email"
              label="Email"
              fullWidth
              sx={{ my: 2 }}
              color="white"
              inputProps={{ sx: { color: "#fff" } }}
              focused
              type="email"
            />
            <Button disableElevation className="rounded-2" variant="contained">
              Subscribe
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Subscribe;
