import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  Typography,
  IconButton,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";
import HomeNavbar from "parts/navbar/homeNavbar";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import RouteConstant from "navigation/constant";
import { useEffect, useState } from "react";
const Button1 = styled(Button)(({ theme }) => ({
  color: "#D279C7",
  backgroundColor: " rgba(160, 71, 157, 0.26)",
  "&:hover": {
    backgroundColor: "rgba(160, 71, 157, 0.5)",
  },
}));
const Button2 = styled(Button)(({ theme }) => ({
  color: " rgba(255, 255, 255, 0.22)",
  backgroundColor: "rgba(255, 255, 255, 0.03)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
}));
const GetStart = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const [fromCountry, setFromCountry] = useState(null);
  const [toCountry, setToCountry] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [actionLink, setActionLink] = useState(null);
  const availableToCountry = useSelector((state) => state.countries.to);
  const availableFromCountry = useSelector((state) => state.countries.from);

  useEffect(() => {
    // NOTE temp only vise
    let baseUrl = RouteConstant.APPLY_VISA + "/?";
    if (fromCountry) {
      baseUrl += "from=" + fromCountry.code;
    }
    if (toCountry) {
      baseUrl += `${fromCountry ? "&" : ""}to=` + toCountry.code;
    }
    setActionLink(baseUrl);
  }, [fromCountry, toCountry, selectedService]);
  return (
    <div className="home-page">
      <Helmet>
        <title>Get start </title>
      </Helmet>
      <HomeNavbar />
      <div className="py-40 home-content px-3 content-height">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} className="p-center">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <Link to={RouteConstant.REGISTER + "?type=individual"}>
                  <Button1 variant="contained" color="primary" sx={{ mr: 2 }}>
                    Individuals
                  </Button1>
                </Link>
                <Link to={RouteConstant.REGISTER + "?type=business"}>
                  <Button2 variant="contained" color="secondary2">
                    Business
                  </Button2>
                </Link>
              </Box>
              <h1 className="home-heading">
                Apply For Your <br /> VISA from your <br />
                finger tip
              </h1>
              <Link to={RouteConstant.VISA_GUIDE}>
                <Button variant="contained" sx={{ borderRadius: 4 }}>
                  Learn More
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} className="p-center">
            <Card sx={{ borderRadius: 6, p: mobileSize ? 1 : 3 }}>
              <CardContent>
                <Box display="flex" flexWrap="wrap" mb={3}>
                  <Link to="#" className="home-f-links active">
                    Visas, eVisas & Permits
                  </Link>
                  <Link to="#" className="home-f-links">
                    Passport Services
                  </Link>
                  <Link to="#" className="home-f-links">
                    Govt Service
                  </Link>
                  <Link to="#" className="home-f-links">
                    Tourism Service
                  </Link>
                </Box>
                <Box
                  bgcolor="secondary.main"
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  borderRadius={2}
                  mb={3}
                >
                  <p className="mb-0 text-white">
                    Secure your travel with Covid compliant Travel Insurance
                    from well-known brands!{" "}
                    <Link
                      to="#"
                      className="bright-link text-decoration-underline"
                    >
                      Get Now
                    </Link>
                  </p>
                  <IconButton>
                    <EastIcon color="primary" />
                  </IconButton>
                </Box>
                <Box>
                  <Box mb={3}>
                    <Typography variant="subtitle1" mb={1}>
                      I’m Going From
                    </Typography>
                    <Autocomplete
                      disablePortal
                      options={availableFromCountry}
                      value={fromCountry}
                      onChange={(e, value) => setFromCountry(value)}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Country" />
                      )}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography variant="subtitle1" mb={1}>
                      I’m Applying To
                    </Typography>
                    <Autocomplete
                      disablePortal
                      options={availableToCountry}
                      value={toCountry}
                      onChange={(e, value) => setToCountry(value)}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Country" />
                      )}
                    />
                  </Box>
                </Box>
                <Link to={actionLink}>
                  <Button variant="contained" size="large">
                    TAKE ME TO WEBSITE
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GetStart;
