export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/v1"
    : process.env.REACT_APP_BASE_URL;
// export const DOMAIN = "http://localhost:5000/";

export const DOMAIN =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/"
    : process.env.REACT_APP_DOMAIN;

//USER**************************************************************************
export const SIGNUP = "/user/signup";
export const LOGIN = `/user/login`;
export const AUTH = "/user/auth";
export const LOGOUT = (ID, TOKEN) => `/user/logout/${ID}?token=${TOKEN}`;
export const FORGOT_PASSWORD = (EMAIL) => `/user/forgot-pw/${EMAIL}`;
export const VERIFY_OTP = (EMAIL, OTP) =>
  `/user/verify-otp?email=${EMAIL}&otp=${OTP}`;
export const PASSWORD_CHANGE = "/user/change-pw";
export const RESEND_EMAIL = (EMAIL) => `/user/resend-email/${EMAIL}`;
export const UPDATE_USER_PROFILE = (ID) => `/user/update-profile/${ID}`;
export const DIRECT_PASSWORD_CHANGE = (ID) =>
  `/user/direct-change-password/${ID}`;
// USER FOR ADMIN ONLY
export const CREATE_USER = "/user/create-user-by-admin";
export const UPDATE_USER = (ID) => `/user/update-user-by-admin/${ID}`;
export const BAN_USER = (ID) => `/user/ban-user-by-admin/${ID}`;
export const GET_USER_BY_ID = (ID) => `/user/get-by-id/${ID}`;
export const GET_ALL_MANAGEMENT_USERS = "/user/get-all-management";
export const GET_ALL_AGENCY_USERS = "/user/get-all-agency";
export const GET_ALL_BASIC_USERS = "/user/get-basic-user";
export const GET_USER_FOR_ADMIN_BY_ID = (ID) =>
  `/user/get-by-id-for-admin/${ID}`;
//  END USER

// COUNTRY
// COUNTRY FOR ADMIN ONLY
export const ADD_COUNTRY = "/country/create-new";
export const UPDATE_COUNTRY = (ID) => `/country/update/${ID}`;
export const GET_COUNTRY_BY_ID = (ID) => `/country/get-by-id/${ID}`;
export const DELETE_COUNTRY = (ID) => `/country/delete/${ID}`;
//ALL
export const GET_ALL_COUNTRIES = "/country/get-all-country";
// COUNTRY END

//VISA TIME ADMIN
export const VISA_TIME_SETTINGS = "/visa-time-settings/update";
export const GET_VISA_TIME_SETTINGS = "/visa-time-settings/get";
//VISA TIME END

//APPLICATION
export const APPLY_VISA = "/visa-application/create-new";
export const GET_VISA_APP_ID = (ID) => `/visa-application/get-by-id/${ID}`;
export const GET_VISA_APP_DETAILS_ID = (ID) =>
  `/visa-application/get-details-by-id/${ID}`;
export const BOOK_SLOT = (ID) =>
  `/visa-application/book-appointment-slot/${ID}`;
export const GET_VISA_PAYMENT_DETAIL = (ID) =>
  `/visa-application/get-payment-details/${ID}`;
export const PAY_IN_VISA_CENTER = (ID) =>
  `/visa-application/pay-in-visa-center/${ID}`;
export const APPLICATION_PAID = (ID) => `/visa-application/paid/${ID}`;
export const GET_BY_APPLICATION_ID = (ID) =>
  `/visa-application/track-by-book-id/${ID}`;
export const DELETE_VISA_BY_ID = (ID) => `/visa-application/delete/${ID}`;
export const UPDATE_VISA_BY_ID = (ID) => `/visa-application/update/${ID}`;
export const GET_ALL_APPLICATION_BY_BULK = (bulkId) =>
  `/visa-application/get-all-application-by-bulk/${bulkId}`;
export const GET_ALL_APPLICATION_BY_USER = (USER_ID) =>
  `/visa-application/get-all-application-by-user/${USER_ID}`;
//ADMINS ONLY
export const GET_ALL_APPLICATION_BY_BULK_ADMIN = (bulkId) =>
  `/visa-application/get-all-application-by-bulk-admin/${bulkId}`;
export const GET_APPLICATION_BY_ADMIN = (APP_ID) =>
  `/visa-application/get-application-details-by-admin/${APP_ID}`;
export const COUNT_ALL_APPLICATION =
  "/visa-application/get-all-application-counted";
export const COUNT_REMAIN_APPLICATION =
  "/visa-application/get-all-remain-counted";
export const COUNT_DONE_APPLICATION = "/visa-application/get-all-done-counted";
export const GET_RECENT_APPLICATION =
  "/visa-application/get-recent-application";
export const CHANGE_STATUS_BY_ADMIN = (ID) =>
  `/visa-application/change-status-by-admin-manager/${ID}`;
export const CHANGE_STATUS_BY_STAFF = (ID) =>
  `/visa-application/change-status-by-staff/${ID}`;
export const CHANGE_STATUS_BY_EMBASSY = (ID) =>
  `/visa-application/change-status-by-embassy/${ID}`;
export const GET_ALL_APPS_BY_EMBASSY = `/visa-application/get-application-by-embassy`;
export const GET_APP_DETAILS_BY_EMBASSY = (ID) =>
  `/visa-application/get-application-details-by-embassy/${ID}`;
export const APPLICATION_ANALYZE_DAY = `/visa-application/application-DAY-analyze`;
export const APPLICATION_ANALYZE_MONTH = `/visa-application/application-monthly-analyze`;
export const APPLICATION_ANALYZE_YEAR = `/visa-application/application-year-analyze`;
//APPLICATION END

//SLOTS
export const GET_SLOTS_DETAILS = `/visa-time-slots/get-by-date`;
export const GET_BY_ID = (ID) => `/visa-time-slots/get-slot-by-id/${ID}`;
export const GET_DETAILS_ID = (ID) => `/visa-time-slots/get-slot-details/${ID}`;
//SLOTS END

// BULK APPLICATION
export const GET_BULK_APPLICATION_BY_ID = (ID) =>
  `/visa-bulk-application/get-by-id/${ID}`;
export const GET_BULK_APPLICATION_BY_AGENCY = (AGENCY_ID) =>
  `/visa-bulk-application/get/${AGENCY_ID}`;
export const GET_BULK_APPLICATION = (AGENCY_ID) =>
  `/visa-bulk-application/get-all-application/${AGENCY_ID}`;
export const GET_BULK_PROFILE = (USER_ID) =>
  `/visa-bulk-application/get-by-user-id/${USER_ID}`;
export const GET_BULK_SLOT_CALCULATION = (ID) =>
  `/visa-bulk-application/get-slot-calculation/${ID}`;
export const BULK_SUBMIT = (ID) => `/visa-bulk-application/submit/${ID}`;
export const BULK_PAYMENT_DETAILS = (ID) =>
  `/visa-bulk-application/get-payment-detail/${ID}`;
export const UPDATE_BULK_PAYMENT_METHOD = (ID) =>
  `/visa-bulk-application/update-payment-method/${ID}`;
export const BULK_PAY_IN_CENTER = (ID) =>
  `/visa-bulk-application/pay-in-center/${ID}`;
export const GET_BULK_BY_SEARCH = (AGENCY_ID) =>
  `/visa-bulk-application/get-by-search/${AGENCY_ID}`;
export const BULK_APPLICATION_DELETE = (ID) =>
  `/visa-bulk-application/delete-by-id/${ID}`;
// BULK APPLICATION ADMIN
export const GET_ALL_BULKS = `/visa-bulk-application/get-all-bulks`;
export const GET_BULK_DETAILS_ADMIN = (ID) =>
  `/visa-bulk-application/get-by-id-admin/${ID}`;
// BULK APPLICATION END

// AGENCY
export const GET_AGENCY_DETAILS = (AGENCY_ID) => `/agency/get/${AGENCY_ID}`;
export const GET_AGENCY_BY_USER = (USER_ID) => `/agency/get-by-user/${USER_ID}`;
//AGENCY END

// BLOG And NEWS **************************
export const CREATE_BLOG = `/blog/create`;
export const UPDATE_BLOG = (ID) => `/blog/update/${ID}`;
export const GET_BLOG_BY_ID = (ID) => `/blog/get-by-id/${ID}`;
export const DELETE_BLOG_BY_ID = (ID) => `/blog/delete/${ID}`;
export const GET_ALL_BLOG_BY_SEARCH = `/blog/get-all-by-search`;
export const GET_ALL_BLOG_BY_FEATURED = `/blog/get-all-by-featured`;

export const CREATE_NEWS = `/news/create`;
export const UPDATE_NEWS = (ID) => `/news/update/${ID}`;
export const GET_NEWS_BY_ID = (ID) => `/news/get-by-id/${ID}`;
export const DELETE_NEWS_BY_ID = (ID) => `/news/delete/${ID}`;
export const GET_ALL_NEWS_BY_SEARCH = `/news/get-all-by-search`;
export const GET_ALL_NEWS_BY_FEATURED = `/news/get-all-by-featured`;

//REPORT
export const CREATE_REPORT = "/report/create";
export const UPDATE_REPORT = (ID) => `/report/update/${ID}`;
export const GET_REPORT_BY_ID = (ID) => `/report/get-by-id/${ID}`;
export const GET_REPORT_BY_APPLICATION = (applicationId) =>
  `/report/get-by-application/${applicationId}`;
export const GET_ALL_REPORT = "/report/get-all";

// contact
export const CREATE_CONTACT = "/contact/create";
export const CONTACT_GET_BY_ID = (ID) => `/contact/get-by-id/${ID}`;
export const CONTACT_CHANGE_STATUS = (ID) => `/contact/change-status/${ID}`;
export const GET_ALL_CONTACT = "/contact/get-all";
//visa application assistant

export const CREATE_VISA_ASSISTANT_APPLICATION =
  "/visa-application-assistant/create";
export const VISA_ASSISTANT_APP_GET_BY_ID = (ID) =>
  `/visa-application-assistant/get-by-id/${ID}`;
export const VISA_ASSISTANT_APP_CHANGE_STATUS = (ID) =>
  `/visa-application-assistant/change-status/${ID}`;
export const GET_ALL_VISA_ASSISTANT_APPLICATION =
  "/visa-application-assistant/get-all";

// PAYMENT
export const PAYMENT_CHECKOUT = `/payment/checkout`;
export const GET_PAYMENT_BY_PAYMENT_ID = (PAYMENT_ID) =>
  `/payment/get-payment-by-paymentId/${PAYMENT_ID}`;
//PAYMENT ADMIN
export const UPDATE_CURRENCY_RATE = "/currency/update";
export const GET_CURRENCY_RATE = "/currency/get";
export const GET_ALL_PAYMENTS = `/payment/get-all`;
export const PAYMENT_DETAILS = (PAYMENT_ID) =>
  `/payment/payment-details/${PAYMENT_ID}`;
export const PAYMENT_MANUALLY_PAID = (PAYMENT_ID) =>
  `/payment/payment-manually-paid/${PAYMENT_ID}`;
export const PAYMENT_MANUALLY_UNPAID = (PAYMENT_ID) =>
  `/payment/payment-manually-unpaid/${PAYMENT_ID}`;

export const PAYMENT_CHART_DAY_DATA = `/payment/get-analyze-by-day`;
export const PAYMENT_CHART_YEAR_DATA = `/payment/get-analyze-by-year`;
export const PAYMENT_CHART_MONTH_DATA = `/payment/get-analyze-by-month`;
//PAYMENT END
