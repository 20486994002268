import { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import LaptopPic from "assets/img/static/bg/laptop.png";
import LogoIcon from "assets/img/static/icon/logo_icon.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Role from "config/Role";
import RouteConstant from "navigation/constant";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { ErrorUserLoginModel } from "_models/error/errors.userLogin.model";
import { UserLoginModel } from "_models/data/data.userLogin.model";
import { ValidateLoginForm } from "functions/validators";
import { LOGIN } from "config/endpoints";
import { useDispatch } from "react-redux";
import { setUser } from "state/slice/user";

const Login = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const Dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");
  //local state
  const [error, setError] = useState(new ErrorUserLoginModel());
  const [state, setState] = useState(new UserLoginModel());
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const activated = searchParams.get("ae");
  const isAdmin = searchParams.get("type");
  const handleLogin = useCallback(() => {
    const isValid = ValidateLoginForm(state);
    if (isValid.valid) {
      toggleLoading(true);
      PostRequest(LOGIN, {
        contact: state.contact?.trim(),
        password: state.password,
      })
        .then((res) => {
          if (res.data.status) {
            toast.success("Successfully logged in.");
            localStorage.setItem("token", res.data.data.token);
            const role = res.data?.data?.role;
            Dispatch(
              setUser({
                ...res.data.data,
                id: res.data.data._id,
              })
            );
            if (redirectUrl) {
              navigate(redirectUrl);
            } else {
              if (role === Role.ADMIN || role === Role.MANAGER) {
                navigate(RouteConstant.ADMIN_DASHBOARD);
              } else if (role === Role.EMBASSY) {
                navigate(RouteConstant.EMBASSY_DASHBOARD);
              } else if (role === Role.STAFF) {
                navigate(RouteConstant.STAFF_DASHBOARD);
              } else navigate("/");
            }
            setState(new UserLoginModel());
          } else {
            if (res?.data?.isEmailVerified === false) {
              navigate(
                `${RouteConstant.USER_NOT_VERIFIED}/${res?.data?.email}`
              );
              return;
            }
            if (res?.data?.data && !res?.data?.data?.isVerified) {
              navigate(`${RouteConstant.USER_NOT_VERIFIED}/${state.email}`);
              return;
            }
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => {
          toggleLoading(false);
        });
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  }, [state, redirectUrl]);
  useEffect(() => {
    if (activated === "true") {
      toast.success("Your email is verified successfully");
    }
    if (activated === "false") {
      toast.error("Email verification failed.");
    }
    searchParams.delete("ae");
    // console.log(searchParams);
    // setSearchParams(searchParams);
  }, [activated]);
  useEffect(() => {
    if (isAdmin === "adm") {
      navigate(RouteConstant.ADMIN_LOGIN);
    }
  }, [isAdmin]);

  useEffect(() => {
    const enterHit = (ev) => {
      if (ev.keyCode === 13 || ev.which === 13) {
        handleLogin();
      }
    };
    window.addEventListener("keydown", enterHit);
    return () => {
      window.removeEventListener("keydown", enterHit);
    };
  }, [handleLogin]);
  return (
    <section className="login-page ">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Grid container spacing={3} style={{ minHeight: "calc(100vh - 80px)" }}>
        <Grid md={6} xs={12} item>
          <div className="left-carousel-container p-center px-3 px-lg-0">
          <Swiper
              modules={[Pagination]}
              className="lef-auth-swiper"
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide>
                <Box>
                  <div className="text-center resImg">
                    <img src={LaptopPic} alt="laptop" />
                  </div>
                  <Box>
                    <Typography
                      variant="h6"
                      color="white.main"
                      textAlign="center"
                      mb={2}
                    >
                      Apply VISA From Anywhere
                    </Typography>
                    <Typography
                      variant="body2"
                      maxWidth={400}
                      color="white.main"
                      mx="auto"
                      textAlign="center"
                      mb={14}
                    >
                      Apply for Visas Anywhere with Our Easy-to-Use Online
                      Application System. Submit Your Application in Minutes and
                      Get Your Visa Quickly and Hassle-Free. No Matter Where
                      You're Going, We've Got You Covered!
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
             
            </Swiper>
          </div>
        </Grid>
        <Grid md={6} xs={12} item>
          <Box px={3} py={4}>
            <Box textAlign="center">
              <div className="resImg text-center">
                <Link to={RouteConstant.HOME}>
                  <img src={LogoIcon} alt="logo icon" width={80} />
                </Link>
              </div>
              <Typography variant="h5" mt={2} fontWeight={500}>
                Login
              </Typography>
            </Box>
            <Box mt={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Email or Phone
              </Typography>
              <TextField
                label="Enter email or phone"
                variant="outlined"
                fullWidth
                error={error.contactError !== ""}
                helperText={error.contactError}
                onFocus={() => handleFocus("contactError")}
                id="signup_email"
                name="contact"
                value={state.contact}
                onChange={(ev) => handleChange(ev, "contact")}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Password
              </Typography>
              <TextField
                label="Enter password"
                variant="outlined"
                fullWidth
                type="password"
                error={error.passwordError !== ""}
                helperText={error.passwordError}
                onFocus={() => handleFocus("passwordError")}
                id="password"
                name="password"
                value={state.password}
                onChange={(ev) => handleChange(ev, "password")}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" mt={3}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember me"
              />
              <Link to={RouteConstant.FORGOT_PASSWORD} className="dark-link ">
                Forgot Password
              </Link>
            </Box>
            <div className="p-center">
              <Button
                variant="contained"
                sx={{ width: 300, my: 4, mx: "auto" }}
                size="large"
                onClick={handleLogin}
              >
                Login
              </Button>
            </div>
            <Box mt={3} textAlign="center">
              I Don’t have an account? &nbsp;
              <Link to={RouteConstant.REGISTER} className="dark-link fw-bold">
                Register Now
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default Login;
