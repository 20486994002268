import {
  Box,
  Button,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
  Modal,
  Card,
  Grid,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DateRangePicker from "component/other/dateRangePicker";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min( 600px, 100% )",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  maxHeight: "100vh",
};
const PaymentFilter = ({
  element,
  value,
  onClose = () => {},
  onChange = () => {},
  isFiltered = () => {},
}) => {
  const [filter, setFilter] = useState(value);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  useEffect(() => {
    setFilterAnchorEl(element || null);
  }, [element]);
  const handleClose = () => {
    setFilterAnchorEl(null);
    onClose();
  };
  const handleClearFilter = () => {
    const f = {
      status: "",
      method: "",
      fromDate: null,
      toDate: null,
      minAmount: 0,
      maxAmount: 0,
      paymentStatus: 1,
    };
    setFilter(f);
    onChange(f);
    handleClose();
    isFiltered(false);
  };

  const handleSubmit = () => {
    //adding time bcz date range picker not supporting timezone
    onChange({
      ...filter,
      fromDate: filter.fromDate
        ? moment(filter.fromDate).add(150, "minutes").format("MM/DD/YYYY")
        : null,
      toDate: filter.toDate
        ? moment(filter.toDate).add(150, "minutes").format("MM/DD/YYYY")
        : null,
    });
    let flag = false;
    for (const key in filter) {
      if (Boolean(filter[key])) {
        flag = true;
      }
    }
    isFiltered(flag);
    handleClose();
  };

  return (
    <Modal
      open={openFilter}
      onClose={handleClose}
      aria-labelledby="filter-dlg"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <DialogTitle id="filter-dlg">
          <Box display="flex" justifyContent="space-between">
            Filter
            <Box>
              <Button onClick={handleClearFilter}>Clear</Button>
              <Button onClick={handleSubmit} sx={{ ml: 2 }} variant="contained">
                Ok
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="filter-dlg-select">Status</InputLabel>
                <Select
                  labelId="filter-dlg-select"
                  label="Status"
                  value={filter.status}
                  onChange={(ev) =>
                    setFilter({ ...filter, status: ev.target.value })
                  }
                >
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"inReview"}>In Review</MenuItem>
                  <MenuItem value={"processing"}>Processing</MenuItem>
                  <MenuItem value={"hold"}>Hold</MenuItem>
                  <MenuItem value={"returned"}>Returned</MenuItem>
                  <MenuItem value={"sentToEmbassy"}>Sent To Embassy</MenuItem>
                  <MenuItem value={"approved"}>Approved</MenuItem>
                  <MenuItem value={"denied"}>Denied</MenuItem>
                  <MenuItem value={"collect"}>Ready To Collect</MenuItem>
                  <MenuItem value={"collected"}>Collected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="filter-dlg-select">Method</InputLabel>
                <Select
                  labelId="filter-dlg-select"
                  label="Status"
                  value={filter.method}
                  onChange={(ev) =>
                    setFilter({ ...filter, method: ev.target.value })
                  }
                >
                  <MenuItem value={"online"}>Online</MenuItem>
                  <MenuItem value={"visa_center"}>Visa center</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Minimum price"
                placeholder="Enter Minimum price"
                type="number"
                inputProps={{ min: 0 }}
                onChange={(ev) =>
                  setFilter({ ...filter, minAmount: ev.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Maximum price"
                placeholder="Enter maximum price"
                type="number"
                inputProps={{ min: 0 }}
                onChange={(ev) =>
                  setFilter({ ...filter, maxAmount: ev.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <DateRangePicker
                startDate={filter.fromDate}
                endDate={filter.toDate}
                setStartDate={(date) =>
                  setFilter({ ...filter, fromDate: date })
                }
                setEndDate={(date) => setFilter({ ...filter, toDate: date })}
                width={"100%"}
                flex="1 1 auto"
                minDate={moment(new Date()).toDate()}
                fromLabel="Appointment Date from"
                toLabel="Appointment Date to"
                fullInputWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="filter-dlg-select">Payment status</InputLabel>
                <Select
                  labelId="filter-dlg-select"
                  label="Payment status"
                  value={filter.paymentStatus}
                  onChange={(ev) =>
                    setFilter({
                      ...filter,
                      paymentStatus: ev.target.value,
                    })
                  }
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Paid</MenuItem>
                  <MenuItem value={3}>Not Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
      </Card>
    </Modal>
  );
};
PaymentFilter.propTypes = {
  element: PropTypes.object,
  value: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isFiltered: PropTypes.func,
};
export default PaymentFilter;
