import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  user: {
    id: "",
    name: "",
    email: "",
    role: "",
    token: "",
    address: "",
    phone: "",
    avatar: "",
    agencyId: "",
  },
  status: "idle",
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.status = "success";
    },
    setLogout: (state, action) => {
      state.user = initialState.user;
      state.isLoggedIn = false;
      state.status = "idle";
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setToggleLogin: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setLogout, setStatus, setToggleLogin } =
  UserSlice.actions;

export default UserSlice.reducer;
