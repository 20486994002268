import RenderCellExpand from "component/grid/expandCell";
import { Button, Chip } from "@mui/material";
const SupportColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    renderCell: RenderCellExpand,
  },

  {
    field: "phone",
    headerName: "Phone",
    minWidth: 200,
    renderCell: RenderCellExpand,
  },
  {
    field: "passport",
    minWidth: 150,
    headerName: "Passport",
    renderCell: RenderCellExpand,
  },
  {
    field: "message",
    minWidth: 250,
    headerName: "Message",
    renderCell: RenderCellExpand,
  },

  {
    field: "isSolved",
    headerName: "Is Solved",
    renderCell: ({ value }) => {
      return (
        <span>
          {value ? (
            <Chip size="small" label="Solved" color="success" />
          ) : (
            <Chip size="small" label="Not Solved" color="error" />
          )}
        </span>
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    renderCell: ({ value }) => <Button>View</Button>,
  },
];

export default SupportColumn;
