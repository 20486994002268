import {
  Box,
  Button,
  IconButton,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import TransparentNavbar from "parts/navbar/transparentNavbar";
import { useNavigate, Link } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import RouteConstant from "navigation/constant";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { Helmet } from "react-helmet";
import { CREATE_CONTACT } from "config/endpoints";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ContactModel } from "_models/data/data.contact.model";
import { ErrorContactModel } from "_models/error/errors.contact.model";
import { ValidateContact } from "functions/validators";
import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
import StandardNavbar from "parts/navbar/standardNavbar";
import FaqHeader from "./component/faqHeader";
import FaqList from "./component/faqList";
import QueryForm from "parts/queryForm";

const Faq = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const toggleLoading = useToggleLoading();
  const [state, setState] = useState(new ContactModel());
  const [error, setError] = useState(new ErrorContactModel());
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const handleSubmit = () => {
    const isValid = ValidateContact(state);
    if (isValid.valid) {
      toggleLoading(true);
      const data = {
        user: user._id,
        ...state,
      };
      PostRequest(CREATE_CONTACT, data)
        .then((res) => {
          if (res.data.status) {
            toast.success("Message sent.");
            setState(new ContactModel());
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const newDate = new Date();
  const ugandaDate = utcToZonedTime(new Date(), "Africa/Kampala");
  const locale = newDate.toLocaleString("en-US", {
    timeZone: "Africa/Kampala",
  });
  // Set the date to "2018-09-01T16:01:36.386Z"
  const utcDate = zonedTimeToUtc(ugandaDate, "Africa/Kampala");

  // Obtain a Date instance that will render the equivalent Berlin time for the UTC date
  const timeZone = "Africa/Kampala";
  const zonedDate = utcToZonedTime(new Date(), timeZone);
  // zonedDate could be used to initialize a date picker or display the formatted local date/time

  // Set the output to "1.9.2018 18:01:36.386 GMT+02:00 (CEST)"
  const pattern = "d.M.yyyy HH:mm:ss.SSS 'GMT' XXX (z)";
  // const output = format(zonedDate, pattern, { timeZone: "Africa/Kampala" });
  console.log("zonedDate", zonedDate);
  console.log("output2", new Date(locale));
  console.log("new date", newDate);
  console.log("uganda date", ugandaDate);
  console.log("difference", ugandaDate.getTime(), newDate.getTime());

  return (
    <section>
      <Helmet>
        <title>FAQ </title>
      </Helmet>
      <StandardNavbar />
      <FaqHeader />
      <FaqList />
      <QueryForm title="For More Your Queries <br /> Contact Our Support <br /> Team " />

      {/* <Box className="bg-dark" py={10}>
        <div className="container">
          <div className="subscribe-bg">
            <div
              className="overlay"
              style={{ backgroundColor: " rgba(0, 0, 0, 0.65)" }}
            ></div>
            <Grid
              container
              spacing={3}
              sx={{ position: "relative", zIndex: 2 }}
            >
              <Grid item xs={12} md={6} className="p-center">
                <Typography variant="h4" color="white.main">
                  For More Your Queries <br /> Contact Our Support <br />
                  Team
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  placeholder="Enter your name"
                  label="Name"
                  fullWidth
                  color="white"
                  inputProps={{ sx: { color: "#fff" } }}
                  error={error.nameError !== ""}
                  helperText={error.nameError}
                  onFocus={() => handleFocus("nameError")}
                  value={state.name}
                  name="name"
                  onChange={(ev) => handleChange(ev, "name")}
                  focused
                />
                <TextField
                  placeholder="Enter your email"
                  label="Email"
                  fullWidth
                  sx={{ my: 2 }}
                  color="white"
                  inputProps={{ sx: { color: "#fff" } }}
                  type="email"
                  error={error.emailError !== ""}
                  helperText={error.emailError}
                  onFocus={() => handleFocus("emailError")}
                  value={state.email}
                  name="email"
                  onChange={(ev) => handleChange(ev, "email")}
                  focused
                />
                <TextField
                  placeholder="Enter message"
                  label="Message"
                  fullWidth
                  sx={{ my: 2 }}
                  color="white"
                  inputProps={{ sx: { color: "#fff" } }}
                  multiline
                  rows={4}
                  error={error.messageError !== ""}
                  helperText={error.messageError}
                  onFocus={() => handleFocus("messageError")}
                  value={state.message}
                  name="message"
                  onChange={(ev) => handleChange(ev, "message")}
                  focused
                />
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </div>
          <Box my={4}>
            <Typography variant="caption" color="white.main">
              Quick Links
            </Typography>
            <Box display="flex" mt={2}>
              <Typography color="white.main" mr={3}>
                <Link to={RouteConstant.APPLY_VISA} className="bright-link">
                  Apply For Visa <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
              <Typography color="white.main" mr={3}>
                <Link to={RouteConstant.FAQ} className="bright-link">
                  FAQ <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
              <Typography color="white.main">
                <Link to={RouteConstant.CONTACT_US} className="bright-link">
                  Contact Us <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
            </Box>
          </Box>
        </div>
      </Box> */}
    </section>
  );
};

export default Faq;
