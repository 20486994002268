import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const VisApplyStepper = ({ step = 0 }) => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const steps = ["Fill Form", "Book Appointment", "Payment", "Confirmation"];

  return (
    <Box my={4}>
      {mobileSize ? (
        <Typography textAlign="center">
          Step {step + 1} out of {steps.length}
        </Typography>
      ) : (
        <Stepper activeStep={step} className="visa-apply-stepper">
          {steps.map((label, index) => {
            return (
              <Step key={label} color="orange.main">
                <StepLabel sx={{ color: "orange.main" }}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </Box>
  );
};

export default VisApplyStepper;
