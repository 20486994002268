import { useState } from "react";
import LogoPic from "assets/img/static/icon/logo.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import { GetRequest } from "functions/http";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "state/slice/user";
import { useToggleLoading } from "hooks";
import { LOGOUT } from "config/endpoints";
import RouteConstant from "navigation/constant";
import Role from "config/Role";
import { Channel } from "functions/helper";

const AdminNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const toggleLoading = useToggleLoading();
  const Navigate = useNavigate();

  const handleLogout = () => {
    handleClose();
    Channel.postMessage("logout");
    toggleLoading(true);
    GetRequest(LOGOUT(user._id, localStorage.getItem("token"))).finally(() =>
      toggleLoading(false)
    );
    localStorage.removeItem("token");
    Dispatch(setLogout());
    Navigate(RouteConstant.LOGIN);
  };

  return (
    <div className="main-navbar">
      <div className="resImg logo">
        <Link to="/">
          <img src={LogoPic} alt="logo" />
        </Link>
      </div>
      <div className="right-side">
        {/* <IconButton>
          <NotificationsIcon color="white" />
        </IconButton> */}
        <div
          className="c-menu"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <AccountCircleIcon sx={{ fontSize: 34 }} color="white" />
          <KeyboardArrowDownIcon color="white" />
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isLoggedIn ? (
          <MenuItem onClick={handleClose}>
            <Link
              to={
                user.role === Role.ADMIN || user.role === Role.MANAGER
                  ? RouteConstant.ADMIN_DASHBOARD
                  : user.role === Role.STAFF
                  ? RouteConstant.STAFF_DASHBOARD
                  : RouteConstant.EMBASSY_DASHBOARD
              }
              className="menu-link"
            >
              Dashboard
            </Link>
          </MenuItem>
        ) : null}
        {isLoggedIn ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.PROFILE_SETTINGS} className="menu-link">
              Profile
            </Link>
          </MenuItem>
        ) : null}
        {isLoggedIn && user.role === Role.ADMIN ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.SETTINGS} className="menu-link">
              Setting
            </Link>
          </MenuItem>
        ) : null}
        {isLoggedIn && user.role === Role.ADMIN ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.ALL_USERS} className="menu-link">
              Users
            </Link>
          </MenuItem>
        ) : null}
        {isLoggedIn ? (
          <MenuItem onClick={handleLogout}>
            <Link to="#" className="menu-link">
              Logout
            </Link>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.LOGIN} className="menu-link">
              Login
            </Link>
          </MenuItem>
        )}
        {user.role === Role.ADMIN || user.role === Role.MANAGER ? (
          <MenuItem>
            <Typography variant="subtitle2" px={2} bgcolor="grey.400" width={1}>
              Quick Links
            </Typography>
          </MenuItem>
        ) : null}
        {user.role === Role.ADMIN || user.role === Role.MANAGER ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.ABOUT_US} className="menu-link">
              About
            </Link>
          </MenuItem>
        ) : null}
        {user.role === Role.ADMIN || user.role === Role.MANAGER ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.ALL_BLOGS} className="menu-link">
              Blog
            </Link>
          </MenuItem>
        ) : null}
        {user.role === Role.ADMIN || user.role === Role.MANAGER ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.ALL_NEWS} className="menu-link">
              News
            </Link>
          </MenuItem>
        ) : null}
        {user.role === Role.ADMIN || user.role === Role.MANAGER ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.SUPPORT} className="menu-link">
              Support
            </Link>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default AdminNavbar;
