import Navbar from "parts/navbar";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";

import { useEffect, useState } from "react";
import RouteConstant from "navigation/constant";
import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import {
  GET_BLOG_BY_ID,
  DOMAIN,
  GET_ALL_BLOG_BY_SEARCH,
} from "config/endpoints";
import NoResult from "parts/noResult";
import moment from "moment";

const BlogDetails = () => {
  const toggleLoading = useToggleLoading();
  const { id } = useParams();
  const [allBlog, setAllBlogs] = useState([]);
  const [detail, setDetail] = useState(null);
  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_BLOG_BY_ID(id))
        .then((res) => {
          if (res.data?.status) {
            setDetail(res?.data?.data);
          } else {
            setDetail(null);
          }
        })
        .catch((er) => {
          setDetail(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);
  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_ALL_BLOG_BY_SEARCH)
        .then((res) => {
          if (res.data?.status) {
            setAllBlogs(res?.data?.data);
          } else {
            setAllBlogs([]);
          }
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, []);
  return (
    <section className="blog-details-page">
      <Navbar />
      <section className="wrapper container g-0 content-height">
        {detail != null ? (
          <>
            <div className="news-feed-layout ">
              <div className="new-feed-banner-container">
                <img src={DOMAIN + detail?.image?.path} alt="item" />
                <div className="banner-layer"></div>
                <div className="details justify-content-end pb-5">
                  <div>
                    <div className="badge-container">
                      {detail.isFeatured && (
                        <span className="c-badge">Featured</span>
                      )}
                      {detail.isAd && (
                        <span className="c-badge active">AD</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Grid container spacing={3} mt={4}>
              <Grid item xs={12} lg={8}>
                <Typography variant="h5" mb={1}>
                  {detail.title}
                </Typography>
                <Typography variant="caption" color="grey.500" mb={4}>
                  {moment(detail.date).format("D MMM Y")} &nbsp; &#x2022; &nbsp;
                  {detail.isAd ? (detail.adFor || " ") + " Ad" : null}
                </Typography>
                <Box>
                  <div
                    dangerouslySetInnerHTML={{ __html: detail.description }}
                  ></div>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                {allBlog.length > 0
                  ? allBlog.slice(0, 3).map((blog) => {
                      return (
                        <Card
                          sx={{
                            position: "relative",
                            cursor: "pointer",
                            mb: 3,
                          }}
                          key={blog._id}
                        >
                          <Link
                            to={RouteConstant.BLOG_DETAILS + "/" + blog._id}
                            className="stretched-link"
                          />
                          <CardMedia
                            component="img"
                            height="140"
                            image={DOMAIN + blog?.image?.path}
                            alt="news 1"
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {blog.title}
                            </Typography>
                            <Box
                              height={100}
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              color="#000"
                              dangerouslySetInnerHTML={{
                                __html: blog.description,
                              }}
                            ></Box>
                          </CardContent>
                        </Card>
                      );
                    })
                  : null}
              </Grid>
            </Grid>
          </>
        ) : (
          <NoResult />
        )}
      </section>
    </section>
  );
};

export default BlogDetails;
