import RenderCellExpand from "component/grid/expandCell";
import { Button, Chip, Avatar } from "@mui/material";
import { DOMAIN } from "config/endpoints";
import RouteConstant from "navigation/constant";
import { Link } from "react-router-dom";
const ReportColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "photo",
    headerName: "Screenshot",
    renderCell: ({ value }) => {
      return (
        <Avatar alt="NA" src={DOMAIN + value} sx={{ width: 40, height: 40 }} />
      );
    },
    width: 100,
  },
  {
    field: "application",
    headerName: "Application",
    minWidth: 150,
    renderCell: ({ value }) => {
      return (
        <Link to={RouteConstant.ADMIN_APPLICATION_DETAILS + "/" + value}>
          {value}
        </Link>
      );
    },
  },

  {
    field: "isSolved",
    headerName: "Is Solved",
    renderCell: ({ value }) => {
      return (
        <span>
          {value ? (
            <Chip size="small" label="Solved" color="success" />
          ) : (
            <Chip size="small" label="Not Solved" color="error" />
          )}
        </span>
      );
    },
  },

  {
    field: "report",
    headerName: "Report",
    minWidth: 100,
    renderCell: RenderCellExpand,
  },
  {
    field: "reply",
    minWidth: 150,
    headerName: "Reply",
    renderCell: RenderCellExpand,
  },

  {
    field: "detail",
    headerName: "Details",
    renderCell: ({ value }) => <Button>Details</Button>,
  },
];

export default ReportColumn;
