import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { Box } from "@mui/material";
import ApplicationColumn from "../columns/adminApplication";
const AdminApplicationTable = ({ width, height, URL, ...props }) => {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);
  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    `${URL}`
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);
  useEffect(() => {
    if (data.length < 1) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        applicationId: el?.applicationId,
        fromCountry: el?.fromCountry?.label,
        toCountry: el?.toCountry?.label,
        appointmentDate: el?.appointmentSlotDetails.date || null,
        appointmentTime:
          {
            time: el?.appointmentSlotDetails.from,
            id: el?.appointmentSlotDetails?._id,
          } || null,
        total: el?.total,
        isPaid: el.isPaid,
        phone: el?.phone,
        detail: el?._id,
      };
    });
    setRows(value);
  }, [data]);
  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={ApplicationColumn}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        disableColumnSelector
        components={{ Toolbar: GridToolbar }}
        // disableDensitySelector
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
};

export default AdminApplicationTable;
