import React from "react";
import { Box, Typography } from "@mui/material";
import StandardNavbar from "parts/navbar/standardNavbar";
import SaudiArabiaWelcome from "./component/saudiArabiaWelcome";
import SaudiArabiaHelp from "./component/saudiArabiaHelp";
import SaudiArabiaInfo from "./component/saudiArabiaInfo";

const SaudiArabia = () => {
  return (
    <section className="saudi-arabia-page">
      <StandardNavbar />
      <section className="content-height">
        <SaudiArabiaWelcome />
        <SaudiArabiaHelp />

        {/* Worl Map  BG */}
        <Box className="world-map-bg bg-dark" py={8}>
          <Box
            className="overlay"
            sx={{ backgroundColor: "#1C1C1C", opacity: 0.85 }}
          />
          <Box className="container">
            <Typography variant="body1" color="white.main" fontWeight={400}>
              To enter Saudi Arabia, individuals from most countries will need
              to obtain a visa in advance. The specific type of visa that is
              required will depend on the purpose of the visit and the length of
              the stay. Some common types of visas for Saudi Arabia include:
            </Typography>
          </Box>
        </Box>

        <SaudiArabiaInfo />
      </section>
    </section>
  );
};

export default SaudiArabia;
