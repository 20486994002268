import RenderCellExpand from "component/grid/expandCell";
import { Button, Chip } from "@mui/material";
import RouteConstant from "navigation/constant";
import { Link } from "react-router-dom";
import moment from "moment";
const SlotByDateColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "date",
    headerName: " Appointment Date ",
    width: 200,
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("D MMM Y") : "NA"}</span>;
    },
  },
  {
    field: "from",
    headerName: "From",
    minWidth: 150,
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("h:mm A") : "NA"}</span>;
    },
  },
  {
    field: "to",
    headerName: "To",
    minWidth: 150,
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("h:mm A") : "NA"}</span>;
    },
  },

  {
    field: "allowedApplication",
    headerName: "Allowed Application",
    minWidth: 100,
  },
  {
    field: "applied",
    minWidth: 150,
    headerName: "Applied",
    renderCell: ({ value }) => {
      return (
        <span>
          {value?.exceed ? (
            <Chip size="small" label={value?.applied} color="error" />
          ) : (
            <Chip size="small" label={value?.applied} color="success" />
          )}
        </span>
      );
    },
  },

  {
    field: "detail",
    headerName: "Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.SLOTS_DETAILS + "/" + value}>
        <Button>Details</Button>
      </Link>
    ),
  },
];

export default SlotByDateColumn;
