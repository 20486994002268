import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const QueryForm = ({ title }) => {
  const inputList = [
    { type: "text", name: "email", label: "Email" },
    { type: "textarea", name: "message", label: "Message" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Box className="world-map-bg">
      <Box className="overlay" sx={{ opacity: 0.9 }} />
      <Box className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Box pt={3}>
              <Typography
                variant="h4"
                color="white.main"
                fontWeight={400}
                fontSize={{ xs: 24, sm: 36 }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box component="form" onSubmit={handleSubmit}>
              {inputList?.map(({ type, name, label }, i) => (
                <FormControl key={i} fullWidth sx={{ mb: 2 }}>
                  <TextField
                    type={type}
                    name={name}
                    placeholder={label}
                    label={label}
                    variant="filled"
                    sx={{ backgroundColor: "#2B2B2B", color: "#808080" }}
                    multiline={type === "textarea"}
                    rows={type === "textarea" ? 4 : 1}
                  />
                </FormControl>
              ))}
            </Box>

            <Button
              disableElevation
              variant="contained"
              type="submit"
              className="rounded-2"
              sx={{ textTransform: "capitalize", fontWeight: 400 }}
            >
              Submit Queries
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default QueryForm;
