import { createTheme } from "@mui/material";

const Theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          lineHeight: 1.3,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          borderRadius: "20px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#A35E9A",
      contrastText: "#fff",
    },
    secondary: {
      dark: "#333333",
      main: "#414042",
      contrastText: "#fff",
    },
    secondary1: {
      dark: "#333333",
      main: "#3C3A3C",
      contrastText: "#fff",
    },
    secondary2: {
      dark: "#F0EFE9",
      main: "#F0EFE9",
      contrastText: "#555353",
    },
    white: {
      main: "#fff",
      contrastText: "#000",
    },
    black: {
      main: "#000000",
      contrastText: "#fff",
    },
    second: {
      main: "#F46412",
      contrastText: "#fff",
    },
  },
});
console.log(Theme);

export default Theme;
