import { Box, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import StandardNavbar from "parts/navbar/standardNavbar";
import HeaderImage from "assets/img/static/bg/visa-assistance-header.png";
import NavigateToBack from "component/navigateToBack";

import VisaAssistanceInfo from "./component/visaAssistanceInfo";
import VisaAssistanceForm from "./component/visaAssistanceForm";

const VisaAssistant = () => {
  return (
    <section className="passport-process-page">
      <Helmet>
        <title>Apply visa assistance </title>
      </Helmet>

      <StandardNavbar transparent />
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height={700}
        zIndex={-1}
        sx={{
          background: `url(${HeaderImage})`,
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box py={15}>
        <Box className="container">
          <Box color="white.main">
            <NavigateToBack color="inherit" />
          </Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <VisaAssistanceInfo />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                py={5}
                px={3}
                className="bg-white"
                sx={{
                  filter: "drop-shadow(-4px 0px 8px rgba(0, 0, 0, 0.1))",
                }}
              >
                <VisaAssistanceForm />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </section>
  );
};

export default VisaAssistant;
