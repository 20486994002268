import { Box, Button, Grid, Typography } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Countries from "state/data/countries";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { useSelector } from "react-redux";
let selected = ["SA", "IN", "IQ", "EG", "AI", "BZ"];
const SelectCountry = () => {
  const availableToCountry = useSelector((state) => state.countries.to);
console.log("availableToCountry",availableToCountry);
  return (
    <div className="select-country ">
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} className="p-center">
            <div>
              <Typography variant="h4" fontWeight={600} mb={2}>
                Select Your <br />
                Country
              </Typography>
              <Typography variant="body2" color="grey.700" mb={2}>
                Select the country to know more about the visa and process of
                applying visa.
              </Typography>
              <Button
                disableElevation
                variant="contained"
                startIcon={<PublicIcon />}
                className="rounded-2"
              >
                SHOW ALL COUNTRIES
              </Button>
              <Typography variant="body2" color="grey.700" mt={2}>
                Currently We are Serving Only Saudi Arabia. You can still check
                all the information about your favorite country
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="home-country-swiper"
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 4,
                },
              }}
            >
              {availableToCountry.map((country) => {
                return (
                  <SwiperSlide key={country.code} style={{ height: "auto" }}>
                    <div className="country-card">
                      <img
                        src={`https://flagcdn.com/w160/${country.code.toLocaleLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w320/${country.code.toLocaleLowerCase()}.png 2x`}
                        className="w-100 rounded-4"
                        alt={country.label}
                      />
                      <Box className="w-100 text-start">
                        <h4 className="country-name">{country.label}</h4>
                        {country.code === "SA" || "UAE" ? (
                          <Link
                            to={
                              country.code === "SA"
                                ? RouteConstant.SAUDI_ARABIA
                                : RouteConstant.UAE
                            }
                          >
                            <Typography variant="caption">
                              Learn more{" "}
                            </Typography>
                            <OpenInNewOutlinedIcon sx={{ fontSize: 14 }} />
                          </Link>
                        ) : (
                          <Box my={1}></Box>
                        )}
                      </Box>
                    </div>
                  </SwiperSlide>
                );
              })}
             
             
             
            </Swiper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SelectCountry;
