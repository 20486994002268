import { useState, useEffect, useCallback } from "react";
const usePhotoReview = (file) => {
  const [selectedFile, setSelectedFile] = useState(file);
  const [preview, setPreview] = useState();
  const setPhoto = useCallback((photo) => {
    setSelectedFile(photo);
  }, []);
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl =
      typeof selectedFile === "string"
        ? selectedFile
        : URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return [selectedFile, preview, setPhoto];
};
export default usePhotoReview;
