import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
const Footer = () => {
  return (
    <section className="footer">
      <div className="upper">
        <Link to={RouteConstant.DISCLAIMER} className="bright-link">
          Disclaimer
        </Link>
        <Link to={RouteConstant.TERM_CONDITION} className="bright-link">
          Term & Condition
        </Link>
        <Link to={RouteConstant.COOKIE_POLICY} className="bright-link">
          Cookie Policy
        </Link>
        <Link to={RouteConstant.PRIVACY_POLICY} className="bright-link">
          Privacy Policy
        </Link>
        <Link to={RouteConstant.REFUND_POLICY} className="bright-link">
          Refund Policy
        </Link>
        <Link to={RouteConstant.ABOUT_US} className="bright-link">
          About Us
        </Link>
        <Link to={RouteConstant.CONTACT_US} className="bright-link">
          Contact Us
        </Link>
      </div>
      <div className="lower">
        <p className="text-center">
          © ExpressVFS Limited 2023 All rights reserved.
        </p>
      </div>
    </section>
  );
};

export default Footer;
