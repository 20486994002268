import { Box, Typography, IconButton, Button } from "@mui/material";

import Navbar from "parts/navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <section className="PrivacyPolicy-page">
      <Navbar />
      <section className="bg-dark">
        <section className="wrapper container g-0">
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="white" sx={{ fontSize: 32 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Box
              width={mobileSize ? "100%" : 600}
              display="flex"
              alignItems="center"
              flexDirection="column"
              color="#fff"
            >
              <ReceiptLongIcon color="#fff" sx={{ fontSize: 64 }} />

              <Typography variant={mobileSize ? "h4" : "h3"} p={3}>
                Privacy & Policy
              </Typography>

              <Typography variant="body2" textAlign="center" mt={2}>
                At ExpressVFS, we are committed to protecting the
                privacy of our clients and ensuring the security of their
                personal information. This privacy policy explains how we
                collect, use, and disclose personal information in connection
                with our visa services.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                Collection of Personal Information
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We may collect personal information from our clients in a
                variety of ways, including through our website, over the phone,
                or in person at our office. The types of personal information we
                may collect include:
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                Name <br />
                Date of birth
                <br />
                Contact information (e.g. mailing address, email address, phone
                number)
                <br />
                Passport information
                <br />
                Travel plans and itinerary
                <br />
                Payment information (e.g. credit card number)
                <br />
                Use of Personal Information
                <br />
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We may use the personal information we collect for a variety of
                purposes, including:
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                To process and submit visa applications on behalf of our clients
                <br />
                To provide information and assistance to our clients regarding
                the visa application process
                <br />
                To process payments for our services
                <br />
                To communicate with our clients about their visa application or
                other related matters
                <br />
                To improve the services we offer and enhance our website
                <br />
                Disclosure of Personal Information
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We may disclose personal information to third parties in the
                following circumstances:
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                To government agencies or consulates in connection with the
                processing of a visa application To third-party service
                providers who assist us with providing our services, such as
                payment processors or courier services
                <br />
                As required by law, such as in response to a subpoena or court
                order Security of Personal Information
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We take appropriate measures to protect the personal information
                we collect against unauthorized access, use, disclosure, or
                destruction. These measures may include physical, technical, and
                organizational safeguards.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                Changes to This Privacy Policy
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We may update this privacy policy from time to time to reflect
                changes in our practices or legal requirements. Any changes will
                be posted on our website and will be effective as of the date of
                posting.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                Contact Us
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2} mb={4}>
                If you have any questions about this privacy policy or the
                personal information we collect, please contact us at [contact
                information].
              </Typography>
              <Link to={"/"}>
                <Button variant="contained">Back to home</Button>
              </Link>
            </Box>
          </Box>
        </section>
      </section>
    </section>
  );
};

export default PrivacyPolicy;
