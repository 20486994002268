import RenderCellExpand from "component/grid/expandCell";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
const CountryTableColumns = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
  },

  {
    field: "name",
    minWidth: 250,
    headerName: "Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "type",
    minWidth: 150,
    headerName: "Type",
    renderCell: ({ value }) => {
      return (
        <span>{value === "fromCountry" ? "From country" : "To country"}</span>
      );
    },
  },

  {
    field: "charges",
    headerName: "Visa Charge",
    minWidth: 150,
  },
  {
    field: "tax",
    headerName: "Visa Tax",
    minWidth: 150,
  },
  {
    field: "discount",
    headerName: "Visa Discount ",
    minWidth: 150,
  },
  {
    field: "total",
    headerName: "Visa Total Charges without discount",
    minWidth: 150,
  },

  {
    field: "edit",
    headerName: "Edit",
    renderCell: ({ value }) => (
      <IconButton>
        <EditIcon />
      </IconButton>
    ),
  },
];

export default CountryTableColumns;
