import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { applicationColumn } from "./column";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { DELETE_VISA_BY_ID } from "config/endpoints";
import { toast } from "react-toastify";
import { useToggleLoading } from "hooks";
import { HTTP_ERROR, PostRequest } from "functions/http";
import { setRefresh } from "state/slice/refresh";
import { useSelector, useDispatch } from "react-redux";
const ApplicationTable = ({ width, height, URL, ...props }) => {
  const toggleLoading = useToggleLoading();
  const Dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.status);

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);
  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    `${URL}`
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  const [search, setSearch] = useSearchParams();
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      const data = el.applications_details;
      return {
        id: data._id,
        fromCountry: data?.fromCountry?.label,
        toCountry: data?.toCountry?.label,
        name: data?.name,
        phone: data?.phone,
        passportNumber: data?.passportNumber,
        applicationId: data?.applicationId,
        charges: data?.total,
      };
    });
    setRows(value);
  }, [data]);
  const handleClick = (cell, b) => {
    if (cell.field === "edit") {
      setSearch(`edit=${cell.id}`);
    }
    if (cell.field === "delete") {
      const confirmation = window.confirm("Are you sure to delete?");
      if (confirmation) {
        toggleLoading(true);
        PostRequest(DELETE_VISA_BY_ID(cell.id))
          .then((res) => {
            if (res?.data?.status) {
              toast.success("Application deleted");
              Dispatch(setRefresh(!refresh));
            } else {
              toast.error(res.data.msg);
            }
          })
          .catch((er) => {
            toast.error(HTTP_ERROR(er) || "Something went wrong.");
          })
          .finally(() => toggleLoading(false));
      }
    }
  };
  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={applicationColumn}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        components={{ Toolbar: GridToolbar }}
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        onCellClick={handleClick}
      />
    </Box>
  );
};

export default ApplicationTable;
