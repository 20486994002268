import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import usePostQuery from "hooks/usePostQuery";
import PaymentsColumns from "../columns/paymentColumns";
import { GET_ALL_PAYMENTS } from "config/endpoints";
const PaymentTable = ({ width, height, URL, bodyData, ...props }) => {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);

  const { isLoading, data, rowCount } = usePostQuery(
    rowsState.page,
    rowsState.pageSize,
    `${GET_ALL_PAYMENTS}?`,
    bodyData
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        paymentId: el?.paymentId,
        paymentMethod: el?.paymentMethod,
        paid_time: el?.paid_time,
        fees: el?.fees,
        tax: el?.tax,
        isBulk: el?.isBulk,
        amount: el?.amount,
        status: { isPaid: el?.isPaid, isRefunded: el.isRefunded },
        detail: el?._id,
      };
    });
    setRows(value);
  }, [data]);

  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={PaymentsColumns}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        components={{ Toolbar: GridToolbar }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />
    </Box>
  );
};

export default PaymentTable;
