import { GetRequest } from "functions/http";
import { GET_ALL_COUNTRIES } from "config/endpoints";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchCountries = createAsyncThunk("countries/all", async () => {
  const response = await GetRequest(GET_ALL_COUNTRIES);

  if (response?.data?.status) {
    const _data = response?.data?.data;
    const fromC = _data
      .filter((e) => e.countryType === "fromCountry")
      .map((e) => ({ label: e.label, code: e.code, id: e._id }));
    const toC = _data
      .filter((e) => e.countryType === "toCountry")
      .map((e) => ({ label: e.label, code: e.code, id: e._id }));

    return {
      from: fromC,
      to: toC,
      all: _data,
    };
  } else {
    throw new Error("Error happened.");
  }
});
const initialState = {
  from: [
    // { label: "", code: "" }
  ],
  to: [],
  all: [
    // {
    //   label: "",
    //   code: "",
    //   visaCharges: {
    //     tax: 0,
    //     charges: 0,
    //     total: 0,
    //     discount: 0,
    //     onlyDiscountForBulk: false,
    //   },
    // },
  ],
  status: "idle",
};

export const CountrySlice = createSlice({
  name: "countries",
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchCountries.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.all = action.payload.all;
      state.status = "success";
    });
    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.available = initialState.available;
      state.all = initialState.all;
      state.status = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export default CountrySlice.reducer;
