import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_BY_APPLICATION_ID } from "config/endpoints";
import RouteConstant from "navigation/constant";
import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
const AdminTrackApplication = () => {
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!id) {
      setError("Booking id is required.");
      return;
    }
    toggleLoading(true);
    GetRequest(GET_BY_APPLICATION_ID(id))
      .then((res) => {
        if (res.data?.status) {
          navigate(
            RouteConstant.ADMIN_APPLICATION_DETAILS + "/" + res?.data?.data?._id
          );
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  return (
    <section>
      <AdminNavbar />
      <AdminHeader hideSearch />
      <section className="wrapper container g-0 content-height">
        <IconButton sx={{ mb: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>

        <Box
          my={3}
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexWrap="wrap"
        >
          <TextField
            placeholder="Enter  booking id"
            sx={{ width: 300, mr: 3 }}
            onChange={(e) => setId(e.target.value?.trim())}
            value={id}
            variant="outlined"
            error={error !== ""}
            helperText={error}
            onFocus={() => setError("")}
          />
          <Button
            variant="contained"
            endIcon={<ArrowOutwardIcon />}
            size="large"
            onClick={handleSubmit}
            sx={{ mt: 3 }}
          >
            TRACK APPLICATION
          </Button>
        </Box>
      </section>
    </section>
  );
};

export default AdminTrackApplication;
