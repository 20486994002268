import RenderCellExpand from "component/grid/expandCell";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
import moment from "moment";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const ApplicationColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "applicationId",
    headerName: "Application Id",
    minWidth: 200,
    renderCell: RenderCellExpand,
  },

  {
    field: "fromCountry",
    minWidth: 200,
    headerName: "From Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "toCountry",
    minWidth: 200,
    headerName: "To Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "appointmentDate",
    minWidth: 150,
    headerName: "Appointment Date",
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("D MMM Y") : "NA"}</span>;
    },
  },
  {
    field: "appointmentTime",
    minWidth: 150,
    headerName: "Appointment Time",
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("h:mm A") : "NA"}</span>;
    },
  },
  // {
  //   field: "submitted",
  //   headerName: "Submitted",
  //   renderCell: ({ value }) => {
  //     return value ? (
  //       <Chip color="success" label="Submitted" size="small" />
  //     ) : (
  //       <Chip color="warning" label="Not Submitted" size="small" />
  //     );
  //   },
  // },
  // {
  //   field: "confirmed",
  //   headerName: "Confirmed",
  //   renderCell: ({ value }) => {
  //     return value ? (
  //       <Chip color="success" label="Confirmed" size="small" />
  //     ) : (
  //       <Chip color="warning" label="Not Confirmed" size="small" />
  //     );
  //   },
  // },
  {
    field: "total",
    headerName: "Total Charges",
    valueFormatter: ({ value }) =>
      currencyFormatter.format(Number(value)) || "",
    minWidth: 150,
    cellClassName: "font-tabular-nums",
  },
  {
    field: "phone",
    headerName: "phone",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },

  {
    field: "detail",
    headerName: "All Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.VISA_APPLICATION_DETAILS + "/" + value}>
        Details
      </Link>
    ),
  },
];

export default ApplicationColumn;
