import { useEffect, useState } from "react";
import Navbar from "parts/navbar";

import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { ChangePWModel2 } from "_models/data/data.changePW2.model";
import { ErrorChangePWModel2 } from "_models/error/errors.changePW2.model";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { ErrorUserProfileModel } from "_models/error/errors.userProfile.model";
import { UserProfileModel } from "_models/data/data.userProfile.model";
import useFile from "hooks/useFile";
import { ValidateUserProfile, ValidateChangePw2 } from "functions/validators";
import {
  UPDATE_USER_PROFILE,
  DOMAIN,
  DIRECT_PASSWORD_CHANGE,
} from "config/endpoints";
import usePhotoReview from "config/photoReview";
import { useSelector } from "react-redux";
const AdminProfile = () => {
  const toggleLoading = useToggleLoading();
  const user = useSelector((state) => state.auth.user);
  const [error, setError] = useState(new ErrorUserProfileModel());
  const [state, setState] = useState(new UserProfileModel());

  const [error2, setError2] = useState(new ErrorChangePWModel2());
  const [state2, setState2] = useState(new ChangePWModel2());
  const [avatar, avatarPreview, setAvatar] = usePhotoReview(null);
  const [changePWMenu, setChangePWMenu] = useState(false);
  const fileValidation = useFile();

  const handleAvatar = (event) => {
    const { isError, errorMsg } = fileValidation({
      fileTypes: ["png", "jpg", "jpeg", "PNG"],
      maxSize: 1024 * 1024 * 1,
      file: event?.target?.files[0],
    });
    if (isError) {
      toast.error(errorMsg);
      return;
    }
    setAvatar(event.target.files[0]);
  };
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const handleChange2 = (event, name) => {
    setState2({ ...state2, [name]: event.target.value });
  };
  const handleFocus2 = (name) => {
    setError2({ ...error2, [name]: "" });
  };
  useEffect(() => {
    setAvatar(DOMAIN + user.avatar);
    setState(new UserProfileModel(user));
  }, [user]);
  const handleSubmit = () => {
    const isValid = ValidateUserProfile(state);
    if (isValid.valid) {
      const formData = new FormData();
      formData.append("name", state.name);
      formData.append("email", state.email);
      formData.append("password", state.password);
      if (typeof avatar === "object") {
        formData.append("userProfile", avatar);
      }
      toggleLoading(true);
      PostRequest(UPDATE_USER_PROFILE(user.id), formData, true)
        .then((res) => {
          if (res.data.status) {
            toast.success("Profile updated.");
            window.location.reload();
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          console.log(er);
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  const handleChangePW = () => {
    const isValid = ValidateChangePw2(state2);
    if (isValid.valid) {
      toggleLoading(true);
      PostRequest(DIRECT_PASSWORD_CHANGE(user.id), {
        newPassword: state2.password,
        password: state2.oldPassword,
      })
        .then((res) => {
          if (res.data.status) {
            toast.success(res?.data?.msg);
            window.location.reload();
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => {
          toggleLoading(false);
          setState2(new ChangePWModel2());
        });
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError2((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  return (
    <section className="profile-page">
      <Navbar />
      <section className="wrapper container g-0 content-height">
        <Typography variant="h5" className="fm-pt-mono" mb={1}>
          My Profile
        </Typography>
        <Typography color="grey.500" mb={6}>
          Edit your profile
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              position="relative"
              sx={{ width: 280, height: 280 }}
            >
              <Avatar
                src={avatarPreview}
                alt={user.name}
                sx={{
                  width: 1,
                  height: 1,
                  bgcolor: "second.main",
                  fontSize: 100,
                  position: "relative",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: " -10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  px: 4,
                  py: 2,
                  bgcolor: "white.main",
                  borderRadius: 2,
                  boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
              >
                <input
                  type="file"
                  className="hidden-file-input"
                  onChange={handleAvatar}
                />
                <PhotoCameraIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} className="p-center">
            <Box width="100%" maxWidth={450}>
              <Typography mb={2}>Full Name</Typography>
              <TextField
                fullWidth
                placeholder="Enter your Full Name"
                label="Enter Full Name"
                variant="outlined"
                error={error.nameError !== ""}
                helperText={error.nameError}
                onFocus={() => handleFocus("nameError")}
                name="name"
                value={state.name}
                onChange={(ev) => handleChange(ev, "name")}
              />
              <Typography mb={2} mt={4}>
                Email ID
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your email"
                label="Enter email"
                type="email"
                variant="outlined"
                error={error.emailError !== ""}
                helperText={error.emailError}
                onFocus={() => handleFocus("emailError")}
                name="email"
                value={state.email}
                onChange={(ev) => handleChange(ev, "email")}
              />
              <Typography mb={2} mt={4}>
                Password
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter your password"
                label="Enter password"
                variant="outlined"
                error={error.passwordError !== ""}
                helperText={error.passwordError}
                onFocus={() => handleFocus("passwordError")}
                value={state.password}
                name="password"
                type="password"
                onChange={(ev) => handleChange(ev, "password")}
              />
              <div className="d-flex flex-md-row flex-column my-5 justify-content-between">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  sx={{ mt: 3 }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  sx={{ mt: 3 }}
                  size="large"
                  onClick={() => setChangePWMenu(true)}
                >
                  Change password
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
        <div>
          <Dialog onClose={() => setChangePWMenu(false)} open={changePWMenu}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                placeholder="Enter old password"
                type="password"
                label="Old password"
                variant="outlined"
                error={error2.oldPasswordError !== ""}
                helperText={error2.oldPasswordError}
                onFocus={() => handleFocus2("oldPasswordError")}
                value={state2.oldPassword}
                name="oldPassword"
                onChange={(ev) => handleChange2(ev, "oldPassword")}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                placeholder="Enter new password"
                type="password"
                label="New password"
                variant="outlined"
                error={error2.passwordError !== ""}
                helperText={error2.passwordError}
                onFocus={() => handleFocus2("passwordError")}
                value={state2.password}
                name="password"
                onChange={(ev) => handleChange2(ev, "password")}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                placeholder="Enter  password again"
                type="password"
                label="Confirm password"
                variant="outlined"
                error={error2.confirmPasswordError !== ""}
                helperText={error2.confirmPasswordError}
                onFocus={() => handleFocus2("confirmPasswordError")}
                value={state2.confirmPassword}
                name="confirmPassword"
                onChange={(ev) => handleChange2(ev, "confirmPassword")}
                sx={{ mb: 2 }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setChangePWMenu(false)}>Cancel</Button>
              <Button onClick={handleChangePW} variant="contained">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </section>
    </section>
  );
};

export default AdminProfile;
