import RenderCellExpand from "component/grid/expandCell";
import { Button, Chip } from "@mui/material";
import RouteConstant from "navigation/constant";
import moment from "moment";
import { Link } from "react-router-dom";
import { GetPayMethod } from "functions/outputFunctions";
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const PaymentsColumns = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "paymentId",
    headerName: "Payment Id",
    width: 200,
  },
  {
    field: "paymentMethod",
    headerName: "Payment Method",
    renderCell: ({ value }) => <span>{GetPayMethod(value)}</span>,
  },
  {
    field: "paid_time",
    headerName: "Paid Time",
    minWidth: 140,
    renderCell: ({ value }) => {
      return (
        <span>{value ? moment(value).format("DD-MMM-YY HH:mm:ss") : "NA"}</span>
      );
    },
  },
  {
    field: "fees",
    headerName: "Charges",
    valueFormatter: ({ value }) =>
      currencyFormatter.format(Number(value)) || "",
    minWidth: 100,
    cellClassName: "font-tabular-nums",
  },
  {
    field: "tax",
    headerName: "Tax",
    valueFormatter: ({ value }) =>
      currencyFormatter.format(Number(value)) || "",
    minWidth: 100,
    cellClassName: "font-tabular-nums",
  },
  {
    field: "amount",
    headerName: "Total Charges",
    valueFormatter: ({ value }) =>
      currencyFormatter.format(Number(value)) || "",
    minWidth: 100,
    cellClassName: "font-tabular-nums",
  },
  {
    field: "isBulk",
    headerName: "Application Type",
    renderCell: ({ value }) => {
      return value ? (
        <Chip color="info" label="Business" />
      ) : (
        <Chip label="Single" />
      );
    },
  },
  {
    field: "status",
    headerName: "status",
    minWidth: 150,
    renderCell: ({ value }) => {
      return value.isPaid ? (
        <Chip label="Paid" color="success" />
      ) : value.isRefunded ? (
        <Chip label="Refunded" color="warning" />
      ) : (
        <Chip label="Not Paid" color="error" />
      );
    },
  },

  {
    field: "detail",
    headerName: "Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.PAYMENT_DETAILS + "/" + value}>
        <Button>Details</Button>
      </Link>
    ),
  },
];

export default PaymentsColumns;
