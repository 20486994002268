export class BlogModel {
  title;
  description;
  isFeatured;
  isAd;
  adFor;
  date;
  constructor(data = {}) {
    this.title = data.title || "";
    this.description = data.description || "";
    this.isFeatured = data.isFeatured || false;
    this.isAd = data.isAd || false;
    this.adFor = data.adFor || "";
    this.tags = data.tags || "";
    this.date = data.date || new Date();
  }
}
