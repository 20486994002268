import React from "react";

const TwitterIcon = (props) => {
  return (
    <svg
      viewBox="0 0 21 18"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.3499 2.65601C12.7681 2.65601 11.4786 3.92406 11.4504 5.50254L11.4223 7.07759C11.4164 7.40891 11.143 7.67271 10.8117 7.66681C10.7881 7.66641 10.7646 7.66461 10.7413 7.66141L9.1805 7.44851C7.12699 7.16851 5.15883 6.22332 3.27072 4.65008C2.67275 7.95971 3.84 10.2532 6.65342 12.0219L8.40041 13.1202C8.68095 13.2966 8.7654 13.667 8.58903 13.9475C8.54861 14.0118 8.49636 14.0678 8.43504 14.1126L6.84338 15.2755C7.78973 15.335 8.68938 15.2935 9.435 15.1447C14.1526 14.2032 17.2897 10.653 17.2897 4.79705C17.2897 4.31896 16.2774 2.65601 14.3499 2.65601ZM9.4507 5.46687C9.4983 2.8018 11.6735 0.656006 14.3499 0.656006C15.7132 0.656006 16.9465 1.21279 17.8348 2.11136C18.5462 2.10601 19.1514 2.28587 20.5035 1.46564C20.1693 3.10601 20.0034 3.81798 19.2897 4.79705C19.2897 12.4386 14.5926 16.1549 9.8264 17.106C6.5587 17.7581 1.80646 16.6874 0.445312 15.2646C1.13874 15.2113 3.95928 14.908 5.58895 13.7151C4.20994 12.8046 -1.27863 9.57411 2.32772 0.892376C4.02119 2.86904 5.73797 4.21452 7.47807 4.92883C8.63548 5.40395 8.91978 5.39446 9.4507 5.46687Z" />
    </svg>
  );
};

export default TwitterIcon;
