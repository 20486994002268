import { Box, Button, IconButton, Typography } from "@mui/material";
import TransparentNavbar from "parts/navbar/transparentNavbar";
import RouteConstant from "navigation/constant";
import { Link, useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

const EnjazFamilyVisa = () => {
  const navigate = useNavigate();
  return (
    <section>
      <div className=" enjaz-family-visa-header">
        <TransparentNavbar />
        <div className="enjaz-header-overlay"></div>
        <Box className="enjaz-header-container family-visa container " pt={6}>
          <div className="enjaz-header-content ">
            <IconButton onClick={() => navigate(-1)}>
              <WestIcon sx={{ fontSize: 38, color: "#fff" }} />
            </IconButton>
            <h3 className="enjazHeading">Family Visa</h3>
            <p>
              Saudi Arabia has a unique system for family visas, which is
              designed to allow foreign nationals to bring their family members
              with them to the country. The family visa is often referred to as
              the "dependent visa" because it allows the holder to reside in
              Saudi Arabia as a dependent of the sponsor, who is typically a
              foreign national working in the country.
            </p>
            <Box display="flex" mt={4} alignItems="center">
              <Link to={RouteConstant.APPLY_VISA}>
                <Button variant="contained" sx={{ mr: 3 }}>
                  Apply for visa
                </Button>
              </Link>
              <Link to={RouteConstant.TRACK_APPLICATION}>
                <Button color="white">Track My Visa</Button>
              </Link>
            </Box>
          </div>
        </Box>
      </div>
      <Box className="bg-dark" py={10}>
        <Box
          className="container text-center text-white"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography mb={3}>
            To apply for a family visa, the sponsor must first obtain a work
            visa and a residence permit, which will allow them to live and work
            in Saudi Arabia. Once the sponsor has these documents, they can then
            apply for a family visa on behalf of their family members.
          </Typography>
          <Typography>
            To apply for a family visa, the sponsor must provide the following
            documents:
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">01</span>
          <Typography>
            A completed application form for a family visa, which can be
            obtained from the Saudi Arabian embassy or consulate in the
            applicant's home country
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">02</span>
          <Typography>
            A completed application form for a family visa, which can be
            obtained from the Saudi Arabian embassy or consulate in the
            applicant's home country
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">03</span>
          <Typography>
            Copies of the passport pages for the sponsor and all family members
            who will be applying for a visa
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">04</span>
          <Typography>
            Proof of family relationship (e.g. marriage certificate for spouse,
            birth certificate for children)
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">05</span>
          <Typography>
            Once the sponsor has gathered all the necessary documents, they can
            submit the application to the Saudi Arabian embassy or consulate in
            their home country. The embassy or consulate will review the
            application and, if approved, issue a family visa to the applicant.
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">06</span>
          <Typography>
            Once the family visa has been issued, the sponsor and their family
            members can travel to Saudi Arabia and begin the process of
            obtaining a residence permit. This process typically involves a
            medical examination and an interview with the Saudi Arabian Ministry
            of Interior.
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">07</span>
          <Typography>
            Once the residence permit has been issued, the sponsor and their
            family members can live and work in Saudi Arabia, subject to the
            terms and conditions of their visas. It is important to note that
            the family visa is only valid for the duration of the sponsor's work
            visa, and it must be renewed every time the sponsor's work visa is
            renewed.
          </Typography>
          <span className="v-step"></span>
          <span className="c-badge-num">08</span>
          <Typography>
            In conclusion, the family visa system in Saudi Arabia allows foreign
            nationals to bring their family members with them to the country,
            provided that they have a valid work visa and residence permit. The
            process for applying for a family visa is straightforward, but it is
            important to carefully gather all the necessary documents and follow
            the instructions provided by the embassy or consulate.
          </Typography>
        </Box>
      </Box>
    </section>
  );
};

export default EnjazFamilyVisa;
