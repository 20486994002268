// import moment

export const StringOrder = (Items, Order, Field) => {
  return Items.sort((a, b) =>
    Order === "Asc"
      ? JSON.stringify(a[Field] || "")
          .toLowerCase()
          .replace(/ /g, "") >
        JSON.stringify(b[Field] || "")
          .toLowerCase()
          .replace(/ /g, "")
        ? 1
        : JSON.stringify(b[Field] || "")
            .toLowerCase()
            .replace(/ /g, "") >
          JSON.stringify(a[Field] || "")
            .toLowerCase()
            .replace(/ /g, "")
        ? -1
        : 0
      : JSON.stringify(a[Field] || "")
          .toLowerCase()
          .replace(/ /g, "") <
        JSON.stringify(b[Field] || "")
          .toLowerCase()
          .replace(/ /g, "")
      ? 1
      : JSON.stringify(b[Field] || "")
          .toLowerCase()
          .replace(/ /g, "") <
        JSON.stringify(a[Field] || "")
          .toLowerCase()
          .replace(/ /g, "")
      ? -1
      : 0
  );
};

export const NumberOrder = (Items, Order, Field) => {
  return Items.sort((a, b) =>
    Order === "Asc"
      ? parseInt(a[Field] || 0) > parseInt(b[Field] || 0)
        ? 1
        : parseInt(b[Field] || 0) > parseInt(a[Field] || 0)
        ? -1
        : 0
      : parseInt(a[Field] || 0) < parseInt(b[Field] || 0)
      ? 1
      : parseInt(b[Field] || 0) < parseInt(a[Field] || 0)
      ? -1
      : 0
  );
};

export const DateOrder = (Items, Order, Field) => {
  return Items.sort((a, b) =>
    Order === "Asc"
      ? new Date(a[Field]).getTime() - new Date(b[Field]).getTime()
      : new Date(b[Field]).getTime() - new Date(a[Field]).getTime()
  );
};

export const UpdateFilter = (TYPE, Config) => {
  if (TYPE === "standard") {
    Config.pageNo = Config.pageNo;
  } else if (TYPE === "prev") {
    Config.pageNo = Config.pageNo - 1;
  } else if (TYPE === "next") {
    Config.pageNo = Config.pageNo + 1;
  }
  return Config;
};

export const disableScroll = () => {
  document.body.style.overflow = "hidden";
};

export const enableScroll = () => {
  document.body.style.overflow = "scroll";
};
// export const convertDateFromOneTzToAnother = (date, fromTz, toTz, format) => {
//   const dateInFromTz = moment.tz(date, fromTz);
//   const dateInToTz = dateInFromTz.clone().tz(toTz);
//   return dateInToTz.format(format || "YYYY-MM-DDTHH:mm:ss");
// };

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const sizeCalculate = (value) => {
  if (!value) return { kb: 0 };
  console.log("value", value);
  const total_kb = value / 1024;
  const kb = Math.trunc(total_kb % 1024);
  console.log(total_kb);
  const total_mb = kb / 1024;
  console.log(total_mb % 1024);
  console.log(total_mb);
  const mb = Math.trunc(total_mb % 1024);
  const gb = mb / 1024;
  return { kb: total_kb > 1024, mb, gb };
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const Channel = new BroadcastChannel("logout");

export const monthString = (n) => {
  let month = "January";
  switch (n) {
    default:
    case 1:
      month = "January";
      break;
    case 2:
      month = "February";
      break;
    case 3:
      month = "March";
      break;
    case 4:
      month = "April";
      break;
    case 5:
      month = "May";
      break;
    case 6:
      month = "Jun";
      break;
    case 7:
      month = "July";
      break;
    case 8:
      month = "August";
      break;
    case 9:
      month = "September";
      break;
    case 10:
      month = "October";
      break;
    case 11:
      month = "November";
      break;
    case 12:
      month = "December";
      break;
  }
  return month;
};
