export class ErrorUserProfileModel {
  nameError;
  emailError;
  passwordError;
  constructor(data = {}) {
    this.nameError = data.nameError || "";
    this.emailError = data.emailError || "";
    this.passwordError = data.passwordError || "";
  }
}
