import RenderCellExpand from "component/grid/expandCell";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
import moment from "moment";
import { Chip } from "@mui/material";
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const ApplicationColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "applicationId",
    headerName: "Application Id",
    minWidth: 210,
    renderCell: RenderCellExpand,
  },

  {
    field: "fromCountry",
    minWidth: 150,
    headerName: "From Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "toCountry",
    minWidth: 150,
    headerName: "To Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "appointmentDate",
    minWidth: 130,
    headerName: "Appointment Date",
    renderCell: ({ value }) => {
      return <span>{value ? moment(value).format("D MMM Y") : "NA"}</span>;
    },
  },
  {
    field: "appointmentTime",
    minWidth: 100,
    headerName: "Appointment Time",
    renderCell: ({ value }) => {
      return (
        <Link to={RouteConstant.SLOTS_DETAILS + "/" + value.id}>
          {value?.time ? moment(value.time).format("h:mm A") : "NA"}
        </Link>
      );
    },
  },

  {
    field: "total",
    headerName: "Total Charges",
    valueFormatter: ({ value }) =>
      currencyFormatter.format(Number(value)) || "",
    minWidth: 100,
    cellClassName: "font-tabular-nums",
  },
  {
    field: "isPaid",
    headerName: "Payment",
    renderCell: ({ value }) => {
      return value ? (
        <Chip color="success" label="Paid" size="small" />
      ) : (
        <Chip color="error" label="Pending" size="small" />
      );
    },
  },
  {
    field: "phone",
    headerName: "phone",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },

  {
    field: "detail",
    headerName: "All Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.ADMIN_APPLICATION_DETAILS + "/" + value}>
        Details
      </Link>
    ),
  },
];

export default ApplicationColumn;
