import { configureStore } from "@reduxjs/toolkit";
import progressBar from "./slice/progressBar";
import user from "./slice/user";
import refresh from "./slice/refresh";
import country from "./slice/countries";
const store = configureStore({
  reducer: {
    progressBar: progressBar,
    auth: user,
    refresh: refresh,
    countries: country,
  },
});
export default store;
