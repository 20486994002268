import { useState, useEffect, useCallback } from "react";

import { Box, Grid, TextField, Typography, Button } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import LaptopPic from "assets/img/static/bg/laptop.png";
import LogoIcon from "assets/img/static/icon/logo_icon.png";
import { Link } from "react-router-dom";

import RouteConstant from "navigation/constant";
import { useToggleLoading } from "hooks";
import { GetRequest } from "functions/http";
import { FORGOT_PASSWORD } from "config/endpoints";
import { HTTP_ERROR } from "functions/http";
import { toast } from "react-toastify";
import { ForgotPWModel } from "_models/data/data.forgotPW.model";
import { ErrorForgotPWModel } from "_models/error/errors.forgotPW.model";
import { useNavigate } from "react-router-dom";
import { ValidateForgotPWForm } from "functions/validators";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  //local state
  const [error, setError] = useState(new ErrorForgotPWModel());
  const [state, setState] = useState(new ForgotPWModel());
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const handleSubmit = useCallback(() => {
    const isValid = ValidateForgotPWForm(state);
    if (isValid.valid) {
      toggleLoading(true);
      GetRequest(FORGOT_PASSWORD(state.email))
        .then((res) => {
          if (res.data.status) {
            navigate(`${RouteConstant.VERIFY_OTP}/${state.email}`);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  }, [state]);
  useEffect(() => {
    const enterHit = (ev) => {
      if (ev.keyCode === 13 || ev.which === 13) {
        handleSubmit();
      }
    };
    window.addEventListener("keydown", enterHit);
    return () => {
      window.removeEventListener("keydown", enterHit);
    };
  }, [handleSubmit]);
  return (
    <section className="forgot-password-page ">
      <Grid container spacing={3}>
        <Grid md={6} xs={12} item>
          <div className="left-carousel-container p-center px-3 px-lg-0">
          <Swiper
              modules={[Pagination]}
              className="lef-auth-swiper"
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide>
                <Box>
                  <div className="text-center resImg">
                    <img src={LaptopPic} alt="laptop" />
                  </div>
                  <Box>
                    <Typography
                      variant="h6"
                      color="white.main"
                      textAlign="center"
                      mb={2}
                    >
                      Apply VISA From Anywhere
                    </Typography>
                    <Typography
                      variant="body2"
                      maxWidth={400}
                      color="white.main"
                      mx="auto"
                      textAlign="center"
                      mb={14}
                    >
                      Apply for Visas Anywhere with Our Easy-to-Use Online
                      Application System. Submit Your Application in Minutes and
                      Get Your Visa Quickly and Hassle-Free. No Matter Where
                      You're Going, We've Got You Covered!
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
             
            </Swiper>
          </div>
        </Grid>
        <Grid md={6} xs={12} item>
          <Box px={3} py={4} className="p-center">
            <Box width="min(100%,350px)">
              <Box>
                <div>
                  <Link to={RouteConstant.HOME}>
                    <img src={LogoIcon} alt="logo icon" width={80} />
                  </Link>
                </div>
                <Typography variant="h5" fontWeight={600}>
                  Forget Password
                </Typography>
                <Typography variant="subtitle2" color="grey.400">
                  Forget password ! Don’t worry we got you
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle1" mb={1} fontWeight={600}>
                  Email
                </Typography>
                <TextField
                  label="Enter email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  sx={{ my: 1 }}
                  error={error.emailError !== ""}
                  helperText={error.emailError}
                  onFocus={() => handleFocus("emailError")}
                  name="email"
                  value={state.email}
                  onChange={(ev) => handleChange(ev, "email")}
                />
              </Box>

              <Button
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                size="large"
                fullWidth
                onClick={handleSubmit}
              >
                Continue
              </Button>
              <Button fullWidth onClick={() => navigate(-1)}>
                Back
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default ForgotPassword;
