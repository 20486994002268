import { Box, Typography, IconButton, Button } from "@mui/material";

import Navbar from "parts/navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "react-router-dom";
const Disclaimer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <section className="disclaimer-page">
      <Navbar />
      <section className="bg-dark">
        <section className="wrapper container g-0 content-height">
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="white" sx={{ fontSize: 32 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Box
              width={mobileSize ? "100%" : 600}
              display="flex"
              alignItems="center"
              flexDirection="column"
              color="#fff"
            >
              <InfoOutlinedIcon color="#fff" sx={{ fontSize: 64 }} />

              <Typography variant={mobileSize ? "h4" : "h3"} p={3}>
                Disclaimer
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                The information and resources provided on this website are for
                general informational purposes only and should not be considered
                legal or professional advice. We do not guarantee the accuracy,
                completeness, or timeliness of the information provided on this
                website, and we will not be liable for any errors or omissions.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not endorse any third-party websites or resources that may
                be linked to or from this website, and we are not responsible
                for the content or practices of those websites. Your use of any
                third-party websites or resources is at your own risk.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                The services provided by our company are subject to change
                without notice, and we do not guarantee that our website or the
                services we offer will be uninterrupted or error-free. We are
                not responsible for any delays, failures, or other issues that
                may arise in connection with the use of our website or the
                services we offer.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                By using our website and the services we offer, you agree to
                indemnify and hold us harmless from any claims, losses, damages,
                or liabilities arising from your use of our website or the
                services we offer.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2} mb={4}>
                Please consult with a licensed attorney or other qualified
                professional if you have any legal or other specific questions.
                We do not provide legal or other professional advice, and the
                information provided on
              </Typography>
              <Link to={"/"}>
                <Button variant="contained">Back to home</Button>
              </Link>
            </Box>
          </Box>
        </section>
      </section>
    </section>
  );
};

export default Disclaimer;
