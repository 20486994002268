import RenderCellExpand from "component/grid/expandCell";
import { Button, Chip } from "@mui/material";
import Countries from "state/data/countries";
const VisaAssistantColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "name",
    minWidth: 150,
    headerName: "Name",
    renderCell: RenderCellExpand,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    renderCell: RenderCellExpand,
  },

  {
    field: "phone",
    headerName: "Phone",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "visaType",
    headerName: "Visa Type",
    minWidth: 100,
    renderCell: RenderCellExpand,
  },
  {
    field: "nationality",
    headerName: "nationality",
    minWidth: 100,
    renderCell: ({ value }) => {
      return (
        <span>
          {value ? Countries.find((e) => e.code === value)?.label : ""}
        </span>
      );
    },
  },

  {
    field: "preferredDestination",
    headerName: "Preferred Destination",
    renderCell: RenderCellExpand,
  },
  {
    field: "reason",
    minWidth: 150,
    headerName: "Reason",
    renderCell: RenderCellExpand,
  },

  {
    field: "isSolved",
    headerName: "Is Solved",
    renderCell: ({ value }) => {
      return (
        <span>
          {value ? (
            <Chip size="small" label="Solved" color="success" />
          ) : (
            <Chip size="small" label="Not Solved" color="error" />
          )}
        </span>
      );
    },
  },
  {
    field: "view",
    headerName: "View",
    renderCell: ({ value }) => <Button>View</Button>,
  },
];

export default VisaAssistantColumn;
