import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Pic from "assets/img/static/bg/404.png";
const NotFound = (props) => {
  return (
    <Box
      className=" p-center"
      height={"calc( 100vh - 90px)"}
      bgcolor="primary.main"
    >
      <Box textAlign="center">
        <Box height="calc( 100vh - 300px)" overflow="hidden">
          <img src={Pic} alt="404" style={{ maxHeight: "100%" }} />
        </Box>
        <h3 className="heading-3 fw-light text-white my-3">
          Ops ! Lost your way ?
        </h3>
        <Link to="/">
          <Button
            variant="contained"
            color="white"
            sx={{ borderRadius: 5, px: 3 }}
          >
            Go Home
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
export default NotFound;
