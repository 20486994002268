import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { Box } from "@mui/material";
import { GET_BULK_BY_SEARCH } from "config/endpoints";
import { useSelector } from "react-redux";
import AgencyBulkTableColumns from "component/grid/columns/agencyColumn";
import { GetPayMethod } from "functions/outputFunctions";
const AgencyAllBulk = ({ width, height, URL, ...props }) => {
  const user = useSelector((state) => state.auth.user);
  const [searchKey, setSearchKey] = useState("");
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);
  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    `${GET_BULK_BY_SEARCH(user.agencyId)}?searchKey=${searchKey}`
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        bulkId: el?.bulkId,
        submitted: el?.submitted,
        confirmed: el?.confirmed,
        total: el?.total,
        totalApplied: el.totalApplied,
        isPaid: el.isPaid,
        date: el.createdAt,
        paymentMethod: GetPayMethod(el?.paymentMethod),
        detail: el?._id,
      };
    });
    setRows(value);
  }, [data]);
  const handleSearchFilter = (v) => {
    const searchValue = v?.quickFilterValues?.toString()?.replace(",", " ");
    setSearchKey(searchValue);
  };
  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={AgencyBulkTableColumns}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        disableColumnSelector
        // disableDensitySelector
        components={{ Toolbar: GridToolbar }}
        onFilterModelChange={handleSearchFilter}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
};

export default AgencyAllBulk;
