import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import { Link, useNavigate } from "react-router-dom";
import ReportTable from "component/grid/table/report";
import {
  GET_ALL_REPORT,
  GET_REPORT_BY_ID,
  UPDATE_REPORT,
} from "config/endpoints";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import DownloadIcon from "@mui/icons-material/Download";
import { useEffect, useState } from "react";
import moment from "moment";
import RouteConstant from "navigation/constant";
import useDownload from "hooks/useDownload";
const CustomerComplain = () => {
  const toggleLoading = useToggleLoading();
  const download = useDownload();
  const [selected, setSelected] = useState(null);
  const [details, setDetails] = useState(null);
  const [status, setStatus] = useState("");
  const [reply, setReply] = useState("");
  const [refresh, setRefresh] = useState(false);

  const onDetailClick = (id) => {
    setSelected(id);
  };
  useEffect(() => {
    if (!selected) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_REPORT_BY_ID(selected))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res?.data?.data);
            setReply(res?.data?.data?.reply)
            window.scrollTo(0, 0);
          } else {
            setDetails(null);
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
          setDetails(null);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [selected, refresh]);
  console.log(details);
  const handleSubmit = () => {
    toggleLoading(true);
    PostRequest(UPDATE_REPORT(details._id), {
      isSolved: details.isSolved,
      reply,
    })
      .then((res) => {
        if (res.data?.status) {
          toast.success("Submitted.");
          setRefresh(!refresh);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
        setDetails(null);
      })
      .finally(() => toggleLoading(false));
  };
  return (
    <section className="admin-dashboard-page">
      <AdminNavbar />
      <AdminHeader hideSearch />
      <section className="wrapper container g-0 content-height-2">
        {details && (
          <Box
            sx={{
              bgcolor: "grey.200",
              borderRadius: 4,
              p: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body2"> Application </Typography>
                <Typography variant="subtitle2" fontWeight={600}>
                  <Link
                    to={
                      RouteConstant.ADMIN_APPLICATION_DETAILS +
                      "/" +
                      details.application
                    }
                  >
                    {details.application}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body2"> Status </Typography>
                {details.isSolved ? (
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    color="success.main"
                  >
                    Solved
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    color="error.main"
                  >
                    Not Solved
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body2"> Date </Typography>
                <Typography variant="subtitle2" fontWeight={600}>
                  {moment(details.createdAt).format("D MMM Y")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography variant="body2"> User </Typography>
                <Typography variant="subtitle2" fontWeight={600}>
                  {details.user}
                </Typography>
              </Grid>
              {details.screenshot && (
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download Screenshot</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        download(details.screenshot?.path, "report-screenshot")
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download Screenshot
                    </Button>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}></Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body2">Customer Report </Typography>
                <Typography variant="subtitle2" fontWeight={600}>
                  {details.report}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Reply"
                  placeholder="Reply to customer"
                  value={reply}
                  onChange={(ev) => setReply(ev.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value={details.isSolved}
                  control={<Checkbox checked={details.isSolved} />}
                  onChange={(ev) =>
                    setDetails({ ...details, isSolved: ev.target.checked })
                  }
                  label="Mark as resolved"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="contained" size="large" onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box display={"flex"} justifyContent="space-between" mb={1} mt={4}>
          <Typography variant="h5">All customer reports</Typography>
          <Box width={100}>
            <FormControl fullWidth>
              <InputLabel id="report-status">Status</InputLabel>
              <Select
                labelId="report-status"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={(ev) => setStatus(ev.target.value)}
              >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={true}>Solved</MenuItem>
                <MenuItem value={false}>Active</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <ReportTable
          URL={GET_ALL_REPORT + `?status=${status}`}
          onDetailClick={onDetailClick}
        />
      </section>
    </section>
  );
};

export default CustomerComplain;
