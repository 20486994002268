import { useEffect, useState } from "react";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisApplyStepper from "../visaFormFill/component/stepper";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import RouteConstant from "navigation/constant";
import { GET_VISA_APP_ID, BOOK_SLOT } from "config/endpoints";
import { GetRequest, HTTP_ERROR, PostRequest } from "functions/http";
import { useToggleLoading } from "hooks";
import { toast } from "react-toastify";
import NoResult from "parts/noResult";
import { GET_SLOTS_DETAILS } from "config/endpoints";
import moment from "moment";
const isWeekend = (date) => {
  if (date.day() === 0 || date.day() === 6) return true;
  if (moment(date).diff(moment(), "days") > 15) return true;
  return false;
};

const VisaAppointment = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const [date, setDate] = useState(moment());
  const [data, setData] = useState(null);
  const [slots, setSlots] = useState([]);
  const [isBulk, setIsBulk] = useState(false);
  const [selected, setSelected] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    if (!id) navigate(RouteConstant.APPLY_VISA);
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_VISA_APP_ID(id))
        .then((res) => {
          if (res?.data?.status) {
            setData(res.data?.data);
            setIsBulk(res.data?.data?.applicationType === "business");
            if (res.data?.data?.appointmentSlot) {
              setSelected(res.data?.data?.appointmentSlot);
              setDate(res.data?.data?.appointmentDate);
            }
          } else {
            // toast.error(res.data.msg);
            setData(null);
            setIsBulk(false);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er));
          setIsBulk(false);
          setData(null);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);
  useEffect(() => {
    if (!date) {
      setSlots([]);
      return;
    }
    const _date = moment(date);
    if (_date.day() === 0 || _date.day() === 6) return;
    if (_date.diff(moment(), "days") > 15) return;
    toggleLoading(true);
    PostRequest(GET_SLOTS_DETAILS, { date: date })
      .then((res) => {
        if (res?.data?.status) {
          setSlots(res.data.data);
        } else {
          // toast.error(res.data.msg);
          setSlots([]);
        }
      })
      .catch((er) => {
        // toast.error(HTTP_ERROR(er));
        setSlots([]);
      })
      .finally(() => toggleLoading(false));
  }, [date]);
  const handleSubmit = () => {
    if (!selected) {
      return toast.error("Please select slot.");
    }
    toggleLoading(true);
    PostRequest(BOOK_SLOT(id), { slotId: selected })
      .then((res) => {
        if (res?.data?.status) {
          navigate(RouteConstant.VISA_PAYMENT + "/" + id);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er));
        setSlots([]);
      })
      .finally(() => toggleLoading(false));
  };
  if (data?.confirmed) {
    return (
      <Box>
        <section>
          <Navbar />
          <div className="p-center my-4">
            <NoResult />
          </div>
        </section>
      </Box>
    );
  }
  return (
    <section>
      <Navbar />
      <Helmet>
        <title>Application appointment </title>
      </Helmet>
      <section className="wrapper container g-0">
        {isBulk ? (
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
        ) : (
          ""
        )}
        <Typography variant="h5" className="fm-pt-mono" mb={1}>
          Apply for a visa
        </Typography>
        <Typography color="grey.500">
          Find the information and begin your application
        </Typography>
        <Box>
          <VisApplyStepper step={1} />
        </Box>
        {data != null ? (
          <Box>
            <Box mt={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker
                      orientation={mobileSize ? "portrait" : "landscape"}
                      openTo="day"
                      value={date}
                      disablePast
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      shouldDisableDate={isWeekend}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" mb={3}>
                    Available Time Slots
                  </Typography>
                  {slots.length > 0 ? (
                    <Box flexWrap="wrap" display="flex">
                      {slots.map((slot) => {
                        return (
                          <Button
                            key={slot._id}
                            variant="contained"
                            color={
                              selected === slot._id ? "primary" : "secondary2"
                            }
                            sx={{ mr: 1, mb: 1 }}
                            onClick={() => setSelected(slot._id)}
                            disabled={
                              slot.allowedApplication <= slot.applied ||
                              moment(slot.to).isBefore(moment())
                            }
                          >
                            {moment(slot.from).format("hh:mm a")}
                          </Button>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h4">
                        {date != null
                          ? "No slots available on this date."
                          : "Please select a date."}
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" justifyContent="flex-end" mt={6}>
                    <Button
                      variant="contained"
                      endIcon={<ArrowOutwardIcon />}
                      size="large"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <div className="p-center my-4">
            <NoResult />
          </div>
        )}
      </section>
    </section>
  );
};

export default VisaAppointment;
