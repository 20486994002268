import { Box, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import HeaderImage from "assets/img/static/bg/application-process-header.png";
import { Link } from "react-router-dom";

const ApplicationProcessHeader = () => {
  return (
    <Box pt={5}>
      <Box className="container" color="black.main">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box pb={5}>
              <NavigateToBack />

              <Title
                title="Visa Application process"
                fontFamilyClass="fm-poppins"
                fontSize={42}
                fontWeight={700}
              />
              <Typography variant="body1" fontWeight={400} mb={2} mt={2}>
                translation services are a vital tool in today's globalized
                world, where businesses and individuals often need to
                communicate across language barriers
              </Typography>
              <Typography
                variant="body1"
                fontWeight={700}
                letterSpacing={1}
                textTransform="uppercase"
              >
                <Link>Apply Now</Link>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            position="relative"
            overflow="hidden"
          >
            <Box
              sx={{ color: "#B964B645" }}
              position="absolute"
              bottom={{ xs: 0, lg: "-10%" }}
            >
              <Typography
                className="fm-poppins"
                fontSize={{ xs: "36vw", md: 120, lg: 220 }}
                lineHeight={{ xs: 1 }}
                fontWeight={700}
                variant="h4"
                color="inherit"
              >
                VISA
              </Typography>
            </Box>
            <Box className="resImg" width="100%">
              <img src={HeaderImage} alt="Welcome" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ApplicationProcessHeader;
