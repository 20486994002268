import {
  Box,
  Button,
  TextField,
  Dialog,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DateRangePicker from "component/other/dateRangePicker";

const PaymentFilter = ({
  element,
  value,
  onClose = () => {},
  onChange = () => {},
  isFiltered = () => {},
}) => {
  const [filter, setFilter] = useState(value);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  useEffect(() => {
    setFilterAnchorEl(element || null);
  }, [element]);
  const handleClose = () => {
    setFilterAnchorEl(null);
    onClose();
  };
  const handleClearFilter = () => {
    const f = {
      category: "",
      method: "",
      startDate: null,
      endDate: null,
      minAmount: 0,
      maxAmount: 0,
    };
    setFilter(f);
    onChange(f);
    handleClose();
    isFiltered(false);
  };

  const handleSubmit = () => {
    console.log(filter);
    onChange({
      ...filter,
      startDate: filter.startDate
        ? moment(filter.startDate).add(150, "minutes").format("MM/DD/YYYY")
        : null,
      endDate: filter.endDate
        ? moment(filter.endDate).add(150, "minutes").format("MM/DD/YYYY")
        : null,
    });
    let flag = false;
    for (const key in filter) {
      if (Boolean(filter[key])) {
        flag = true;
      }
    }
    isFiltered(flag);
    handleClose();
  };
  return (
    <Dialog
      open={openFilter}
      onClose={handleClose}
      aria-labelledby="filter-dlg"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle id="filter-dlg">
        <Box display="flex" justifyContent="space-between">
          Filter
          <Box>
            <Button onClick={handleClearFilter}>Clear</Button>
            <Button onClick={handleSubmit} sx={{ ml: 2 }} variant="contained">
              Ok
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="filter-dlg-select">Category</InputLabel>
              <Select
                labelId="filter-dlg-select"
                label="Category"
                value={filter.category}
                onChange={(ev) =>
                  setFilter({ ...filter, category: ev.target.value })
                }
              >
                <MenuItem value={"paid"}>Paid</MenuItem>
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"refund"}>Refund</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="filter-dlg-select">Method</InputLabel>
              <Select
                labelId="filter-dlg-select"
                label="Status"
                value={filter.method}
                onChange={(ev) =>
                  setFilter({ ...filter, method: ev.target.value })
                }
              >
                <MenuItem value={"online"}>Online</MenuItem>
                <MenuItem value={"visa_center"}>Visa center</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Minimum price"
              placeholder="Enter Minimum price"
              type="number"
              inputProps={{ min: 0 }}
              onChange={(ev) =>
                setFilter({ ...filter, minAmount: ev.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Maximum price"
              placeholder="Enter maximum price"
              type="number"
              inputProps={{ min: 0 }}
              onChange={(ev) =>
                setFilter({ ...filter, maxAmount: ev.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker
              startDate={filter.startDate}
              endDate={filter.endDate}
              setStartDate={(date) => setFilter({ ...filter, startDate: date })}
              setEndDate={(date) => setFilter({ ...filter, endDate: date })}
              width={"100%"}
              flex="1 1 auto"
              minDate={moment().valueOf()}
              fullInputWidth={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
PaymentFilter.propTypes = {
  element: PropTypes.object,
  value: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isFiltered: PropTypes.func,
  filtered: PropTypes.object,
};
export default PaymentFilter;
