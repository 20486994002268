import StandardNavbar from "parts/navbar/standardNavbar";
import NotarizationHeader from "./component/notarizationHeader";
import NotarizationInfo from "./component/notarizationInfo";
import QuickLinks from "parts/quickLinks";
import QueryForm from "parts/queryForm";
const NotarizationService = () => {
  return (
    <section className="notarization-page">
      <StandardNavbar />
      <NotarizationHeader />
      <NotarizationInfo />
      <QuickLinks />
      <QueryForm title="For More Your Queries <br /> Contact Our Support <br /> Team " />
    </section>
  );
};

export default NotarizationService;
