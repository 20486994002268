import { Box } from "@mui/material";
import PropTypes from "prop-types";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Link } from "react-router-dom";
const ServiceCard = ({ title, description, Icon, link, linkType }) => {
  return (
    <Box className="service-card">
      <span className="forward-icon">
        <OpenInNewRoundedIcon />
      </span>
      {linkType !== "external" ? (
        <Link to={link} className="stretched-link"></Link>
      ) : (
        <a
          href={link}
          target="_blank"
          className="stretched-link"
          rel="noreferrer"
        ></a>
      )}

      <div className="content-sec">
        {Icon ? (
          <span className="service-icon">
            <Icon />
          </span>
        ) : (
          <></>
        )}
        <h5 className="heading" dangerouslySetInnerHTML={{ __html: title }} />
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </Box>
  );
};
ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  link: PropTypes.string.isRequired,
};
export default ServiceCard;
