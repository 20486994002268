import React from "react";

const FacebookIcon = (props) => {
  return (
    <svg
      viewBox="0 0 11 21"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 11.6062H9.5L10.5 7.6062H7V5.6062C7 4.57682 7 3.6062 9 3.6062H10.5V0.246301C10.1743 0.203051 8.943 0.106201 7.6429 0.106201C4.9284 0.106201 3 1.76306 3 4.80591V7.6062H0V11.6062H3V20.1062H7V11.6062Z" />
    </svg>
  );
};

export default FacebookIcon;
