import { Box, Typography, IconButton, Button } from "@mui/material";

import Navbar from "parts/navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CookieIcon from "assets/img/static/icon/cookieIcon.png";
const CookiePolicy = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <section className="CookiePolicy-page">
      <Navbar />
      <section className="bg-dark">
        <section className="wrapper container g-0 content-height">
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="white" sx={{ fontSize: 32 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Box
              width={mobileSize ? "100%" : 600}
              display="flex"
              alignItems="center"
              flexDirection="column"
              color="#fff"
            >
              <Box className="resImg" width={200}>
                <img src={CookieIcon} alt="CookieIcon" />
              </Box>
              <Typography variant={mobileSize ? "h4" : "h3"} p={3}>
                Cookie Policy
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                At Express data analytics we use cookies and other similar
                technologies on our website to improve the user experience and
                to gather information about how our website is used. By using
                our website, you consent to the use of cookies and similar
                technologies in accordance with this policy.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                A cookie is a small piece of data that is stored on your device
                when you visit a website. Cookies are used to store information
                about your preferences and activities on the website, and to
                enable certain features and functions of the website.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We use cookies to improve the performance of our website, to
                personalize the content and ads that are shown to you, and to
                analyze how our website is used. We may also use cookies to
                enable features and functions of our website, such as
                remembering your login information or preferences.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                You can control the use of cookies on your device through your
                browser settings. Most browsers allow you to block or delete
                cookies, but doing so may impact the functionality of our
                website and other websites that you visit.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not use cookies to collect personal information about you,
                and we do not share the information that is collected through
                cookies with third parties, except as may be required by law.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2} mb={4}>
                If you have any questions or concerns about our use of cookies,
                please contact us at info@expressvfs.com
              </Typography>
              <Link to={"/"}>
                <Button variant="contained">Back to home</Button>
              </Link>
            </Box>
          </Box>
        </section>
      </section>
    </section>
  );
};

export default CookiePolicy;
