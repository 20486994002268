import { useCallback } from "react";
import { useSelector } from "react-redux";
import Role from "config/Role";
const useAccess = () => {
  const userRole = useSelector((state) => state.auth.user.role);

  const isGranted = useCallback(
    (roles) => {
      if (!roles || roles === undefined) {
        return true;
      }
      let ROLES = Array.from(roles);
      if (typeof roles === "string") {
        ROLES = [roles];
      }
      ROLES.push(Role.ADMIN);
      if (ROLES?.includes(userRole)) {
        return true;
      } else {
        return false;
      }
    },
    [userRole]
  );

  return isGranted;
};
export default useAccess;
