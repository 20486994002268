import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import HeaderImage from "assets/img/static/bg/passport-processing-header-1.png";
import HeaderImage2 from "assets/img/static/bg/passport-processing-header-2.png";
import HeaderImage3 from "assets/img/static/bg/passport-processing-header-3.png";

const PassportProcessingHeader = () => {
  return (
    <Box pt={5}>
      <Box className="container" color="black.main">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box>
              <NavigateToBack />
              <Box pt={5}>
                <Title
                  title="Passport processing <br /> and renewal"
                  fontFamilyClass="fm-poppins"
                  fontSize={42}
                  fontWeight={700}
                />
                <Typography variant="body1" fontWeight={400} mb={2} mt={2}>
                  If you need a new passport or need to renew your existing
                  passport, we can assist with the process and ensure that your
                  passport is ready in time for your trip.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            position="relative"
            overflow="hidden"
            minHeight={450}
          >
            <Box
              className="resImg"
              width="45%"
              position="absolute"
              bottom={-2}
              left={0}
              zIndex={1}
            >
              <img src={HeaderImage} alt="Welcome" />
            </Box>
            <Box
              className="resImg"
              width="65%"
              position="absolute"
              bottom={0}
              right={0}
            >
              <img src={HeaderImage2} alt="Welcome" />
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            background: `url(${HeaderImage3}), rgba(163, 94, 154, 0.91);`,
            borderRadius: "8px",
            backgroundSize: {
              xs: "100%",
              lg: "cover",
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
          }}
        >
          <Grid container pt={{ xs: "25%", lg: 0 }}>
            <Grid item xs={12} lg={6}>
              <Box p={5} color="white.main">
                <Typography variant="body1" mb={3}>
                  At ExpressVFS, we understand that obtaining a passport can be
                  a daunting task. That's why we offer comprehensive passport
                  processing and renewal services to help our clients obtain or
                  renew their passports quickly and easily.
                </Typography>
                <Typography variant="body1">
                  Our team of experts is well-versed in the passport application
                  process and will guide you through the entire process from
                  start to finish. We offer a range of services to meet the
                  needs of our clients, including new passport applications,
                  passport renewals, and expedited passport processing.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PassportProcessingHeader;
