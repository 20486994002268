import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import { Box, Typography, TextField } from "@mui/material";
import { useState } from "react";
import { GET_SLOTS_DETAILS } from "config/endpoints";
import SlotByDateTable from "component/grid/table/slotByDateTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
const isWeekend = (date) => {
  if (date.day() === 0 || date.day() === 6) return true;
  if (moment(date).diff(moment(), "days") > 15) return true;
  return false;
};
const AllBookingSlots = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const [date, setDate] = useState(moment);
  return (
    <section className="admin-all-bookings-page">
      <AdminNavbar />
      <AdminHeader hideSearch />
      <section className="wrapper container g-0">
        <Box mt={3}>
          <Typography variant="overline" fontSize={20}>
            All Booking slots
          </Typography>
        </Box>
        <Box className="p-center" my={3}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              orientation={mobileSize ? "portrait" : "landscape"}
              openTo="day"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              shouldDisableDate={isWeekend}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box my={4}>
          <SlotByDateTable
            URL={GET_SLOTS_DETAILS + "?"}
            bodyData={{ date: date }}
          />
        </Box>
      </section>
    </section>
  );
};

export default AllBookingSlots;
