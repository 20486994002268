import { Box, Typography } from "@mui/material";

import StandardNavbar from "parts/navbar/standardNavbar";
import AboutWelcome from "./component/aboutWelcome";
import AboutHelp from "./component/aboutHelp";

import QueryForm from "parts/queryForm";

const AboutUs = () => {
  return (
    <section className="about-us-page">
      <StandardNavbar />
      <section className="content-height">
        {/* Welcome */}
        <AboutWelcome />

        {/* Help */}
        <AboutHelp />

        {/* Worl Map  BG */}
        <Box className="world-map-bg bg-dark" py={8}>
          <Box
            className="overlay"
            sx={{ backgroundColor: "#1C1C1C", opacity: 0.85 }}
          />
          <Box className="container">
            <Typography variant="body1" color="white.main" fontWeight={400}>
              We also offer comprehensive passport processing and renewal
              services, including new passport applications, passport renewals,
              and expedited passport processing. Our team of experts will guide
              you through the entire process and ensure that your application is
              completed accurately and submitted on time.
            </Typography>
          </Box>
        </Box>

        {/* info */}
        <Box py={5}>
          <Box className="container" sx={{ color: "#010101", fontWeight: 400 }}>
            <Typography variant="body1" fontWeight={400} mb={3}>
              Our ticketing services make it easy for you to plan your travel
              and make your trip as seamless as possible. We offer airline
              tickets, hotel reservations, car rentals, and cruise bookings to
              help you find the best options for your travel needs.
            </Typography>

            <Typography variant="body1" fontWeight={400} mb={3}>
              At ExpressVFS, we are committed to building long-term
              relationships with our clients. We believe in providing
              exceptional customer service and support to ensure that every
              client has a positive experience with our company.
            </Typography>
            <Typography variant="body1" fontWeight={400}>
              Thank you for choosing ExpressVFS as your travel partner. We look
              forward to working with you and helping you achieve your travel
              goals.
            </Typography>
          </Box>
        </Box>

        {/* Query */}
        <QueryForm title="For More Your Queries <br /> Contact Our Support <br /> Team " />
      </section>
    </section>
  );
};

export default AboutUs;
