export class ContactModel {
  name;
  email;
  passport;
  message;
  constructor(data = {}) {
    this.name = data.name || "";
    this.email = data.email || "";
    this.passport = data.passport || "";
    this.message = data.message || "";
  }
}
