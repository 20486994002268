import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import AdminApplicationTable from "component/grid/table/adminApplicationTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Filter from "component/filter";
import { GET_RECENT_APPLICATION, GET_ALL_BULKS } from "config/endpoints";
import AdminAllBulk from "component/grid/table/adminAllBulk";
const AllBookings = () => {
  const [active, setActive] = useState("individual");
  const [filter, setFilter] = useState({
    status: "",
    method: "",
    fromDate: null,
    toDate: null,
    minAmount: 0,
    maxAmount: 0,
    paymentStatus: 1,
  });
  const [filterButton, setFilterButton] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);

  return (
    <section className="admin-all-bookings-page">
      <AdminNavbar />
      <AdminHeader onSearchChange={(value) => setSearchKey(value)} />
      <section className="wrapper container g-0">
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Typography variant="overline" fontSize={20}>
            Booking
          </Typography>
          <Box>
            <div className={isFiltered ? "filter-badge" : ""}>
              <IconButton
                onClick={(event) => setFilterButton(event.currentTarget)}
              >
                <FilterAltIcon />
              </IconButton>
            </div>
          </Box>
        </Box>
        <Box display="flex" mt={3}>
          <Button
            onClick={() => setActive("individual")}
            variant={active === "individual" ? "contained" : "text"}
            sx={{
              borderTopLeftRadius: 35,
              borderBottomRightRadius: 35,
              mr: 3,
            }}
          >
            Individual
          </Button>
          <Button
            onClick={() => setActive("business")}
            variant={active === "business" ? "contained" : "text"}
            sx={{
              borderTopLeftRadius: 35,
              borderBottomRightRadius: 35,
              mr: 3,
            }}
          >
            Business
          </Button>
          <Button
            onClick={() => setActive("bulk")}
            variant={active === "bulk" ? "contained" : "text"}
            sx={{
              borderTopLeftRadius: 35,
              borderBottomRightRadius: 35,
            }}
          >
            Bulk Application
          </Button>
        </Box>
        <Box my={4}>
          {active === "bulk" ? (
            <AdminAllBulk URL={GET_ALL_BULKS + `?searchKey=${searchKey}`} />
          ) : (
            <AdminApplicationTable
              URL={
                GET_RECENT_APPLICATION +
                `?searchKey=${searchKey}&status=${filter.status}&fromDate=${filter.fromDate}&toDate=${filter.toDate}&minAmount=${filter.minAmount}&maxAmount=${filter.maxAmount}&method=${filter.method}&paymentStatus=${filter.paymentStatus}&type=${active}`
              }
            />
          )}
        </Box>
        <Filter
          element={filterButton}
          onChange={(filter) => setFilter(filter)}
          onClose={() => setFilterButton(null)}
          value={filter}
          isFiltered={(value) => setIsFiltered(value)}
        />
      </section>
    </section>
  );
};

export default AllBookings;
