import { useState } from "react";
import LogoPic from "assets/img/static/icon/logo.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import { GetRequest } from "functions/http";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "state/slice/user";
import { useToggleLoading } from "hooks";
import { LOGOUT, DOMAIN } from "config/endpoints";
import { Channel } from "functions/helper";
import RouteConstant from "navigation/constant";
const UserNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const toggleLoading = useToggleLoading();
  const Navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    toggleLoading(true);
    Channel.postMessage("logout");
    GetRequest(LOGOUT(user._id, localStorage.getItem("token"))).finally(() =>
      toggleLoading(false)
    );
    localStorage.removeItem("token");
    Dispatch(setLogout());
    Navigate(RouteConstant.LOGIN);
  };
  return (
    <div className="main-navbar">
      <div className="resImg logo">
        <Link to="/">
          <img src={LogoPic} alt="logo" />
        </Link>
      </div>
      <div className="right-side">
        {/* <IconButton>
          <NotificationsIcon color="white" />
        </IconButton> */}
        <div
          className="c-menu"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {user?.avatar ? (
            <Avatar
              sx={{ width: 28, height: 28 }}
              alt={user?.name}
              src={DOMAIN + user?.avatar}
            />
          ) : (
            <AccountCircleIcon sx={{ fontSize: 34 }} color="white" />
          )}
          <KeyboardArrowDownIcon color="white" />
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isLoggedIn ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.TRACK_APPLICATION} className="menu-link">
              Track Application
            </Link>
          </MenuItem>
        ) : null}
        {isLoggedIn ? (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.USER_PROFILE} className="menu-link">
              My Profile
            </Link>
          </MenuItem>
        ) : null}
        {isLoggedIn ? (
          <MenuItem onClick={handleLogout}>
            <Link to="#" className="menu-link">
              Logout
            </Link>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClose}>
            <Link to={RouteConstant.LOGIN} className="menu-link">
              Login
            </Link>
          </MenuItem>
        )}
        <MenuItem>
          <Typography variant="subtitle2" px={2} bgcolor="grey.400" width={1}>
            Quick Links
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={RouteConstant.ABOUT_US} className="menu-link">
            About
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={RouteConstant.BLOG} className="menu-link">
            Blog
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={RouteConstant.NEWS} className="menu-link">
            News
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={RouteConstant.CONTACT_US} className="menu-link">
            Support
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to={RouteConstant.FAQ} className="menu-link">
            FAQ
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserNavbar;
