export class VisaSlotSettingModel {
  duration;
  totalSlots;
  applicationPerSlot;
  breakDuration;
  startingTime;
  breakTime;
  constructor(data = {}) {
    this.duration = data.duration || "";
    this.totalSlots = data.totalSlots || "";
    this.applicationPerSlot = data.applicationPerSlot || "";
    this.breakDuration = data.breakDuration || "";
    this.startingTime = data.startingTime || "";
    this.breakTime = data.breakTime || "";
  }
}
