import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavigateToBack = ({ color, buttonColor }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      sx={{ mb: 2, color: buttonColor || "inherit" }}
      onClick={() => navigate(-1)}
    >
      <ArrowBackIcon color={color || "inherit"} sx={{ fontSize: 32 }} />
    </IconButton>
  );
};

export default NavigateToBack;
