import { Box, Grid, Typography } from "@mui/material";
import HelpImage from "assets/img/static/bg/about-help.png";

const AboutHelp = () => {
  return (
    <Box
      color="white.main"
      sx={{ backgroundColor: ({ palette }) => palette?.primary?.main }}
    >
      <Box className="container">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            alignItems="flex-end"
            order={{ xs: 2, md: 1 }}
          >
            <Box className="resImg">
              <img src={HelpImage} alt="Help" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Box py={10}>
              <Typography
                variant="body1"
                textTransform="uppercase"
                fontWeight={500}
                letterSpacing={3}
              >
                HOW WE HELP YOU WITH
              </Typography>

              <Typography variant="body1" fontWeight={400} my={2}>
                We understand that obtaining a visa, renewing a passport, or
                planning travel can be a complicated and time-consuming process.
                That's why we've developed a range of services to simplify the
                process and provide personalized attention and support to our
                clients.
              </Typography>
              <Typography variant="body1" fontWeight={400} my={2}>
                Our visa services include visa application processing, document
                review and submission, and visa consultation. We specialize in
                providing visa solutions for a wide range of industries,
                including business, travel, education, and immigration.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutHelp;
