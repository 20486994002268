import { Box, Typography, IconButton, Button } from "@mui/material";

import Navbar from "parts/navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
const RefundPolicy = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <section className="RefundPolicy-page">
      <Navbar />
      <section className="bg-dark">
        <section className="wrapper container g-0">
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="white" sx={{ fontSize: 32 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Box
              width={mobileSize ? "100%" : 600}
              display="flex"
              alignItems="center"
              flexDirection="column"
              color="#fff"
            >
              <PaidOutlinedIcon color="#fff" sx={{ fontSize: 64 }} />
              <Typography variant={mobileSize ? "h4" : "h2"} p={3}>
                Refund Policy
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                At ExpressVFS, we strive to provide our clients with
                the highest level of service and support. However, if you are
                not satisfied with the services we have provided, we are happy
                to offer a refund in accordance with the following policy:
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                Refund requests must be made within 30 days of the date on which
                the services were provided. Refunds will be issued in the same
                form of payment as the original payment.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We reserve the right to refuse a refund request if it is deemed
                to be fraudulent or otherwise invalid.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not offer refunds for services that have been completed
                and delivered to the client, unless the services were not
                performed in accordance with the agreed-upon terms.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not offer refunds for services that have been canceled by
                the client, unless the cancellation is due to a mistake on our
                part or a failure on our part to provide the agreed-upon
                services.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not offer refunds for services that have been delayed or
                otherwise impacted by circumstances beyond our control, such as
                government actions or natural disasters.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2} mb={4}>
                If you have any questions about our refund policy or would like
                to request a refund, please contact us at [contact information].
                We will do our best to resolve any issues and provide a
                satisfactory resolution.
              </Typography>
              <Link to={"/"}>
                <Button variant="contained">Back to home</Button>
              </Link>
            </Box>
          </Box>
        </section>
      </section>
    </section>
  );
};

export default RefundPolicy;
