import { useEffect, useState, useCallback } from "react";

import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import WhiteLogoIcon from "assets/img/static/icon/white_logo_icon.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import RouteConstant from "navigation/constant";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { ErrorUserLoginModel } from "_models/error/errors.userLogin.model";
import { UserLoginModel } from "_models/data/data.userLogin.model";
import { ValidateLoginForm } from "functions/validators";
import { LOGIN } from "config/endpoints";
import { useDispatch } from "react-redux";
import { setUser } from "state/slice/user";
import Role from "config/Role";
const AdminLogin = () => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const toggleLoading = useToggleLoading();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_url");
  //local state
  const [error, setError] = useState(new ErrorUserLoginModel());
  const [state, setState] = useState(new UserLoginModel());
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };

  const handleLogin = useCallback(() => {
    const isValid = ValidateLoginForm(state);
    if (isValid.valid) {
      toggleLoading(true);
      PostRequest(LOGIN, {
        contact: state.contact?.trim(),
        password: state.password,
      })
        .then((res) => {
          if (res.data.status) {
            const role = res.data?.data?.role;
            if (role === Role.AGENCY || role === Role.BASIC) {
              toast.error("You are not admin.Please login as user.");
            } else {
              // toast.success("Successfully logged in.");
              localStorage.setItem("token", res.data.data.token);
              Dispatch(
                setUser({
                  ...res.data.data,
                  id: res.data.data._id,
                })
              );
              if (redirectUrl) {
                navigate("/" + redirectUrl);
              } else {
                // NOTE
                if (role === Role.ADMIN || role === Role.MANAGER) {
                  navigate(RouteConstant.ADMIN_DASHBOARD);
                } else if (role === Role.EMBASSY) {
                  navigate(RouteConstant.EMBASSY_DASHBOARD);
                } else {
                  navigate(RouteConstant.STAFF_DASHBOARD);
                }
              }
            }
          } else {
            if (!res.data.isEmailVerified) {
              navigate(`${RouteConstant.USER_NOT_VERIFIED}/${state.email}`);
              return;
            }
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  }, [state]);

  useEffect(() => {
    const enterHit = (ev) => {
      if (ev.keyCode === 13 || ev.which === 13) {
        handleLogin();
      }
    };
    window.addEventListener("keydown", enterHit);
    return () => {
      window.removeEventListener("keydown", enterHit);
    };
  }, [handleLogin]);
  return (
    <section className="admin-login-page px-2 ">
      <Grid container spacing={3} sx={{ minHeight: "calc(100vh - 200px)" }}>
        <Grid md={4} xs={12} item>
          <div className="admin-login-left-side">
            <div className="resImg">
              <Link to={RouteConstant.HOME}>
                <img src={WhiteLogoIcon} alt="logo icon" />
              </Link>
            </div>
            <div className="footer-items">
              <Link to={RouteConstant.CONTACT_US}>Help</Link>
              <Link to={RouteConstant.FAQ}>Faq</Link>
              <Link to={RouteConstant.LOGIN}>Back to user</Link>
            </div>
          </div>
        </Grid>
        <Grid md={8} xs={12} item>
          <Box px={3} py={3} className="admin-login-card">
            <Box textAlign="center">
              <Typography variant="h5" mt={2} fontWeight={500}>
                Welcome, Admin
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Email or Phone
              </Typography>
              <TextField
                label="Enter email or phone number."
                variant="outlined"
                fullWidth
                error={error.contactError !== ""}
                helperText={error.contactError}
                onFocus={() => handleFocus("contactError")}
                name="contact"
                value={state.contact}
                onChange={(ev) => handleChange(ev, "contact")}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Password
              </Typography>
              <TextField
                label="Enter password"
                variant="outlined"
                fullWidth
                type="password"
                error={error.passwordError !== ""}
                helperText={error.passwordError}
                onFocus={() => handleFocus("passwordError")}
                id="password"
                name="password"
                value={state.password}
                onChange={(ev) => handleChange(ev, "password")}
              />
            </Box>
            <div className="d-flex justify-content-between flex-column flex-lg-row mt-3">
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember me"
              />
              <Link to={RouteConstant.FORGOT_PASSWORD} className="dark-link ">
                Forgot Password
              </Link>
            </div>
            <div className="p-center">
              <Button
                variant="contained"
                sx={{ width: 300, my: 3, mx: "auto" }}
                size="large"
                onClick={handleLogin}
              >
                Login
              </Button>
            </div>
          </Box>
          <div className="r-footer-items">
            <Link to={RouteConstant.CONTACT_US}>Help</Link>
            <Link to={RouteConstant.FAQ}>Faq</Link>
            <Link to={RouteConstant.LOGIN}>Back to user</Link>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default AdminLogin;
