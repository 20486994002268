import RouteConstant from "navigation/constant";

import StandardNavbar from "parts/navbar/standardNavbar";
import TipsAbroadHeader from "./component/tipsAbroadHeader";
import TipsAbroadInfo from "./component/tipAbroadInfo";
import QuickLinks from "parts/quickLinks";
import QueryForm from "parts/queryForm";

const TipsForAbroad = () => {
  return (
    <section className="tips-for-abroad-page">
      <StandardNavbar />
      <TipsAbroadHeader />
      <TipsAbroadInfo />
      <QuickLinks />
      <QueryForm title="For More Your Queries <br /> Contact Our Support <br /> Team " />
    </section>
  );
};

export default TipsForAbroad;
