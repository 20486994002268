import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
const UnAuthorized = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  return (
    <Box className="p-center un-authorize-page" height={"100vh"}>
      {auth.status === "loading" || auth.status === "idle" ? (
        <Typography variant="h5">Loading...</Typography>
      ) : (
        <Box textAlign="center">
          <Typography variant="h4" color="error.main" textAlign="center" mb={3}>
            {auth.status === "success"
              ? " You have no authorize to access this page."
              : "Oops! Something went wrong. Please reload the page."}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/")}
            startIcon={<KeyboardBackspaceIcon />}
          >
            Go Home
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default UnAuthorized;
