export class ErrorContactModel {
  nameError;
  emailError;
  messageError;
  constructor(data = {}) {
    this.nameError = data.nameError || "";
    this.emailError = data.emailError || "";
    this.messageError = data.messageError || "";
  }
}
