export const GetStatus = (status) => {
  let output = "Pending";
  switch (status) {
    case "pending":
      output = "Pending";
      break;
    case "inReview":
      output = "In Review";
      break;
    case "processing":
      output = "Processing";
      break;
    case "hold":
      output = "On Hold";
      break;
    case "returned":
      output = "Returned";
      break;
    case "sentToEmbassy":
      output = "Sent to embassy";
      break;
    case "approved":
      output = "Approved";
      break;
    case "denied":
      output = "Denied";
      break;

    case "collect":
      output = "Ready to collect";
      break;
    default:
      output = "Pending";
      break;
  }
  return output;
};

export const GetActiveStatus = (status) => {
  let output = 0;
  switch (status) {
    case "pending":
      output = 0;
      break;
    case "inReview":
      output = 0;
      break;
    case "processing":
      output = 1;
      break;

    case "sentToEmbassy":
      output = 2;
      break;
    case "approved":
      output = 3;
      break;

    case "collect":
      output = 4;
      break;
    case "collected":
      output = 5;
      break;
    default:
      output = 0;
      break;
  }
  return output;
};

export const GetPayMethod = (status) => {
  let output = "NA";
  switch (status) {
    case "online":
      output = "Online";
      break;
    case "visa_center":
      output = "Visa center";
      break;
    default:
      output = "NA";
      break;
  }
  return output;
};
