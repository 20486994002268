import { Box, Typography, IconButton, Button } from "@mui/material";

import Navbar from "parts/navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const TermAndCondition = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <section className="TermAndCondition-page">
      <Navbar />
      <section className="bg-dark">
        <section className="wrapper container g-0">
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="white" sx={{ fontSize: 32 }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <Box
              width={mobileSize ? "100%" : 600}
              display="flex"
              alignItems="center"
              flexDirection="column"
              color="#fff"
            >
              <ReceiptLongIcon color="#fff" sx={{ fontSize: 64 }} />

              <Typography variant={mobileSize ? "h4" : "h3"} p={3} color="#fff">
                Term & Condition
              </Typography>

              <Typography variant="body2" textAlign="center" mt={2}>
                By using our website and the services we offer, you agree to be
                bound by these terms and conditions.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We reserve the right to modify these terms and conditions at any
                time without prior notice. The information and resources
                provided on our website are for general informational purposes
                only and should not be considered legal or professional advice.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not guarantee the accuracy, completeness, or timeliness of
                the information provided on our website.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We are not responsible for any errors or omissions on our
                website, and we will not be liable for any damages arising from
                the use of or reliance on the information provided on our
                website. We do not endorse any third-party websites or resources
                that may be linked to or from our website.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We do not guarantee that our website or the services we offer
                will be uninterrupted or error-free.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2}>
                We reserve the right to change or discontinue any aspect of our
                website or the services we offer at any time without prior
                notice.
              </Typography>
              <Typography variant="body2" textAlign="center" mt={2} mb={4}>
                Any disputes arising from the use of our website or the services
                we offer will be governed by the laws of the state of [state]
                and will be resolved in the courts of [state]. By using our
                website and the services we offer, you agree to indemnify and
                hold us harmless from any claims, losses, damages, or
                liabilities arising from your use of our website or the services
                we offer.
              </Typography>
              <Link to={"/"}>
                <Button variant="contained">Back to home</Button>
              </Link>
            </Box>
          </Box>
        </section>
      </section>
    </section>
  );
};

export default TermAndCondition;
