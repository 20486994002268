import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setToggleProgress } from "state/slice/progressBar";
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

//   const useDeepCompareMemoize = (value) => {
//     const ref = useRef();
//     if (!deepCompareEqualsForMaps(value, ref.current)) {
//       ref.current = value;
//     }

//     return ref.current;
//   };

//   const useDeepCompareEffectForMaps = (callback, dependencies) => {
//     useEffect(callback, dependencies.map(useDeepCompareMemoize));
//   };

export const useToggleLoading = () => {
  const Dispatch = useDispatch();
  const toggle = (status) => Dispatch(setToggleProgress(status));
  return toggle;
};
 
