import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";
import NotarizationImage from "assets/img/static/bg/notarization-image.png";
import { Link } from "react-router-dom";

const TranslationInfo = () => {
  const list = [
    {
      title: "Legal translation",
      description: `Legal translation services specialize in translating legal documents and contracts, ensuring that all necessary legal terms and requirements are accurately conveyed.`,
      link: "#",
    },
    {
      title: "Medical translation",
      description: `Medical translation services help translate important medical documents, such as patient records, clinical trial materials, and pharmaceutical labels, to ensure that they are easily understood by healthcare professionals and patients around the world.`,
      link: "#",
    },
    {
      title: "Technical translation",
      description: `Technical translation services specialize in translating technical documents, such as user manuals, product specifications, and technical reports, to ensure that technical information is accurately conveyed to a global audience.`,
      link: "#",
    },
    {
      title: "Marketing translation",
      description: `Marketing translation services help translate marketing materials, such as website content, brochures, and social media posts, to ensure that they are effectively conveyed to a global audience and that the message and tone of the content is accurately maintained.`,
      link: "#",
    },
  ];
  return (
    <>
      <Box className="container">
        <Box pt={5}>
          <Typography variant="body1">
            Translation services are a vital tool in today's globalized world,
            where businesses and individuals often need to communicate across
            language barriers. Translation services can help ensure that
            important documents, marketing materials, and other important
            content are accurately and effectively conveyed to a global
            audience.
          </Typography>
        </Box>
        <Box py={5}>
          <Grid container spacing={3}>
            {list?.map(({ title, description, link }, i) => (
              <Grid item key={i} xs={12} sm={6} lg={3}>
                <StandardCard
                  title={title}
                  description={description}
                  link={link}
                  linkText="Apply Now"
                  titleColor="primary"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default TranslationInfo;
