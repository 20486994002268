import { Box, Typography, IconButton, Card, CardContent } from "@mui/material";
import {
  APPLICATION_ANALYZE_DAY,
  APPLICATION_ANALYZE_MONTH,
  APPLICATION_ANALYZE_YEAR,
} from "config/endpoints";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import ApplicationChartFilter from "component/filter/applicationChartFilter";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
const ApplicationChart = () => {
  const toggleLoading = useToggleLoading();
  const [details, setDetails] = useState(null);
  const [filterButton, setFilterButton] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);

  const [doneApplication, setDoneApplication] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const [filter, setFilter] = useState({
    month: "",
    type: "",
    year: new Date(),
    years: {
      start: "",
      end: "",
    },
  });
  const [dataBY, setDataBy] = useState("month");
  useEffect(() => {
    if (dataBY !== "day") return;
    let fetchData = () => {
      const data = {
        method: filter.method,
        month: filter.month,
        type: filter.type,
      };
      toggleLoading(true);
      PostRequest(APPLICATION_ANALYZE_DAY, data)
        .then((res) => {
          if (res.data?.status) {
            const data = res.data?.data;
            const formatted = data.map((e) => ({
              Application: e.count,
              Day: e.day,
            }));
            setDoneApplication(res.data?.done);
            setRemaining(res.data?.remaining);
            setDetails(formatted);
          } else {
            // toast.error(res.data.msg);
            setDetails([]);
            setDoneApplication(0);
            setRemaining(0);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
          setDetails([]);
          setDoneApplication(0);
          setRemaining(0);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [filter, dataBY]);
  useEffect(() => {
    if (dataBY !== "month") return;
    let fetchData = () => {
      toggleLoading(true);
      const data = {
        method: filter.method,
        year: filter.year,
        type: filter.type,
      };
      PostRequest(APPLICATION_ANALYZE_MONTH, data)
        .then((res) => {
          if (res.data?.status) {
            const data = res.data?.data;
            const formatted = data.map((e) => ({
              Application: e.count,
              Month: moment()
                .month(e.month - 1)
                .format("MMM"),
            }));
            setDoneApplication(res.data?.done);
            setRemaining(res.data?.remaining);
            setDetails(formatted);
          } else {
            // toast.error(res.data.msg);
            setDetails([]);
            setDoneApplication(0);
            setRemaining(0);
          }
        })
        .catch((er) => {
          setDetails([]);
          setDoneApplication(0);
          setRemaining(0);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [filter, dataBY]);

  useEffect(() => {
    if (dataBY !== "year") return;
    let fetchData = () => {
      toggleLoading(true);
      const data = {
        method: filter.method,
        startYear: filter?.years?.start,
        endYear: filter?.years?.end,
        type: filter.type,
      };
      PostRequest(APPLICATION_ANALYZE_YEAR, data)
        .then((res) => {
          if (res.data?.status) {
            const data = res.data?.data;
            const formatted = data.map((e) => ({
              Application: e.count,
              Year: e.year,
            }));
            setDetails(formatted);
            setDoneApplication(res.data?.done);
            setRemaining(res.data?.remaining);
          } else {
            setRemaining(0);
            setDetails([]);
            setDoneApplication(0);
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
          setRemaining(0);
          setDetails([]);
          setDoneApplication(0);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [filter, dataBY]);
  const handleFilter = (filter) => {
    setFilter(filter);
    setDataBy(filter.dataBy);
    console.log("filter", filter);
  };
  const handleDefault = () => {
    setDataBy("month");
    setFilter({
      month: "",
      year: new Date(),
      years: {
        start: "",
        end: "",
      },
    });
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="h5"></Typography>
        <div className={isFiltered ? "filter-badge" : ""}>
          <IconButton onClick={(event) => setFilterButton(event.currentTarget)}>
            <FilterAltIcon />
          </IconButton>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
        mb={3}
        textAlign="center"
      >
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total applications</Typography>
            <Typography variant="h3">{doneApplication + remaining}</Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total remain</Typography>
            <Typography variant="h3">{remaining}</Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total done</Typography>
            <Typography variant="h3">{doneApplication}</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box width={1} height={500}>
        <Typography variant="" fontStyle="italic" pl={7} mb={1}>
          Applied applications data:
        </Typography>
        {dataBY === "day" ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart minWidth={600} height={300} data={details} width="100%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"Day"} padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip
                formatter={(v) => [`Total Application: ${v}`]}
                labelFormatter={(v) =>
                  v + " " + moment(filter.month).format("MMM")
                }
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="Application"
                stroke="#a0489e"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
        {dataBY === "month" ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart minWidth={600} height={300} data={details} width="100%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"Month"} padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip
                formatter={(v) => [`Total Application: ${v}`]}
                labelFormatter={(v) =>
                  v + " " + moment(filter.year).format("YYYY")
                }
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="Application"
                stroke="#a0489e"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
        {dataBY === "year" ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart minWidth={600} height={300} data={details} width="100%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"Year"} padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip formatter={(v) => [`Total Application: ${v}`]} />
              <Legend />
              <Line
                type="monotone"
                dataKey="Application"
                stroke="#a0489e"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
      </Box>

      <ApplicationChartFilter
        element={filterButton}
        onSubmit={handleFilter}
        onDefault={handleDefault}
        onClose={() => setFilterButton(null)}
        isFiltered={(value) => setIsFiltered(value)}
      />
    </Box>
  );
};

export default ApplicationChart;
