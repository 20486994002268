export class CreateUserModel {
  agencyName;
  phone;
  address;
  name;
  email;
  password;
  confirmPassword;
  role;
  countries;
  description;

  constructor(data = {}) {
    this.agencyName = data.agencyName || "";
    this.phone = data.phone || "";
    this.address = data.address || "";
    this.name = data.name || "";
    this.email = data.email || "";
    this.password = data.password || "";
    this.confirmPassword = data.confirmPassword || "";
    this.role = data.role || "basic";
    this.countries = data.countries || [];
    this.description = data.description || "";
  }
}
