import {
  Box,
  Button,
  Dialog,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DATEPicker from "component/other/datePicker";
import { toast } from "react-toastify";

const PaymentChartFilter = ({
  element,
  onClose = () => {},
  onSubmit = () => {},
  isFiltered = () => {},
  onDefault = () => {},
}) => {
  const [method, setMethod] = useState("");
  const [dataBy, setDataBy] = useState("month");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [month, setMonth] = useState();
  const [year, setYear] = useState(new Date());
  const [years, setYears] = useState({});
  const openFilter = Boolean(filterAnchorEl);
  useEffect(() => {
    setFilterAnchorEl(element || null);
  }, [element]);
  const handleClose = () => {
    setFilterAnchorEl(null);
    onClose();
  };
  const handleClearFilter = () => {
    setMethod("");
    setDataBy("");
    onDefault();
    handleClose();
    isFiltered(false);
    setMonth();
    setYear();
    setYears();
  };

  const handleSubmit = () => {
    if (dataBy === "day" && !month) {
      toast.error("Please select valid date field");
      return;
    }
    if (dataBy === "month" && !year) {
      toast.error("Please select valid date field");
      return;
    }
    if (dataBy === "year" && (!years.start || !years.end)) {
      console.log(years);
      toast.error("Please select valid date field");
      return;
    }

    isFiltered(Boolean(dataBy) || Boolean(method));
    handleClose();
    onSubmit({
      method,
      year: moment(year).add(150, "minutes").toDate(),
      years: {
        start: moment(years?.start).add(150, "minutes").toDate() || "",
        end: moment(years?.end).add(150, "minutes").toDate() || "",
      },
      month: moment(month).add(150, "minutes").toDate(),
      dataBy,
    });
  };

  const handleYear = (date, isEnd) => {
    const alreadyStart = years?.start;
    const alreadyEnd = years?.end;
    if (isEnd) {
      if (moment(date).isBefore(alreadyStart)) return;
      else {
        setYears({ ...years, end: date });
      }
    } else {
      if (moment(date).isAfter(alreadyEnd)) return;
      else {
        setYears({ ...years, start: date });
      }
    }
  };
  return (
    <Dialog
      open={openFilter}
      onClose={handleClose}
      aria-labelledby="filter-dlg"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={"sm"}
      sx={{ minHeight: 400 }}
    >
      <DialogTitle id="filter-dlg">
        <Box display="flex" justifyContent="space-between">
          Filter
          <Box>
            <Button onClick={handleClearFilter}>Clear</Button>
            <Button onClick={handleSubmit} sx={{ ml: 2 }} variant="contained">
              Ok
            </Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 300 }}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="filter-dlg-select">Data By</InputLabel>
              <Select
                labelId="filter-dlg-select"
                label="Data By"
                value={dataBy}
                onChange={(ev) => setDataBy(ev.target.value)}
              >
                <MenuItem value={"day"}>Day</MenuItem>
                <MenuItem value={"month"}>Month</MenuItem>
                <MenuItem value={"year"}>Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="filter-dlg-select">Method</InputLabel>
              <Select
                labelId="filter-dlg-select"
                label="Status"
                value={method}
                onChange={(ev) => setMethod(ev.target.value)}
              >
                <MenuItem value={""}>Unselect</MenuItem>
                <MenuItem value={"online"}>Online</MenuItem>
                <MenuItem value={"visa_center"}>Visa center</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {dataBy === "day" ? (
            <Grid item xs={12} md={6}>
              <DATEPicker
                selected={month}
                onChange={(date) => setMonth(date)}
                label="Select month"
                textProps={{ fullWidth: true }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </Grid>
          ) : null}
          {dataBy === "month" ? (
            <>
              <Grid item xs={12} md={6}>
                <DATEPicker
                  selected={year}
                  onChange={(date) => setYear(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  label="Select year"
                  textProps={{ fullWidth: true }}
                />
              </Grid>
            </>
          ) : null}

          {dataBy === "year" ? (
            <>
              <Grid item xs={12} md={6}>
                <DATEPicker
                  selected={years?.start}
                  onChange={(date) => handleYear(date, false)}
                  label="Start Year"
                  textProps={{ fullWidth: true }}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DATEPicker
                  selected={years?.end}
                  onChange={(date) => handleYear(date, true)}
                  label="End Year"
                  textProps={{ fullWidth: true }}
                  showYearPicker
                  dateFormat="yyyy"
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
PaymentChartFilter.propTypes = {
  element: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  isFiltered: PropTypes.func,
};
export default PaymentChartFilter;
