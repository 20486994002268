import { useCallback } from "react";
import { formatBytes } from "functions/helper";
export default function useFile() {
  const fileValidation = useCallback(
    ({ fileTypes, minSize, maxSize, file }) => {
      let error = "";

      let size = file?.size;
      if (minSize && minSize > size) {
        const k = formatBytes(minSize);

        error = `File size must be minimum ${k}`;
      }
      if (maxSize && maxSize < size) {
        const k = formatBytes(maxSize);
        error = `File size must be maximum ${k}`;
      }
      if (!fileTypes?.includes(file?.type?.split("/")[1])) {
        error = `File type is not supported.Please select one of ${fileTypes?.join(
          ", "
        )} file.`;
      }
      if (!file) {
        error = "File is required";
      }
      return {
        isError: Boolean(error),
        errorMsg: error,
      };
    },
    []
  );
  return fileValidation;
}
