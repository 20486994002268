import { Box, IconButton, Typography } from "@mui/material";
import TransparentNavbar from "parts/navbar/transparentNavbar";
import { Link, useNavigate } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

import RouteConstant from "navigation/constant";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

const Requirement = () => {
  const navigate = useNavigate();

  return (
    <section className="requirement-page">
      <div className=" banner-header requirement-bg">
        <TransparentNavbar />
        <div className="banner-header-overlay"></div>
        <div className="banner-header-container container">
          <div className="banner-header-content ">
            <IconButton onClick={() => navigate(-1)}>
              <WestIcon sx={{ fontSize: 38, color: "#fff" }} />
            </IconButton>
            <h3 className="bannerHeading">Requirement</h3>
            <p>
              Get hands on all the resource that help for your successfully trip
            </p>
          </div>
        </div>
      </div>
      <Box className="bg-dark" py={10}>
        <div className="container">
          <Typography variant="body1" color="white.main" mb={2}>
            As part of the process for applying for a data analytics visa, you
            may be required to provide certain documentation to support your
            application. Here is a list of some common documents that may be
            required:
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Passport: You will need to provide a copy of your passport,
            including the biographical information page and any applicable visas
            or entry stamps.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Educational qualifications: You may need to provide transcripts,
            diplomas, or other proof of your educational qualifications.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Employment history: You may be asked to provide proof of your
            employment history, such as letters of recommendation from previous
            employers or a resume.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Financial documents: You may need to provide evidence of your
            financial situation, such as bank statements or proof of income.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Proof of residence: You may need to provide proof of your current
            residence, such as a utility bill or rental agreement.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Medical examination results: Some countries may require you to
            undergo a medical examination as part of the visa process. If this
            is the case, you will need to provide the results of the
            examination.
          </Typography>
          <Typography variant="body1" color="white.main" mb={2}>
            Police clearance certificate: You may be required to obtain a police
            clearance certificate from your home country or any countries where
            you have lived for an extended period of time. It is important to
            carefully review the specific requirements for the data analytics
            visa you are applying for, as the required documentation may vary
            depending on the country and specific visa category. The
            Express Visa Facilitation Services Centre can provide further guidance on the
            documentation required for your specific situation.
          </Typography>
          <Box my={4}>
            <Typography variant="caption" color="white.main">
              Quick Links
            </Typography>
            <Box display="flex" mt={2}>
              <Typography color="white.main" mr={3}>
                <Link to={RouteConstant.APPLY_VISA} className="bright-link">
                  Apply For Visa <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
              <Typography color="white.main" mr={3}>
                <Link to={RouteConstant.FAQ} className="bright-link">
                  FAQ <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
              <Typography color="white.main">
                <Link to={RouteConstant.CONTACT_US} className="bright-link">
                  Contact Us <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Link>
              </Typography>
            </Box>
          </Box>
        </div>
      </Box>
    </section>
  );
};

export default Requirement;
