import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";
import { Link } from "react-router-dom";
import SaudiImage from "assets/img/static/bg/saudi.png";

const SaudiArabiaInfo = () => {
  const list = [
    {
      title: "Haji visas",
      description:
        "The haji visa is for individuals who are making the pilgrimage during the annual hajj season.",
      link: "#",
    },
    {
      title: "Umrah visas",
      description:
        "The umrah visa is for individuals who are making the pilgrimage at other times of the year.",
      link: "#",
    },
    {
      title: "Business visas",
      description:
        "Business visas are available for individuals who need to travel to Saudi Arabia for business purposes, such as attending meetings or conferences. Business visas may be valid for a single entry or multiple entries, and can be valid for up to one year.",
      link: "#",
    },
    {
      title: "Work visas",
      description:
        "Work visas are required for individuals who will be working in Saudi Arabia, either as employees or as self-employed individuals. Work visas are typically sponsored by the employer and are valid for the duration of the individual's employment.",
      link: "#",
    },
    {
      title: "Student visas",
      description:
        "Student visas are available for individuals who will be studying in Saudi Arabia. Student visas are typically sponsored by the educational institution and are valid for the duration of the individual's studies.",
      link: "#",
    },
    {
      title: "Family visa",
      description: `Saudi Arabia has a unique system for family visas, which is designed to allow foreign nationals to bring their family members with them to the country. The family visa is often referred to as the "dependent visa" because it allows the holder to reside in Saudi Arabia .`,
      link: "#",
    },
    {
      title: "Tourist  visa",
      description: `we provide comprehensive assistance and support for obtaining your Saudi tourist visa. Our team of experts will guide you through the application process and ensure that your application is submitted accurately and on time. Contact us today to learn more about how we can assist you in obtaining your Saudi tourist visa.`,
      link: "#",
    },
  ];
  return (
    <Box className="container">
      <Box maxWidth={1100} margin="auto" py={5}>
        <Grid container spacing={3}>
          {list?.map(({ title, description, link }, i) => (
            <Grid item key={i} xs={12} sm={6} md={i === 0 || i === 1 ? 6 : 4}>
              <StandardCard
                title={title}
                description={description}
                link={link}
                linkText="Apply Now"
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              className="standard-card hovered d-flex flex-column justify-content-center"
              p={5}
              sx={{
                backgroundImage: `url(${SaudiImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Typography variant="h6" fontWeight={700} className="title">
                Saudi Tourist Visa <br /> Made Easy.
                <br /> Contact Us
                <br /> Today.
              </Typography>

              <Typography variant="body2" mt={3}>
                <Link to={""}>Apply</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SaudiArabiaInfo;
