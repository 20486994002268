import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Dialog,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
  Chip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  GET_BULK_APPLICATION_BY_ID,
  GET_ALL_APPLICATION_BY_BULK,
  BULK_APPLICATION_DELETE,
} from "config/endpoints";
import NoResult from "parts/noResult";
import moment from "moment";
import ApplicationTable from "component/grid/table/applicationTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector } from "react-redux";
import RouteConstant from "navigation/constant";
import { GetPayMethod } from "functions/outputFunctions";

const BulkAllApplication = () => {
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const userRole = useSelector((state) => state.auth.user.role);
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const [details, setDetails] = useState(null);
  const [filter, setFilter] = useState({
    status: "",
    fromDate: null,
    toDate: null,
  });

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const openFilter = Boolean(filterAnchorEl);
  const handleOpenFilter = (event) => {
    // setFilterAnchorEl(event.currentTarget);
    console.log(event);
    console.log(event.currentTarget);
  };
  const handleClose = () => {
    setFilterAnchorEl(null);
  };

  useEffect(() => {
    let fetchData = () => {
      if (!id) {
        return;
      }
      toggleLoading(true);
      GetRequest(GET_BULK_APPLICATION_BY_ID(id))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res.data.data);
          } else {
            // toast.error(res.data.msg);
            setDetails(null);
          }
        })
        .catch((er) => {
          setDetails(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);
  const handleClearFilter = () => {
    setFilter({ status: "", fromDate: null, toDate: null });
    handleClose();
  };
  if (userRole === "basic") {
    navigate(RouteConstant.HOME);
    return null;
  }
  const handleDelete = () => {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
      toggleLoading(true);
      PostRequest(BULK_APPLICATION_DELETE(id))
        .then((res) => {
          if (res.data?.status) {
            toast.success("Application deleted.");
            navigate(-1);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          setDetails(null);
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    }
  };
  return (
    <section>
      <Navbar />
      <section className="wrapper container g-0 content-height">
        <IconButton sx={{ mb: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>

        <Box mt={3}>
          {details ? (
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">Bulk Application Details</Typography>
                {!details.submitted ? (
                  <Box>
                    <Link
                      to={RouteConstant.APPLY_VISA}
                      className="dark-link me-2"
                    >
                      <Button>Edit</Button>
                    </Link>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Box>
                ) : null}
                {!details.isPaid ? (
                  <Link
                    to={
                      RouteConstant.VISA_PAYMENT +
                      "/" +
                      details._id +
                      "?bulk=true"
                    }
                    className="dark-link me-2"
                  >
                    <Button variant="contained">Pay</Button>
                  </Link>
                ) : null}
              </Box>
              <Box
                sx={{
                  p: 2,
                  bgcolor: "grey.200",
                  borderRadius: 3,
                  my: 4,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Agency</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.agency?.agencyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Bulk ID</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.bulkId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">
                      Total Application
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.applications?.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Date</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {moment(details.updatedAt).format("D MMM Y")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Submission</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.submitted ? "Submitted" : "Not Submitted"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Confirmation</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.confirmed ? "Confirmed" : "Not Confirmed"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Fees</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.fees}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Tax</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.tax}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Discount</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Total Charges</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details?.total}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Payment Method</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {GetPayMethod(details?.paymentMethod)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="subtitle2">Payment Status</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {details.isPaid ? (
                        <Chip label="Paid" color="success" />
                      ) : (
                        <Chip label="Not Paid" color="error" />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box my={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                  }}
                >
                  <Typography variant="h5"> All application</Typography>
                  <IconButton onClick={handleOpenFilter}>
                    <FilterAltIcon />
                  </IconButton>
                </Box>
                <ApplicationTable
                  URL={
                    GET_ALL_APPLICATION_BY_BULK(details._id) +
                    `?status=${filter.status}&fromDate=${filter.fromDate}&toDate=${filter.toDate}`
                  }
                />
              </Box>
            </Box>
          ) : (
            <Box className="p-center" my={3}>
              <NoResult />
            </Box>
          )}
        </Box>
      </section>
      <Dialog
        open={openFilter}
        onClose={handleClose}
        aria-labelledby="filter-dlg"
        aria-describedby="alert-dialog-description"
        fullWidth={mobileSize}
        sx={{ Width: 800 }}
      >
        <DialogTitle id="filter-dlg">
          <Box display="flex" justifyContent="space-between">
            Filter
            <Button onClick={handleClearFilter}>Clear</Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MenuItem onClick={handleClose}>
            <FormControl fullWidth>
              <InputLabel id="filter-dlg-select">Status</InputLabel>
              <Select
                labelId="filter-dlg-select"
                label="Status"
                value={filter.status}
                onChange={(ev) =>
                  setFilter({ ...filter, status: ev.target.value })
                }
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"inReview"}>In Review</MenuItem>
                <MenuItem value={"processing"}>Processing</MenuItem>
                <MenuItem value={"hold"}>Hold</MenuItem>
                <MenuItem value={"returned"}>Returned</MenuItem>
                <MenuItem value={"approved"}>Approved</MenuItem>
                <MenuItem value={"denied"}>Denied</MenuItem>
                <MenuItem value={"sentToEmbassy"}>Sent To Embassy</MenuItem>
                <MenuItem value={"collect"}>Collect</MenuItem>
              </Select>
            </FormControl>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="Date from"
                inputFormat="dd/MM/yyyy"
                value={filter.fromDate}
                onChange={(value) => setFilter({ ...filter, fromDate: value })}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                label="Date to"
                inputFormat="dd/MM/yyyy"
                onChange={(value) => setFilter({ ...filter, toDate: value })}
                value={filter.toDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </MenuItem>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default BulkAllApplication;
