import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { Box } from "@mui/material";
import AgencyBulkTableColumnsAdmin from "../columns/agencyColumnAdmin";
import { GetPayMethod } from "functions/outputFunctions";
const AdminAllBulk = ({ width, height, URL, ...props }) => {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);
  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    URL
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        agencyName: el?.agency_details?.agencyName,
        bulkId: el?.bulkId,
        totalApplied: el.totalApplied,
        isPaid: el.isPaid,
        total: el?.total,
        date: el.createdAt,
        paymentMethod: GetPayMethod(el?.paymentMethod),
        detail: el?._id,
      };
    });
    setRows(value);
  }, [data]);

  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={AgencyBulkTableColumnsAdmin}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        disableColumnSelector
        components={{ Toolbar: GridToolbar }}
        disableDensitySelector
      />
    </Box>
  );
};

export default AdminAllBulk;
