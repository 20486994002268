import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { TextField } from "@mui/material";
const DATEPicker = ({ fullInputWidth, label, textProps, ...props }) => {
  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      inputRef={ref}
      value={value}
      label={label}
      fullWidth={fullInputWidth}
      {...textProps}
    />
  ));
  return (
    <DatePicker
      {...props}
      customInput={<DateInput />}
      calendarClassName="custom-date-picker"
    />
  );
};

export default DATEPicker;
