import TouristVisaPic from "assets/img/static/item/touristVisa.png";
import EnjazService from "assets/img/static/item/enjazService.png";
import FamilyVisa from "assets/img/static/item/familyVisa.png";
import StudentVisa from "assets/img/static/item/studentVisa.png";
import WorkVisa from "assets/img/static/item/workVisa.png";
import BusinessVisa from "assets/img/static/item/businessVisa.png";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import RouteConstant from "navigation/constant";
const EnjazVisaType = () => {
  return (
    <div className="enjaz-visa-type bg-dark py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-12 col-md-6">
            <div className="resImg">
              <img src={TouristVisaPic} alt="TouristVisaPic" />
            </div>
          </div>
          <div className="col-12 col-md-6 p-center">
            <div>
              <Typography variant="h5" mb={1} color="white.main">
                Tourist visa:
              </Typography>
              <Typography color="grey.700" mb={2}>
                Tourist visas are available for individuals who wish to visit
                Saudi Arabia for leisure purposes, such as sightseeing or
                visiting friends or family. Tourist visas are typically valid
                for a period of up to three months.
              </Typography>
            </div>
          </div>

          <div className="col-12 col-md-6 p-center order-2 order-md-1">
            <div>
              <Typography variant="h5" mb={1} color="white.main">
                Business visa:
              </Typography>
              <Typography color="grey.700" mb={2}>
                Business visas are available for individuals who need to travel
                to Saudi Arabia for business purposes, such as attending
                meetings or conferences. Business visas may be valid for a
                single entry or multiple entries, and can be valid for up to one
                year.
              </Typography>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <div className="resImg">
              <img src={BusinessVisa} alt="BusinessVisa" />
            </div>
          </div>
        </div>
        <div className="row g-5 mt-3">
          <div className="col-12 col-md-6">
            <div className="resImg">
              <img src={WorkVisa} alt="WorkVisa" />
            </div>
          </div>
          <div className="col-12 col-md-6 p-center">
            <div>
              <Typography variant="h5" mb={1} color="white.main">
                Work visa:
              </Typography>
              <Typography color="grey.700" mb={2}>
                Work visas are required for individuals who will be working in
                Saudi Arabia, either as employees or as self-employed
                individuals. Work visas are typically sponsored by the employer
                and are valid for the duration of the individual's employment.
              </Typography>
            </div>
          </div>

          <div className="col-12 col-md-6 p-center order-2 order-md-1">
            <div>
              <Typography variant="h5" mb={1} color="white.main">
                Student visa:
              </Typography>
              <Typography color="grey.700" mb={2}>
                Student visas are available for individuals who will be studying
                in Saudi Arabia. Student visas are typically sponsored by the
                educational institution and are valid for the duration of the
                individual's studies.
              </Typography>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <div className="resImg">
              <img src={StudentVisa} alt="StudentVisa" />
            </div>
          </div>
        </div>
        <div className="row g-5 mt-3">
          <div className="col-12 col-md-6">
            <div className="resImg">
              <img src={EnjazService} alt="TouristVisaPic" />
            </div>
          </div>
          <div className="col-12 col-md-6 p-center">
            <div>
              <Typography variant="h5" mb={1} color="white.main">
                Enjaz services:
              </Typography>
              <Typography color="grey.700" mb={2}>
                Enjaz is a service provided by the Saudi Ministry of Foreign
                Affairs that allows individuals to submit their visa
                applications online, track the status of their applications, and
                receive updates about their applications via email or SMS. By
                using Enjaz services at Express Data Analytics, you can save
                time and avoid the hassle of submitting your visa application in
                person.
              </Typography>
            </div>
          </div>

          <div className="col-12 col-md-6 p-center order-2 order-md-1">
            <div>
              <Typography variant="h5" mb={1} color="white.main">
                Family visa:
              </Typography>
              <Typography color="grey.700" mb={2}>
                Saudi Arabia has a unique system for family visas, which is
                designed to allow foreign nationals to bring their family
                members with them to the country. The family visa is often
                referred to as the "dependent visa" because it allows the holder
                to reside in Saudi Arabia as a dependent of the sponsor, who is
                typically a foreign national working in the country.
              </Typography>
              <Link to={RouteConstant.ENJAZ_FAMILY_VISA_SERVICE}>
                <Typography variant="subtitle1" color="grey.700">
                  Learn More &nbsp;
                  <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
                </Typography>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2">
            <div className="resImg">
              <img src={FamilyVisa} alt="FamilyVisa" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnjazVisaType;
