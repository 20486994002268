import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { Box, Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  placeholder,
  value,
  onChange,
  error,
  onFocus = () => {},
  ...props
}) => {
  const handleChange = (html) => {
    onChange(html);
  };

  return (
    <Box {...props}>
      <ReactQuill
        theme={"snow"}
        onChange={handleChange}
        value={value}
        modules={Editor.modules}
        formats={Editor.formats}
        // bounds={".app"}
        placeholder={placeholder}
        onFocus={onFocus}
      />
      {error !== "" ? (
        <Typography variant="caption" color="error.main" pl={1}>
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};

let Editor = {};
Editor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4] }],
    [{ size: ["normal"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ background: [] }, { color: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "background",
  "color",
];

Editor.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  onFocus: PropTypes.func,
};
export default TextEditor;
