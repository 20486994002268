import { Box, Button } from "@mui/material";
import TransparentNavbar from "parts/navbar/transparentNavbar";
import RouteConstant from "navigation/constant";
import { Link } from "react-router-dom";
const EnjazHeader = () => {
  return (
    <div className=" enjaz-header">
      <TransparentNavbar />
      <div className="enjaz-header-overlay"></div>
      <div className="enjaz-header-container container">
        <div className="enjaz-header-content ">
          <h5>Welcome to</h5>
          <h3 className="enjazHeading">Saudi Arabia</h3>
          <p>
            Saudi Arabia, officially the Kingdom of Saudi Arabia is a country in
            Western Asia. It covers the bulk of the Arabian Peninsula, and has a
            land area of about 2,150,000 km2 (830,000 sq mi), making it the
            fifth-largest country in Asia, the second-largest in the Arab world,
            and the largest in Western Asia and the Middle East.
          </p>
          <Box display="flex" mt={4} alignItems="center">
            <Link to={RouteConstant.APPLY_VISA}>
              <Button variant="contained" sx={{ mr: 3 }}>
                Apply for visa
              </Button>
            </Link>
            <Link to={RouteConstant.TRACK_APPLICATION}>
              <Button color="white">Track My Visa</Button>
            </Link>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default EnjazHeader;
