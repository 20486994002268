import { useState, useEffect, useMemo } from "react";

import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisApplyStepper from "../visaFormFill/component/stepper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { PostRequest, GetRequest } from "functions/http";
import { useToggleLoading } from "hooks";
import {
  GET_VISA_APP_ID,
  GET_VISA_PAYMENT_DETAIL,
  PAY_IN_VISA_CENTER,
  BULK_PAYMENT_DETAILS,
  GET_BULK_APPLICATION_BY_ID,
  PAYMENT_CHECKOUT,
  BULK_PAY_IN_CENTER,
} from "config/endpoints";
import { useNavigate } from "react-router-dom";
import RouteConstant from "navigation/constant";
import { useParams, useSearchParams } from "react-router-dom";
import NoResult from "parts/noResult";
const VisaPayment = () => {
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const { id } = useParams();
  const [application, setApplication] = useState(null);
  const [payment, setPayment] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [currency, setCurrency] = useState("UGX");
  const [search] = useSearchParams();
  const isBulkApp = useMemo(() => Boolean(search.get("bulk")), [search]);

  useEffect(() => {
    if (!id) navigate(RouteConstant.APPLY_VISA);
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(
        isBulkApp ? GET_BULK_APPLICATION_BY_ID(id) : GET_VISA_APP_ID(id)
      )
        .then((res) => {
          if (res?.data?.status) {
            setApplication(res.data?.data);
          } else {
            // toast.error(res.data.msg);
            setApplication(null);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er));
          setApplication(null);
        })
        .finally(() => toggleLoading(false));
      toggleLoading(true);
      GetRequest(
        isBulkApp ? BULK_PAYMENT_DETAILS(id) : GET_VISA_PAYMENT_DETAIL(id)
      )
        .then((res) => {
          if (res?.data?.status) {
            if (res.data?.dat?.isPaid) {
              // navigate()
            } else {
              setPayment(res.data?.data);
            }
          } else {
            // toast.error(res.data.msg);
            setPayment(null);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er));
          setPayment(null);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id, isBulkApp]);
  const handleSubmit = () => {
    if (!paymentMethod) {
      toast.error("Please select payment method.");
      return;
    }

    if (paymentMethod === "online") {
      toggleLoading(true);
      const data = {
        paymentId: payment._id,
        currency: currency,
      };
      PostRequest(PAYMENT_CHECKOUT, data)
        .then((res) => {
          if (res?.data?.status) {
            window.location.href = res?.data?.data;
            // navigate(res?.data?.data, { replace: true });
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er));
        });
      // .finally(() => toggleLoading(false));
    } else {
      // if (paymentMethod === "visa_center") {
      toggleLoading(true);
      PostRequest(isBulkApp ? BULK_PAY_IN_CENTER(id) : PAY_IN_VISA_CENTER(id))
        .then((res) => {
          if (res?.data?.status) {
            navigate(
              RouteConstant.VISA_CONFIRMED +
                `?status=success&tx_ref=${payment.paymentId}`
            );
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er));
        })
        .finally(() => toggleLoading(false));
    }
  };

  if (payment?.isPaid) {
    navigate(RouteConstant.VISA_CONFIRMED + "?tx_ref=" + payment.paymentId);
    return null;
  }
  return (
    <section>
      <Navbar />
      <section className="wrapper container g-0">
        <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h5" className="fm-pt-mono" mb={1}>
          Apply for a visa
        </Typography>
        <Typography color="grey.500">
          Find the information and begin your application
        </Typography>
        <Box>
          <VisApplyStepper step={2} />
        </Box>
        <Box>
          <Box mt={6}>
            {application != null && payment != null ? (
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="grey.600" mb={2}>
                    BOOKING INFO
                  </Typography>
                  <Box>
                    {isBulkApp ? (
                      <Box display="flex" flexWrap="wrap">
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">
                            Bulk Application ID{" "}
                          </span>
                          <br />
                          {application.bulkId}
                        </Typography>
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">
                            {" "}
                            Total Application{" "}
                          </span>
                          <br />
                          {application.totalApplied}
                        </Typography>

                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">Service Type</span>
                          <br />
                          Applied for visa
                        </Typography>
                      </Box>
                    ) : (
                      <Box display="flex" flexWrap="wrap">
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">Booking ID </span>
                          <br />
                          {application.applicationId}
                        </Typography>
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">Name </span>
                          <br />
                          {application.name}
                        </Typography>
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">Email </span>
                          <br />
                          {application.email}
                        </Typography>
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">Passport Type</span>
                          <br />
                          {application.passportType}
                        </Typography>
                        <Typography variant="body1" mr={5} mb={3}>
                          <span className="fw-bold mb-2">Service Type</span>
                          <br />
                          Applied for visa
                        </Typography>
                      </Box>
                    )}

                    <Box>
                      <Typography variant="h6" color="grey.600" mb={2} mt={6}>
                        PAYMENT INFO
                      </Typography>
                      <Box
                        sx={{
                          width: "min(100%, 400px)",
                          mt: 4,
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #cecece",
                          mb: 2,
                        }}
                      >
                        <Box>
                          <Typography fontWeight="bold" variant="h6">
                            Fee
                          </Typography>
                          <Typography color="grey.600">
                            Convenience Fee
                          </Typography>
                        </Box>
                        <Typography>{payment?.fees}</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "min(100%, 400px)",
                          mt: 4,
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #cecece",
                          mb: 2,
                        }}
                      >
                        <Box>
                          <Typography fontWeight="bold" variant="h6">
                            TAX
                          </Typography>
                        </Box>
                        <Typography>{payment?.tax}</Typography>
                      </Box>
                      {payment?.discount > 0 ? (
                        <Box
                          sx={{
                            width: "min(100%, 400px)",
                            mt: 4,
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #cecece",
                            mb: 2,
                          }}
                        >
                          <Box>
                            <Typography fontWeight="bold" variant="h6">
                              DISCOUNT
                            </Typography>
                          </Box>
                          <Typography>{payment?.discount}</Typography>
                        </Box>
                      ) : null}
                      <Box
                        sx={{
                          width: "min(100%, 400px)",
                          mt: 4,
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #cecece",
                          mb: 2,
                        }}
                      >
                        <Box>
                          <Typography fontWeight="bold" variant="h6">
                            Total
                          </Typography>
                        </Box>
                        <Typography>{payment?.amount}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="grey.600" mb={2}>
                    PAYMENT METHOD
                  </Typography>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    my={3}
                    alignItems="center"
                  >
                    <Typography variant="overline" mr={2} fontWeight={600}>
                      Choose currency:
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currency === "UGX"}
                          onChange={() => setCurrency("UGX")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="UGX"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currency === "USD"}
                          onChange={() => setCurrency("USD")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="USD"
                    />
                  </Box>
                  <Card
                    sx={{
                      mb: 3,
                      cursor: "pointer",
                      bgcolor:
                        paymentMethod === "online" ? "primary.main" : "white",
                    }}
                    onClick={() => setPaymentMethod("online")}
                  >
                    <CardContent>
                      <Box display="flex">
                        <CreditCardIcon />
                        <Typography variant="button" ml={3} fontWeight={600}>
                          Online Payment
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      mb: 3,
                      cursor: "pointer",
                      bgcolor:
                        paymentMethod === "visa_center"
                          ? "primary.main"
                          : "white",
                    }}
                    onClick={() => setPaymentMethod("visa_center")}
                  >
                    <CardContent>
                      <Box display="flex">
                        <LocationOnIcon />
                        <Typography variant="button" ml={3} fontWeight={600}>
                          Pay AT Visa Center
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>

                  <Box mt={4} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <div className="p-center">
                <NoResult my={3} />
              </div>
            )}
          </Box>
        </Box>
      </section>
    </section>
  );
};
export default VisaPayment;
