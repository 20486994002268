import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import SlotByDateColumn from "../columns/slotByDateColumn";
import usePostQuery from "hooks/usePostQuery";
const SlotByDateTable = ({ width, height, URL, bodyData, ...props }) => {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);

  const { isLoading, data, rowCount } = usePostQuery(
    rowsState.page,
    rowsState.pageSize,
    `${URL}`,
    bodyData
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        date: el?.date,
        from: el?.from,
        to: el?.to,
        allowedApplication: el?.allowedApplication,
        applied: {
          exceed: el?.allowedApplication === el?.applied,
          applied: el?.applied,
        },
        detail: el?._id,
      };
    });
    setRows(value);
  }, [data]);

  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={SlotByDateColumn}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        components={{ Toolbar: GridToolbar }}
        disableColumnSelector
        disableDensitySelector
      />
    </Box>
  );
};

export default SlotByDateTable;
