import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import NoResult from "parts/noResult";
import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import {
  PAYMENT_DETAILS,
  PAYMENT_MANUALLY_PAID,
  PAYMENT_MANUALLY_UNPAID,
} from "config/endpoints";
import moment from "moment";

import RouteConstant from "navigation/constant";
const PaymentDetails = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const { paymentId } = useParams();
  const [details, setDetails] = useState(null);
  const [application, setApplication] = useState(null);
  const [status, setStatus] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [reason, setReason] = useState("");
  const [openPaid, setOpenPaid] = useState(false);
  const [openNotPaid, setOpenNotPaid] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);

  useEffect(() => {
    if (!paymentId) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(PAYMENT_DETAILS(paymentId))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res?.data?.data);
            setApplication(res?.data?.application);
          } else {
            // toast.error(res.data.msg);
            setDetails(null);
            setApplication(null);
          }
        })
        .catch((er) => {
          setDetails(null);
          setApplication(null);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [paymentId, refresh]);
  const handleManualPaid = (unpaid) => {
    const url = unpaid
      ? PAYMENT_MANUALLY_UNPAID(details._id)
      : PAYMENT_MANUALLY_PAID(details._id);
    setOpenNotPaid(false);
    setOpenPaid(false);
    toggleLoading(true);

    PostRequest(url)
      .then((res) => {
        if (res.data?.status) {
          setRefresh(!refresh);
          toast.success("Payment status updated.");
        } else {
          toast.error(res.data.msg || "Something went wrong.");
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };

  return (
    <section className="application-details-page">
      <Navbar />
      <section className="wrapper container g-0 content-height-2">
        <Box mb={4}>
          <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography variant="overline" ml={2} fontSize={18}>
            Payment Details
          </Typography>
        </Box>
        {details != null ? (
          <Box>
            <Box>
              <Box
                sx={{
                  bgcolor: "grey.200",
                  borderRadius: 4,
                  p: 3,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> User Name </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details?.userDetails.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Fees </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      ${details.fees}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Tax </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      ${details.tax}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Discount </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      ${details.discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Total charges </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      ${details.amount}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Status </Typography>
                    <Box>
                      {details.isPaid ? (
                        <Chip label="Paid" color="success" />
                      ) : details.isRefunded ? (
                        <Chip label="Refunded" color="warning" />
                      ) : (
                        <Chip label="Not Paid" color="error" />
                      )}
                    </Box>
                  </Grid>
                  {details.paid_time ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Paid Time </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {moment(details.paid_time).format("DD-MMM-YY HH:mm:ss")}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Payment Id </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.paymentId}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Payment Method </Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      <Box>
                        {details.paymentMethod === "visa_center" ? (
                          <Chip label="Visa center" />
                        ) : (
                          <Chip label="Online" color="info" />
                        )}
                      </Box>
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Application Type</Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.isBulk ? "Business Application" : "Individual"}
                    </Typography>
                  </Grid>
                  {details.isBulk ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Agency Name</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {application.agency.agencyName}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Application</Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.isBulk ? (
                        <Link
                          to={
                            RouteConstant.BULK_ALL_APPLICATION +
                            `/${application._id}`
                          }
                        >
                          Application Details
                        </Link>
                      ) : (
                        <Link
                          to={
                            RouteConstant.ADMIN_APPLICATION_DETAILS +
                            `/${application._id}`
                          }
                        >
                          Application Details
                        </Link>
                      )}
                    </Typography>
                  </Grid>
                  {!details.isBulk ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> From Country</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {application?.fromCountry?.label}
                      </Typography>
                    </Grid>
                  ) : null}
                  {!details.isBulk ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> To Country</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {application?.toCountry?.label}
                      </Typography>
                    </Grid>
                  ) : null}
                  {!details.isBulk ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Application Id</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {application?.applicationId}
                      </Typography>
                    </Grid>
                  ) : null}

                  {details.isBulk ? (
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">
                        Bulk Application Id
                      </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {application?.bulkId}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Applied Date</Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {moment(details.date).format("D MMM Y")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {details.transactionDetails ? (
              <Box>
                <Typography variant="subtitle1" mt={4} fontSize={18} ml={2}>
                  Transaction Details
                </Typography>
                <Typography variant="caption" mt={0} ml={2}>
                  This information provided by Flutter wave
                </Typography>
                <Box
                  sx={{
                    bgcolor: "grey.200",
                    borderRadius: 4,
                    p: 3,
                    mt: 3,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Account id </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.account_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Amount</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.amount}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Currency </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.currency}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Flutter Id </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.flw_ref}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Id </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Payment Type </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.payment_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Date </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {moment(details?.transactionDetails?.created_at).format(
                          "DD-MMM-YY HH:mm:ss"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Customer Name </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.customer?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Customer Email </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.customer?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2"> Customer Phone </Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {details?.transactionDetails?.customer?.phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ) : null}
            <Box display="flex" flexWrap="wrap" my={4} justifyContent="center">
              <Button
                sx={{ ml: 3, mt: 3 }}
                variant="contained"
                onClick={() => setOpenPaid(true)}
                color="success"
              >
                Change Status to paid
              </Button>

              <Button
                sx={{ ml: 3, mt: 3 }}
                variant="contained"
                onClick={() => setOpenNotPaid(true)}
                color="error"
              >
                Change Status to not paid
              </Button>
              {details.isPaid && details.paymentMethod === "online" ? (
                <Button
                  sx={{ ml: 3, mt: 3 }}
                  variant="contained"
                  // onClick={handleSubmit}
                  color="black"
                >
                  Refund
                </Button>
              ) : null}
            </Box>
          </Box>
        ) : (
          <Box className="p-center">
            <NoResult />
          </Box>
        )}
      </section>
      <Dialog
        open={openPaid}
        onClose={() => setOpenPaid(false)}
        aria-labelledby="paid-dlg"
        aria-describedby="alert-paid-desc"
      >
        <DialogTitle id="paid-dlg">Are you sure to continue?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-paid-desc">
            Are you sure to change status to{" "}
            <Typography variant="body" fontSize={20} color="error">
              PAID
            </Typography>{" "}
            . Also Application(s) status will be changed to paid.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPaid(false)}>Cancel</Button>
          <Button
            onClick={() => handleManualPaid(false)}
            autoFocus
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openNotPaid}
        onClose={() => setOpenNotPaid(false)}
        aria-labelledby="not-paid-dlg"
        aria-describedby="alert-not-paid-desc"
      >
        <DialogTitle id="not-paid-dlg">Are you sure to continue?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-not-paid-desc">
            Are you sure to change status to{" "}
            <Typography variant="body" fontSize={20} color="error">
              NOT PAID
            </Typography>{" "}
            . Also Application(s) status will be changed to not paid.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNotPaid(false)}>Cancel</Button>
          <Button
            onClick={() => handleManualPaid(true)}
            autoFocus
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRefund}
        onClose={() => setOpenPaid(false)}
        aria-labelledby="refund-dlg"
        aria-describedby="alert-refund-desc"
      >
        <DialogTitle id="refund-dlg">Are you sure to continue?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-refund-desc">
            Are you sure to change status to paid. Also Application(s) status
            will be changed to paid.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPaid(false)}>Cancel</Button>
          <Button onClick={() => {}} autoFocus variant="contained">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default PaymentDetails;
