import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Helmet } from "react-helmet";
import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GET_BY_APPLICATION_ID,
  GET_ALL_APPLICATION_BY_USER,
} from "config/endpoints";
import RouteConstant from "navigation/constant";
import { useSelector } from "react-redux";
import AgencyApplication from "../../component/grid/table/agencyAllBulk";
import ApplicationTable from "component/grid/table/applicationTable";
const TrackApplication = () => {
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [error, setError] = useState("");
  const isAgency = useSelector((state) => state.auth.user.role) === "agency";
  const user = useSelector((state) => state.auth.user);
  console.log(isAgency);
  const handleSubmit = () => {
    if (!id) {
      setError("Booking id is required.");
      return;
    }
    toggleLoading(true);
    GetRequest(GET_BY_APPLICATION_ID(id))
      .then((res) => {
        if (res.data?.status) {
          navigate(
            RouteConstant.VISA_APPLICATION_DETAILS + "/" + res?.data?.data?._id
          );
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  return (
    <section>
      <Navbar />
      <Helmet>
        <title>Track application </title>
      </Helmet>
      <section className="wrapper container g-0">
        <IconButton sx={{ mb: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h5" className="fw-bold" mb={1}>
          Track Application
        </Typography>
        <Typography color="grey.700" mb={2}>
          You will receive an email update when your decision has been returned
          to the Visa Application Centre. If you can’t access email easily, or
          would like more detailed tracking information, you may also be able to
          get updates by SMS sent directly to your phone. Check whether this
          service is available at the Visa Application Centre you are visiting.
        </Typography>
        <Typography color="grey.700" mb={2}>
          You can also track your visa application status online. Use the
          Reference Number present on the invoice/receipt issued by the Visa
          Application Centre along with your last name to access this service.
        </Typography>

        <Box
          my={3}
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexWrap="wrap"
        >
          <TextField
            placeholder="Enter your booking id"
            sx={{ width: 300, mr: 3 }}
            onChange={(e) => setId(e.target.value?.trim())}
            value={id}
            variant="outlined"
            error={error !== ""}
            helperText={error}
            onFocus={() => setError("")}
          />
          <Button
            variant="contained"
            endIcon={<ArrowOutwardIcon />}
            size="large"
            onClick={handleSubmit}
            sx={{ mt: 3 }}
          >
            TRACK APPLICATION
          </Button>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">My Application</Typography>
          {isAgency ? (
            <AgencyApplication />
          ) : (
            <ApplicationTable
              URL={GET_ALL_APPLICATION_BY_USER(user.id) + "?"}
              height={400}
            />
          )}
        </Box>
      </section>
    </section>
  );
};

export default TrackApplication;
