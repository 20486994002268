import { Box, Typography, IconButton, Card, CardContent } from "@mui/material";
import {
  PAYMENT_CHART_DAY_DATA,
  PAYMENT_CHART_YEAR_DATA,
  PAYMENT_CHART_MONTH_DATA,
} from "config/endpoints";
import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import PaymentChartFilter from "component/filter/paymentChartFilter";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
const PaymentChart = () => {
  const toggleLoading = useToggleLoading();
  const [details, setDetails] = useState(null);
  const [filterButton, setFilterButton] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [totalApplication, setTotalApplication] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [unpaid, setUnpaid] = useState(0);
  const [filter, setFilter] = useState({
    month: "",
    year: new Date(),
    years: {
      start: "",
      end: "",
    },
  });
  const [dataBY, setDataBy] = useState("month");
  useEffect(() => {
    if (dataBY !== "day") return;
    let fetchData = () => {
      const data = {
        method: filter.method,
        month: filter.month,
      };
      toggleLoading(true);
      PostRequest(PAYMENT_CHART_DAY_DATA, data)
        .then((res) => {
          if (res.data?.status) {
            const data = res.data?.data;
            const formatted = data.map((e) => ({
              Amount: e.totalAmount,
              Day: e.day,
            }));
            const k = data.reduce((p, c) => p + c.totalAmount, 0);
            setTotalAmount(k);
            setUnpaid(res.data?.unpaid);
            setDetails(formatted);
            setTotalApplication(res.data?.totalApplication);
          } else {
            // toast.error(res.data.msg);
            setDetails([]);
            setTotalApplication(0);
            setTotalAmount(0);
            setUnpaid(0);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
          setDetails([]);
          setTotalApplication(0);
          setTotalAmount(0);
          setUnpaid(0);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [filter, dataBY]);
  useEffect(() => {
    if (dataBY !== "month") return;
    let fetchData = () => {
      toggleLoading(true);
      const data = {
        method: filter.method,
        year: filter.year,
      };
      PostRequest(PAYMENT_CHART_MONTH_DATA, data)
        .then((res) => {
          if (res.data?.status) {
            const data = res.data?.data;
            const formatted = data.map((e) => ({
              Amount: e.totalAmount,
              Month: moment()
                .month(e.month - 1)
                .format("MMM"),
            }));
            const k = data.reduce((p, c) => p + c.totalAmount, 0, 0);

            setUnpaid(res.data?.unpaid);
            setTotalAmount(k);
            setDetails(formatted);
            setTotalApplication(res.data?.totalApplication);
          } else {
            // toast.error(res.data.msg);
            setDetails([]);
            setTotalAmount(0);
            setTotalApplication(0);
            setUnpaid(0);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
          setDetails([]);
          setTotalAmount(0);
          setTotalApplication(0);
          setUnpaid(0);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [filter, dataBY]);

  useEffect(() => {
    if (dataBY !== "year") return;
    let fetchData = () => {
      toggleLoading(true);
      const data = {
        method: filter.method,
        startYear: filter?.years?.start,
        endYear: filter?.years?.end,
      };
      PostRequest(PAYMENT_CHART_YEAR_DATA, data)
        .then((res) => {
          if (res.data?.status) {
            const data = res.data?.data;
            const formatted = data.map((e) => ({
              Amount: e.totalAmount,
              Year: e.year,
            }));
            const k = data.reduce((p, c) => p + c.totalAmount, 0);
            setTotalAmount(k);
            setDetails(formatted);
            setTotalApplication(res.data?.totalApplication);
            setUnpaid(res.data?.unpaid);
          } else {
            setUnpaid(0);
            setDetails([]);
            setTotalApplication(0);
            setTotalAmount(0);
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          setUnpaid(0);
          setDetails([]);
          setTotalApplication(0);
          setTotalAmount(0);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [filter, dataBY]);
  const handleFilter = (filter) => {
    setFilter(filter);
    setDataBy(filter.dataBy);
    console.log("filter", filter);
  };
  const handleDefault = () => {
    setDataBy("month");
    setFilter({
      month: "",
      year: new Date(),
      years: {
        start: "",
        end: "",
      },
    });
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="h5"></Typography>
        <div className={isFiltered ? "filter-badge" : ""}>
          <IconButton onClick={(event) => setFilterButton(event.currentTarget)}>
            <FilterAltIcon />
          </IconButton>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
        mb={3}
        textAlign="center"
      >
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total sale</Typography>
            <Typography variant="h3">
              <small className="f-s-16">$</small>
              {totalAmount + unpaid}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total earing</Typography>
            <Typography variant="h3">
              <small className="f-s-16">$</small>
              {totalAmount}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total unpaid</Typography>
            <Typography variant="h3">
              <small className="f-s-16">$</small>
              {unpaid}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 330, ml: 1, mb: 1 }}>
          <CardContent>
            <Typography variant="h6">Total applications</Typography>
            <Typography variant="h3">{totalApplication}</Typography>
          </CardContent>
        </Card>
      </Box>
      <Box width={1} height={500}>
        <Typography variant="" fontStyle="italic" pl={7} mb={1}>
          Paid applications data:
        </Typography>
        {dataBY === "day" ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart minWidth={600} height={300} data={details} width="100%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"Day"} padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip
                formatter={(v) => [`Amount: $${v}`]}
                labelFormatter={(v) =>
                  v + " " + moment(filter.month).format("MMM")
                }
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="Amount"
                stroke="#a0489e"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
        {dataBY === "month" ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart minWidth={600} height={300} data={details} width="100%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"Month"} padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip
                formatter={(v) => [`Amount: $${v}`]}
                labelFormatter={(v) =>
                  v + " " + moment(filter.year).format("YYYY")
                }
              />
              <Legend formatter={(value) => "Amount"} />
              <Line
                type="monotone"
                dataKey="Amount"
                stroke="#a0489e"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
        {dataBY === "year" ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart minWidth={600} height={300} data={details} width="100%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"Year"} padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip formatter={(v) => [`Amount: $${v}`]} />
              <Legend formatter={(value) => "Amount"} />
              <Line
                type="monotone"
                dataKey="Amount"
                stroke="#a0489e"
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
      </Box>

      <PaymentChartFilter
        element={filterButton}
        onSubmit={handleFilter}
        onDefault={handleDefault}
        onClose={() => setFilterButton(null)}
        isFiltered={(value) => setIsFiltered(value)}
      />
    </Box>
  );
};

export default PaymentChart;
