import { Box, Button, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import WelcomeImage from "assets/img/static/bg/about-welcome.png";

const AboutWelcome = () => {
  return (
    <Box pt={5}>
      <Box className="container" color="black.main">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box pb={5}>
              <NavigateToBack />
              <Typography
                variant="body1"
                textTransform="uppercase"
                fontWeight={600}
                letterSpacing={1}
                mb={1}
              >
                Welcome to
              </Typography>
              <Title title="ExpressVFS" />
              <Typography variant="body1" fontWeight={400} mb={1} mt={8}>
                ExpressVFS is a leading travel services company that provides
                comprehensive visa, passport, and ticketing services to
                individuals and businesses around the world. Our team of
                experienced professionals is dedicated to simplifying the travel
                process and providing exceptional service and expertise to our
                clients.
              </Typography>
              <Box className="d-flex" mt={2}>
                <Button
                  disableElevation
                  variant="contained"
                  className="rounded-2"
                  sx={{
                    my: 1,
                    mr: 2,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Apply For Visa
                </Button>
                <Button
                  disableElevation
                  variant="outlined"
                  className="rounded-2"
                  color="secondary"
                  sx={{
                    my: 1,
                    mr: 2,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Track my visa
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="flex-end">
            <Box className="resImg">
              <img src={WelcomeImage} alt="Welcome" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutWelcome;
