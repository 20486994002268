import AllRoutes from "./routes";
import RoutingFunction from "./routingFunction";
import { Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useSelector, useDispatch } from "react-redux";
import { AUTH } from "config/endpoints";
import { useToggleLoading } from "hooks";
import { setUser, setStatus, setToggleLogin } from "state/slice/user";
import { GetRequest } from "functions/http";
import { fetchCountries } from "state/slice/countries";
import { Channel } from "functions/helper";
import Footer from "parts/footer";
const Navigation = () => {
  const isProgressing = useSelector((state) => state.progressBar.isProgressing);
  const { pathname } = useLocation();
  const Dispatch = useDispatch();
  const toggleLoading = useToggleLoading();
  const auth = useSelector((data) => data.auth);
  const isLoggedIn = auth.isLoggedIn;
  useEffect(() => {
    if (localStorage.getItem("token") && !isLoggedIn) {
      Dispatch(setStatus("loading"));
      toggleLoading(true);
      GetRequest(AUTH)
        .then((res) => {
          if (res.data.status) {
            Dispatch(
              setUser({
                ...res.data.data,
                id: res.data.data._id,
              })
            );
          }
          Dispatch(setStatus("success"));
        })
        .catch((er) => {
          Dispatch(setStatus("failed"));
        })
        .finally(() => toggleLoading(false));
    } else if (isLoggedIn) {
      Dispatch(setStatus("success"));
    } else {
      Dispatch(setStatus("failed"));
      Dispatch(setToggleLogin(false));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    let listener = () => {
      console.log("Scrolled to top");
      window.scrollTo(0, 0);
    };
    listener();
    return () => (listener = null);
  }, [pathname]);
  useEffect(() => {
    Dispatch(fetchCountries());
  }, []);
  useEffect(() => {
    const Listener = (event) => {
      if (event.data === "logout") {
        localStorage.removeItem("token");
        window.location.href = "/";
      }
    };
    Channel.addEventListener("message", Listener);
    return () => {
      Channel.removeEventListener("message", Listener);
    };
  }, []);
  return (
    <div className="navigation ">
      <div className="content-height">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
          open={isProgressing}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Routes>{AllRoutes.map((r) => RoutingFunction(r))}</Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Navigation;
