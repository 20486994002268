import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import { Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import AdminApplicationTable from "component/grid/table/adminApplicationTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Filter from "component/filter";
import { GET_RECENT_APPLICATION, GET_SLOTS_DETAILS } from "config/endpoints";
import { Link } from "react-router-dom";
import SlotByDateTable from "component/grid/table/slotByDateTable";
import RouteConstant from "navigation/constant";
const StaffAdminDashboard = () => {
  const [filter, setFilter] = useState({
    status: "",
    method: "",
    fromDate: null,
    toDate: null,
    minAmount: 0,
    maxAmount: 0,
    paymentStatus: 1,
  });
  const [filterButton, setFilterButton] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  return (
    <section className="admin-all-bookings-page">
      <AdminNavbar />
      <AdminHeader onSearchChange={(value) => setSearchKey(value)} />
      <section className="wrapper container g-0">
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Typography variant="overline" fontSize={20}>
            All Bookings
          </Typography>
          <Box>
            <div className={isFiltered ? "filter-badge" : ""}>
              <IconButton
                onClick={(event) => setFilterButton(event.currentTarget)}
              >
                <FilterAltIcon />
              </IconButton>
            </div>
          </Box>
        </Box>

        <Box my={4}>
          <AdminApplicationTable
            URL={
              GET_RECENT_APPLICATION +
              `?searchKey=${searchKey}&status=${filter.status}&fromDate=${filter.fromDate}&toDate=${filter.toDate}&minAmount=${filter.minAmount}&maxAmount=${filter.maxAmount}&method=${filter.method}&paymentStatus=${filter.paymentStatus}`
            }
          />
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Typography variant="overline" fontSize={20}>
              Today Appointment Slots
            </Typography>
            <Box>
              <Link
                className="dark-link ms-2"
                to={RouteConstant.ALL_BOOKING_SLOTS}
              >
                View all
              </Link>
            </Box>
          </Box>
          <Box>
            <SlotByDateTable
              URL={GET_SLOTS_DETAILS + "?"}
              bodyData={{ date: new Date() }}
            />
          </Box>
        </Box>
        <Filter
          element={filterButton}
          onChange={(filter) => setFilter(filter)}
          onClose={() => setFilterButton(null)}
          value={filter}
          isFiltered={(value) => setIsFiltered(value)}
        />
      </section>
    </section>
  );
};

export default StaffAdminDashboard;
