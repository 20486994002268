const RouteConstant = {
  HOME: "/home",
  GET_STARTED: "/get-started",
  REGISTER: "/register",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_OTP: "/verify-otp",
  CHANGE_PASSWORD: "/change-password",
  VISA_GUIDE: "/visa-guide",
  APPLY_VISA: "/apply-visa/form-fill-up",
  VISA_SET_APPOINTMENT: "/apply-visa/appointment",
  VISA_SET_BULK_APPOINTMENT: "/apply-visa/bulk-appointment",
  VISA_PAYMENT: "/apply-visa/visa-payment",
  VISA_CONFIRMED: "/apply-visa/visa-book-confirmed",
  TRACK_APPLICATION: "/track-application",
  USER_PROFILE: "/user-profile",
  NEWS: "/news",
  NEWS_DETAILS: "/news-details",
  BLOG: "/blog",
  BLOG_DETAILS: "/blog-details",
  USER_NOT_VERIFIED: "/user-not-verified",
  ADMIN_LOGIN: "/admin/admin-login",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ALL_BOOKINGS: "/admin/all-bookings",
  ADMIN_APPLICATION_DETAILS: "/admin/application-details",
  PROFILE_SETTINGS: "/admin/profile-settings",
  SETTINGS: "/admin/settings",
  ALL_BLOGS: "/admin/all-blogs",
  CREATE_BLOG: "/admin/create-blog",
  CREATE_NEWS: "/admin/create-news",
  ALL_NEWS: "/admin/all-news",
  VISA_APPLICATION_DETAILS: "/visa-application-details",
  DISCLAIMER: "/disclaimer",
  TERM_CONDITION: "/term-and-condition",
  COOKIE_POLICY: "/cookie-policy",
  PRIVACY_POLICY: "/privacy-and-policy",
  REFUND_POLICY: "/refund-policy",
  CONTACT_US: "/contact-us",
  BULK_ALL_APPLICATION: "/bulk-all-application",
  ALL_USERS: "/admin/all-users",
  CREATE_USER: "/admin/create-user",
  CUSTOMER_COMPLAIN: "/admin/customer-complain",
  TRACK_BY_ADMIN: "/admin/track-by-admin",
  ENJAZ_SERVICE: "/enjaz-service",
  ENJAZ_FAMILY_VISA_SERVICE: "/enjaz-service/family-visa",
  FAQ: "/faq",
  STAFF_DASHBOARD: "/admin/staff-dashboard",
  EMBASSY_DASHBOARD: "/admin/embassy-dashboard",
  EMBASSY_APPLICATION_DETAILS: "/admin/embassy-application-details",
  ALL_BOOKING_SLOTS: "/admin/all-booking-slots",
  SLOTS_DETAILS: "/admin/slot-all-details",
  SUPPORT: "/admin/support",
  PAYMENTS: "/admin/payments",
  PASSPORT_PROCESSING_AND_RENEWAL: "/passport-processing-and-renewal",
  TRANSLATION_SERVICE: "/translation-service",
  NOTARIZATION_SERVICE: "/notarization-service",
  APPLICATION_PROCESS: "/application-process",
  TIPS_FOR_ABROAD: "/tips-for-abroad",
  RESOURCES: "/resources",
  REQUIREMENT: "/requirement",
  ABOUT_US: "/about-us",
  VISA_ASSISTANT: "/visa-assistant",
  CHECKOUT: "/checkout",
  PAYMENT_DETAILS: "/admin/payment-details",
  ADMIN_BULK_APPLICATION_DETAILS: "/admin/bulk-application-details",
  ACCESS_DENIED: "/access-denied",
  SAUDI_ARABIA: "/saudi-arabia",
  UAE: "/uae",
  TICKETING: "/ticketing",
};
export default RouteConstant;
