import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";
import { Link } from "react-router-dom";
import TipsAbroadImage from "assets/img/static/bg/tips-abroad.png";

const TipsAbroadInfo = () => {
  const list = [
    {
      title: "Research your destination",
      description: `Before you go, it's important to learn as much as you can about your destination. This may include researching the local culture, customs, and laws, as well as the climate, language, and currency.`,
    },
    {
      title: "Get the necessary documents",
      description: `Make sure you have all of the necessary documents, such as a passport, visas, and any other required documents for entry into the country you will be visiting.`,
    },
    {
      title: "Make travel arrangements",
      description: `Plan and book your travel arrangements, including flights, hotels, and any transportation you will need while you are there.`,
    },
    {
      title: "Get travel insurance",
      description: `Consider purchasing travel insurance to protect you in case of unexpected emergencies or cancellations.`,
    },
    {
      title: "Pack wisely",
      description: `Make a list of the items you will need and pack accordingly. Consider the climate and culture of your destination, and leave room in your suitcase for souvenirs.`,
    },
    {
      title: "Learn some basic phrases",
      description: `It can be helpful to learn some basic phrases in the language of the country you will be visiting. This can make communication easier and help you navigate your way around more easily.`,
    },
    {
      title: "Know your limits",
      description: `Be mindful of your physical and mental limits and try to pace yourself while traveling. It's okay to take breaks and rest when you need to.`,
    },
    {
      title: "Stay safe",
      description: `Research the safety and security situation in your destination, and take precautions to protect yourself and your belongings.`,
    },
  ];
  return (
    <Box className="container">
      <Box py={5}>
        <Grid container spacing={3}>
          {list?.map(({ title, description }, i) => (
            <Grid item key={i} xs={12} sm={6} md={4}>
              <StandardCard
                title={title}
                description={description}
                titleColor="primary"
                tag={`Tip No ${i + 1}`}
                tagColor="primary"
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              className="standard-card hovered d-flex flex-column justify-content-center"
              p={5}
              sx={{
                backgroundImage: `url(${TipsAbroadImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Typography variant="h6" fontWeight={700} className="title">
                Submit your <br />
                application now
              </Typography>

              <Typography variant="body2" mt={3}>
                <Link to={""}>Apply</Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TipsAbroadInfo;
