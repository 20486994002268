import { Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import useAccess from "hooks/useAccess";
import UnAuthorized from "pages/UnAuthorized";
const RoutingFunction = (route, index) => {
  const isGranted = useAccess();
  if (route?.access && !isGranted(route.access)) {
    return (
      <Route
        exact={route.exact || false}
        path={route.path}
        index={route.index || false}
        element={<UnAuthorized />}
        key={route.name || index}
      />
    );
  } else if (route.children) {
    return (
      <Route
        exact={route.exact || false}
        path={route.path}
        index={route.index || false}
        key={route.name || index}
        element={route.element || undefined}
      >
        {route.children.map((r) => RoutingFunction(r))}
      </Route>
    );
  } else {
    return (
      <Route
        exact={route.exact || false}
        path={route.path}
        index={route.index || false}
        element={
          route.isSecured ? (
            <PrivateRoute>{route.element}</PrivateRoute>
          ) : (
            route.element
          )
          // route.element
        }
        key={route.name || index}
      />
    );
  }
};
export default RoutingFunction;
