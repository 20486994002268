import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { Box } from "@mui/material";
import CountryTableColumns from "../columns/countryColumn";
const AllCountriesTables = ({
  width,
  height,
  URL,
  handleCellClick = () => {},
  ...props
}) => {
  const [searchKey, setSearchKey] = useState("");
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rows, setRows] = useState([]);
  const { isLoading, data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    `${"/country/get-all-country"}?searchKey=${searchKey}`
  );
  const [rowCountState, setRowCountState] = useState(rowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (!data.length) {
      setRows([]);
      return;
    }
    const value = data.map((el) => {
      return {
        id: el._id,
        name: el?.label,
        type: el?.countryType,
        charges: el?.visaCharges?.charges,
        discount: el?.visaCharges?.discount,
        tax: el?.visaCharges?.tax,
        total: el?.visaCharges?.total,
        edit: el?._id,
      };
    });
    setRows(value);
  }, [data]);
  const handleSearchFilter = (v) => {
    const searchValue = v?.quickFilterValues?.toString()?.replace(",", " ");
    setSearchKey(searchValue);
  };
  return (
    <Box height={height || 600} width={width || "100%"} {...props}>
      <DataGrid
        columns={CountryTableColumns}
        rows={rows}
        rowCount={rowCountState}
        loading={isLoading}
        rowsPerPageOptions={[20]}
        pagination
        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        filterMode="server"
        disableColumnFilter
        components={{ Toolbar: GridToolbar }}
        disableColumnSelector
        onCellClick={handleCellClick}
        // disableDensitySelector
        // components={{ Toolbar: GridToolbar }}
        // onFilterModelChange={handleSearchFilter}
        // componentsProps={{
        //   toolbar: {
        //     showQuickFilter: true,
        //     quickFilterProps: { debounceMs: 500 },
        //   },
        // }}
      />
    </Box>
  );
};

export default AllCountriesTables;
