import StandardNavbar from "parts/navbar/standardNavbar";
import React from "react";
import TicketingHeader from "./component/ticketingHeader";
import TicketingInfo from "./component/ticketingInfo";
import QuickLinks from "parts/quickLinks";
import QueryForm from "parts/queryForm";

const Ticketing = () => {
  return (
    <section className="ticketing-page">
      <StandardNavbar />
      <TicketingHeader />
      <TicketingInfo />
      <QuickLinks />
      <QueryForm title="For More Your Queries <br /> Contact Our Support<br /> Team " />
    </section>
  );
};

export default Ticketing;
