import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RouteConstant from "navigation/constant";
const AccessDenied = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const loginType = searchParams.get("loginType");
  const redirect_url = searchParams.get("redirect_url");
  return (
    <Box className="p-center un-authorize-page" height={"100vh"}>
      <Box textAlign="center" maxWidth={400}>
        <Typography variant="h6" color="error.main" textAlign="center" mb={3}>
          You are not authorize to access this page{" "}
          <Button>
            <Link
              to={
                loginType === "admin"
                  ? RouteConstant.ADMIN_LOGIN + `?redirect_url=${redirect_url}`
                  : RouteConstant.LOGIN + `?redirect_url=${redirect_url}`
              }
            >
              Login
            </Link>
          </Button>
          to continue
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(-2)}
          startIcon={<KeyboardBackspaceIcon />}
        >
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default AccessDenied;
