import Joi from "joi";
const emailRegex = /^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*\.[a-zA-Z]{2,}$/;
const phoneRegex = /^\+?\d{1,3}[-.\s]?\d{3,14}$/;
export const validEmail = (val) => {
  // eslint-disable-next-line
  const reg =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (val) {
    if (reg.exec(val)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
function detectContactType(contact) {
  if (emailRegex.test(contact)) {
    return "email";
  }
  if (phoneRegex.test(contact)) {
    return "phone";
  }
  return null;
}

const validPassword = (password) => {
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
  return strongPasswordRegex.test(password);
  // eslint-disable-next-line
  // const reg = /^(?=.*[A-Za-z#?!@$%^&*-])(?=.*\d)[A-Za-z#?!@$%^&*-\d]{8,}$/;
  // if (val) {
  //   if (reg.exec(val)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return false;
  // }
};

const validPhone = (val) => {
  // eslint-disable-next-line
  const reg = /^\+?\d{1,3}[-.\s]?\d{3,14}$/;
  if (val) {
    if (reg.exec(val)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const isValidLongitude = (longitude) => {
  if (Number(longitude) <= 180 && Number(longitude) >= -180) return true;
  else return false;
};
const isValidLatitude = (latitude) => {
  if (Number(latitude) <= 90 && Number(latitude) >= -90) return true;
  else return false;
};
const isValidCoordinate = (coordinate) => {
  if (!Array.isArray(coordinate)) return false;
  if (isValidLongitude(coordinate[0]) && isValidLatitude(coordinate[1])) {
    return true;
  } else return false;
};
//FILE UPLOAD TYPE VALIDATION
export const IsValidFileType = (filename, fileTypes) => {
  let fileType = filename.split(".");
  fileType = fileType[fileType.length - 1];
  return fileTypes.includes(fileType);
};

//FILE UPLOAD VALIDATION
export const UploadDocumentValidate = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.file || !DATA.file.length == 0) {
    valid = false;
    errors.push({ name: "fileError", error: "Please select a file." });
  }
  return { valid, errors };
};
export const ValidateRegistrationForm = (DATA, isUpdate) => {
  let valid = true;
  let errors = [];
  if (!DATA.name) {
    valid = false;
    errors.push({ name: "nameError", error: "Name is required." });
  }
  if (!DATA.email) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is required." });
  }
  if (!DATA.phone) {
    valid = false;
    errors.push({ name: "phoneError", error: "Phone number is required." });
  }
  if (DATA.phone && !validPhone(DATA.phone)) {
    valid = false;
    errors.push({ name: "phoneError", error: "Enter valid phone number." });
  }

  if (
    DATA?.role !== "basic" &&
    DATA?.role !== "agency" &&
    DATA?.countries?.length < 1
  ) {
    valid = false;
    errors.push({ name: "countriesError", error: "Country is required." });
  }
  if (DATA.email && !validEmail(DATA.email)) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is not valid." });
  }
  if (!isUpdate && !validPassword(DATA.password)) {
    valid = false;
    errors.push({
      name: "passwordError",
      error:
        "Password must be at least 8 characters long and contain lowercase and uppercase letters, a number, and a special character.",
    });
  }
  if (!isUpdate && !DATA.password) {
    valid = false;
    errors.push({ name: "passwordError", error: "Password is required." });
  }
  if (!isUpdate && !DATA.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPasswordError",
      error: "Confirm password is required.",
    });
  }
  if (
    (DATA.password || DATA.confirmPassword) &&
    DATA.confirmPassword !== DATA.password
  ) {
    valid = false;
    errors.push({
      name: "confirmPasswordError",
      error: "Password does not match.",
    });
  }
  return { valid, errors };
};

export const ValidateLoginForm = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.contact) {
    valid = false;
    errors.push({
      name: "contactError",
      error: "Email or phone number is required.",
    });
  }
  console.log(detectContactType(DATA.contact));
  if (DATA.contact && !detectContactType(DATA.contact)) {
    valid = false;
    errors.push({
      name: "contactError",
      error: "Please enter valid phone or email.",
    });
  }
  if (!DATA.password) {
    valid = false;
    errors.push({ name: "passwordError", error: "Password is required." });
  }
  return { valid, errors };
};

export const ValidateVisSettings = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA?.country) {
    valid = false;
    errors.push({
      name: "countryError",
      error: "Please select country.",
    });
  }
  if (!DATA?.countryType) {
    valid = false;
    errors.push({
      name: "countryTypeError",
      error: "Please select country type.",
    });
  }
  if (DATA.countryType === "toCountry") {
    if (!DATA.tax && DATA.tax != 0) {
      valid = false;
      errors.push({ name: "taxError", error: "Tax is required." });
    }
    if (!DATA.charges) {
      valid = false;
      errors.push({ name: "chargesError", error: "Fees is required." });
    }
    if (!DATA.countryType) {
      valid = false;
      errors.push({
        name: "countryTypeError",
        error: "Country Type is required.",
      });
    }
  }
  return { valid, errors };
};

export const ValidateVisaSlotSetting = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.duration) {
    valid = false;
    errors.push({
      name: "durationError",
      error: "Duration  is required.",
    });
  }
  if (!DATA.totalSlots) {
    valid = false;
    errors.push({ name: "totalSlotsError", error: "Total slots is required." });
  }
  if (!DATA.applicationPerSlot) {
    valid = false;
    errors.push({
      name: "applicationPerSlotError",
      error: "Application per slot is required.",
    });
  }
  if (!DATA.breakDuration) {
    valid = false;
    errors.push({
      name: "breakDurationError",
      error: "Break duration is required.",
    });
  }
  if (!DATA.startingTime) {
    valid = false;
    errors.push({
      name: "startingTimeError",
      error: "Starting time is required.",
    });
  }
  if (!DATA.breakTime) {
    valid = false;
    errors.push({ name: "breakTimeError", error: "Break time is required." });
  }

  return { valid, errors };
};
export const ValidateForgotPWForm = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.email) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is required." });
  }
  if (DATA.email && !validEmail(DATA.email)) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is not valid." });
  }

  return { valid, errors };
};
export const ValidateVerifyOTPForm = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.OTP) {
    valid = false;
    errors.push({ name: "OTPError", error: "OTP is required." });
  }

  return { valid, errors };
};

export const ValidateChangePWForm = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.password) {
    valid = false;
    errors.push({ name: "passwordError", error: "Password is required." });
  }
  if (!DATA.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPasswordError",
      error: "Confirm password is required.",
    });
  }
  if (DATA.password && DATA?.confirmPassword !== DATA?.password) {
    valid = false;
    errors.push({
      name: "confirmPasswordError",
      error: "Password does not match.",
    });
  }
  if (DATA.password && !validPassword(DATA?.password)) {
    valid = false;
    errors.push({
      name: "passwordError",
      error:
        "Password must be at least 8 characters long and contain lowercase and uppercase letters, a number, and a special character.",
    });
  }
  return { valid, errors };
};
export const ValidateVisaApplication_old = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.fromCountry) {
    valid = false;
    errors.push({
      name: "fromCountryError",
      error: "From Country is required.",
    });
  }
  if (!DATA.toCountry) {
    valid = false;
    errors.push({ name: "toCountryError", error: "To Country is required." });
  }
  if (DATA?.toCountry?.code === DATA?.fromCountry?.code) {
    valid = false;
    errors.push({
      name: "toCountryError",
      error: "Both country must not be same.",
    });
  }
  if (!DATA.nameTitle) {
    valid = false;
    errors.push({
      name: "nameTitleError",
      error: "Name title is required.",
    });
  }
  if (!DATA.name) {
    valid = false;
    errors.push({ name: "nameError", error: "Name is required." });
  }
  if (!DATA.passportNumber) {
    valid = false;
    errors.push({
      name: "passportNumberError",
      error: "Passport Number is required.",
    });
  }
  if (!DATA.passportType) {
    valid = false;
    errors.push({
      name: "passportTypeError",
      error: "Passport Type is required.",
    });
  }
  if (!DATA.nationalId) {
    valid = false;
    errors.push({ name: "nationalIdError", error: "NationalId is required." });
  }
  if (!DATA.email) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is required." });
  }
  if (!DATA.phone) {
    valid = false;
    errors.push({ name: "phoneError", error: "Phone is required." });
  }
  if (!DATA.passportPic) {
    valid = false;
    errors.push({
      name: "passportPictureError",
      error: "Passport document is required.",
    });
  }
  if (!DATA.nationalIdPic) {
    valid = false;
    errors.push({
      name: "nationalIdPictureError",
      error: "National ID document is required.",
    });
  }
  if (!DATA.interpolCertificatePic) {
    valid = false;
    errors.push({
      name: "interpolCertificatePictureError",
      error: "Interpol certificate document is required.",
    });
  }
  if (DATA?.toCountry?.code === "SA") {
    if (!DATA.enjazPic) {
      valid = false;
      errors.push({
        name: "enjazPicError",
        error: "Enjaz document is required.",
      });
    }
    if (!DATA.medicalGccPic) {
      valid = false;
      errors.push({
        name: "medicalGccPicError",
        error: "Medical Gcc document is required.",
      });
    }
    if (!DATA.wakalaPic) {
      valid = false;
      errors.push({
        name: "wakalaPicError",
        error: "Wakala is required.",
      });
    }
    if (!DATA.embassyFormPic) {
      valid = false;
      errors.push({
        name: "embassyFormPicError",
        error: "Embassy form is required.",
      });
    }
    if (!DATA.contracPic) {
      valid = false;
      errors.push({
        name: "contracPicError",
        error: "Contract document is required.",
      });
    }
    if (!DATA.yellowBookPic) {
      valid = false;
      errors.push({
        name: "yellowBookPicError",
        error: "Yellow Book is required.",
      });
    }
  }
  return { valid, errors };
};
export const ValidateVisaApplication = (DATA, SAUDI_ID = null) => {
  const schema = Joi.object({
    fromCountry: Joi.string().label("From Country").trim().min(18).required(),
    toCountry: Joi.string().label("To Country").trim().min(18).required(),
    nameTitle: Joi.string().label("Name Title").trim().min(2).max(8).required(),
    name: Joi.string().label("Name").trim().min(3).max(100).required(),
    passportNumber: Joi.string()
      .label("Passport Number")
      .trim()
      .min(5)
      .max(25)
      .required(),
    passportType: Joi.string().label("Passport Type").trim().required(),
    nationalId: Joi.string()
      .label("National ID")
      .trim()
      .min(3)
      .max(30)
      .required(),
    email: Joi.string()
      .trim()
      .label("Email")
      .min(3)
      .max(60)
      .pattern(emailRegex)
      .required()
      .messages({
        "string.pattern.base": "Invalid email.",
      }),
    phone: Joi.string()
      .label("Phone Number")
      .trim()
      .pattern(phoneRegex)
      .required()
      .messages({
        "string.pattern.base": "Invalid Phone number.",
      }),
    passportPic: Joi.alternatives()
      .try(Joi.object(), Joi.string())
      .label("Passport document")
      .required(),
    nationalIdPic: Joi.alternatives()
      .try(Joi.object(), Joi.string())
      .label("National  document")
      .required(),
    interpolCertificatePic: Joi.alternatives()
      .try(Joi.object(), Joi.string())
      .label("International certificate  document")
      .required(),
    enjazPic: Joi.alternatives().conditional("toCountry", {
      is: SAUDI_ID,
      then: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .label("Enjaz document")
        .required(),
      otherwise: Joi.allow(""),
    }),
    medicalGccPic: Joi.when("toCountry", {
      is: SAUDI_ID,
      then: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .label("Medical GCC document")
        .required(),
      otherwise: Joi.allow(""),
    }),
    wakalaPic: Joi.when("toCountry", {
      is: SAUDI_ID,
      then: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .label("Wakala  document")
        .required(),
      otherwise: Joi.allow(""),
    }),
    embassyFormPic: Joi.when("toCountry", {
      is: SAUDI_ID,
      then: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .label("Embassy Form  document")
        .required(),
      otherwise: Joi.allow(""),
    }),
    contracPic: Joi.when("toCountry", {
      is: SAUDI_ID,
      then: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .label("Contract  document")
        .required(),
      otherwise: Joi.allow(""),
    }),
    yellowBookPic: Joi.when("toCountry", {
      is: SAUDI_ID,
      then: Joi.alternatives()
        .try(Joi.object(), Joi.string())
        .label("Yellow Book  document")
        .required(),
      otherwise: Joi.allow(""),
    }),
    comment: Joi.string().label("Comment").trim().max(400).allow(""),
  });
  const validation = schema.validate(DATA, { abortEarly: false });
  const { error } = validation;
  console.log(validation);
  if (error) {
    const errors = validation?.error?.details?.map((detail) => ({
      name: `${detail?.path?.[0]}Error`,
      error: detail?.message
        .replace("is not allowed to be empty", "is required.")
        .replace(/"/g, ""),
    }));
    console.log(errors);
    return {
      valid: false,
      errors,
    };
  } else {
    return {
      valid: true,
      data: validation.value,
    };
  }
};
export const ValidateUserProfile = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.email) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is required." });
  }
  if (DATA.email && !validEmail(DATA.email)) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is not valid." });
  }
  if (!DATA.password) {
    valid = false;
    errors.push({ name: "passwordError", error: "Password is required." });
  }
  if (!DATA.name) {
    valid = false;
    errors.push({ name: "nameError", error: "Name can not be blanked." });
  }
  return { valid, errors };
};

export const ValidateChangePw2 = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.oldPassword) {
    valid = false;
    errors.push({
      name: "oldPasswordError",
      error: "Old password is required.",
    });
  }
  if (!DATA.password) {
    valid = false;
    errors.unshift({ name: "passwordError", error: "Password is required." });
  }
  if (!DATA.confirmPassword) {
    valid = false;
    errors.unshift({
      name: "confirmPasswordError",
      error: "Confirm password is required.",
    });
  }
  if (DATA.password && DATA.confirmPassword !== DATA.password) {
    valid = false;
    errors.unshift({
      name: "confirmPasswordError",
      error: "Password does not match.",
    });
  }
  if (!validPassword(DATA.password)) {
    valid = false;
    errors.unshift({
      name: "passwordError",
      error:
        "Password must be at least 8 characters long and contain lowercase and uppercase letters, a number, and a special character.",
    });
  }
  return { valid, errors };
};

export const ValidateBlog = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.title) {
    valid = false;
    errors.push({
      name: "titleError",
      error: "Title is required.",
    });
  }
  if (!DATA.description) {
    valid = false;
    errors.push({
      name: "descriptionError",
      error: "Description is required.",
    });
  }

  return { valid, errors };
};

export const ValidateContact = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.name) {
    valid = false;
    errors.push({
      name: "nameError",
      error: "Name is required.",
    });
  }
  if (!DATA.email) {
    valid = false;
    errors.push({
      name: "emailError",
      error: "Email is required.",
    });
  }
  if (!DATA.message) {
    valid = false;
    errors.push({
      name: "messageError",
      error: "Email is required.",
    });
  }

  return { valid, errors };
};

export const ValidateVisaAssistant = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.name) {
    valid = false;
    errors.push({
      name: "nameError",
      error: "Name is required.",
    });
  }
  if (!DATA.email) {
    valid = false;
    errors.push({
      name: "emailError",
      error: "Email is required.",
    });
  }
  if (!DATA.phone) {
    valid = false;
    errors.push({
      name: "phoneError",
      error: "Phone is required.",
    });
  }
  if (!DATA.visaType) {
    valid = false;
    errors.push({
      name: "visaTypeError",
      error: "Visa Type is required.",
    });
  }
  if (!DATA.nationality) {
    valid = false;
    errors.push({
      name: "nationalityError",
      error: "Nationality is required.",
    });
  }
  if (!DATA.preferredDestination) {
    valid = false;
    errors.push({
      name: "preferredDestinationError",
      error: "Preferred Destination is required.",
    });
  }
  if (!DATA.reason) {
    valid = false;
    errors.push({
      name: "reasonError",
      error: "Reason is required.",
    });
  }

  return { valid, errors };
};
