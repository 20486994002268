import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import LogoPic from "assets/img/static/icon/logo.png";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RouteConstant from "navigation/constant";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "state/slice/user";
import { useToggleLoading } from "hooks";
import { GetRequest } from "functions/http";
import { LOGOUT } from "config/endpoints";
import Role from "config/Role";
import { Channel } from "functions/helper";

const AdminButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "rgba(49,49,49,.65)",
  "&:hover": {
    backgroundColor: "rgba(49,49,49,1)",
  },
}));
const TransparentNavbar = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const toggleLoading = useToggleLoading();
  const handleLogout = () => {
    handleClose();
    Channel.postMessage("logout");
    toggleLoading(true);
    GetRequest(LOGOUT(user._id, localStorage.getItem("token"))).finally(() =>
      toggleLoading(false)
    );
    localStorage.removeItem("token");
    Dispatch(setLogout());
  };
  return (
    <section className="transparent-navbar">
      <div className="mid-sec">
        <div className="resImg" style={{ width: 120 }}>
          <Link to={RouteConstant.HOME}>
            <img src={LogoPic} alt="logo" />
          </Link>
        </div>
      </div>
      {!mobileSize ? (
        <div className="left-sec">
          <NavLink
            to={RouteConstant.GET_STARTED}
            className={({ isActive }) =>
              isActive ? "active home-nav-item" : "home-nav-item"
            }
          >
            Get Started
          </NavLink>
          <NavLink
            to={RouteConstant.BLOG}
            className={({ isActive }) =>
              isActive ? "active home-nav-item" : "home-nav-item"
            }
          >
            Blog
          </NavLink>
          <NavLink
            to={RouteConstant.FAQ}
            className={({ isActive }) =>
              isActive ? "active home-nav-item" : "home-nav-item"
            }
          >
            Faq
          </NavLink>
          {isLoggedIn ? (
            <>
              {user.role !== Role.AGENCY && user.role !== Role.BASIC ? (
                <NavLink
                  to={
                    user.role === Role.ADMIN || user.role === Role.MANAGER
                      ? RouteConstant.ADMIN_DASHBOARD
                      : user.role === Role.STAFF
                      ? RouteConstant.STAFF_DASHBOARD
                      : RouteConstant.EMBASSY_DASHBOARD
                  }
                  className="home-nav-item"
                >
                  Dashboard
                </NavLink>
              ) : null}
            </>
          ) : null}
          {isLoggedIn ? (
            <Button color="white" onClick={handleLogout}>
              Logout
            </Button>
          ) : (
            <>
              <Link to={RouteConstant.ADMIN_LOGIN}>
                <AdminButton
                  color="secondary"
                  variant="contained"
                  sx={{ mr: 1 }}
                >
                  Admin login
                </AdminButton>
              </Link>
              <Link to={RouteConstant.LOGIN}>
                <Button color="white">Login</Button>
              </Link>
            </>
          )}
        </div>
      ) : (
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon color="white" />
        </IconButton>
      )}

      {/* Mobile size */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink
            to={RouteConstant.GET_STARTED}
            className={({ isActive }) =>
              isActive ? "active dark-link" : "dark-link"
            }
          >
            Get Started
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink
            to={RouteConstant.BLOG}
            className={({ isActive }) =>
              isActive ? "active dark-link" : "dark-link"
            }
          >
            Blog
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink
            to={RouteConstant.FAQ}
            className={({ isActive }) =>
              isActive ? "active dark-link" : "dark-link"
            }
          >
            Faq
          </NavLink>
        </MenuItem>
        {isLoggedIn ? (
          <MenuItem onClick={handleClose}>
            {user.role !== Role.AGENCY && user.role !== Role.BASIC ? (
              <NavLink
                to={
                  user.role === Role.ADMIN || user.role === Role.MANAGER
                    ? RouteConstant.ADMIN_DASHBOARD
                    : user.role === Role.STAFF
                    ? RouteConstant.STAFF_DASHBOARD
                    : RouteConstant.EMBASSY_DASHBOARD
                }
                className="dark-link"
              >
                Dashboard
              </NavLink>
            ) : null}
          </MenuItem>
        ) : null}
        {isLoggedIn && (
          <MenuItem>
            <Button color="white" onClick={handleLogout}>
              Logout
            </Button>
          </MenuItem>
        )}
        {!isLoggedIn && (
          <MenuItem onClick={handleClose}>
            <NavLink to={RouteConstant.ADMIN_LOGIN}>
              <AdminButton variant="contained" sx={{ mr: 1 }}>
                Admin login
              </AdminButton>
            </NavLink>
          </MenuItem>
        )}
        {!isLoggedIn && (
          <MenuItem onClick={handleClose}>
            <NavLink to={RouteConstant.LOGIN}>
              <Button color="black">Login</Button>
            </NavLink>
          </MenuItem>
        )}
      </Menu>
    </section>
  );
};

export default TransparentNavbar;
