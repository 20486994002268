import { Box, Typography } from "@mui/material";

const EnjazServices = () => {
  return (
    <div className="enjaz-service bg-dark">
      <div className="container text-center text-white pb-5">
        <Box py={10}>
          <Typography variant="h4" mb={3}>
            Enjaz services
          </Typography>
          <Typography mb={2}>
            At XYZ Visa Center, we are proud to offer Enjaz services to help our
            clients with their visa applications to Saudi Arabia. Enjaz is a
            service provided by the Saudi Ministry of Foreign Affairs that
            allows individuals to submit their visa applications online, track
            the status of their applications, and receive updates about their
            applications via email or SMS.
          </Typography>
          <Typography mb={2}>
            By using Enjaz services at XYZ Visa Center, you can save time and
            avoid the hassle of submitting your visa application in person. Our
            team of experienced professionals is here to guide you through the
            Enjaz process and assist you with any questions you may have.
          </Typography>
          <Typography mb={2}>
            To use the Enjaz service, you will need to create an account on the
            Enjaz website and provide the necessary information and documents
            for your visa application. We can help you with this process and
            ensure that your application is completed accurately and on time.
          </Typography>
          <Typography mb={2}>
            In addition to Enjaz services, we also offer a range of other
            services to assist with your visa application, including passport
            processing and renewal, translation services, and notarization
            services. We are committed to providing our clients with the highest
            level of service and support, and we look forward to helping you
            with your visa application to Saudi Arabia.
          </Typography>
        </Box>
        <Box py={10}>
          <Typography variant="h4" mb={3}>
            Haji and umrah visas
          </Typography>
          <Typography mb={2}>
            Haji and umrah visas are special types of visas for Saudi Arabia
            that are issued to individuals who wish to make the pilgrimage to
            Mecca. The haji visa is for individuals who are making the
            pilgrimage during the annual hajj season, while the umrah visa is
            for individuals who are making the pilgrimage at other times of the
            year.
          </Typography>
          <Typography mb={2}>
            To apply for a haji or umrah visa, individuals will need to fill out
            an application form and submit it along with supporting documents,
            such as a passport and proof of travel arrangements. The visa
            application process can be complex, so it is recommended that
            individuals use the services of a visa center to assist with the
            application.
          </Typography>
          <Typography mb={2}>
            Haji and umrah visas are typically valid for a short period of time,
            and may only allow individuals to enter and exit Saudi Arabia
            through specific airports or land borders. It is important to
            carefully review the terms and conditions of the visa before
            traveling to ensure that you are in compliance with the
            requirements.
          </Typography>
        </Box>
        <Typography variant="body1" mt={4}>
          To enter Saudi Arabia, individuals from most countries will need to
          obtain a visa in advance. The specific type of visa that is required
          will depend on the purpose of the visit and the length of the stay.
          Some common types of visas for Saudi Arabia include:
        </Typography>
      </div>
    </div>
  );
};

export default EnjazServices;
