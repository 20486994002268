import RenderCellExpand from "component/grid/expandCell";
import { Avatar, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const applicationColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    minWidth: 150,
    renderCell: RenderCellExpand,
  },

  {
    field: "fromCountry",
    minWidth: 150,
    headerName: "From",
    renderCell: RenderCellExpand,
  },
  {
    field: "toCountry",
    headerName: "To",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "phone",
    headerName: "Phone",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "passportNumber",
    headerName: "Passport Number",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "applicationId",
    headerName: "Booking Id",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "charges",
    headerName: "Charges",
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: "font-tabular-nums",
  },
  {
    field: "edit",
    headerName: "Edit",
    renderCell: () => (
      <IconButton>
        <ModeEditOutlinedIcon sx={{ fontSize: 26 }} />
      </IconButton>
    ),
  },
  {
    field: "delete",
    headerName: "Delete",
    renderCell: () => (
      <IconButton>
        <DeleteIcon sx={{ fontSize: 26 }} color="error" />
      </IconButton>
    ),
  },
];
