import StandardNavbar from "parts/navbar/standardNavbar";
import ApplicationProcessHeader from "./component/applicationProcessHeader";
import ApplicationProcessInfo from "./component/applicationProcessInfo";
import QuickLinks from "parts/quickLinks";
import QueryForm from "parts/queryForm";

const ApplicationProcess = () => {
  return (
    <section className="application-process-page">
      <StandardNavbar />
      <ApplicationProcessHeader />
      <ApplicationProcessInfo />
      <QuickLinks />
      <QueryForm title="For More Your Queries <br /> Contact Our Support <br /> Team " />
    </section>
  );
};

export default ApplicationProcess;
