import { useEffect, useState } from "react";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisApplyStepper from "../visaFormFill/component/stepper";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import RouteConstant from "navigation/constant";
import { GET_BULK_SLOT_CALCULATION, BULK_SUBMIT } from "config/endpoints";
import { GetRequest, HTTP_ERROR, PostRequest } from "functions/http";
import { useToggleLoading } from "hooks";
import { toast } from "react-toastify";
import NoResult from "parts/noResult";
import { GET_SLOTS_DETAILS } from "config/endpoints";
import { useSelector } from "react-redux";
const isWeekend = (date) => {
  if (date.day() === 0 || date.day() === 6) return true;
  if (moment(date).diff(moment(), "days") > 15) return true;
  return false;
};

const VisaBulkAppointment = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const [date, setDate] = useState(moment());
  const [data, setData] = useState(null);
  const [slots, setSlots] = useState([]);
  const [minimum, setMinimum] = useState(0);
  const [selected, setSelected] = useState([]);
  const [warnDlg, setWarnDlg] = useState(false);
  const { id } = useParams();
  const userRole = useSelector((state) => state.auth.user.role);

  const handleSelect = (slotId) => {
    const isExist = selected.find((e) => e === slotId);
    if (isExist) {
      const copyItems = selected.filter((el) => el !== slotId);
      setSelected(copyItems);
    } else if (minimum <= selected.length) {
      const copyItems = Array.from(selected);
      copyItems.shift();
      copyItems.push(slotId);
      setSelected(copyItems);
      return;
    } else {
      const copyItems = Array.from(selected);
      copyItems.push(slotId);
      setSelected(copyItems);
    }
  };
  useEffect(() => {
    if (!id) navigate(RouteConstant.APPLY_VISA);
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_BULK_SLOT_CALCULATION(id))
        .then((res) => {
          if (res?.data?.status) {
            setData(res.data?.data);
            setMinimum(res.data?.minimumSlots);
          } else {
            // toast.error(res.data.msg);
            setData(null);
            setMinimum(0);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er));
          setMinimum(0);
          setData(null);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);
  useEffect(() => {
    toggleLoading(true);
    PostRequest(GET_SLOTS_DETAILS, { date: date })
      .then((res) => {
        if (res?.data?.status) {
          setSlots(res.data.data);
        } else {
          // toast.error(res.data.msg);
          setSlots([]);
        }
      })
      .catch((er) => {
        // toast.error(HTTP_ERROR(er));
        setSlots([]);
      })
      .finally(() => toggleLoading(false));
  }, [date]);
  const handelWarning = () => {
    if (selected.length !== minimum) {
      return toast.error(`Please select ${minimum} slots.`);
    }
    setWarnDlg(true);
  };
  const handleSubmit = () => {
    toggleLoading(true);
    PostRequest(BULK_SUBMIT(id), { selectedSlots: selected })
      .then((res) => {
        if (res?.data?.status) {
          console.log(res.data.data);
          navigate(RouteConstant.VISA_PAYMENT + "/" + id + "?bulk=true");
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er));
        setSlots([]);
      })
      .finally(() => toggleLoading(false));
  };
  if (userRole === "basic") {
    navigate(RouteConstant.HOME);
    return null;
  }
  return (
    <section>
      <Navbar />
      <Helmet>
        <title>Bulk application appointment </title>
      </Helmet>
      {data != null ? (
        <section className="wrapper container g-0">
          <IconButton sx={{ mb: 3 }}>
            <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
          </IconButton>
          <Typography variant="h5" className="fm-pt-mono" mb={1}>
            Apply for a visa
          </Typography>
          <Typography color="grey.500">
            Find the information and begin your application
          </Typography>
          <Box>
            <VisApplyStepper step={1} />
          </Box>
          <Box>
            <Box mt={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <StaticDatePicker
                      orientation={mobileSize ? "portrait" : "landscape"}
                      openTo="day"
                      value={date}
                      disablePast
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      shouldDisableDate={isWeekend}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" mb={3}>
                    Available Time Slots
                  </Typography>
                  {slots.length > 0 ? (
                    <Box>
                      <Box
                        flexWrap="wrap"
                        display="flex"
                        alignItems="flex-start"
                      >
                        {slots.map((slot) => {
                          return (
                            <Button
                              key={slot._id}
                              variant="contained"
                              color={
                                selected.includes(slot._id)
                                  ? "primary"
                                  : "secondary2"
                              }
                              sx={{ mr: 1, mb: 1 }}
                              onClick={() => handleSelect(slot._id)}
                              disabled={
                                slot.allowedApplication <= slot.applied ||
                                moment(slot.to).isBefore(moment())
                              }
                            >
                              {moment(slot.from).format("hh:mm a")}
                            </Button>
                          );
                        })}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={3}
                        pr={3}
                      >
                        <Typography
                          variant="subtitle2"
                          textAlign="right"
                          sx={{
                            fontStyle: "italic",
                            fontSize: 14,
                            color: "success.main",
                            pr: 3,
                          }}
                        >
                          Please select {minimum} slots
                        </Typography>
                        <Button onClick={() => setSelected([])}>Clear</Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Typography variant="h4">
                        No slots available on this date
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" justifyContent="flex-end" mt={6}>
                    <Button
                      variant="contained"
                      endIcon={<ArrowOutwardIcon />}
                      size="large"
                      onClick={() => handelWarning(true)}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Dialog
            open={warnDlg}
            onClose={() => setWarnDlg(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure to continue?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                After submitting you can not edit any application. Also you can
                not change appointment time slots. You have few hours to set
                payment.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setWarnDlg(false)}>Cancel</Button>
              <Button onClick={handleSubmit} autoFocus variant="contained">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </section>
      ) : (
        <div className="p-center my-4">
          <NoResult />
        </div>
      )}
    </section>
  );
};

export default VisaBulkAppointment;
