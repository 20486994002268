import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";

import BgImage from "assets/img/static/bg/uae-welcome-bg.png";

const UAEWelcome = () => {
  return (
    <Box
      pt={20}
      pb={10}
      sx={{
        backgroundImage: `url(${BgImage})`,
      }}
    >
      <Box className="container" color="white.main">
        <Grid container position="relative">
          <Grid item xs={12} lg={5}>
            <Box pb={5}>
              <NavigateToBack buttonColor="#fff" />
              <Typography
                variant="body1"
                textTransform="uppercase"
                fontWeight={600}
                letterSpacing={1}
                mb={1}
              >
                Welcome to
              </Typography>

              <Title title="United Arab Emirates" color="inherit" />
              <Typography variant="body1" fontWeight={400} mb={1} mt={8}>
                Saudi Arabia, officially the Kingdom of Saudi Arabia is a
                country in Western Asia. It covers the bulk of the Arabian
                Peninsula, and has a land area of about 2,150,000 km2 (830,000
                sq mi), making it the fifth-largest country in Asia, the
                second-largest in the Arab world, and the largest in Western
                Asia and the Middle East.
              </Typography>
              <Box className="d-flex" mt={2}>
                <Button
                  disableElevation
                  variant="contained"
                  className="rounded-2"
                  sx={{
                    my: 1,
                    mr: 2,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Apply For Visa
                </Button>
                <Button
                  disableElevation
                  variant="outlined"
                  className="rounded-2"
                  color="inherit"
                  sx={{
                    my: 1,
                    textTransform: "capitalize",
                    fontWeight: 400,
                  }}
                >
                  Track my visa
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UAEWelcome;
