import {
  Box,
  Grid,
  Typography,
  Stepper,
  StepLabel,
  Step,
  StepContent,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Input,
  Chip,
} from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import ErrorIcon from "@mui/icons-material/Error";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import NoResult from "parts/noResult";
import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import {
  GET_VISA_APP_DETAILS_ID,
  CREATE_REPORT,
  GET_REPORT_BY_APPLICATION,
} from "config/endpoints";
import moment from "moment";
import { useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import { GetStatus } from "functions/outputFunctions";
import { GetActiveStatus } from "functions/outputFunctions";
import { GetPayMethod } from "functions/outputFunctions";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RouteConstant from "navigation/constant";
import useDownload from "hooks/useDownload";
import useFile from "hooks/useFile";
const VisaApplicationDetails = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const Download = useDownload();
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const ROLE = useSelector((state) => state.auth.user.role);
  const user = useSelector((state) => state.auth.user);
  const [openReportDlg, setOpenReportDlg] = useState(false);
  const [screenshot, setScreenshot] = useState("");
  const [report, setReport] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);

  const fileValidation = useFile();
  const handleScreenShot = (event) => {
    const { isError, errorMsg } = fileValidation({
      fileTypes: ["png", "jpg", "jpeg", "PNG"],
      maxSize: 1024 * 1024 * 1,
      file: event?.target?.files[0],
    });
    if (isError) {
      toast.error(errorMsg);
      return;
    }
    setScreenshot(event.target.files[0]);
  };

  const handleCloseReportDlg = () => {
    setOpenReportDlg(false);
  };
  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_VISA_APP_DETAILS_ID(id))
        .then((res) => {
          if (res.data?.status) {
            setDetails(res?.data?.data);
          } else {
            setDetails(null);
            // toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
          setDetails(null);
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id, refresh]);
  useEffect(() => {
    if (!id) return;
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_REPORT_BY_APPLICATION(id))
        .then((res) => {
          if (res.data?.status) {
            // setReportDetails(res?.data?.data);
          } else {
            setReportDetails(null);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [id]);

  const handleReportSubmit = () => {
    if (!report) {
      toast.error("Please write your issue.");
      return;
    }
    const formData = new FormData();
    formData.append("user", user.id);
    formData.append("application", id);
    formData.append("report", report);
    formData.append("screenshot", screenshot);
    handleCloseReportDlg();
    toggleLoading(true);
    PostRequest(CREATE_REPORT, formData, true)
      .then((res) => {
        if (res.data?.status) {
          toast.success("Report submitted");
          setRefresh(!refresh);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  console.log("details", details);
  return (
    <section className="application-details-page">
      <Navbar />
      <section className="wrapper container g-0 content-height">
        <Box
          mb={4}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Box>
            <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
              <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
            </IconButton>
            <Typography variant="overline" ml={2} fontSize={18}>
              Visa Application Details
            </Typography>
          </Box>
          {!details?.isPaid && details?.user?._id === user?.id ? (
            <Link to={RouteConstant.VISA_PAYMENT + "/" + details?._id}>
              <Button variant="contained">Pay</Button>
            </Link>
          ) : null}
        </Box>
        {details != null ? (
          <Box>
            {!details.confirmed ? (
              <Typography variant="h5" color="error" textAlign="center" mb={3}>
                Your application is not confirmed. Please pay to continue.
                <br />
                <Link to={RouteConstant.VISA_PAYMENT + "/" + details._id}>
                  PAY
                </Link>
              </Typography>
            ) : null}
            <Box
              sx={{
                bgcolor: "grey.200",
                borderRadius: 4,
                p: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Full Name </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.nameTitle} {details.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Email </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Phone </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport Type </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.passportType}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport Number </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.passportNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> National Id </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.nationalId}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Booking Id </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.applicationId}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Appointment Date </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {moment(details.appointmentSlot?.date).format("D MMM Y")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Appointment Time </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {moment(details.appointmentSlot?.from).format("h:mm A")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Tax</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.tax}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Fees</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.charges}
                  </Typography>
                </Grid>
                {details.discount ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Discount</Typography>
                    <Typography variant="subtitle2" fontWeight={600}>
                      {details.discount}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Total Fees</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.total}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Payment status</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.isPaid ? (
                      <Chip label="Paid" color="success" />
                    ) : (
                      <Chip label="Not Paid" color="error" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Payment method</Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {GetPayMethod(details.paymentMethod)}
                  </Typography>
                </Grid>
                {details?.payment?.length > 0 ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2">Payment Id</Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {details?.payment[0].paymentId}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Applied Date</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {moment(details.date).format("D MMM Y")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">From Country</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.fromCountry?.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">To Country</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {details.toCountry?.label}
                  </Typography>
                </Grid>
                {ROLE === "agency" ? (
                  <>
                    {details.agency?.length > 0 ? (
                      <>
                        <Grid item xs={12} md={4} lg={3}>
                          <Typography variant="body2">Agency Name</Typography>
                          <Typography variant="subtitle2" fontWeight={600}>
                            {details?.agency[0].agencyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                          <Typography variant="body2">Agency Phone</Typography>
                          <Typography variant="subtitle2" fontWeight={600}>
                            {details?.agency[0].phone}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}
                    {details.bulk?.length > 0 ? (
                      <Grid item xs={12} md={4} lg={3}>
                        <Typography variant="body2">Bulk Id</Typography>
                        <Typography variant="subtitle2" fontWeight={600}>
                          {details.bulk[0]?.bulkId}
                        </Typography>
                      </Grid>
                    ) : null}
                  </>
                ) : null}

                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Booking Status </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {GetStatus(details.status)}
                  </Typography>
                </Grid>
                {details.reason ? (
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography variant="body2"> Reason </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      textTransform="capitalize"
                    >
                      {details.reason}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download Passport</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(details.passportPicture?.path, "passport-EDA")
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Download National ID</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(
                          details.nationalIdPicture?.path,
                          "national-id-EDA"
                        )
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Interpol certificate</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <Button
                      onClick={() =>
                        Download(
                          details.interpolCertificatePicture?.path,
                          "interpol-certificate-EDA"
                        )
                      }
                      endIcon={<DownloadIcon />}
                      size="small"
                    >
                      Download
                    </Button>
                  </Typography>
                </Grid>
                {details?.toCountry?.code === "SA" ? (
                  <>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Enjaz</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(details.enjazPicture?.path, "enjaz-doc")
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Medical Gcc</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(
                              details.medicalGccPicture?.path,
                              "medical-gcc"
                            )
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Wakala</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(details.wakalaPicture?.path, "wakala")
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Embassy Form</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(
                              details.embassyFormPicture?.path,
                              "embasyForm"
                            )
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Contract</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(details.contractPicture?.path, "Contract")
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                      <Typography variant="body2">Yellow Book</Typography>
                      <Typography variant="subtitle2" fontWeight={600}>
                        <Button
                          onClick={() =>
                            Download(
                              details.yellowBookPicture?.path,
                              "yellow-book"
                            )
                          }
                          endIcon={<DownloadIcon />}
                          size="small"
                        >
                          Download
                        </Button>
                      </Typography>
                    </Grid>
                  </>
                ) : null}
                {reportDetails != null ? (
                  <Grid item xs={12} md={4} lg={3}>
                    {!reportDetails.isSolved ? (
                      <Typography variant="subtitle2" fontWeight={600}>
                        You have reported. We will solve your issue soon.
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="subtitle2" fontWeight={600}>
                          Your report is solved.
                        </Typography>
                      </>
                    )}
                    {reportDetails.reply && (
                      <>
                        <Typography variant="subtitle2" fontWeight={600}>
                          Admin Reply:
                        </Typography>
                        <Typography variant="subtitle2">
                          {reportDetails.reply}
                        </Typography>
                      </>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: 4,
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography variant="body1">Submitted</Typography>
                <Stepper
                  activeStep={GetActiveStatus(details?.status)}
                  orientation="vertical"
                >
                  {steps.map((step, index) => {
                    const labelProps = {};
                    if (details?.status === "returned" && index === 2) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          Returned
                        </Typography>
                      );

                      labelProps.error = true;
                    }
                    if (details?.status === "denied" && index === 3) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          Denied
                        </Typography>
                      );

                      labelProps.error = true;
                    }
                    return (
                      <Step key={step.label} expanded>
                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                        <StepContent>
                          <Typography variant="caption" color="grey.400">
                            {step.description}
                          </Typography>
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              <Box display="flex" alignItems="center">
                <Link to={RouteConstant.CONTACT_US}>
                  <Button
                    variant="outlined"
                    endIcon={<HeadphonesIcon />}
                    sx={{ mr: 2 }}
                    color="secondary"
                  >
                    Support
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="secondary2"
                  endIcon={<ErrorIcon />}
                  onClick={() => setOpenReportDlg(true)}
                  disabled={reportDetails != null}
                >
                  Report
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="p-center">
            <NoResult />
          </Box>
        )}
      </section>
      <Dialog open={openReportDlg} onClose={handleCloseReportDlg}>
        <DialogTitle>Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please write your issue.Also you can send screenshot.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Write your issue"
            fullWidth
            multiline
            rows={5}
            value={report}
            onChange={(ev) => setReport(ev.target.value)}
          />
          <label htmlFor="screenshot" style={{ width: "100%" }}>
            <Input
              id="screenshot"
              type="file"
              sx={{ display: "none" }}
              name="screenshot"
              onChange={handleScreenShot}
            />
            <Button
              variant="outlined"
              component="span"
              fullWidth
              endIcon={<FileUploadOutlinedIcon />}
              color={"secondary"}
              sx={{
                justifyContent: "center",
                py: 1.7,
              }}
            >
              <span className="text-ellipse w-250">
                {typeof screenshot === "object"
                  ? screenshot?.name
                  : "  Upload Screenshot"}
              </span>
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportDlg}>Cancel</Button>
          <Button onClick={handleReportSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default VisaApplicationDetails;
const steps = [
  {
    label: "Booked An Appointment",
  },

  {
    label: "Physical Verification done",
  },
  {
    label: "Sent to embassy",
  },
  {
    label: "Verified From Embassy",
  },
  {
    label: "Ready to collect",
  },
  {
    label: "Collected",
  },
];
