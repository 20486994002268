import { useState, useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PaymentReceipt from "component/pdf/receipt";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Navbar from "parts/navbar";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import moment from "moment";
import Logo from "assets/img/static/icon/logo2.png";
import { GET_PAYMENT_BY_PAYMENT_ID } from "config/endpoints";
import { GetRequest, HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import NoResult from "parts/noResult";
import {
  useParams,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import RouteConstant from "navigation/constant";
import Progress from "component/other/progress";
const VisaBookConfirmed = () => {
  const navigate = useNavigate();
  const toggleLoading = useToggleLoading();
  const { id } = useParams();
  const [payment, setPayment] = useState(null);
  const [application, setApplication] = useState(null);
  const [isBulk, setIsBulk] = useState(false);
  const [search] = useSearchParams();
  const status = search.get("status");
  const payment_id = search.get("tx_ref");
  const [isLoaded, setIsLoaded] = useState(true);
  useEffect(() => {
    if (!payment_id) return;
    let fetchData = () => {
      toggleLoading(true);
      setIsLoaded(false);
      GetRequest(GET_PAYMENT_BY_PAYMENT_ID(payment_id))
        .then(async (res) => {
          if (res?.data?.status) {
            const _data = res.data.data;
            if (
              !_data.payment.orderConfirmed &&
              status !== "cancelled" &&
              _data.payment.paymentMethod === "online"
            ) {
              await new Promise((resolve) => {
                setTimeout(() => {
                  resolve(true);
                }, 5000);
              });
              return GetRequest(GET_PAYMENT_BY_PAYMENT_ID(payment_id));
            } else {
              return res;
            }
          } else {
            return res;
          }
        })
        .then((res) => {
          console.log(res);
          if (res?.data?.status) {
            const _data = res.data.data;
            if (!_data.payment?.orderConfirmed && status !== "cancelled") {
              navigate(
                RouteConstant.VISA_PAYMENT +
                  "/" +
                  _data.application._id +
                  `${_data.isBulk ? "?bulk=true" : ""}`
              );
            }
            setPayment(_data.payment);
            setApplication(_data.application);
            setIsBulk(_data.isBulk);
            if (
              !_data?.payment?.isPaid &&
              _data?.payment?.paymentMethod === "online"
            ) {
              return true;
            }
          } else {
            // toast.error(res.data.msg);
            setPayment(null);
            setApplication(null);
            setIsBulk(null);
          }
        })

        .catch((er) => {
          setPayment(null);
          setApplication(null);
          setIsBulk(null);
          // toast.error(HTTP_ERROR(er));
        })
        .finally(() => {
          console.log(Date.now());
          toggleLoading(false);
          setIsLoaded(true);
        });
    };
    fetchData();
    return () => (fetchData = null);
  }, [id, payment_id, status]);
  if (!isLoaded) {
    return (
      <Box height="100vh" width={1} className="p-center">
        <Progress />
      </Box>
    );
  }
  return (
    <section className="c-bg-primary ">
      <Navbar />
      <Helmet>
        <title>Visa confirmed </title>
      </Helmet>
      <section className="wrapper container g-0 min-h-100vh">
        {!payment_id ? (
          <NoResult my={5} />
        ) : (
          <>
            {status === "cancelled" ? (
              <Card
                sx={{
                  maxWidth: 350,
                  width: 1,
                  mx: "auto",
                  textAlign: "center",
                }}
              >
                <CardContent>
                  <ErrorOutlineOutlinedIcon
                    color="error"
                    sx={{ fontSize: 52 }}
                  />
                  <Typography variant="h4" color="error.main" my={3}>
                    Payment Failed
                  </Typography>
                  <Link
                    to={
                      RouteConstant.VISA_PAYMENT +
                      "/" +
                      application?._id +
                      `?${payment?.isBulk ? "bulk=true" : ""}`
                    }
                  >
                    <Button variant="contained">Try Again</Button>
                  </Link>
                </CardContent>
              </Card>
            ) : (
              <>
                {application && payment ? (
                  <Grid container spacing={3} mb={5}>
                    <Grid item xs={12} md={6}>
                      <Card
                        sx={{
                          maxWidth: 350,
                          width: 1,
                          mx: "auto",
                        }}
                      >
                        <CardContent>
                          <Box display="flex" justifyContent="space-between">
                            <div className="resImg">
                              <img
                                src={Logo}
                                alt="logo"
                                style={{ width: 80 }}
                              />
                            </div>
                            <Tooltip title="Download receipt">
                              <IconButton sx={{ position: "relative" }}>
                                <PDFDownloadLink
                                  className="stretched-link"
                                  document={
                                    <PaymentReceipt
                                      isBulk={isBulk}
                                      application={application}
                                      payment={payment}
                                    />
                                  }
                                  fileName="visa-application-receipt.pdf"
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? "" : ""
                                  }
                                </PDFDownloadLink>
                                <FileDownloadOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Typography variant="body1" my={3}>
                            Welcome onboard!
                          </Typography>
                          <Typography variant="subtitle2" mb={2}>
                            Hi {application?.userDetails?.name},
                          </Typography>
                          <Typography variant="subtitle2">
                            Thanks for applying for visa. We’ve received your
                            application
                            {isBulk ? (
                              "s"
                            ) : (
                              <b>{application?.applicationId}</b>
                            )}{" "}
                            . You can visit the visa center on your chosen date{" "}
                            <b>
                              {application?.appointmentDate &&
                                moment(application?.appointmentDate).format(
                                  "DD-MMM-YY"
                                )}
                            </b>{" "}
                            for further verification process.
                          </Typography>
                          {isBulk ? (
                            <Box
                              bgcolor="grey.200"
                              borderRadius={4}
                              p={1}
                              mt={3}
                            >
                              <Box display="flex" flexWrap="wrap">
                                <Typography variant="subtitle2" mr={2} mb={2}>
                                  <span className="fw-bold mb-2">Bulk ID </span>
                                  <br />
                                  {application.bulkId}
                                </Typography>
                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">
                                    Agency Name
                                  </span>
                                  <br />
                                  {application?.agencyDetails?.agencyName}
                                </Typography>
                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">
                                    Total Application
                                  </span>
                                  <br />
                                  {application?.totalApplied}
                                </Typography>

                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">
                                    Service type
                                  </span>
                                  <br />
                                  {/* NOTE */}
                                  Applied for visa
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              bgcolor="grey.200"
                              borderRadius={4}
                              p={1}
                              mt={3}
                            >
                              <Box display="flex" flexWrap="wrap">
                                <Typography variant="subtitle2" mr={2} mb={2}>
                                  <span className="fw-bold mb-2">
                                    Booking ID{" "}
                                  </span>
                                  <br />
                                  {application.applicationId}
                                </Typography>
                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">Name</span>
                                  <br />
                                  {application?.name}
                                </Typography>
                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">Email</span>
                                  <br />
                                  {application?.email}
                                </Typography>
                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">
                                    Passport Type
                                  </span>
                                  <br />
                                  {application?.passportType}
                                </Typography>
                                <Typography variant="subtitle2" mr={5} mb={3}>
                                  <span className="fw-bold mb-2">
                                    Service type
                                  </span>
                                  <br />
                                  {/* NOTE */}
                                  Applied for visa
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          <Box bgcolor="grey.200" borderRadius={4} p={1} mt={3}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #cecece",
                                mb: 2,
                              }}
                            >
                              <Box>
                                <Typography
                                  fontWeight="bold"
                                  variant="subtitle2"
                                >
                                  Fee
                                </Typography>
                                <Typography
                                  color="grey.600"
                                  variant="subtitle2"
                                >
                                  Convenience Fee
                                </Typography>
                              </Box>
                              <Typography variant="subtitle2">
                                {payment?.fees}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #cecece",
                                mb: 2,
                              }}
                            >
                              <Box>
                                <Typography
                                  fontWeight="bold"
                                  variant="subtitle2"
                                >
                                  TAX
                                </Typography>
                              </Box>
                              <Typography variant="subtitle2">
                                {payment?.tax}
                              </Typography>
                            </Box>
                            {payment?.discount > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderBottom: "1px solid #cecece",
                                  mb: 2,
                                }}
                              >
                                <Box>
                                  <Typography
                                    fontWeight="bold"
                                    variant="subtitle2"
                                  >
                                    DISCOUNT
                                  </Typography>
                                </Box>
                                <Typography variant="subtitle2">
                                  {payment?.discount}
                                </Typography>
                              </Box>
                            ) : null}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #cecece",
                                mb: 2,
                              }}
                            >
                              <Box>
                                <Typography
                                  fontWeight="bold"
                                  variant="subtitle2"
                                >
                                  Total
                                </Typography>
                              </Box>
                              <Typography variant="subtitle2">
                                {payment?.amount}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box color="#fff" pt={12}>
                        <Box display="flex" mb={2}>
                          <Typography variant="h6">Payment Mode: </Typography>
                          &nbsp;
                          <Typography
                            variant="h5"
                            fontWeight={600}
                            textTransform="capitalize"
                          >
                            {payment.paymentMethod}
                          </Typography>
                        </Box>
                        <Box display="flex" mb={2}>
                          <Typography variant="h6">Payment Status: </Typography>
                          &nbsp;
                          <Typography variant="h5" fontWeight={600}>
                            {payment.isPaid ? " Paid" : " Not Paid"}
                          </Typography>
                        </Box>
                        <Box display="flex" mb={2}>
                          <Typography variant="h6">Payment Amount: </Typography>
                          &nbsp;
                          <Typography variant="h5" fontWeight={600}>
                            ${payment.amount}
                          </Typography>
                        </Box>
                        <Box display="flex" flexWrap="wrap" mb={2}>
                          <Typography variant="h6">Payment ID: </Typography>
                          &nbsp;
                          <Typography variant="h5" fontWeight={600}>
                            {payment.paymentId}
                          </Typography>
                        </Box>
                        <Box display="flex">
                          {!payment.isPaid ? (
                            <Link
                              to={`${RouteConstant.VISA_PAYMENT}/${
                                application._id
                              }?${isBulk ? "bulk=true" : ""}`}
                            >
                              <Button
                                variant="contained"
                                sx={{ mr: 2 }}
                                color="black"
                              >
                                Pay
                              </Button>
                            </Link>
                          ) : null}
                          <Button
                            variant="outlined"
                            title="Download receipt"
                            color="white"
                          >
                            <PDFDownloadLink
                              style={{ color: "#fff" }}
                              document={
                                <PaymentReceipt
                                  isBulk={isBulk}
                                  application={application}
                                  payment={payment}
                                />
                              }
                              fileName="visa-application-receipt.pdf"
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? "Loading document..." : "Download now"
                              }
                            </PDFDownloadLink>
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <NoResult my={5} />
                )}
              </>
            )}
          </>
        )}
      </section>
    </section>
  );
};

export default VisaBookConfirmed;
