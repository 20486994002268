export const visaAssistanceFormInputs = [
  {
    type: "text",
    name: "name",
    nameError: "nameError",
    label: "Full Name",
    placeholder: "Eg: John Walker",
  },
  {
    type: "email",
    name: "email",
    nameError: "emailError",
    label: "Email",
    placeholder: "Eg: example@mail.com",
  },

  {
    type: "number",
    name: "phone",
    nameError: "phoneError",
    label: "Phone Number",
    placeholder: "(724) 000 0000",
  },
  {
    type: "select",
    name: "visaType",
    nameError: "visaTypeError",
    label: "Visa Type",
    placeholder: "Visa Type",
    options: [
      { label: "Tourist", value: "tourist" },
      { label: "Business", value: "business" },
      { label: "Work", value: "work" },
      { label: "Student", value: "student" },
      { label: "enjaz", value: "Enjaz Services" },
      { label: "family", value: "Family" },
    ],
  },
  {
    type: "autocomplete",
    name: "nationality",
    nameError: "nationalityError",
    label: "Nationality",
  },
  {
    type: "text",
    name: "preferredDestination",
    nameError: "preferredDestinationError",
    label: "Preferred Destination",
    placeholder: "Enter your destination",
  },
  {
    type: "textarea",
    name: "reason",
    nameError: "reasonError",
    label: "Reason",
    placeholder: "Enter your reason for applying",
  },
];
