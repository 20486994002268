import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { VisaSlotSettingModel } from "_models/data/data.visaSlotSettings.model";
import { ErrorVisaSlotSettingModel } from "_models/error/error.visaSlotSettings.model";
import { ValidateVisaSlotSetting } from "functions/validators";
import { GetRequest, PostRequest } from "functions/http";
import { VISA_TIME_SETTINGS, GET_VISA_TIME_SETTINGS } from "config/endpoints";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import set from "date-fns/set";
const VisaSlotTimeSettings = () => {
  const toggleLoading = useToggleLoading();
  const [error, setError] = useState(new ErrorVisaSlotSettingModel());
  const [state, setState] = useState(new VisaSlotSettingModel());
  const [refresh, setRefresh] = useState(false);

  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_VISA_TIME_SETTINGS)
        .then((res) => {
          if (res.data.status) {
            const _DATA = res.data.data;
            const constant = {
              duration: _DATA.duration,
              totalSlots: _DATA.totalSlots,
              applicationPerSlot: _DATA.applicationPerSlot,
              breakDuration: _DATA.breakTime.forMinutes,
              startingTime: set(new Date(), {
                hours: _DATA.startingTime.hour,
                minutes: _DATA.startingTime.minute,
              }),
              breakTime: set(new Date(), {
                hours: _DATA.breakTime.from.hour,
                minutes: _DATA.breakTime.from.minute,
              }),
            };
            setState(new VisaSlotSettingModel(constant));
          } else {
            // toast.error(res?.data?.msg);
          }
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [refresh]);
  const handleSubmit = () => {
    const isValid = ValidateVisaSlotSetting(state);
    if (isValid.valid) {
      const data = {
        duration: state.duration,
        totalSlots: state.totalSlots,
        startingTime: {
          hour: getHours(state.startingTime),
          minute: getMinutes(state.startingTime),
        },
        breakTime: {
          from: {
            hour: getHours(state.breakTime),
            minute: getMinutes(state.breakTime),
          },
          forMinutes: state.breakDuration,
        },
        applicationPerSlot: state.applicationPerSlot,
      };
      toggleLoading(true);
      PostRequest(VISA_TIME_SETTINGS, data)
        .then((res) => {
          if (res.data.status) {
            toast.success("Visa slot settings updated.");
            setRefresh(!refresh);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  return (
    <section className="mt-5">
      <Typography variant="h5">Visa Slot Time Settings</Typography>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Enter start time"
              onChange={(newValue) => {
                setState({ ...state, startingTime: newValue });
              }}
              value={state.startingTime}
              name="startingHour"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error.startingTimeError !== ""}
                  helperText={error.startingTimeError}
                  onFocus={() => handleFocus("startingTimeError")}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label="Enter break start time"
              onChange={(newValue) => {
                setState({ ...state, breakTime: newValue });
              }}
              value={state.breakTime}
              name="startingHour"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error.breakTimeError !== ""}
                  helperText={error.breakTimeError}
                  onFocus={() => handleFocus("breakTimeError")}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TextField
            label="Total Break In Minutes"
            fullWidth
            type="number"
            placeholder="Enter Break duration in minutes"
            variant="outlined"
            error={error.breakDurationError !== ""}
            helperText={error.breakDurationError}
            onFocus={() => handleFocus("breakDurationError")}
            value={state.breakDuration}
            name="breakDuration"
            onChange={(ev) => handleChange(ev, "breakDuration")}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            label="Slot Duration"
            fullWidth
            type="number"
            placeholder="Enter Slot duration in minutes"
            variant="outlined"
            error={error.durationError !== ""}
            helperText={error.durationError}
            onFocus={() => handleFocus("durationError")}
            value={state.duration}
            name="duration"
            onChange={(ev) => handleChange(ev, "duration")}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            label="Total Slots"
            fullWidth
            type="number"
            placeholder="Enter Total Slots "
            variant="outlined"
            error={error.totalSlotsError !== ""}
            helperText={error.totalSlotsError}
            onFocus={() => handleFocus("totalSlotsError")}
            value={state.totalSlots}
            name="totalSlots"
            onChange={(ev) => handleChange(ev, "totalSlots")}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            label="Application Per Slot"
            fullWidth
            type="number"
            placeholder="Enter Total Slots "
            variant="outlined"
            error={error.applicationPerSlotError !== ""}
            helperText={error.applicationPerSlotError}
            onFocus={() => handleFocus("applicationPerSlotError")}
            value={state.applicationPerSlot}
            name="applicationPerSlot"
            onChange={(ev) => handleChange(ev, "applicationPerSlot")}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button variant="contained" size="large" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </section>
  );
};

export default VisaSlotTimeSettings;
