import { useState, useEffect } from "react";
import { PostRequest } from "functions/http";
import { useSelector } from "react-redux";
const usePostQuery = (page, pageSize, url, _data) => {
  const [rowCount, setRowCount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const refresh = useSelector((data) => data.refresh.status);

  useEffect(() => {
    let active = true;
    setRowCount(undefined);
    let fetchData = async () => {
      if (!active) {
        return;
      }
      setIsLoading(true);
      PostRequest(`${url}&page=${page + 1}`, _data)
        .then((res) => {
          if (res?.data?.status) {
            setData(res?.data?.data);
            setRowCount(res?.data?.count);
          } else {
            setData([]);
            setRowCount(0);
            // toast.error(res?.data?.msg || "Something went wrong.");
          }
        })
        .catch((er) => {
          console.log("er", er);
          setData([]);
          setRowCount(0);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => setIsLoading(false));
    };
    fetchData();
    return () => {
      fetchData = null;
      active = false;
    };
  }, [page, pageSize, refresh, url, _data]);
  return { isLoading, data, rowCount };
};
export default usePostQuery;
