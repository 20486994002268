import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import HelpImage from "assets/img/static/bg/saudi-arabia-help.png";
import HelpBgImage from "assets/img/static/bg/saudi-arabia-help-bg.png";
import EnjazImage from "assets/img/static/bg/saudi-arabia-help-enjaz.png";

const SaudiArabiaHelp = () => {
  return (
    <Box
      color="white.main"
      sx={{
        backgroundColor: ({ palette }) => palette?.primary?.main,
        mt: "-1px",
      }}
    >
      <Box className="container">
        <Grid container position="relative">
          <Grid item xs={12} lg={7}>
            <Box pt={10}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  display="flex"
                  justifyContent="center"
                >
                  <Box className="resImg">
                    <img src={EnjazImage} alt="Enjaz" />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box>
                    <Typography
                      variant="body1"
                      textTransform="uppercase"
                      fontWeight={500}
                      letterSpacing={3}
                    >
                      HOW WE HELP YOU WITH
                    </Typography>

                    <Typography
                      variant="h4"
                      fontWeight={600}
                      fontSize={60}
                      my={1}
                    >
                      Enjaz services
                    </Typography>

                    <Typography variant="body1" fontWeight={400} my={2}>
                      At Express Visa Facilitation Service Center, we are proud to offer
                      Enjaz services to help our clients with their visa
                      applications to Saudi Arabia. Enjaz is a service provided
                      by the Saudi Ministry of Foreign Affairs that allows
                      individuals to submit their visa applications online,
                      track the status of their applications, and receive
                      updates about their applications via email or SMS.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            display="flex"
            alignItems="flex-end"
            position="relative"
          >
            <Box
              sx={{
                backgroundImage: `url(${HelpBgImage})`,
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundRepeat: " no-repeat",
                backgroundPosition: "center",
              }}
            />
            <Box className="resImg" zIndex={9} maxWidth="70%" margin="auto">
              <img src={HelpImage} alt="Help" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SaudiArabiaHelp;
