import { Box, Grid, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";
import React from "react";

import FAQImage from "assets/img/static/bg/faq-top.png";

const FaqHeader = () => {
  return (
    <Box className="container" color="black.main">
      <Grid container alignItems="center">
        <Grid item xs={12} md={6}>
          <Box py={5}>
            <NavigateToBack />

            <Title
              title="Frequently Asked Questions"
              fontFamilyClass="fm-poppins"
            />
            <Typography variant="body1" fontWeight={400} mb={1} mt={2}>
              Get answered to your most of the question
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="flex-end">
          <Box className="resImg">
            <img src={FAQImage} alt="FAQ" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FaqHeader;
