export class ErrorVisaAssistantModel {
  userError;
  nameError;
  emailError;
  phoneError;
  visaTypeError;
  nationalityError;
  preferredDestinationError;
  reasonError;

  constructor(data = {}) {
    this.userError = data.userError || "";
    this.nameError = data.nameError || "";
    this.emailError = data.emailError || "";
    this.phoneError = data.phoneError || "";
    this.visaTypeError = data.visaTypeError || "";
    this.nationalityError = data.nationalityError || "";
    this.preferredDestinationError = data.preferredDestinationError || "";
    this.reasonError = data.reasonError || "";
  }
}
