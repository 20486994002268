import NotFoundImg from "assets/img/static/icon/notFound.png";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Pic from "assets/img/static/bg/notfound.png";
const NoResult = ({ size, height = "auto", ...props }) => {
  const isLoading = useSelector((state) => state.progressBar.isProgressing);
  let parentSize = 200;
  switch (size) {
    case "xs":
      parentSize = 100;
      break;
    case "sm":
      parentSize = 250;
      break;
    case "md":
      parentSize = 400;
      break;
    case "lg":
      parentSize = 600;
      break;
    case "xl":
      parentSize = 720;
      break;
    case "auto":
      parentSize = "auto";
      break;
    default:
      parentSize = "auto";
      break;
  }
  const ParentStyle = {
    overflow: "hidden",
    height: height,
    width: `min(100%,${parentSize}px)`,
    bgcolor: "primary.main",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: 4,
  };
  const imgStyle = {
    width: "55%",
    verticalAlign: "baseline",
  };
  return (
    <>
      {isLoading ? (
        <Box className="p-center" height="100vh">
          <Typography variant="h5">Loading...</Typography>
        </Box>
      ) : (
        <Box sx={ParentStyle} {...props}>
          <img src={Pic} alt="Notfound" style={imgStyle} />
        </Box>
      )}
    </>
  );
};
export default NoResult;
