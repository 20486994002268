import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";
import NotarizationImage from "assets/img/static/bg/notarization-image.png";
import { Link } from "react-router-dom";

const NotarizationInfo = () => {
  const list = [
    {
      title: "Identifying the signer",
      description: `The notary public must verify the identity of the person signing the document. This may involve examining a government-issued ID, such as a driver's license or passport.`,
      link: "#",
    },
    {
      title: "Examining the document",
      description: `The notary public must ensure that the document is complete and properly executed. This includes verifying that all necessary signatures are present and that the document has been properly filled out.`,
      link: "#",
    },
    {
      title: "Determining the signer's willingness and understanding",
      description: `Technical translation services specialize in translating technical documents, such as user manuals, product specifications, and technical reports, to ensure that technical information is accurately conveyed to a global audience.`,
      link: "#",
    },
    {
      title: "Administering the oath or affirmation",
      description: `Depending on the type of document being notarized, the notary public may administer an oath or affirmation to the signer, in which the signer swears or affirms that the information in the document is true and correct.`,
      link: "#",
    },
    {
      title: "Affixing the notary seal",
      description: `Once the notary public has completed the above steps, they will affix their official seal to the document, indicating that the notarization has taken place.`,
      link: "#",
    },
  ];
  return (
    <>
      <Box className="container">
        <Box pt={5}>
          <Typography variant="body1" mb={3}>
            Notarization is a process in which a notary public, an official
            appointed by the government to serve as an impartial witness,
            verifies the identity of a person signing a document and ensures
            that the document is signed willingly and voluntarily. The notary
            public then affixes their official seal to the document, indicating
            that the notarization has taken place.
          </Typography>
          <Typography variant="body1">
            Notarization is often required for legal documents, such as deeds,
            contracts, and power of attorney documents, to provide evidence that
            the document has been properly executed and to prevent fraud. In
            some cases, notarization may also be required for documents that
            need to be submitted to a foreign country or to a government agency.
          </Typography>
        </Box>
        <Box py={5}>
          <Grid container spacing={3}>
            {list?.map(({ title, description, link }, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <StandardCard
                  title={title}
                  description={description}
                  link={link}
                  linkText="Apply Now"
                  titleColor="primary"
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                className="standard-card hovered d-flex flex-column justify-content-end"
                p={5}
                sx={{
                  backgroundImage: `url(${NotarizationImage})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Typography variant="h6" fontWeight={700} className="title">
                  Submit your <br /> application now
                </Typography>

                <Typography variant="body2" mt={3}>
                  <Link to={""}>Apply</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#121212" }} py={8} mb={5}>
        <Box className="container">
          <Typography variant="body1" color="white.main" fontWeight={400}>
            It is important to carefully review the requirements and
            instructions for the specific visa you are seeking, and to follow
            them closely. Submitting a complete and accurate application is
            essential for being considered for the visa. It may also be helpful
            to review visa application tips and best practices to increase your
            chances of success.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default NotarizationInfo;
