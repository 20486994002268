export class ErrorUserRegisterModel {
  nameError;
  emailError;
  passwordError;
  confirmPasswordError;
  phoneError;
  constructor(data = {}) {
    this.nameError = data.nameError || "";
    this.emailError = data.emailError || "";
    this.passwordError = data.passwordError || "";
    this.confirmPasswordError = data.confirmPasswordError || "";
    this.phoneError = data.phoneError || "";
  }
}
