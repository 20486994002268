export class ErrorVisaApplicationModel {
  fromCountryError;
  toCountryError;
  nameTitleError;
  nameError;
  passportNumberError;
  passportTypeError;
  nationalIdError;
  emailError;
  phoneError;
  passportPicError;
  nationalIdPicError;
  interpolCertificatePicError;
  applicationTypeError;
  enjazPicError;
  medicalGccPicError;
  wakalaPicError;
  embassyFormPicError;
  contracPicError;
  yellowBookPicError;
  constructor(data = {}) {
    this.fromCountryError = data.fromCountryError || "";
    this.toCountryError = data.toCountryError || "";
    this.nameTitleError = data.nameTitleError || "";
    this.nameError = data.nameError || "";
    this.phoneError = data.phoneError || "";
    this.emailError = data.emailError || "";
    this.nationalIdError = data.nationalIdError || "";
    this.passportNumberError = data.passportNumberError || "";
    this.passportTypeError = data.passportTypeError || "";
    this.passportPicError = data.passportPicError || "";
    this.nationalIdPicError = data.nationalIdPicError || "";
    this.interpolCertificatePicError = data.interpolCertificatePicError || "";
    this.applicationTypeError = data.applicationTypeError || "";

    this.enjazPicError = data.enjazPicError || "";
    this.medicalGccPicError = data.medicalGccPicError || "";
    this.wakalaPicError = data.wakalaPicError || "";
    this.embassyFormPicError = data.embassyFormPicError || "";
    this.contracPicError = data.contracPicError || "";
    this.yellowBookPicError = data.yellowBookPicError || "";
  }
}
