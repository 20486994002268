import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
const Search = ({
  value,
  onChange = () => {},
  placeholder,
  iconColor,
  inputStyle,
  ...props
}) => {
  return (
    <Box className="custom-search" {...props}>
      <SearchIcon color={iconColor || "white"} />
      <input
        type="search"
        value={value}
        onChange={onChange}
        placeholder={placeholder || "Search"}
        style={inputStyle}
      />
    </Box>
  );
};

export default Search;
