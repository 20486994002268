export class VisaAssistantApplicationModel {
  user;
  name;
  email;
  phone;
  visaType;
  nationality;
  preferredDestination;
  reason;

  constructor(data = {}) {
    this.user = data.user || "";
    this.name = data.name || "";
    this.email = data.email || "";
    this.phone = data.phone || "";
    this.visaType = data.visaType || "";
    this.nationality = data.nationality || null;
    this.preferredDestination = data.preferredDestination || "";
    this.reason = data.reason || "";
  }
}
