import { useCallback, useEffect, useState } from "react";

import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import LaptopPic from "assets/img/static/bg/laptop.png";
import LogoIcon from "assets/img/static/icon/logo_icon.png";
import { Link, useSearchParams } from "react-router-dom";

import { ErrorUserRegisterModel } from "_models/error/errors.userRegister.model";
import { UserRegisterModel } from "_models/data/data.userRegister.model";
import { ValidateRegistrationForm } from "functions/validators";
import { PostRequest } from "functions/http";
import { SIGNUP } from "config/endpoints";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import RouteConstant from "navigation/constant";

const UserRegister = () => {
  //local state data
  const [error, setError] = useState(new ErrorUserRegisterModel());
  const [state, setState] = useState(new UserRegisterModel());

  const toggleLoading = useToggleLoading();
  const [search, setSearch] = useSearchParams();
  //input handler
  const handleChange = (event, name) => {
    if (name === "email") {
      setState({ ...state, [name]: event?.target?.value.trim() });
    } else {
      setState({ ...state, [name]: event.target.value });
    }
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  useEffect(() => {
    const type = search.get("type");
    if (type) {
      setState((state) => ({ ...state, type: type }));
    }
  }, [search]);
  const handleSignup = useCallback(() => {
    const isValid = ValidateRegistrationForm(state);
    if (isValid.valid) {
      toggleLoading(true);
      PostRequest(SIGNUP, state)
        .then((res) => {
          if (res.data.status) {
            toast.success(
              "Successfully registered. We have sent an email. Please verify your email."
            );
            setState(new UserRegisterModel());
          }
        })
        .catch((er) => {
          console.log(HTTP_ERROR(er));
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  }, [state]);

  useEffect(() => {
    const enterHit = (ev) => {
      if (ev.keyCode === 13 || ev.which === 13) {
        handleSignup();
      }
    };
    window.addEventListener("keydown", enterHit);
    return () => {
      window.removeEventListener("keydown", enterHit);
    };
  }, [handleSignup]);
  return (
    <section className="register-page ">
      <Grid container spacing={3} sx={{ minHeight: "calc(100vh - 80px)" }}>
        <Grid md={6} xs={12} item>
          <div className="left-carousel-container p-center px-3 px-lg-0">
            <Swiper
              modules={[Pagination]}
              className="lef-auth-swiper"
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide>
                <Box>
                  <div className="text-center resImg">
                    <img src={LaptopPic} alt="laptop" />
                  </div>
                  <Box>
                    <Typography
                      variant="h6"
                      color="white.main"
                      textAlign="center"
                      mb={2}
                    >
                      Apply VISA From Anywhere
                    </Typography>
                    <Typography
                      variant="body2"
                      maxWidth={400}
                      color="white.main"
                      mx="auto"
                      textAlign="center"
                      mb={14}
                    >
                      Apply for Visas Anywhere with Our Easy-to-Use Online
                      Application System. Submit Your Application in Minutes and
                      Get Your Visa Quickly and Hassle-Free. No Matter Where
                      You're Going, We've Got You Covered!
                    </Typography>
                  </Box>
                </Box>
              </SwiperSlide>
             
            </Swiper>
          </div>
        </Grid>
        <Grid md={6} xs={12} item>
          <Box px={3} py={4}>
            <Box textAlign="center">
              <div className="resImg text-center">
                <Link to={RouteConstant.HOME}>
                  <img src={LogoIcon} alt="logo icon" width={80} />
                </Link>
              </div>
              <Typography variant="h5" mt={2} fontWeight={500}>
                Individual Register
              </Typography>
            </Box>
            <Box mt={6}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Name
              </Typography>
              <TextField
                label="Enter Name"
                variant="outlined"
                fullWidth
                type="text"
                id="signup_name"
                error={error.nameError !== ""}
                helperText={error.nameError}
                onFocus={() => handleFocus("nameError")}
                name="name"
                value={state.name}
                onChange={(ev) => handleChange(ev, "name")}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Email
              </Typography>
              <TextField
                label="Enter email"
                variant="outlined"
                fullWidth
                type="email"
                error={error.emailError !== ""}
                helperText={error.emailError}
                onFocus={() => handleFocus("emailError")}
                id="signup_email"
                name="email"
                value={state.email}
                onChange={(ev) => handleChange(ev, "email")}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Phone
              </Typography>
              <TextField
                label="Enter phone number"
                variant="outlined"
                fullWidth
                error={error.phoneError !== ""}
                helperText={error.phoneError}
                onFocus={() => handleFocus("phoneError")}
                name="phone"
                value={state.phone}
                onChange={(ev) => handleChange(ev, "phone")}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Password
              </Typography>
              <TextField
                label="Enter password"
                variant="outlined"
                fullWidth
                type="password"
                name="password"
                error={error.passwordError !== ""}
                helperText={error.passwordError}
                onFocus={() => handleFocus("passwordError")}
                id="password"
                value={state.password}
                onChange={(ev) => handleChange(ev, "password")}
              />
            </Box>
            <Box mt={3}>
              <Typography variant="subtitle1" mb={1} fontWeight={600}>
                Confirm Password
              </Typography>
              <TextField
                label="Enter confirm password"
                variant="outlined"
                fullWidth
                type="password"
                error={error.confirmPasswordError !== ""}
                helperText={error.confirmPasswordError}
                onFocus={() => handleFocus("confirmPasswordError")}
                id="password-confirm"
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={(ev) => handleChange(ev, "confirmPassword")}
              />
            </Box>
            <FormControlLabel
              sx={{ mt: 3 }}
              control={<Checkbox defaultChecked />}
              label="Remember me"
            />
            <div className="p-center">
              <Button
                variant="contained"
                sx={{ width: 300, my: 4, mx: "auto" }}
                size="large"
                onClick={handleSignup}
              >
                Register
              </Button>
            </div>
            <Box mt={3} textAlign="center">
              I already have an account?{" "}
              <Link to={RouteConstant.LOGIN} className="dark-link fw-bold">
                Log in
              </Link>
            </Box>
            <Box mt={3} textAlign="center">
              <Link
                to={RouteConstant.REGISTER + "?type=business"}
                className="dark-link fw-bold"
              >
                Agency Registration
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default UserRegister;
