import { useSearchParams } from "react-router-dom";

import AgencyRegister from "./component/agencyRegister";
import UserRegister from "./component/userRegister";
const Register = () => {
  const [search, setSearch] = useSearchParams();

  if (search.get("type") === "business") {
    return <AgencyRegister />;
  }
  return <UserRegister />;
};

export default Register;
