import axios from "axios";
// AXIOS INSTANCE
import { BASE_URL } from "config/endpoints";
console.log("BASE_URL", BASE_URL);
const axiosClient = axios.create({
  baseURL: BASE_URL,
});

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
//GENERIC REQUEST FUNCTION THAT USES AXIOS
export const PostRequest = (Url, Data = {}, isFormData = false) => {
  let token = localStorage.getItem("token");
  let _headers = {
    Authorization: token ? `Bearer ${token}` : null,
  };
  if (isFormData) {
    _headers["Content-Type"] = "multipart/form-data";
  }
  return axiosClient({
    method: "post",
    url: Url,
    data: Data,
    headers: _headers,
  });
};
export const GetRequest = (Url, Data = {}) => {
  let token = localStorage.getItem("token");
  return axiosClient({
    method: "get",
    url: Url,
    data: Data,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });
};
export const BLOB = (Method, Url, Data = {}) => {
  let token = localStorage.getItem("token");
  return axiosClient({
    method: Method,
    responseType: "blob",
    url: Url,
    data: Data,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });
};

//GENERIC ERROR FUNCTION FOR PARSING ERRORS
export const HTTP_ERROR = (ERROR) => {
  if (ERROR.response) {
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      console.log(ERROR.response.data);
    }
    if (ERROR.response.status >= 500) {
      return "An unexpected error has occurred. 500 internal server error.";
    }
    // else if (ERROR.response.status === 401) {
    //   //   openUnauthorizedModal();
    //   return "Token Expired";
    // }
    else {
      return ERROR.response.data
        ? RES_ERROR(ERROR.response.data)
        : "An unexpected error has occurred";
    }
  } else {
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      console.log(ERROR);
    }
    return "An unexpected error has occurred";
  }
};

export const RES_ERROR = (ERROR) => {
  if (ERROR.msg) return ERROR.msg;
  else {
    return "An unexpected error has occurred";
  }
};
