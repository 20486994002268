import RenderCellExpand from "component/grid/expandCell";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
import Chip from "@mui/material/Chip";

const UserColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: RenderCellExpand,
  },

  {
    field: "email",
    minWidth: 200,
    headerName: "Email",
    renderCell: RenderCellExpand,
  },
  {
    field: "phone",
    minWidth: 150,
    headerName: "Phone",
    renderCell: RenderCellExpand,
  },
  {
    field: "address",
    minWidth: 150,
    headerName: "Address",
    renderCell: RenderCellExpand,
  },
  {
    field: "role",
    headerName: "Role",
    renderCell: ({ value }) => {
      return <span className="text-capitalize">{value}</span>;
    },
  },
  {
    field: "isActive",
    minWidth: 150,
    headerName: "Ban Status",
    renderCell: ({ value }) => {
      return value ? (
        <Chip color="success" label="Not Banned" size="small" />
      ) : (
        <Chip color="warning" label="Banned" size="small" />
      );
    },
  },

  {
    field: "detail",
    headerName: "All Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.CREATE_USER + "?id=" + value}>Edit</Link>
    ),
  },
];

export default UserColumn;
