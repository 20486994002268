import Navbar from "parts/navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";

import RouteConstant from "navigation/constant";
import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useEffect, useState } from "react";
import {
  GET_ALL_BLOG_BY_SEARCH,
  GET_ALL_BLOG_BY_FEATURED,
  DOMAIN,
} from "config/endpoints";
import PaginationMui from "@mui/material/Pagination";
import Search from "component/search";
import NoResult from "parts/noResult";

const Blog = () => {
  const toggleLoading = useToggleLoading();
  const [searchKey, setSearchKey] = useState("");
  const [allBlog, setAllBlogs] = useState([]);
  const [featuredBlog, setFeaturedBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [carouselItem, setCarouselItem] = useState([]);
  useEffect(() => {
    if (featuredBlog.length === 0) {
      const k = allBlog.slice(0, 4);
      setCarouselItem(k);
    } else {
      setCarouselItem(featuredBlog);
    }
  }, [allBlog, featuredBlog]);
  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_ALL_BLOG_BY_SEARCH + "?searchKey=" + searchKey)
        .then((res) => {
          if (res.data?.status) {
            setAllBlogs(res?.data?.data);
            setCount(res.data.count);
          } else {
            setAllBlogs([]);
          }
        })
        .catch((er) => {
          setAllBlogs([]);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [searchKey]);
  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_ALL_BLOG_BY_FEATURED)
        .then((res) => {
          if (res.data?.status) {
            setFeaturedBlogs(res?.data?.data);
          } else {
            setFeaturedBlogs([]);
          }
        })
        .catch((er) => {
          setFeaturedBlogs([]);
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, []);
  return (
    <section className="blog-page">
      <Navbar />
      <section className="wrapper container g-0 content-height">
        <Box height={40} w={1} mb={2} display="flex" justifyContent="flex-end">
          <Search
            onChange={(ev) => setSearchKey(ev.target.value)}
            value={searchKey}
            placeholder="Search Blog"
          />
        </Box>

        <div className="news-feed-layout ">
          {carouselItem.length > 0 ? (
            <Swiper
              modules={[Pagination]}
              className="news-feed-swiper"
              pagination={{
                clickable: true,
              }}
            >
              {carouselItem.map((item, index) => {
                return (
                  <SwiperSlide key={item._id}>
                    <div className="new-feed-banner-container">
                      <img src={DOMAIN + item?.image?.path} alt="item" />
                      <div className="banner-layer"></div>
                      <div className="details">
                        <h1 className="hed1">Blog</h1>
                        <div>
                          <div className="badge-container">
                            {item.isFeatured && (
                              <span className="c-badge">Featured</span>
                            )}
                            {item.isAd && (
                              <span className="c-badge active">AD</span>
                            )}
                          </div>
                          <h1 className="hed2">
                            Now get your visa at your doorstep with new express{" "}
                          </h1>
                          <Box
                            width="80%"
                            height={30}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            color="#fff"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></Box>
                          <p className="desc"></p>
                          <Link
                            to={RouteConstant.BLOG_DETAILS + "/" + item._id}
                            className="banner-link"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <NoResult />
          )}
        </div>
        <Grid container spacing={3} mt={4}>
          {allBlog.length > 0 ? (
            allBlog.map((blog) => {
              return (
                <Grid item xs={12} md={4} key={blog._id}>
                  <Card sx={{ position: "relative", cursor: "pointer" }}>
                    <Link
                      to={RouteConstant.BLOG_DETAILS + "/" + blog._id}
                      className="stretched-link"
                    />
                    <CardMedia
                      component="img"
                      height="140"
                      image={DOMAIN + blog?.image?.path}
                      alt="news 1"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {blog.title}
                      </Typography>
                      <Box
                        height={100}
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        color="#000"
                        dangerouslySetInnerHTML={{
                          __html: blog.description,
                        }}
                      ></Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <NoResult />
          )}
        </Grid>
        <Box className="p-center">
          <PaginationMui
            count={Math.ceil(count / 20)}
            page={page}
            onChange={(ev, va) => setPage(va)}
          />
        </Box>
      </section>
    </section>
  );
};

export default Blog;
