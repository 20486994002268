import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { VisaAssistantApplicationModel } from "_models/data/data.assistantApplication.model";
import { ErrorVisaAssistantModel } from "_models/error/errors.assistantApplication.model";
import React, { Fragment, useState } from "react";
import { visaAssistanceFormInputs } from "./visaAssistanceFormInputs";
import Countries from "state/data/countries";
import RouteConstant from "navigation/constant";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { useToggleLoading } from "hooks";
import { useSelector } from "react-redux";
import { ValidateVisaAssistant } from "functions/validators";
import { HTTP_ERROR, PostRequest } from "functions/http";
import { CREATE_VISA_ASSISTANT_APPLICATION } from "config/endpoints";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const VisaAssistanceForm = () => {
  const toggleLoading = useToggleLoading();
  const [error, setError] = useState(new ErrorVisaAssistantModel());
  const [state, setState] = useState(new VisaAssistantApplicationModel());
  const [term, setTerm] = useState(true);
  const [getUpdate, setGetUpdate] = useState(true);
  const userId = useSelector((state) => state.auth.user.id);
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };
  const handleSubmit = () => {
    const isValid = ValidateVisaAssistant(state);
    if (!term) {
      toast.error("You have to agree our term & policy.");
      return;
    }
    if (isValid.valid) {
      toggleLoading(true);
      const data = {
        ...state,
        nationality: state?.nationality?.code,
        wantToReceiveUpdate: getUpdate,
        user: userId,
      };
      PostRequest(CREATE_VISA_ASSISTANT_APPLICATION, data)
        .then((res) => {
          if (res.data.status) {
            toast.success("Application submitted");
            setState(new VisaAssistantApplicationModel());
          } else {
            toast.error(res.data.msg || "Something went wrong.");
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  return (
    <>
      {visaAssistanceFormInputs?.map(
        ({ type, name, nameError, label, placeholder, options }, i) => {
          return (
            <Fragment key={i}>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel
                  margin="dense"
                  htmlFor={name}
                  sx={{ position: "unset", transform: "none", marginBottom: 1 }}
                >
                  {label}
                </InputLabel>
                {type === "text" ||
                type === "textarea" ||
                type === "email" ||
                type === "number" ? (
                  <>
                    <TextField
                      type={type}
                      id={name}
                      name="name"
                      fullWidth
                      placeholder={placeholder}
                      error={error?.[nameError] !== ""}
                      helperText={error?.[nameError]}
                      onChange={(ev) => handleChange(ev, name)}
                      onFocus={() => handleFocus(nameError)}
                      value={state?.[name]}
                      multiline={type === "textarea"}
                      rows={4}
                      autoComplete="off"
                    />
                  </>
                ) : type === "select" ? (
                  <>
                    <Select
                      labelId={name}
                      id={name}
                      value={state?.[name]}
                      onChange={(ev) => handleChange(ev, name)}
                      error
                    >
                      {options?.map((option, idx) => (
                        <MenuItem value={option?.value} key={idx}>
                          {option?.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.[nameError] && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {error?.[nameError]}
                      </FormHelperText>
                    )}
                    {name === "visaType" ? (
                      <Typography variant="body2" mt={1}>
                        <Link to={""} style={{ textDecoration: "underline" }}>
                          Learn More About Visa Types{" "}
                        </Link>
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </>
                ) : type === "autocomplete" ? (
                  <>
                    <Autocomplete
                      disablePortal
                      options={Countries}
                      value={state?.[name]}
                      onChange={(e, value) =>
                        setState({ ...state, [name]: value })
                      }
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={label}
                          error={error?.[nameError] !== ""}
                          onFocus={() => handleFocus(nameError)}
                          helperText={error?.[nameError]}
                        />
                      )}
                    />
                  </>
                ) : (
                  <></>
                )}
              </FormControl>
            </Fragment>
          );
        }
      )}
      <Box color="grey.500">
        <Typography variant="body1" mb={3}>
          ExpressVFS do not share your details without your
          permission
        </Typography>
        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                value={term}
                checked={term}
                onChange={(ev) => setTerm(ev.target.checked)}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
              />
            }
            label={
              <Typography variant="body1">
                I Agree to Express ExpressVFS's{" "}
                <Link
                  to={RouteConstant.TERM_CONDITION}
                  style={{ color: "#49DEFF" }}
                >
                  Terms
                </Link>{" "}
                and
                <Link
                  to={RouteConstant.PRIVACY_POLICY}
                  style={{ color: "#49DEFF" }}
                >
                  {" "}
                  privacy policy.
                </Link>
              </Typography>
            }
          />
        </Box>
        <Box mb={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={getUpdate}
                value={getUpdate}
                onChange={(ev) => setGetUpdate(ev.target.checked)}
                icon={<RadioButtonUncheckedOutlinedIcon />}
                checkedIcon={<RadioButtonCheckedOutlinedIcon />}
              />
            }
            label={
              <Typography variant="body1">
                I would like to receive updates and offers from Express Data
                Analytics
              </Typography>
            }
          />
        </Box>

        <Button
          variant="contained"
          fullWidth
          size="large"
          color="primary"
          onClick={handleSubmit}
          disableElevation
          className="rounded-2"
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default VisaAssistanceForm;
