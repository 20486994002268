import { Box, Grid, Typography } from "@mui/material";
import StandardCard from "component/card/standardCard";
import React from "react";

const ApplicationProcessInfo = () => {
  const list = [
    {
      title: "Determine your visa eligibility",
      description: `The first step in the visa application process is to determine whether you are eligible to apply for a visa. This may involve reviewing the requirements and restrictions for the specific type of visa you are seeking, as well as the requirements of the country you will be visiting.`,
      link: "#",
    },
    {
      title: "Gather required documents",
      description: `You will need to gather all of the necessary documents required for your visa application. This may include a passport, birth certificate, marriage certificate, proof of employment or financial stability, and any other required documents.`,
      link: "#",
    },
    {
      title: "Complete the visa application form",
      description: `You will need to complete a visa application form, which typically requires information about your personal and travel details, as well as your purpose of travel.`,
      link: "#",
    },
    {
      title: "Pay the visa application fee",
      description: `Most visa applications require a fee, which must be paid in order to process the application.`,
      link: "#",
    },
    {
      title: "Schedule an interview",
      description: `Depending on the country and the type of visa you are seeking, you may be required to attend an interview at the embassy or consulate of the country you will be visiting.`,
      link: "#",
    },
    {
      title: "Await a decision",
      description: `After you have completed the application process, you will need to wait for a decision on your visa application. The processing time for visa applications can vary widely depending on the country and the type of visa being sought.`,
      link: "#",
    },
  ];
  return (
    <>
      <Box className="container">
        <Box pt={5}>
          <Typography variant="body1" mb={3}>
            The visa application process is the process of applying for a visa,
            which is a document that allows a foreign national to enter, stay
            in, or travel through a country for a specific purpose, such as
            tourism, work, study, or family reunification.
          </Typography>
          <Typography variant="body1">
            The visa application process can be complex and time-consuming, and
            it varies depending on the country and the type of visa being
            sought.
          </Typography>
        </Box>
        <Box py={5}>
          <Grid container spacing={3}>
            {list?.map(({ title, description, link }, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <StandardCard
                  title={title}
                  description={description}
                  link={link}
                  linkText="Apply Now"
                  titleColor="primary"
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: ({ palette }) => palette?.primary?.main }}
        py={8}
        my={5}
      >
        <Box className="container">
          <Typography variant="body1" color="white.main" fontWeight={400}>
            It is important to carefully review the requirements and
            instructions for the specific visa you are seeking, and to follow
            them closely. Submitting a complete and accurate application is
            essential for being considered for the visa. It may also be helpful
            to review visa application tips and best practices to increase your
            chances of success.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationProcessInfo;
