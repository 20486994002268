import { Box, Typography } from "@mui/material";
import NavigateToBack from "component/navigateToBack";
import Title from "component/title";
import React from "react";
import { Link } from "react-router-dom";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import RouteConstant from "navigation/constant";

const VisaAssistanceInfo = () => {
  return (
    <Box>
      <Box color="white.main">
        <Title
          title="Visa application <br /> Assistance"
          color="inherit"
          fontWeight={600}
          fontSize={42}
        />
        <Typography variant="body1" mt={3}>
          We can help you fill out the necessary forms and provide guidance on
          the documents that are required for your visa application. We will
          review your application for accuracy and completeness, and submit it
          on your behalf to ensure that it is processed as quickly as possible.
        </Typography>
      </Box>

      <Box pt={30} fontWeight={400}>
        <Typography variant="body1" mb={3}>
          Welcome to ExpressVFS, your trusted source for visas to destinations
          around the world. At ExpressVFS we are dedicated to providing our
          clients with fast, efficient, and reliable service to help them obtain
          the visas they need to travel abroad.
        </Typography>
        <Typography variant="body1" mb={3}>
          Our team of experienced professionals is here to guide you through the
          visa application process, and we are available to answer any questions
          you may have. We offer a wide range of visa services, including
          assistance with filling out forms, checking documents for accuracy,
          and submitting applications on your behalf.
        </Typography>
        <Typography variant="body1" mb={3}>
          Whether you are traveling for business, leisure, or education, we can
          help you obtain the visa you need. We offer visas for destinations
          around the world, and we can help you determine the best visa option
          for your trip.
        </Typography>
        <Typography variant="body1" mb={3}>
          To learn more about the types of visas available and the requirements
          for each, please visit our website or contact us today. We look
          forward to helping you plan your trip and make it a success.
        </Typography>
        <Typography variant="body1" pt={3} fontWeight={600} color="primary">
          <Link to={RouteConstant.APPLY_VISA}>
            Apply For Visa <OpenInNewOutlinedIcon sx={{ fontSize: 16 }} />
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default VisaAssistanceInfo;
