import React from "react";

const LinkedinIcon = (props) => {
  return (
    <svg
      viewBox="0 0 20 19"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.94048 2.10613C4.94011 2.92044 4.44608 3.65322 3.69134 3.95893C2.9366 4.26465 2.07187 4.08225 1.5049 3.49775C0.93793 2.91324 0.78195 2.04335 1.1105 1.29827C1.43906 0.553191 2.18654 0.0817006 3.00048 0.106131C4.08155 0.138581 4.94097 1.02457 4.94048 2.10613ZM5.00048 5.58613H1.00048V18.1061H5.00048V5.58613ZM11.3205 5.58613H7.34048V18.1061H11.2805V11.5361C11.2805 7.8761 16.0505 7.5361 16.0505 11.5361V18.1061H20.0005V10.1761C20.0005 4.00613 12.9405 4.23613 11.2805 7.2661L11.3205 5.58613Z" />
    </svg>
  );
};

export default LinkedinIcon;
