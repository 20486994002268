import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: false,
};

export const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    setRefresh: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setRefresh } = refreshSlice.actions;

export default refreshSlice.reducer;
