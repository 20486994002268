import { Typography } from "@mui/material";
import React from "react";

const Title = ({
  variant,
  title,
  color,
  fontSize,
  fontWeight,
  fontFamilyClass,
}) => {
  return (
    <Typography
      variant={variant || "h1"}
      color={color || "primary"}
      fontSize={{ xs: 42, md: fontSize || 60 }}
      fontWeight={fontWeight || 800}
      className={fontFamilyClass || "fm-urbanist"}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};

export default Title;
