import SearchIcon from "@mui/icons-material/Search";
import { NavLink } from "react-router-dom";
import RouteConstant from "navigation/constant";
import { useSelector } from "react-redux";
import Role from "config/Role";
const AdminHeader = ({ hideSearch, onSearchChange = () => {} }) => {
  const role = useSelector((state) => state.auth.user.role);

  return (
    <section className="admin-header">
      <div className="left-side">
        <NavLink
          to={
            role === Role.ADMIN || role === Role.MANAGER
              ? RouteConstant.ADMIN_DASHBOARD
              : role === Role.STAFF
              ? RouteConstant.STAFF_DASHBOARD
              : RouteConstant.EMBASSY_DASHBOARD
          }
          className={({ isActive }) =>
            isActive ? `header-link active` : "header-link"
          }
        >
          Home
        </NavLink>
        <NavLink
          to={RouteConstant.TRACK_BY_ADMIN}
          className={({ isActive }) =>
            isActive ? `header-link active` : "header-link"
          }
        >
          Track Application
        </NavLink>
        {role === Role.ADMIN || role === Role.MANAGER ? (
          <NavLink
            to={RouteConstant.CUSTOMER_COMPLAIN}
            className={({ isActive }) =>
              isActive ? `header-link active` : "header-link"
            }
          >
            Customer Complain
          </NavLink>
        ) : null}
        {role === Role.ADMIN ? (
          <NavLink
            to={RouteConstant.PAYMENTS}
            className={({ isActive }) =>
              isActive ? `header-link active` : "header-link"
            }
          >
            Payment
          </NavLink>
        ) : null}
      </div>
      {hideSearch ? null : (
        <div className="right-side">
          <div className="search-box">
            <SearchIcon color="black.main" />
            <input
              onChange={(ev) => onSearchChange(ev.target.value)}
              placeholder="Search application "
              type="search"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default AdminHeader;
