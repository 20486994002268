import { Link, NavLink } from "react-router-dom";
import { Fragment, useState } from "react";
import LogoPic from "assets/img/static/icon/logo3.png";
import TransparentLogoPic from "assets/img/static/icon/logo.png";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RouteConstant from "navigation/constant";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "state/slice/user";
import { useToggleLoading } from "hooks";
import { GetRequest } from "functions/http";
import { LOGOUT } from "config/endpoints";
import Role from "config/Role";
import { Channel } from "functions/helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StandardNavbar = ({ transparent }) => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const toggleLoading = useToggleLoading();
  const handleLogout = () => {
    handleClose();
    Channel.postMessage("logout");
    toggleLoading(true);
    GetRequest(LOGOUT(user._id, localStorage.getItem("token"))).finally(() =>
      toggleLoading(false)
    );
    localStorage.removeItem("token");
    Dispatch(setLogout());
  };

  const navList = [
    { label: "Home", link: RouteConstant?.HOME },
    { label: "About", link: RouteConstant?.ABOUT_US },
    {
      label: "Services",
      link: "",
      submenu: [
        {
          label: "Visa application assistance",
          link: RouteConstant?.VISA_ASSISTANT,
        },
        {
          label: "Passport processing and renewal",
          link: RouteConstant?.PASSPORT_PROCESSING_AND_RENEWAL,
        },
        {
          label: "Translation services",
          link: RouteConstant?.TRANSLATION_SERVICE,
        },
        {
          label: "Notarization services",
          link: RouteConstant?.NOTARIZATION_SERVICE,
        },
        {
          label: "Visa Application process",
          link: RouteConstant?.APPLICATION_PROCESS,
        },
        {
          label: "Translation services",
          link: RouteConstant?.TRANSLATION_SERVICE,
        },
        {
          label: "Ticketing",
          link: RouteConstant?.TICKETING,
        },
      ],
    },
    { label: "Blog", link: RouteConstant?.BLOG },
    { label: "FAQ", link: RouteConstant?.FAQ },
  ];
  return (
    <section
      className={
        transparent ? "standard-navbar transparent" : "standard-navbar"
      }
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mid-sec">
            <div className="resImg" style={{ width: 180 }}>
              <Link to={RouteConstant.HOME}>
                <img
                  src={transparent ? TransparentLogoPic : LogoPic}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          {!mobileSize ? (
            <div className="left-sec">
              <List sx={{ p: 0 }} className="d-flex align-items-center">
                {navList?.map(({ label, link, submenu }, i) => (
                  <MenuItem key={i}>
                    {label && link ? (
                      <NavLink
                        key={i}
                        to={link}
                        className={({ isActive }) =>
                          isActive ? "active home-nav-item" : "home-nav-item"
                        }
                      >
                        {label}
                      </NavLink>
                    ) : submenu ? (
                      <>
                        <a>
                          {label}{" "}
                          <ExpandMoreIcon color="inherit" fontSize="small" />
                        </a>
                        <List>
                          {submenu?.map((sub, idx) => (
                            <MenuItem key={idx}>
                              <Link to={sub?.link}>{sub?.label}</Link>
                            </MenuItem>
                          ))}
                        </List>
                      </>
                    ) : (
                      <></>
                    )}
                  </MenuItem>
                ))}

                <MenuItem>
                  {isLoggedIn ? (
                    <>
                      {user.role !== Role.AGENCY && user.role !== Role.BASIC ? (
                        <NavLink
                          to={
                            user.role === Role.ADMIN ||
                            user.role === Role.MANAGER
                              ? RouteConstant.ADMIN_DASHBOARD
                              : user.role === Role.STAFF
                              ? RouteConstant.STAFF_DASHBOARD
                              : RouteConstant.EMBASSY_DASHBOARD
                          }
                          className="home-nav-item"
                        >
                          Dashboard
                        </NavLink>
                      ) : null}
                    </>
                  ) : null}
                  {isLoggedIn ? (
                    <Button
                      color="inherit"
                      variant="outlined"
                      className="rounded-2"
                      onClick={handleLogout}
                    >
                      Logout
                    </Button>
                  ) : (
                    <>
                      <Link to={RouteConstant.ADMIN_LOGIN}>
                        <Button
                          color={transparent ? "secondary" : "inherit"}
                          variant={transparent ? "contained" : "outlined"}
                          className={transparent ? "rounded-0" : "rounded-2"}
                          sx={{
                            mr: 2,
                            backgroundColor: transparent
                              ? "#040404A6"
                              : "transparent",
                            "&:hover": {
                              backgroundColor: transparent
                                ? "#040404A6"
                                : "transparent",
                            },
                          }}
                        >
                          Admin login
                        </Button>
                      </Link>
                      <Link to={RouteConstant.LOGIN}>
                        <Button
                          disableElevation
                          variant="contained"
                          className="rounded-2"
                        >
                          User Login
                        </Button>
                      </Link>
                    </>
                  )}
                </MenuItem>
              </List>
            </div>
          ) : (
            <IconButton
              onClick={handleClick}
              sx={{ color: transparent ? "#fff" : "inherit" }}
            >
              <MenuIcon color="inherit" />
            </IconButton>
          )}

          {/* Mobile size */}

          <Drawer
            // container={container}
            variant="temporary"
            open={open}
            onClose={handleClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              // display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                minWidth: "40vw",
              },
            }}
          >
            <List sx={{ pt: 5 }} className="standard-navbar-drawer">
              {navList?.map(({ label, link, submenu }, i) => (
                <Fragment key={i}>
                  {link && label ? (
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        to={link}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        {label}
                      </NavLink>
                    </MenuItem>
                  ) : submenu ? (
                    <>
                      <Box className="submenu">
                        <a>
                          {label}
                          <ExpandMoreIcon color="inherit" fontSize="small" />
                        </a>
                        <List>
                          {submenu?.map((sub, idx) => (
                            <MenuItem onClick={handleClose} key={idx}>
                              <Link to={sub?.link}>{sub?.label}</Link>
                            </MenuItem>
                          ))}
                        </List>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Fragment>
              ))}

              {isLoggedIn ? (
                <MenuItem onClick={handleClose}>
                  {user.role !== Role.AGENCY && user.role !== Role.BASIC ? (
                    <NavLink
                      to={
                        user.role === Role.ADMIN || user.role === Role.MANAGER
                          ? RouteConstant.ADMIN_DASHBOARD
                          : user.role === Role.STAFF
                          ? RouteConstant.STAFF_DASHBOARD
                          : RouteConstant.EMBASSY_DASHBOARD
                      }
                      className="dark-link"
                    >
                      Dashboard
                    </NavLink>
                  ) : null}
                </MenuItem>
              ) : null}
              {isLoggedIn && (
                <MenuItem>
                  <Button
                    color="inherit"
                    variant="outlined"
                    className="rounded-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </MenuItem>
              )}
              {!isLoggedIn && (
                <MenuItem onClick={handleClose}>
                  <NavLink to={RouteConstant.ADMIN_LOGIN}>
                    <Button
                      color="inherit"
                      variant="outlined"
                      className="rounded-2"
                      sx={{ mr: 2 }}
                    >
                      Admin login
                    </Button>
                  </NavLink>
                </MenuItem>
              )}
              {!isLoggedIn && (
                <MenuItem onClick={handleClose}>
                  <NavLink to={RouteConstant.LOGIN}>
                    <Button
                      disableElevation
                      variant="contained"
                      className="rounded-2"
                    >
                      User Login
                    </Button>
                  </NavLink>
                </MenuItem>
              )}
            </List>
          </Drawer>
        </div>
      </div>
    </section>
  );
};

export default StandardNavbar;
