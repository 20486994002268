import { Box, Grid } from "@mui/material";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { TextField } from "@mui/material";
const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minDate,
  fullInputWidth,
  fromLabel,
  toLabel,
  textProps,
  gridProps,
  gridItemProps,
  ...props
}) => {
  const DateInput = forwardRef(
    ({ value, onClick, label, placeholder }, ref) => (
      <TextField
        onClick={onClick}
        inputRef={ref}
        value={value}
        label={label}
        placeholder={placeholder}
        sx={{ ml: 1, mb: 1 }}
        fullWidth={fullInputWidth}
        {...textProps}
      />
    )
  );
  return (
    <Box {...props} display="flex" flexWrap="wrap">
      <Grid container spacing={2} {...gridProps}>
        <Grid item xs={12} md={6} {...gridItemProps}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            customInput={<DateInput label={fromLabel || "From date"} />}
            calendarClassName="custom-date-picker"
          />
        </Grid>
        <Grid item xs={12} md={6} {...gridItemProps}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={minDate || startDate}
            customInput={<DateInput label={toLabel || "To date"} />}
            calendarClassName="custom-date-picker"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
DateRangePicker.prototype = {
  startDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  minDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  fullInputWidth: PropTypes.bool,
  toLabel: PropTypes.string,
  fromLabel: PropTypes.string,
  textProps: PropTypes.object,
  gridProps: PropTypes.object,
  gridItemProps: PropTypes.object,
};
export default DateRangePicker;
