import RenderCellExpand from "component/grid/expandCell";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";

const EmbassyApplicationColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "applicationId",
    headerName: "Application Id",
    minWidth: 200,
    renderCell: RenderCellExpand,
  },

  {
    field: "fromCountry",
    minWidth: 150,
    headerName: "From Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "toCountry",
    minWidth: 150,
    headerName: "To Country",
    renderCell: RenderCellExpand,
  },
  {
    field: "passportNumber",
    minWidth: 200,
    headerName: "Passport",
    renderCell: RenderCellExpand,
  },
  {
    field: "passportType",
    minWidth: 150,
    headerName: "Passport Type",
    renderCell: RenderCellExpand,
  },
  {
    field: "nationalId",
    minWidth: 150,
    headerName: "National ID",
    renderCell: RenderCellExpand,
  },

  {
    field: "phone",
    headerName: "Phone",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 150,
    renderCell: RenderCellExpand,
  },

  {
    field: "detail",
    headerName: "All Details",
    renderCell: ({ value }) => (
      <Link to={RouteConstant.EMBASSY_APPLICATION_DETAILS + "/" + value}>
        Details
      </Link>
    ),
  },
];

export default EmbassyApplicationColumn;
