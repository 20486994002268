import RenderCellExpand from "component/grid/expandCell";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Chip, Avatar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DOMAIN } from "config/endpoints";
const ApplicationColumn = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
    renderCell: RenderCellExpand,
  },
  {
    field: "photo",
    headerName: "Image",
    renderCell: ({ value }) => {
      return (
        <Avatar
          alt="picture"
          src={DOMAIN + value}
          sx={{ width: 40, height: 40 }}
        />
      );
    },
    width: 100,
  },
  {
    field: "title",
    headerName: "Title",
    minWidth: 200,
    renderCell: RenderCellExpand,
  },

  {
    field: "isFeatured",
    minWidth: 200,
    headerName: "Featured",
    renderCell: ({ value }) => {
      return (
        <span>
          {value ? (
            <Chip size="small" label="Featured" color="primary" />
          ) : (
            "NA"
          )}
        </span>
      );
    },
  },
  {
    field: "isAd",
    minWidth: 150,
    headerName: "Advertise",
    renderCell: ({ value }) => {
      return (
        <span>
          {value ? (
            <Chip size="small" label="Advertise" color="success" />
          ) : (
            "NA"
          )}
        </span>
      );
    },
  },
  {
    field: "adFor",
    headerName: "Add for",
    minWidth: 100,
    renderCell: RenderCellExpand,
  },
  {
    field: "date",
    minWidth: 150,
    headerName: "Date",
    renderCell: ({ value }) => {
      return (
        <span>{value ? moment(new Date(value)).format("D MMM Y") : "NA"}</span>
      );
    },
  },

  {
    field: "detail",
    headerName: "Edit",
    renderCell: ({ value }) => (
      <Link to={value}>
        <Button endIcon={<EditIcon />}>Edit</Button>
      </Link>
    ),
  },
];

export default ApplicationColumn;
