import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import { useEffect, useState } from "react";
import { GetRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import RouteConstant from "navigation/constant";
import {
  GET_ALL_MANAGEMENT_USERS,
  GET_ALL_AGENCY_USERS,
  GET_ALL_BASIC_USERS,
} from "config/endpoints";
import UserTable from "component/grid/table/userTable";
const AllUsers = () => {
  return (
    <section className="admin-settings-page">
      <AdminNavbar />
      <AdminHeader hideSearch />
      <section className="wrapper container g-0">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          my={3}
        >
          <Typography variant="h4">All users</Typography>
          <Link to={RouteConstant.CREATE_USER}>
            <Button variant="contained">Create user</Button>
          </Link>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" mb={2}>
            Administrative
          </Typography>
          <Box>
            <UserTable URL={GET_ALL_MANAGEMENT_USERS} height={400} />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" mb={2}>
            All agencies
          </Typography>
          <Box>
            <UserTable URL={GET_ALL_AGENCY_USERS} height={400} />
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="h5" mb={2}>
            All basic users
          </Typography>
          <Box>
            <UserTable URL={GET_ALL_BASIC_USERS} height={400} />
          </Box>
        </Box>
      </section>
    </section>
  );
};

export default AllUsers;
