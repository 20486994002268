import { Typography, IconButton } from "@mui/material";
import Navbar from "parts/navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSelector } from "react-redux";
import VisaApplicationForm from "./component/applicationForm";
import VisaBulkApplicationForm from "./component/bulkForm";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const VisaFormFill = () => {
  const user = useSelector((data) => data.auth.user);
  const navigate = useNavigate();
  return (
    <section>
      <Helmet>
        <title>Apply visa </title>
        <meta
          name="description"
          content="Apply visa from Uganda. Get Your Visa Quickly and Hassle-Free with Our Reliable and Professional Visa Services. Apply individual and bulk applications"
        />
      </Helmet>
      <Navbar />
      <section className="wrapper container g-0">
        <IconButton sx={{ mb: 3 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon color="black" sx={{ fontSize: 32 }} />
        </IconButton>
        <Typography variant="h5" className="fm-pt-mono" mb={1}>
          Apply for a visa
        </Typography>
        <Typography color="grey.500">
          Find the information and begin your application
        </Typography>
        {user.role === "agency" ? (
          <VisaBulkApplicationForm />
        ) : (
          <VisaApplicationForm />
        )}
      </section>
    </section>
  );
};

export default VisaFormFill;
