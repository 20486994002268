export class ErrorHomeContactModel {
  name;
  emailPhone;
  serviceType;
  constructor(data = {}) {
    this.name = data.name || "";
    this.emailPhone = data.emailPhone || "";
    this.serviceType = data.serviceType || "";
  }
}
