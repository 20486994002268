import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input,
  Avatar,
  FormHelperText,
} from "@mui/material";
import VisApplyStepper from "./stepper";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ErrorIcon from "@mui/icons-material/Error";
import RouteConstant from "navigation/constant";
import { VisaApplicationModel } from "_models/data/data.visaApplication.model";
import { ErrorVisaApplicationModel } from "_models/error/error.visaApplication.model";
import { ValidateVisaApplication } from "functions/validators";
import { toast } from "react-toastify";
import { GetRequest, HTTP_ERROR } from "functions/http";
import { PostRequest } from "functions/http";
import { useToggleLoading } from "hooks";
import ApplicationTable from "./applicationTable";
import {
  APPLY_VISA,
  GET_BULK_APPLICATION,
  GET_VISA_APP_ID,
  UPDATE_VISA_BY_ID,
  GET_AGENCY_BY_USER,
  GET_BULK_APPLICATION_BY_AGENCY,
} from "config/endpoints";
import { Link, useSearchParams } from "react-router-dom";
import { setRefresh } from "state/slice/refresh";
import { useSelector, useDispatch } from "react-redux";
import useFile from "hooks/useFile";
import ReCAPTCHA from "react-google-recaptcha";

const VisaBulkApplicationForm = () => {
  const toggleLoading = useToggleLoading();
  const Dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.status);
  const [bulkProf, setBulkProf] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const availableFromCountry = useSelector((state) => state.countries.from);
  const availableToCountry = useSelector((state) => state.countries.to);
  const [state, setState] = useState(new VisaApplicationModel());
  const [error, setError] = useState(new ErrorVisaApplicationModel());
  const [saudiId, setSaudiId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [humanVerified, setHumanVerified] = useState(false);
  const fileValidation = useFile();
  const handleChange = (event, name) => {
    setState({ ...state, [name]: event.target.value });
  };
  const handlePicture = (event, name) => {
    const { isError, errorMsg } = fileValidation({
      fileTypes: ["png", "jpg", "jpeg", "PNG", "pdf"],
      maxSize: 1024 * 1024 * 1,
      file: event?.target?.files[0],
    });
    if (isError) {
      toast.error(errorMsg);
      return;
    }
    setError({ ...error, [name + "Error"]: "" });
    setState({ ...state, [name]: event?.target?.files[0] });
  };

  const handleFocus = (name) => {
    setError({ ...error, [name]: "" });
  };

  useEffect(() => {
    const toCountry = searchParams.get("to");
    const fromCountry = searchParams.get("from");
    if (toCountry) {
      const _value = availableToCountry.find((el) => el.code === toCountry);
      setState((state) => ({ ...state, toCountry: _value.id }));
    }
    if (fromCountry) {
      const _value = availableFromCountry.find((el) => el.code === fromCountry);
      setState((state) => ({ ...state, fromCountry: _value.id }));
    }
    const findSaudi = availableToCountry.find((el) => el.code === "SA");
    setSaudiId(findSaudi?.id);
  }, [searchParams, availableToCountry, availableFromCountry]);
  const editId = searchParams.get("edit");
  useEffect(() => {
    if (!editId) return;
    toggleLoading(true);
    GetRequest(GET_VISA_APP_ID(editId))
      .then((res) => {
        if (res?.data?.status) {
          const _data = res.data?.data;
          if (_data.confirmed) {
            toast.error("Unable to update");
            return;
          }
          console.log(_data);
          const DATA = {
            nameTitle: _data.nameTitle,
            name: _data.name,
            passportNumber: _data.passportNumber,
            nationalId: _data.nationalId,
            email: _data.email,
            phone: _data.phone,
            passportPic: _data?.passportPicture?.originalName,
            nationalIdPic: _data?.nationalIdPicture?.originalName,
            interpolCertificatePic:
              _data?.interpolCertificatePicture?.originalName,
            comment: _data.comment,
            passportType: _data.passportType,
            toCountry: _data.toCountryId,
            fromCountry: _data?.fromCountryId,
          };
          setState(new VisaApplicationModel(DATA));
        } else {
          // toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        // toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  }, [editId]);

  useEffect(() => {
    let fetchData = () => {
      toggleLoading(true);
      GetRequest(GET_AGENCY_BY_USER(user.id))
        .then((res) => {
          if (res?.data?.status) {
            return res.data?.data?._id;
          } else toast.error(res?.data?.msg);
        })
        .then((id) => {
          return GetRequest(GET_BULK_APPLICATION_BY_AGENCY(id));
        })
        .then((res) => {
          if (res?.data?.status) {
            setBulkProf(res.data?.data);
          }
          //  else toast.error(res?.data?.msg);
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    };
    fetchData();
    return () => (fetchData = null);
  }, [user.id, refresh]);

  const handleSubmit = () => {
    if (!editId && bulkProf?.applications?.length > 10) {
      toast.error("You can apply 10 application at once.");
      return;
    }
    const isValid = ValidateVisaApplication(state, saudiId);
    if (isValid.valid) {
      if (!humanVerified) {
        toast.error("Please verify that you are not a robot");
        return;
      }
      const data = isValid.data;
      const fileData = new FormData();
      fileData.append("applicationType", "business");
      fileData.append("user", user.id);
      fileData.append("fromCountry", data?.fromCountry);
      fileData.append("toCountry", data?.toCountry);
      fileData.append("nameTitle", data.nameTitle);
      fileData.append("name", data.name);
      fileData.append("phone", data.phone);
      fileData.append("email", data.email);
      fileData.append("nationalId", data.nationalId);
      fileData.append("passportNumber", data.passportNumber);
      fileData.append("passportType", data.passportType);
      fileData.append("comment", data.comment);
      fileData.append("bulkId", bulkProf._id);
      fileData.append("agency", bulkProf.agency);

      if (typeof data.passportPic === "object") {
        fileData.append("passportPic", data.passportPic);
      }
      if (typeof data.nationalIdPic === "object") {
        fileData.append("nationalIdPic", data.nationalIdPic);
      }
      if (typeof data.interpolCertificatePic === "object") {
        fileData.append("interpolCertificatePic", data.interpolCertificatePic);
      }
      if (typeof data.enjazPic === "object") {
        fileData.append("enjazPicture", data.enjazPic);
      }
      if (typeof data.medicalGccPic === "object") {
        fileData.append("medicalGccPicture", data.medicalGccPic);
      }
      if (typeof data.wakalaPic === "object") {
        fileData.append("wakalaPicture", data.wakalaPic);
      }
      if (typeof data.embassyFormPic === "object") {
        fileData.append("embassyFormPicture", data.embassyFormPic);
      }
      if (typeof data.contracPic === "object") {
        fileData.append("contractPicture", data.contracPic);
      }
      if (typeof data.yellowBookPic === "object") {
        fileData.append("yellowBookPicture", data.yellowBookPic);
      }
      toggleLoading(true);
      PostRequest(
        editId ? UPDATE_VISA_BY_ID(editId) : APPLY_VISA,
        fileData,
        true
      )
        .then((res) => {
          if (res?.data?.status) {
            Dispatch(setRefresh(!refresh));
            toast.success("Application updated");
            setSearchParams("");
            setState(new VisaApplicationModel());
            window?.grecaptcha?.reset();
            setHumanVerified(false);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch((er) => {
          toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => toggleLoading(false));
    } else {
      for (let i = 0, item; !!(item = isValid.errors[i++]); ) {
        setError((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };
  const handleCancelUpdate = () => {
    setSearchParams("");
    setState(new VisaApplicationModel());
  };
  const handleCaptcha = (value) => {
    if (value) {
      setHumanVerified(true);
    }
  };
  console.log("bulk", bulkProf);
  return (
    <Box>
      <Box>
        <VisApplyStepper step={0} />
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <FormControl fullWidth required error={error.nameTitleError !== ""}>
              <InputLabel id="name-title-label">Select</InputLabel>
              <Select
                labelId="name-title-label"
                id="name-simple-select"
                label="Select"
                onFocus={() => handleFocus("nameTitleError")}
                value={state.nameTitle}
                name="nameTitle"
                onChange={(ev) => handleChange(ev, "nameTitle")}
              >
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Ms"}>Ms</MenuItem>
                <MenuItem value={"Miss"}>Ms</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
              {error.nameTitleError && (
                <FormHelperText>{error.nameTitleError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              fullWidth
              label="Full name"
              placeholder="Enter your Full Name"
              variant="outlined"
              error={error.nameError !== ""}
              helperText={error.nameError}
              onFocus={() => handleFocus("nameError")}
              value={state.name}
              name="name"
              onChange={(ev) => handleChange(ev, "name")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              placeholder="Enter your Email"
              variant="outlined"
              error={error.emailError !== ""}
              helperText={error.emailError}
              onFocus={() => handleFocus("emailError")}
              value={state.email}
              name="email"
              onChange={(ev) => handleChange(ev, "email")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              fullWidth
              label="Phone"
              placeholder="Enter your Phone"
              variant="outlined"
              error={error.phoneError !== ""}
              helperText={error.phoneError}
              onFocus={() => handleFocus("phoneError")}
              value={state.phone}
              name="phone"
              onChange={(ev) => handleChange(ev, "phone")}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth error={error.fromCountryError !== ""}>
              <InputLabel id="from-country-label">From Country</InputLabel>
              <Select
                labelId="from-country-label"
                id="country-type-select"
                label="From Country"
                onFocus={() => handleFocus("fromCountryError")}
                value={state.fromCountry}
                name="fromCountry"
                onChange={(ev) => handleChange(ev, "fromCountry")}
              >
                {availableFromCountry.map((country) => {
                  return (
                    <MenuItem value={country.id} key={country.id}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      &nbsp;
                      {country.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {error.fromCountryError && (
              <FormHelperText sx={{ color: "error.main" }}>
                {error.fromCountryError}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth error={error.toCountryError !== ""}>
              <InputLabel id="to-country-label">To Country</InputLabel>
              <Select
                labelId="to-country-label"
                id="country-type-select"
                label="To Country"
                onFocus={() => handleFocus("toCountryError")}
                value={state.toCountry}
                name="fromCountry"
                onChange={(ev) => handleChange(ev, "toCountry")}
              >
                {availableToCountry.map((country) => {
                  return (
                    <MenuItem value={country.id} key={country.id}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      &nbsp;
                      {country.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {error.toCountryError && (
              <FormHelperText sx={{ color: "error.main" }}>
                {error.toCountryError}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              fullWidth
              label="National ID "
              placeholder="National ID "
              variant="outlined"
              error={error.nationalIdError !== ""}
              helperText={error.nationalIdError}
              onFocus={() => handleFocus("nationalIdError")}
              value={state.nationalId}
              name="nationalId"
              onChange={(ev) => handleChange(ev, "nationalId")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              fullWidth
              required
              label="Passport Number"
              placeholder="Passport Number"
              variant="outlined"
              error={error.passportNumberError !== ""}
              helperText={error.passportNumberError}
              onFocus={() => handleFocus("passportNumberError")}
              value={state.passportNumber}
              name="passportNumber"
              onChange={(ev) => handleChange(ev, "passportNumber")}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FormControl
              fullWidth
              required
              error={error.passportTypeError !== ""}
            >
              <InputLabel id="passport-type-label">Passport Type</InputLabel>
              <Select
                labelId="passport-type-label"
                id="passport-type-select"
                label="Select Passport Type"
                onFocus={() => handleFocus("passportTypeError")}
                value={state.passportType}
                name="passportType"
                onChange={(ev) => handleChange(ev, "passportType")}
              >
                <MenuItem value={"Ordinary"}>Ordinary</MenuItem>
                <MenuItem value={"Diplomatic"}>Diplomatic</MenuItem>
                <MenuItem value={"Official"}>Official</MenuItem>
              </Select>
              {error.passportTypeError && (
                <FormHelperText>{error.passportTypeError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box width={1}>
              <label htmlFor="passport-pic" style={{ width: "100%" }}>
                <Input
                  id="passport-pic"
                  type="file"
                  // inputProps={{
                  //   accept: "application/pdf,image/png, image/jpeg,image/jpg",
                  // }}
                  sx={{ display: "none" }}
                  name="passportPic"
                  onChange={(ev) => handlePicture(ev, "passportPic")}
                />
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  endIcon={<FileUploadOutlinedIcon />}
                  color={error.passportPicError ? "error" : "secondary"}
                  sx={{
                    justifyContent: "center",
                    py: 1.7,
                  }}
                >
                  <span className="text-ellipse w-250">
                    {typeof state.passportPic === "object"
                      ? state?.passportPic?.name
                      : state?.passportPic?.split("\\")?.pop() ||
                        "  Upload Passport"}
                  </span>
                </Button>
              </label>
              {error.passportPicError ? (
                <Typography variant="caption" color="error">
                  {error.passportPicError}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box width={1}>
              <label htmlFor="national-id-pic" style={{ width: "100%" }}>
                <Input
                  id="national-id-pic"
                  type="file"
                  sx={{ display: "none" }}
                  name="nationalIdPic"
                  onChange={(ev) => handlePicture(ev, "nationalIdPic")}
                />
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  endIcon={<FileUploadOutlinedIcon />}
                  color={error.nationalIdPicError ? "error" : "secondary"}
                  sx={{
                    justifyContent: "center",
                    py: 1.7,
                  }}
                >
                  <span className="text-ellipse w-250">
                    {typeof state.nationalIdPic === "object"
                      ? state?.nationalIdPic?.name
                      : state?.nationalIdPic?.split("\\")?.pop() ||
                        "  Upload National ID "}
                  </span>
                </Button>
              </label>
              {error.nationalIdPicError ? (
                <Typography variant="caption" color="error">
                  {error.nationalIdPicError}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Box width={1}>
              <label htmlFor="interpol-pic" style={{ width: "100%" }}>
                <Input
                  id="interpol-pic"
                  type="file"
                  sx={{ display: "none" }}
                  name="interpolCertificatePic"
                  onChange={(ev) => handlePicture(ev, "interpolCertificatePic")}
                />
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  endIcon={<FileUploadOutlinedIcon />}
                  color={
                    error.interpolCertificatePicError ? "error" : "secondary"
                  }
                  sx={{
                    justifyContent: "center",
                    py: 1.7,
                  }}
                >
                  <span className="text-ellipse w-250">
                    {typeof state.interpolCertificatePic === "object"
                      ? state.interpolCertificatePic?.name
                      : state.interpolCertificatePic?.split("\\")?.pop() ||
                        " Interpol Certificate "}
                  </span>
                </Button>
              </label>
              {error.interpolCertificatePicError ? (
                <Typography variant="caption" color="error">
                  {error.interpolCertificatePicError}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          {state.toCountry === saudiId ? (
            <>
              <Grid item xs={12} md={4} lg={3}>
                <Box width={1}>
                  <label htmlFor="enjaz-doc" style={{ width: "100%" }}>
                    <Input
                      id="enjaz-doc"
                      type="file"
                      sx={{ display: "none" }}
                      name="enjazPic"
                      onChange={(ev) => handlePicture(ev, "enjazPic")}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      endIcon={<FileUploadOutlinedIcon />}
                      color={error.enjazPicError ? "error" : "secondary"}
                      sx={{
                        justifyContent: "center",
                        py: 1.7,
                      }}
                    >
                      <span className="text-ellipse w-250">
                        {typeof state.enjazPic === "object"
                          ? state.enjazPic?.name
                          : " Enjaz Certificate "}
                      </span>
                    </Button>
                  </label>
                  {error.enjazPicError ? (
                    <Typography variant="caption" color="error">
                      {error.enjazPicError}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box width={1}>
                  <label htmlFor="medical-gcc-pic" style={{ width: "100%" }}>
                    <Input
                      id="medical-gcc-pic"
                      type="file"
                      sx={{ display: "none" }}
                      name="medicalGccPic"
                      onChange={(ev) => handlePicture(ev, "medicalGccPic")}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      endIcon={<FileUploadOutlinedIcon />}
                      color={error.medicalGccPicError ? "error" : "secondary"}
                      sx={{
                        justifyContent: "center",
                        py: 1.7,
                      }}
                    >
                      <span className="text-ellipse w-250">
                        {typeof state.medicalGccPic === "object"
                          ? state.medicalGccPic?.name
                          : " Medical GCC"}
                      </span>
                    </Button>
                  </label>
                  {error.medicalGccPicError ? (
                    <Typography variant="caption" color="error">
                      {error.medicalGccPicError}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box width={1}>
                  <label htmlFor="wakala-pic" style={{ width: "100%" }}>
                    <Input
                      id="wakala-pic"
                      type="file"
                      sx={{ display: "none" }}
                      name="wakalaPic"
                      onChange={(ev) => handlePicture(ev, "wakalaPic")}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      endIcon={<FileUploadOutlinedIcon />}
                      color={error.wakalaPicError ? "error" : "secondary"}
                      sx={{
                        justifyContent: "center",
                        py: 1.7,
                      }}
                    >
                      <span className="text-ellipse w-250">
                        {typeof state.wakalaPic === "object"
                          ? state.wakalaPic?.name
                          : " Wakala"}
                      </span>
                    </Button>
                  </label>
                  {error.wakalaPicError ? (
                    <Typography variant="caption" color="error">
                      {error.wakalaPicError}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box width={1}>
                  <label htmlFor="embassyForm-pic" style={{ width: "100%" }}>
                    <Input
                      id="embassyForm-pic"
                      type="file"
                      sx={{ display: "none" }}
                      name="embassyFormPic"
                      onChange={(ev) => handlePicture(ev, "embassyFormPic")}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      endIcon={<FileUploadOutlinedIcon />}
                      color={error.embassyFormPicError ? "error" : "secondary"}
                      sx={{
                        justifyContent: "center",
                        py: 1.7,
                      }}
                    >
                      <span className="text-ellipse w-250">
                        {typeof state.embassyFormPic === "object"
                          ? state.embassyFormPic?.name
                          : " Embassy Form"}
                      </span>
                    </Button>
                  </label>
                  {error.embassyFormPicError ? (
                    <Typography variant="caption" color="error">
                      {error.embassyFormPicError}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box width={1}>
                  <label htmlFor="contrac-pic" style={{ width: "100%" }}>
                    <Input
                      id="contrac-pic"
                      type="file"
                      sx={{ display: "none" }}
                      name="contracPic"
                      onChange={(ev) => handlePicture(ev, "contracPic")}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      endIcon={<FileUploadOutlinedIcon />}
                      color={error.contracPicError ? "error" : "secondary"}
                      sx={{
                        justifyContent: "center",
                        py: 1.7,
                      }}
                    >
                      <span className="text-ellipse w-250">
                        {typeof state.contracPic === "object"
                          ? state.contracPic?.name
                          : "Contract"}
                      </span>
                    </Button>
                  </label>
                  {error.contracPicError ? (
                    <Typography variant="caption" color="error">
                      {error.contracPicError}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Box width={1}>
                  <label htmlFor="yellowBook-pic" style={{ width: "100%" }}>
                    <Input
                      id="yellowBook-pic"
                      type="file"
                      sx={{ display: "none" }}
                      name="yellowBookPic"
                      onChange={(ev) => handlePicture(ev, "yellowBookPic")}
                    />
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      endIcon={<FileUploadOutlinedIcon />}
                      color={error.yellowBookPicError ? "error" : "secondary"}
                      sx={{
                        justifyContent: "center",
                        py: 1.7,
                      }}
                    >
                      <span className="text-ellipse w-250">
                        {typeof state.yellowBookPic === "object"
                          ? state.yellowBookPic?.name
                          : "Yellow Book"}
                      </span>
                    </Button>
                  </label>
                  {error.yellowBookPicError ? (
                    <Typography variant="caption" color="error">
                      {error.yellowBookPicError}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Comment"
              multiline
              rows={5}
              fullWidth
              variant="outlined"
              value={state.comment}
              name="comment"
              onChange={(ev) => handleChange(ev, "comment")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Box mb={3}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE}
                onChange={handleCaptcha}
              />
            </Box>
            <Box>
              {editId && (
                <Button
                  sx={{ my: 4, mr: 4 }}
                  size="large"
                  onClick={handleCancelUpdate}
                  disabled={bulkProf == null}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                sx={{ my: 4 }}
                size="large"
                onClick={handleSubmit}
                disabled={
                  bulkProf == null ||
                  (bulkProf?.applications?.length > 10 && !editId)
                }
              >
                {editId ? "Update" : "Add"}
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="caption" fontStyle="italic" color="warning.main">
            * You can apply 10 applications at once.
          </Typography>
          <br />
          <Typography variant="caption" fontStyle="italic" color="warning.main">
            ** File size must be under 1MB. Valid file size is : jpg, png, jpeg,
            pdf
          </Typography>
        </Grid>
        <Box my={4}>
          {bulkProf != null ? (
            <ApplicationTable
              URL={GET_BULK_APPLICATION(bulkProf.agency) + "?"}
            />
          ) : (
            <Typography textAlign={"center"} variant="h4" my={4}>
              No Application Data found
            </Typography>
          )}
        </Box>
        <Box className="p-center">
          {bulkProf?.applications?.length > 0 ? (
            <Link
              to={RouteConstant.VISA_SET_BULK_APPOINTMENT + "/" + bulkProf._id}
            >
              <Button
                variant="contained"
                sx={{ my: 4 }}
                endIcon={<ArrowOutwardIcon />}
                size="large"
                onClick={handleSubmit}
                disabled={bulkProf == null}
              >
                Submit All Application
              </Button>
            </Link>
          ) : null}
        </Box>
        <Card sx={{ width: 300 }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Avatar>
                <ErrorIcon color="secondary" />
              </Avatar>
              <Typography ml={2}>
                <span className="fw-bold">Note</span>
                Document must be PDF,JPG or PNG file. Maximum file size must be
                under 1mb
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default VisaBulkApplicationForm;
