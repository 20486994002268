import { PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PAYMENT_CHECKOUT } from "config/endpoints";

const Checkout = () => {
  const user = useSelector((state) => state.auth.user);
  const toggleLoading = useToggleLoading();
  const navigate = useNavigate();
  const handleSubmit = () => {
    toggleLoading(true);
    const data = {
      paymentId: "63efdabb3dd9edbe5268a9cd",
      phone: "8346976424",
      currency: "UGX",
    };
    PostRequest(PAYMENT_CHECKOUT, data)
      .then((res) => {
        if (res) {
          console.log(res);
          toast.success("Message sent.");
          window.location.href = res.data;
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((er) => {
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };

  return (
    <section className="checkout-page">
      <button onClick={handleSubmit}>submit</button>
    </section>
  );
};

export default Checkout;
