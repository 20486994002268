import { useState, useEffect } from "react";
import { GetRequest } from "functions/http";
import { useSelector } from "react-redux";
const useQuery = (page, pageSize, url) => {
  const [rowCount, setRowCount] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const refresh = useSelector((data) => data.refresh.status);

  useEffect(() => {
    let active = true;
    setRowCount(undefined);
    let fetchData = async () => {
      if (!active) {
        return;
      }
      setIsLoading(true);
      await GetRequest(`${url}&page=${page + 1}`)
        .then((res) => {
          if (res?.data?.status) {
            setData(res?.data?.data);
          } else {
            setData([]);
          }
          setRowCount(res?.data.count);
        })
        .catch((er) => {
          // toast.error(HTTP_ERROR(er) || "Something went wrong.");
        })
        .finally(() => setIsLoading(false));
    };
    fetchData();
    return () => {
      fetchData = null;
      active = false;
    };
  }, [page, pageSize, refresh, url]);
  return { isLoading, data, rowCount };
};
export default useQuery;
