import AdminNavbar from "parts/navbar/adminNavbar";
import AdminHeader from "parts/navbar/adminHeader";
import SupportTable from "component/grid/table/supportTable";
import {
  GET_ALL_CONTACT,
  CONTACT_GET_BY_ID,
  CONTACT_CHANGE_STATUS,
  GET_ALL_VISA_ASSISTANT_APPLICATION,
  VISA_ASSISTANT_APP_GET_BY_ID,
  VISA_ASSISTANT_APP_CHANGE_STATUS,
} from "config/endpoints";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetRequest, PostRequest } from "functions/http";
import { toast } from "react-toastify";
import { HTTP_ERROR } from "functions/http";
import { useToggleLoading } from "hooks";
import { useState } from "react";
import VisaAssistantAppTable from "component/grid/table/assistantApplication";
import Countries from "state/data/countries";
const Support = () => {
  const toggleLoading = useToggleLoading();
  const [searchKey, setSearchKey] = useState("");
  const [status, setStatus] = useState("");
  const [active, setActive] = useState("");

  const [assistantStatus, setAssistantStatus] = useState("");
  const [activeAssistant, setActiveAssistant] = useState("");

  const handleCellClick = (id) => {
    toggleLoading(true);
    GetRequest(CONTACT_GET_BY_ID(id))
      .then((res) => {
        if (res.data?.status) {
          setActive(res.data.data);
        } else {
          toast.error(res.data.msg);
          setActive(null);
        }
      })
      .catch((er) => {
        setActive(null);
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  const handleResolve = (ev) => {
    PostRequest(CONTACT_CHANGE_STATUS(active?._id), {
      status: ev.target.checked,
    })
      .then((res) => {
        if (res.data?.status) {
          toast.success("Status updated.");
          window.location.reload();
        } else {
          toast.error(res.data.msg);
          setActive(null);
        }
      })
      .catch((er) => {
        setActive(null);
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  const handleAppCellClick = (id) => {
    toggleLoading(true);
    GetRequest(VISA_ASSISTANT_APP_GET_BY_ID(id))
      .then((res) => {
        if (res.data?.status) {
          setActiveAssistant(res.data.data);
        } else {
          toast.error(res.data.msg);
          setActiveAssistant(null);
        }
      })
      .catch((er) => {
        setActiveAssistant(null);
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  const handleVisaAppResolve = (ev) => {
    PostRequest(VISA_ASSISTANT_APP_CHANGE_STATUS(activeAssistant?._id), {
      status: ev.target.checked,
    })
      .then((res) => {
        if (res.data?.status) {
          toast.success("Status updated.");
          window.location.reload();
        } else {
          toast.error(res.data.msg);
          setActive(null);
        }
      })
      .catch((er) => {
        setActive(null);
        toast.error(HTTP_ERROR(er) || "Something went wrong.");
      })
      .finally(() => toggleLoading(false));
  };
  return (
    <section className="admin-dashboard-page">
      <AdminNavbar />
      <AdminHeader onSearchChange={(value) => setSearchKey(value)} />
      <section className="wrapper container g-0 content-height">
        <Box>
          {activeAssistant ? (
            <Box
              my={3}
              sx={{
                bgcolor: "grey.200",
                borderRadius: 4,
                p: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Name </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {activeAssistant.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Email </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {activeAssistant.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Phone </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {activeAssistant.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Visa Type </Typography>
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {activeAssistant.visaType}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Nationality </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {Countries.find(
                      (e) => e.code === activeAssistant?.nationality
                    )?.label || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2">Preferred Destination</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {activeAssistant.preferredDestination}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2">Reason</Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {activeAssistant.reason}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2"> Mark as resolved </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <FormControlLabel
                      control={<Checkbox value={activeAssistant.isSolve} />}
                      label="Resolved"
                      onChange={handleVisaAppResolve}
                      value={activeAssistant.isSolve}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="space-between" mt={6} mb={2}>
            <Typography variant="h5">
              All Visa assistant applications
            </Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="visa-assistant">Status</InputLabel>
                <Select
                  labelId="visa-assistant"
                  value={assistantStatus}
                  label="Status"
                  onChange={(ev) => setAssistantStatus(ev.target.value)}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={false}>Active</MenuItem>
                  <MenuItem value={true}>Resolved</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <VisaAssistantAppTable
              URL={
                GET_ALL_VISA_ASSISTANT_APPLICATION +
                `?searchKey=${searchKey}&status=${assistantStatus}`
              }
              onDetailClick={handleAppCellClick}
            />
          </Box>
        </Box>
        <Box>
          {active ? (
            <Box
              my={3}
              sx={{
                bgcolor: "grey.200",
                borderRadius: 4,
                p: 3,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Name </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {active.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Email </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {active.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Phone </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {active.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Passport </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {active.passport}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2"> Message </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {active.message}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Typography variant="body2"> Mark as resolved </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                    <FormControlLabel
                      control={<Checkbox value={active.isSolve} />}
                      label="Resolved"
                      onChange={handleResolve}
                      value={active.isSolve}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="space-between" my={3}>
            <Typography variant="h5">All contact by users</Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="all-contact-label">Status</InputLabel>
                <Select
                  labelId="all-contact-label"
                  id="all-contact"
                  value={status}
                  label="Status"
                  onChange={(ev) => setStatus(ev.target.value)}
                >
                  <MenuItem value={""}>All</MenuItem>
                  <MenuItem value={false}>Active</MenuItem>
                  <MenuItem value={true}>Resolved</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <SupportTable
              URL={GET_ALL_CONTACT + `?searchKey=${searchKey}&status=${status}`}
              onDetailClick={handleCellClick}
            />
          </Box>
        </Box>
      </section>
    </section>
  );
};

export default Support;
