import { Box, Button } from "@mui/material";

import RouteConstant from "navigation/constant";

import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StandardNavbar from "parts/navbar/standardNavbar";
const HomeHeader = () => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className=" home-header">
      <StandardNavbar transparent />

      <Box className="home-header-content container" pt={10} pb={10}>
        <Box className="p- px-3 px-lg-0" flexDirection="column">
          <h5 className="mt-5 text-white">Welcome to ExpressVFS -</h5>
          <h1 className="homeHeading mt-2">
            Your One-Stop Solution <br /> for Visa, Passport, and <br />{" "}
            Ticketing Services.
          </h1>
          <Box display="flex" mt={4} alignItems="center">
            <Link to={RouteConstant.APPLY_VISA}>
              <Button
                variant="contained"
                disableElevation
                className="rounded-2"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 400,
                  my: 1,
                  mr: { xs: 1, sm: 3 },
                }}
              >
                Apply for visa
              </Button>
            </Link>
            <Link to={RouteConstant.TRACK_APPLICATION}>
              <Button
                variant="contained"
                disableElevation
                className="rounded-2"
                color="secondary"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 400,
                  my: 1,
                  backgroundColor: "#1A1A1A4D",
                  "&:hover": {
                    backgroundColor: "#1A1A1A4D",
                  },
                }}
              >
                Track My Visa
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default HomeHeader;
