import AdminNavbar from "parts/navbar/adminNavbar";
import { Box, Typography, Button } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Link } from "react-router-dom";
import { GET_ALL_BLOG_BY_SEARCH } from "config/endpoints";
import BlogAndNewsTable from "component/grid/table/blogAndNews";
import RouteConstant from "navigation/constant";

const AllBlogs = () => {
  return (
    <section className="admin-create-blog-page">
      <AdminNavbar />
      <section className="wrapper container g-0">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="h5" mb={3}>
            All Blogs
          </Typography>
          <Link to={RouteConstant.CREATE_BLOG}>
            <Button
              variant="contained"
              color="success"
              endIcon={<AddCircleOutlineOutlinedIcon />}
            >
              Create
            </Button>
          </Link>
        </Box>
        <Box>
          <BlogAndNewsTable URL={GET_ALL_BLOG_BY_SEARCH} />
        </Box>
      </section>
    </section>
  );
};

export default AllBlogs;
