export class VisaApplicationModel {
  fromCountry;
  toCountry;
  nameTitle;
  name;
  passportNumber;
  passportType;
  nationalId;
  email;
  phone;
  passportPic;
  nationalIdPic;
  interpolCertificatePic;
  enjazPic;
  medicalGccPic;
  wakalaPic;
  embassyFormPic;
  contracPic;
  yellowBookPic;
  comment;
  constructor(data = {}) {
    this.fromCountry = data.fromCountry || "";
    this.toCountry = data.toCountry || "";
    this.nameTitle = data.nameTitle || "";
    this.name = data.name || "";
    this.phone = data.phone || "";
    this.email = data.email || "";
    this.nationalId = data.nationalId || "";
    this.passportNumber = data.passportNumber || "";
    this.passportType = data.passportType || "";
    this.passportPic = data.passportPic || "";
    this.nationalIdPic = data.nationalIdPic || "";
    this.interpolCertificatePic = data.interpolCertificatePic || "";
    this.comment = data.comment || "";

    this.enjazPic = data.enjazPic || "";
    this.medicalGccPic = data.medicalGccPic || "";
    this.wakalaPic = data.wakalaPic || "";
    this.embassyFormPic = data.embassyFormPic || "";
    this.contracPic = data.contracPic || "";
    this.yellowBookPic = data.yellowBookPic || "";
  }
}
